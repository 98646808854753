import type { AstroCalendarConfig, AstroCalendarNotificationConfig } from '../interfaces';

export * from './events';
export * from './todos';
export * from './bonuses';

export enum ASTRO_CALENDAR_SECTION_TYPE {
  ASTRO_EVENTS = 'astroEvents',
  TODOS = 'todos',
  BONUSES = 'bonuses',
}

export const ASTRO_CALENDAR_SECTIONS: ASTRO_CALENDAR_SECTION_TYPE[] = [
  ASTRO_CALENDAR_SECTION_TYPE.ASTRO_EVENTS,
  ASTRO_CALENDAR_SECTION_TYPE.TODOS,
  ASTRO_CALENDAR_SECTION_TYPE.BONUSES,
];

export const ASTRO_CALENDAR_PLACE = 'astro_calendar';

export enum ASTRO_CALENDAR_ORIGIN {
  NOTIFICATION = 'bonus_notification',
  ASTRO_CALENDAR_WIDGET = 'astro_calendar_widget',
  ASTRO_PLAN_WIDGET = 'astro_plan_widget',
  RETURN_BACK = 'return_back',
}

export const defaultAstroCalendarConfig: AstroCalendarConfig = {};

export const defaultAstroCalendarNotificationConfig: AstroCalendarNotificationConfig = {
  enabled: true,
  displayTimeout: 3,
  sessionStart: 1,
};
