import React, { PureComponent } from 'react';
import { Image, Linking, StyleSheet, View } from 'react-native';
import Modal from 'react-native-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Analytics from '../../../analytics';
import { t } from 'localization';
import { closeCurrentModal } from '../../../store/modals/actions';
import Touchable from '../../../reusable/touchable';
import Button from '../../../components/button';
import * as COLORS from '../../../constants/colors';
import * as FONTS from '../../../constants/fonts';
import * as MODALS from '../../../constants/modals';
import { ACTIONS } from '../../../constants/analytics';
import Text from 'components/text';

import cross from '../images/cross.png';
import star from './images/star.png';

class FeedbackModal extends PureComponent {
  static propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onModalHide: PropTypes.func.isRequired,
    url: PropTypes.string,
    name: PropTypes.string,
  };

  componentDidUpdate(prev) {
    if (this.props.isVisible && !prev.isVisible) {
      Analytics.trackEvent('UserFeedback_PopUp', ACTIONS.OPEN);
    }
  }

  handleStartPress = () => {
    const { url } = this.props;

    if (url) {
      Linking.openURL(url);
    }
  };

  handleCancelPress = () => {
    const { onModalHide } = this.props;

    onModalHide();
  };

  handleClosePress = () => {
    const { onModalHide } = this.props;

    Analytics.trackEvent('UserFeedback_Close', ACTIONS.CLICK);
    onModalHide();
  };

  render() {
    const { isVisible, name } = this.props;

    return (
      <Modal style={styles.bottomModal} isVisible={isVisible} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND} animationInTiming={500}>
        <View style={styles.container}>
          <Text style={styles.title} font="Philosopher">
            {t('MODALS.FEEDBACK.TITLE', { name })}
          </Text>
          <Text style={styles.subtitle}>
            {t('MODALS.FEEDBACK.SUBTITLE')}
            <Image source={star} />
          </Text>
          <View style={styles.buttonContainer}>
            <Button
              style={styles.button}
              title={t('MODALS.FEEDBACK.START_BTN')}
              titleStyle={[styles.commonButtonTitle, styles.primaryButtonTitle]}
              onPress={this.handleStartPress}
            />
            <Button
              isTransparent={true}
              style={[styles.button, styles.transparentButton]}
              title={t('MODALS.FEEDBACK.NOT_BTN')}
              titleStyle={[styles.commonButtonTitle, styles.notButtonTitle]}
              onPress={this.handleCancelPress}
            />
          </View>
          <Touchable style={styles.cross} onPress={this.handleClosePress}>
            <Image source={cross} />
          </Touchable>
        </View>
      </Modal>
    );
  }
}

export default connect(
  state => ({
    isVisible: state.modals.activeModal === MODALS.FEEDBACK,
    name: state.profile?.profileData?.name,
    url: state.remoteConfig.remoteConfigParams.feedbackUrl,
  }),
  dispatch => ({
    onModalHide: () => dispatch(closeCurrentModal()),
  }),
)(FeedbackModal);

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingHorizontal: 50,
    alignItems: 'center',
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    backgroundColor: COLORS.MODAL_BG_COLOR,
  },
  title: {
    marginTop: 50,
    fontSize: 24,
    fontWeight: '600',
    color: COLORS.WHITE,
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 10,
    fontSize: 15,
    fontFamily: FONTS.OpenSans,
    color: COLORS.WHITE,
    opacity: 0.8,
    textAlign: 'center',
  },
  buttonContainer: {
    marginTop: 30,
    marginBottom: 10,
    width: '100%',
  },
  commonButtonTitle: {
    fontSize: 16,
    color: COLORS.WHITE,
    textAlign: 'center',
  },
  primaryButtonTitle: {
    fontFamily: FONTS.OpenSans_Semibold,
    fontWeight: '600',
  },
  notButtonTitle: {
    fontFamily: FONTS.OpenSans,
    fontWeight: '500',
    opacity: 0.9,
  },
  button: {
    alignSelf: 'center',
    marginBottom: 12,
  },
  transparentButton: {
    borderWidth: 1,
    borderColor: COLORS.TEALISH,
  },
  cross: {
    position: 'absolute',
    top: 20,
    right: 16,
  },
});
