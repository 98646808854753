import { useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store';
import { onAppStateChanged } from 'store/app/actions';

const useAppStateChange = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const listener = AppState.addEventListener('change', (nextState: AppStateStatus) => {
      dispatch(onAppStateChanged(nextState));
    });

    return () => {
      listener.remove();
    };
  }, []);
};

export default useAppStateChange;
