import { ImageSourcePropType } from 'react-native';

import { BROWSERS_NAMES, BROWSERS_TYPES } from 'constants/pwa';
import { isIos, isSafariBrowser, isSamsungBrowser } from 'utils/pwa';

import {
  INSTRUCTION_CHROME_ANDROID_GIFS,
  INSTRUCTION_CHROME_IOS_GIFS,
  INSTRUCTION_IMAGES,
  INSTRUCTION_SAFARI_GIFS,
  INSTRUCTION_SAMSUNG_GIFS,
} from './constants';

export const getBrowserType = (browserName: BROWSERS_NAMES): BROWSERS_TYPES => {
  if (isIos()) {
    if (isSafariBrowser(browserName)) {
      return BROWSERS_TYPES.IOS_SAFARI;
    } else if (browserName === BROWSERS_NAMES.CHROME) {
      return BROWSERS_TYPES.IOS_CHROME;
    }
    return BROWSERS_TYPES.IOS_OTHERS;
  } else {
    if (isSamsungBrowser()) {
      return BROWSERS_TYPES.ANDROID_SAMSUNG;
    } else if (browserName === BROWSERS_NAMES.CHROME) {
      return BROWSERS_TYPES.ANDROID_CHROME;
    }
    return BROWSERS_TYPES.ANDROID_OTHERS;
  }
};

export const getInstructionImage = (browserType: BROWSERS_TYPES): ImageSourcePropType => {
  return INSTRUCTION_IMAGES[browserType] ?? INSTRUCTION_IMAGES[BROWSERS_TYPES.IOS_OTHERS];
};

export const getInstructionGif = (browserType: BROWSERS_TYPES, locale: string): ImageSourcePropType => {
  switch (browserType) {
    case BROWSERS_TYPES.IOS_SAFARI: {
      return INSTRUCTION_SAFARI_GIFS[locale] || INSTRUCTION_SAFARI_GIFS.default;
    }
    case BROWSERS_TYPES.IOS_CHROME: {
      return INSTRUCTION_CHROME_IOS_GIFS[locale] || INSTRUCTION_CHROME_IOS_GIFS.default;
    }
    case BROWSERS_TYPES.ANDROID_CHROME: {
      return INSTRUCTION_CHROME_ANDROID_GIFS[locale] || INSTRUCTION_CHROME_ANDROID_GIFS.default;
    }
    case BROWSERS_TYPES.ANDROID_SAMSUNG: {
      return INSTRUCTION_SAMSUNG_GIFS[locale] || INSTRUCTION_SAMSUNG_GIFS.default;
    }
    default: {
      return getInstructionImage(browserType);
    }
  }
};
