import dayjs from 'dayjs';

import { t2 } from 'localization';

export const getLocalizedSign = (sign?: string): string => {
  if (!sign?.length) {
    return '';
  }
  return t2(`SINGS.ZODIAC.${sign?.toUpperCase()}`);
};

// Capricorn (December 22 – January 19)      [0] - 19
// Aquarius (January 20 – February 18)       [1] - 18
// Pisces (February 19 – March 20)           [2] - 20
// Aries (March 21 – April 19)               [3] - 19
// Taurus (April 20 – May 20)                [4] - 20
// Gemini (May 21 – June 21)                 [5] - 21
// Cancer (June 22 – July 22)                [6] - 22
// Leo (July 23 – August 22)                 [7] - 22
// Virgo (August 23 – September 22)          [8] - 22
// Libra (September 23 – October 22)         [9] - 22
// Scorpio (October 23 – November 22)        [10] - 22
// Sagittarius (November 23 – December 21)   [11] - 21

const bound = [19, 18, 20, 19, 20, 21, 22, 22, 22, 22, 22, 21];

const signs = ['Capricorn', 'Aquarius', 'Pisces', 'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius'];

export const getZodiac = (month: number, day: number, noLocalize = false) => {
  const monthIndex = month - 1; //so we can use zero indexed arrays
  let signMonthIndex = 0;
  if (day <= bound[monthIndex]) {
    //it's start of month -- before or equal to bound date
    signMonthIndex = monthIndex;
  } else {
    //it must be later than bound, we use the next month's startMonth
    signMonthIndex = (monthIndex + 1) % 12; //mod 12 to loop around to January index.
  }

  if (noLocalize) {
    return signs[signMonthIndex];
  }

  return getLocalizedSign(signs[signMonthIndex]); //return the Zodiac sign of start Of that month.
};

export const getZodiacDatesBySign = (sign: string) => {
  const index = signs.findIndex(s => s.toLowerCase() === sign.toLowerCase());
  const startIndex = index - 1 < 0 ? bound.length - 1 : index - 1;
  const startDate = bound[startIndex] + 1;
  const endIndex = index + 1 >= bound.length ? 0 : index + 1;
  const endDate = bound[index];

  const startMonth = dayjs()
    .month(index - 1)
    .format('MMM');
  const endMonth = dayjs()
    .month(endIndex - 1)
    .format('MMM');

  return `${startDate} ${startMonth} - ${endDate} ${endMonth}`;
};
