import { Astrologist } from 'api/astrology-chat/constants';
import { AdvisorNotification } from 'api/astrologers/interfaces';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components/const';

import { ResetUserData } from '../types';

export type AstrologerNotification = {
  astrologer: Astrologist;
  notification_id: AdvisorNotification['notification_id'];
  message_id: AdvisorNotification['message_id'];
  message: string;
  category: AdvisorNotification['category'];
};

type LastContextId = AdvisorNotification['notification_id'] | null;

export type TriggerNotification = {
  type: EXTERNAL_TRIGGER_NOTIFICATIONS;
  seconds?: number;
};

export interface State {
  notification: AstrologerNotification | null;
  isPressed: boolean;
  lastContextId: LastContextId;
  randomCounter: number;
  triggerNotifications: TriggerNotification[];
  isPromocodeAddedInPwa: boolean;
}

export enum TYPES {
  SET = 'ASTROLOGERS/NOTIFICATION/SET',
  SET_PRESSED = 'ASTROLOGERS/NOTIFICATION/SET_PRESSED',
  SET_LAST_CONTEXT_ID = 'ASTROLOGERS/NOTIFICATION/SET_LAST_CONTEXT_ID',
  INC_RANDOM_COUNTER = 'ASTROLOGERS/NOTIFICATION/INC_RANDOM_COUNTER',
  RESET_RANDOM_COUNTER = 'ASTROLOGERS/NOTIFICATION/RESET_RANDOM_COUNTER',
  SET_EXTERNAL_TRIGGER_NOTIFICATION = 'ASTROLOGERS/SET_EXTERNAL_TRIGGER_NOTIFICATION',
  SET_PROMOCODE_ADDED_IN_PWA = 'ASTROLOGERS/SET_PROMOCODE_ADDED_IN_PWA',
}

interface SetNotification {
  type: typeof TYPES.SET;
  payload: AstrologerNotification | null;
}

interface SetPressed {
  type: typeof TYPES.SET_PRESSED;
  payload: boolean;
}

interface SetLastContextId {
  type: typeof TYPES.SET_LAST_CONTEXT_ID;
  payload: LastContextId;
}

interface IncRandomCounter {
  type: typeof TYPES.INC_RANDOM_COUNTER;
}

interface ResetRandomCounter {
  type: typeof TYPES.RESET_RANDOM_COUNTER;
}

interface SetExternalTriggerNotification {
  type: typeof TYPES.SET_EXTERNAL_TRIGGER_NOTIFICATION;
  payload: TriggerNotification[];
}

interface SetPromocodeAddedInPwa {
  type: typeof TYPES.SET_PROMOCODE_ADDED_IN_PWA;
  payload: boolean;
}

export type ActionType =
  | SetNotification
  | SetPressed
  | SetLastContextId
  | IncRandomCounter
  | ResetRandomCounter
  | ResetUserData
  | SetExternalTriggerNotification
  | SetPromocodeAddedInPwa;
