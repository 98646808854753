import React, { ReactNode } from 'react';
import { ViewStyle } from 'react-native';
import Animated, {
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
  interpolate,
  Extrapolate,
} from 'react-native-reanimated';

type Props = {
  visible: boolean;
  duration?: number;
  style?: ViewStyle;
  children?: ReactNode | ReactNode[];
};

const FadeInOutView = ({ style, visible, children, duration = 300, ...rest }: Props) => {
  const progress = useDerivedValue(() => {
    return !visible ? withTiming(0, { duration }) : withTiming(1, { duration });
  });

  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(progress.value, [0, 1], [100, 0], Extrapolate.CLAMP);
    const opacity = interpolate(progress.value, [0, 1], [0, 1], Extrapolate.CLAMP);

    return {
      opacity,
      transform: [{ translateY }],
    };
  });

  return (
    <Animated.View style={[style, animatedStyle]} {...rest}>
      {children}
    </Animated.View>
  );
};

export default FadeInOutView;
