import React, {PureComponent} from 'react';
import {Pressable} from 'react-native';
import PropTypes from 'prop-types';
import _ from 'lodash';

export default class Touchable extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    withoutFeedback: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  };

  styling = styleProp => ({pressed}) => {
    let mergedStyle = styleProp;

    if (_.isArray(styleProp)) {
      mergedStyle = styleProp.reduce(
        (accumulator, element) => ({...accumulator, ...element}),
        {},
      );
    }

    return {
      opacity: pressed ? 0.7 : 1,
      ...mergedStyle,
    };
  };

  render() {
    const {children, style, withoutFeedback, ...other} = this.props;

    return (
      <Pressable style={withoutFeedback ? style : this.styling(style)} {...other}>
        {children}
      </Pressable>
    );
  }
}
