import { Platform } from 'react-native';
import RNHapticFeedback, { HapticFeedbackTypes } from 'react-native-haptic-feedback';

export const SELECTION = 'selection';
export const IMPACT_LIGHT = 'impactLight';
export const IMPACT_MEDIUM = 'impactMedium';
export const IMPACT_HEAVY = 'impactHeavy';
export const EFFECT_TICK = 'effectTick';
export const EFFECT_CLICK = 'effectClick';
export const SOFT = 'soft';

const options = {
  enableVibrateFallback: true,
  ignoreAndroidSystemSettings: false,
};

const accessibleLevels = [SELECTION, IMPACT_LIGHT, IMPACT_MEDIUM, IMPACT_HEAVY, EFFECT_TICK, EFFECT_CLICK, SOFT];

export const vibrateLight = () => {
  RNHapticFeedback.trigger(IMPACT_LIGHT, options);
};

export const vibrateMedium = () => {
  RNHapticFeedback.trigger(IMPACT_MEDIUM, options);
};

export const vibrateHeavy = () => {
  RNHapticFeedback.trigger(IMPACT_HEAVY, options);
};

const DEFAULT_METHOD = Platform.select({
  ios: SELECTION,
  android: IMPACT_MEDIUM,
}) as HapticFeedbackTypes;

export const vibrate = (level: HapticFeedbackTypes = DEFAULT_METHOD) => {
  if (accessibleLevels.includes(level)) {
    RNHapticFeedback.trigger(level, options);
  }
};

export default vibrate;
