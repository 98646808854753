import React, { FC } from 'react';
import { Image, ImageSourcePropType, StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import Text from 'components/text';
import { DARK_LAVENDER, LIGHT_BEIGE, BEIGE } from 'constants/colors';
import ASTROLOGERS from 'assets/images/astrologers-promo/astrologers-promo-medium.png';
import Button from 'components/button';

import BG from './image/waves.png';

interface Props {
  buttonTitle: string;
  onPress: () => void;
  title: string;
  subTitle: string;
  style?: StyleProp<ViewStyle>;
  image?: ImageSourcePropType;
}

const BannerBig: FC<Props> = ({ onPress, buttonTitle, title, subTitle, style, image = ASTROLOGERS }) => {
  const handlePress = () => {
    onPress();
  };

  return (
    <View style={[styles.container, style]}>
      <Image style={styles.bg} source={BG} />

      <View style={styles.textsContainer}>
        <Image style={styles.image} source={image} />
        <View style={styles.texts}>
          <Text font="Philosopher" style={styles.title}>
            {title}
          </Text>
          <Text style={styles.text}>{subTitle}</Text>
        </View>
      </View>
      <Button style={styles.button} title={buttonTitle} onPress={handlePress} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingBottom: paddingVertical(20),
    borderRadius: sw(15),
    backgroundColor: DARK_LAVENDER,
  },
  bg: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: sw(145),
    height: sw(105),
  },
  textsContainer: {
    flexDirection: 'row',
  },
  texts: {
    flex: 1,
    paddingTop: paddingVertical(20),
    paddingRight: paddingHorizontal(18),
    marginLeft: paddingHorizontal(5),
  },
  title: {
    width: '100%',
    flexWrap: 'wrap',
    fontWeight: '700',
    color: BEIGE,
    fontSize: fs(18),
    lineHeight: fs(20),
    marginBottom: paddingVertical(5),
  },
  text: {
    width: '100%',
    flexWrap: 'wrap',
    color: LIGHT_BEIGE,
    fontSize: fs(13),
    lineHeight: fs(18),
  },
  image: {
    width: sw(85),
    height: sw(104),
  },
  button: {
    marginHorizontal: paddingHorizontal(25),
    width: 'auto',
    marginTop: paddingHorizontal(14),
    minHeight: sw(40),
  },
});

export default BannerBig;
