import { State, ActionTypes, TYPES } from './types';

const initialState: State = {
  isViewed: false,
  viewedReports: [],
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case TYPES.SET_REPORT_VIEWED:
      return {
        ...state,
        isViewed: true,
        viewedReports: [...state.viewedReports, action.payload],
      };
    default:
      return state;
  }
};
