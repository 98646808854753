import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  todayMatches: [],
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_MATCHES:
      return {
        ...state,
        todayMatches: action.payload.todayMatches,
      };

    default:
      return state;
  }
}
