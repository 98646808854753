import { Component, FC } from 'react';

import * as ROUTES from 'constants/routes';

export enum ADVISORS_CATEGORIES {
  LOVE = 'love',
  ACCURATE = 'accurate',
  RECOMMENDED = 'recommended',
}

export enum ADVISORS_CATALOG_CUSTOM_BLOCK_IDS {
  ALL = 'all',
  CHATS = 'chats',
  RANDOM = 'random',
}

export const ADVISORS_CATALOG_BLOCK_IDS = {
  ...ADVISORS_CATEGORIES,
  ...ADVISORS_CATALOG_CUSTOM_BLOCK_IDS,
};

export type ADVISORS_CATALOG_BLOCK_IDS = ADVISORS_CATEGORIES | ADVISORS_CATALOG_CUSTOM_BLOCK_IDS;

export type AdvisorsCatalogBlocks = {
  [key in ADVISORS_CATALOG_BLOCK_IDS]: FC<any> | typeof Component;
};

export type AdvisorsCatalogBackRoutes = typeof ROUTES.ADVISORS | typeof ROUTES.ADVISORS_CONNECTION;
