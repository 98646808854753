import { SKILLS_IDS } from 'screens/advisors/screens/onboarding/constants';

import { Question } from '../core/types';

export enum ADVISORS_ONB_PAGES_IDS {
  WELCOME = 'WELCOME',
  PAIN = 'PAIN',
  BONUS = 'BONUS',
  SKILLS = 'SKILLS',
  QUESTIONS = 'QUESTIONS',
  ANALYSIS = 'ANALYSIS',
  INAPP = 'INAPP',
}

export enum TYPES {
  SET_IS_COMPLETED = 'ADVISORS_ONB/SET_IS_COMPLETED',
  SET_ONBOARDING_FLOW = 'ADVISORS_ONB/SET_ONBOARDING_FLOW',
  SET_STEP = 'ADVISORS_ONB/SET_STEP',
  UPDATE_DATA = 'ADVISORS_ONB/UPDATE_DATA',
}

export enum ASTROLOGERS_ONBOARDING_INAPP {
  OFFER = 'offer',
  PACKS = 'packs',
}

export interface OnboardingData {
  skills: SKILLS_IDS[];
  question: Question | null;
}

export interface InitialState {
  flow: ADVISORS_ONB_PAGES_IDS[];
  data: OnboardingData;
  isCompleted: boolean;
  step: ADVISORS_ONB_PAGES_IDS | null;
}

interface SetStepAction {
  type: typeof TYPES.SET_STEP;
  payload: ADVISORS_ONB_PAGES_IDS;
}

interface SetOnboardingFlow {
  type: typeof TYPES.SET_ONBOARDING_FLOW;
  payload: ADVISORS_ONB_PAGES_IDS[];
}

interface SetOnboardingCompleted {
  type: typeof TYPES.SET_IS_COMPLETED;
}

interface UpdateData {
  type: typeof TYPES.UPDATE_DATA;
  payload: {
    skills?: SKILLS_IDS[];
    question?: Question;
  };
}

export type ActionTypes = SetStepAction | SetOnboardingCompleted | SetOnboardingFlow | UpdateData;
