import React from 'react';
import { StyleProp, TextStyle } from 'react-native';

import Text from 'components/text';
import { FONT_TYPES } from 'constants/fonts';

interface Props {
  children?: string;
  delimiter?: string | RegExp;
  style?: StyleProp<TextStyle>;
  highlightedWrap?: React.FC<{ children: React.ReactNode }>;
  highlightedTextStyle?: StyleProp<TextStyle>;
  font?: FONT_TYPES;
  numberOfLines?: number;
}

const HighLightText: React.FC<Props> = ({
  children,
  style,
  highlightedTextStyle,
  font = 'OpenSans',
  delimiter = '**',
  numberOfLines,
  highlightedWrap: HighLightedWrap,
}) => {
  if (typeof children !== 'string') {
    return null;
  }

  const renderHighLightedText = (text: string) => {
    if (HighLightedWrap) {
      return (
        <HighLightedWrap key={text}>
          <Text font={font} style={[{ fontWeight: 'bold' }, highlightedTextStyle]}>
            {text}
          </Text>
        </HighLightedWrap>
      );
    } else {
      return (
        <Text font={font} key={text} style={[{ fontWeight: 'bold' }, highlightedTextStyle]}>
          {text}
        </Text>
      );
    }
  };

  const textArr = children.split(delimiter);
  return (
    <Text font={font} style={style} numberOfLines={numberOfLines}>
      {textArr.map((part, i) => {
        if (i % 2 === 0) {
          return part;
        }
        return renderHighLightedText(part);
      })}
    </Text>
  );
};

export default HighLightText;
