import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  tips: [],
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_TIPS:
      return {
        ...state,
        tips: action.payload,
      };
    default:
      return state;
  }
}
