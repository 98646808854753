import React, {PureComponent} from 'react';
import {StyleSheet, View, Image} from 'react-native';
import PropTypes from 'prop-types';

import * as COLORS from '../constants/colors';
import LinearGradient from 'react-native-linear-gradient';
import Button from './button';
import * as ICONS from '../constants/icons';
import Text from './text';

const gradientStart = {x: 0, y: 0};
const gradientEnd = {x: 0, y: 1};

export default class ErrorModal extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    buttonTitle: PropTypes.string,
  };

  render() {
    const {isLoading, onPress, title, subtitle, buttonTitle, style} = this.props;

    return (
      <View style={[styles.root, style]}>
        <LinearGradient
          start={gradientStart}
          end={gradientEnd}
          colors={[COLORS.DARK_BLUE_GREY_2, COLORS.DARK_BLUE_GREY_2]}
          style={styles.modal}>
          <View style={styles.infoWrapper}>
            <Image source={ICONS.ICON_NO_CONNECTION} />
            <Text style={styles.title} font="Philosopher">
              {title}
            </Text>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
          <View style={styles.buttonWrapper}>
            <Button isSpinner={isLoading} onPress={onPress} title={buttonTitle} />
          </View>
        </LinearGradient>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: COLORS.GRAY_DARK_TRANSPERENT,
    paddingVertical: 64,
    paddingHorizontal: 20,
  },
  modal: {
    flexDirection: 'column',
    backgroundColor: COLORS.BLACK_OPACITY,
    width: '100%',
    height: '100%',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingVertical: 30,
  },
  infoWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: COLORS.WHITE,
    letterSpacing: 0,
    fontSize: 34,
    marginTop: 50,
    paddingTop: 30,
    paddingBottom: 10,
  },
  subtitle: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: COLORS.WHITE,
    letterSpacing: 0,
    fontSize: 20,
    opacity: 0.7,
  },
  buttonWrapper: {
    width: '100%',
  },
});
