import api from '..';

import { Biorhythm, Biorhythms, Rate } from './interfaces';

const BIORHYTHMS = '/biorhythms';

export const getBiorhythms = (): Promise<Biorhythms> => {
  return api.fetch(BIORHYTHMS, {
    method: 'GET',
  });
};

export const rateBiorhythms = (id: Biorhythm['id'], mark: 'like' | 'dislike'): Promise<Rate> => {
  return api.fetch(`${BIORHYTHMS}/${mark}/${id}`, {
    method: 'POST',
  });
};
