export const MOON_PHASES = {
  [0]: 'NEW_MOON',
  [1]: 'WAXING_CRESCENT',
  [2]: 'FIRST_QUARTER',
  [3]: 'WAXING_CRESCENT',
  [4]: 'FULL_MOON',
  [5]: 'WANING_GIBBOUS',
  [6]: 'LAST_QUARTER',
  [7]: 'WANING_GIBBOUS',
};
