export enum CONTACT_US_METHODS {
  EMAIL = 'email',
  ZENDESK = 'zendesk',
  HELPDESK = 'helpdesk',
}

export enum TYPES_FIELDS {
  TEXT = 'TEXT',
  EMAIL = 'EMAIL',
  TEXTAREA = 'TEXTAREA',
  CATEGORY = 'CATEGORY',
}

export interface DropdownItem {
  label: string;
  value: string;
}

export const DROPDOWN_VALUES: Array<DropdownItem> = [
  {
    label: 'SUPPORT.FORM.CATEGORY.BUG',
    value: 'BUG',
  },
  {
    label: 'SUPPORT.FORM.CATEGORY.FEEDBACK',
    value: 'FEEDBACK',
  },
  {
    label: 'SUPPORT.FORM.CATEGORY.SUBSCRIPTION',
    value: 'SUBSCRIPTION',
  },
  {
    label: 'SUPPORT.FORM.CATEGORY.OTHER',
    value: 'OTHER',
  },
];
