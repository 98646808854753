import { PROFILE_TYPES } from 'store/profile/types';

import { TYPES, State, ActionType } from './types';

const initialState: State = {
  astroeventCounters: {
    resetCount: 0,
    withoutConnections: 0,
    withConnections: 0,
  },
  freeConsultationShowed: false,
  isAstroeventModalShowed: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_ASTROEVENT_COUNTERS:
      return {
        ...state,
        astroeventCounters: {
          ...state.astroeventCounters,
          ...action.payload,
        },
      };
    case TYPES.SET_FREE_CONSULTATION_SHOWED:
      return {
        ...state,
        freeConsultationShowed: action.payload,
      };
    case TYPES.SET_ASTROEVENT_MODAL_SHOWED:
      return {
        ...state,
        isAstroeventModalShowed: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
