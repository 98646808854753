export enum TYPES {
  ACTIVATE_PROMO_OFFER = 'PROMO_OFFERS/ACTIVATE_PROMO_OFFER',
  DEACTIVATE_PROMO_OFFER = 'PROMO_OFFERS/DEACTIVATE_PROMO_OFFER',
  INCREASE_SESSION = 'PROMO_OFFERS/INCREASE_SESSION',
  INCREASE_SHOW_COUNT = 'PROMO_OFFERS/INCREASE_SHOW_COUNT',
}

export interface State {
  active: boolean;
  inited: boolean;
  timeActivated: number;
  session: number;
  activationSessionNumber: number;
  showCountInCurrentSession: number;
}

interface ActivatePromoOffer {
  type: typeof TYPES.ACTIVATE_PROMO_OFFER;
}

interface DeactivatePromoOffer {
  type: typeof TYPES.DEACTIVATE_PROMO_OFFER;
}

interface SetShowCountAction {
  type: typeof TYPES.INCREASE_SHOW_COUNT;
}

interface IncreaseSessionAction {
  type: typeof TYPES.INCREASE_SESSION;
}

export type ActionTypes =
  | SetShowCountAction
  | ActivatePromoOffer
  | IncreaseSessionAction
  | DeactivatePromoOffer;
