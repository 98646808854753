import React, { FC, useMemo, useEffect } from 'react';
import { StyleSheet, View, Dimensions, StyleProp, ViewStyle } from 'react-native';
import { HEADER_OFFSET } from '@wowmaking/ui/src/utils';
import { useSelector } from 'react-redux';
import { WebView } from 'react-native-webview';
import queryString from 'query-string';

import Analytics from 'analytics';
import { RootState } from 'store';
import { PAYMENT_METHODS, PAYMENT_SYSTEM_PROJECT, WEB_POST_MESSAGE_STATUS } from 'modules/payments/constants';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';
import { ASB_URL } from 'constants/general';
import { type SolidMetadata } from 'api/purchases/interfaces';

import { type Product } from '../../interfaces/product';

const DEVICE_HEIGHT = Dimensions.get('window').height;
const DEVICE_WIDTH = Dimensions.get('window').width;

interface Props {
  method: PAYMENT_METHODS.CARD | PAYMENT_METHODS.PAYPAL;
  product: Product;
  title?: string;
  idfm: string;
  productTrigger?: string;
  metadata?: {
    [key: string]: any;
  };
  solidMetadata: SolidMetadata;
  onSuccess: (method: PAYMENT_METHODS.CARD | PAYMENT_METHODS.PAYPAL, transactionDetails: any) => void;
  onError: (method: PAYMENT_METHODS.CARD | PAYMENT_METHODS.PAYPAL, error: any) => void;
  onDismiss: () => void;
  onLoaded?: () => void;
  style?: StyleProp<ViewStyle>;
}

const PaymentsWebview: FC<Props> = ({
  method,
  idfm,
  title,
  product,
  metadata,
  solidMetadata,
  productTrigger,
  onSuccess,
  onError,
  onDismiss,
  onLoaded,
  style,
}) => {
  const email = useSelector((state: RootState) => state.profile.profileData?.email);
  const { price, currency, id, priceText, textLineThrough } = product;

  useEffect(() => {
    Analytics.track('Payment_Webview_Open', { method, productCode: id, productType: PRODUCT_TYPES.ONE_TIME });
  }, []);

  const url = useMemo(() => {
    const query: any = {
      title: !product.title ? title : product.title,
      amount: price,
      currency: currency,
      email: email ? email : undefined,
      product_trigger: productTrigger,
      ...solidMetadata,
      nativeIdfm: solidMetadata ? solidMetadata.idfm : undefined,
      idfm,
      product: id,
      textLineThrough,
      priceText,
      payment_system_project: PAYMENT_SYSTEM_PROJECT,
      metadata: JSON.stringify({
        ...metadata,
      }),
    };

    if (method === PAYMENT_METHODS.CARD) {
      query.isCard = true;
    }

    if (method === PAYMENT_METHODS.PAYPAL) {
      query.isPayPal = true;
    }

    return queryString.stringifyUrl({
      url: `${ASB_URL}/one-time-payment-web-view` as unknown as string,
      query,
    });
  }, [id, title, price, product.title, textLineThrough, priceText, currency, idfm, email, method, solidMetadata, metadata, productTrigger]);

  const handleMessage = (event: any) => {
    const data = JSON.parse(event.nativeEvent.data);

    if (data.source === 'one-time-payment') {
      const status = data.status as WEB_POST_MESSAGE_STATUS;

      if (status !== WEB_POST_MESSAGE_STATUS.LOADED) {
        onDismiss();
      }

      switch (status) {
        case WEB_POST_MESSAGE_STATUS.SUCCESS:
          onSuccess(method, data.data);
          break;
        case WEB_POST_MESSAGE_STATUS.ERROR:
          onError(method, data.data || {});
          break;
        case WEB_POST_MESSAGE_STATUS.SKIPED:
        case WEB_POST_MESSAGE_STATUS.CLOSED:
          break;
        case WEB_POST_MESSAGE_STATUS.LOADED:
          if (typeof onLoaded === 'function') {
            onLoaded();
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <View style={[styles.wrapper, style]}>
      <WebView style={styles.webview} source={{ uri: url }} javaScriptEnabled={true} domStorageEnabled={true} onMessage={handleMessage} />
    </View>
  );
};

export default PaymentsWebview;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    ...StyleSheet.absoluteFillObject,
    paddingTop: HEADER_OFFSET,
  },
  webview: {
    width: DEVICE_WIDTH,
    height: DEVICE_HEIGHT,
    flex: 1,
  },
});
