import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getCalendars } from 'api/calendars';
import { Calendar } from 'api/calendars/interfaces';

import { TYPES } from './types';

export const setCalendars = createAction(TYPES.SET);

export const initCalendars = () => {
  return async (dispatch: AppDispatch) => {
    const currentDate = new Date();

    try {
      const result = await getCalendars(currentDate.getFullYear(), currentDate.getMonth() + 1);

      if (result && result.length > 0) {
        const calendarsImages: [Calendar['category'], Calendar['src']][] = result.map(x => {
          return [x.category, x.src];
        });
        const calendars = Object.fromEntries(calendarsImages);

        dispatch(setCalendars({ ...calendars }));
      }
    } catch (error) {
      console.log('[ERROR CALENDARS]: ', error);
    }
  };
};
