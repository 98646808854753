import api from 'api';

import { Astrologist, EndChatData, LOG_CHAT } from './constants';

const endChatLog = async (chatId: Astrologist['astrologer_id'], data?: EndChatData): Promise<undefined> => {
  try {
    const response = await api.fetch(LOG_CHAT, {
      method: 'POST',
      data: {
        chatId,
        data,
        type: 'endChat',
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] endChatLog', error);
  }
};

export default endChatLog;
