import { NOTIFICATIONS } from 'constants/notification-center';

export const SET_QUEUE = 'NOTIFICATION_CENTER/SET_QUEUE';
export const SET_PRESSED = 'NOTIFICATION_CENTER/SET_PRESSED';
export const SET_CURRENT_NOTIFICATION = 'NOTIFICATION_CENTER/SET_CURRENT_NOTIFICATION';
export const SET_HIDDEN = 'NOTIFICATION_CENTER/SET_HIDDEN';

interface CurrentNotification {
  id: NOTIFICATIONS;
  height: number;
}

export interface State {
  queue: NOTIFICATIONS[];
  wasPressed: NOTIFICATIONS[];
  isHidden: boolean;
  currentNotification: CurrentNotification | null;
}

interface setQueue {
  type: typeof SET_QUEUE;
  payload: NOTIFICATIONS[];
}

interface setPressed {
  type: typeof SET_PRESSED;
  payload: NOTIFICATIONS[];
}

interface setHidden {
  type: typeof SET_HIDDEN;
  payload: boolean;
}

interface setCurrentNotification {
  type: typeof SET_CURRENT_NOTIFICATION;
  payload: CurrentNotification;
}

export type ActionTypes = setQueue | setPressed | setHidden | setCurrentNotification;
