export enum TYPES {
  VOICE_COMMAND_ADDED = 'SIRI_SHORTCUTS/VOICE_COMMAND_ADDED',
  SHOW_SUGGESTION = 'SIRI_SHORTCUTS/SHOW_SUGGESTION',
  HIDE_SUGGESTION = 'SIRI_SHORTCUTS/HIDE_SUGGESTION',
}

export interface State {
  voiceCommandAdded: boolean;
  suggestionVisible: boolean;
  seenSuggestionTimes: number;
}

interface VoiceCommandAdded {
  type: typeof TYPES.VOICE_COMMAND_ADDED;
  payload: boolean;
}

interface ShowSuggestion {
  type: typeof TYPES.SHOW_SUGGESTION;
}

interface HideSuggestion {
  type: typeof TYPES.HIDE_SUGGESTION;
  payload: boolean;
}

export type ActionTypes = VoiceCommandAdded | ShowSuggestion | HideSuggestion;
