import { createSelector } from 'reselect';
import moment from 'moment';
import { isEmpty, without, sortBy, isArray } from 'lodash';

import { t, t2 } from 'localization';
import { RootState } from 'store';
import { Astrologist, Chats, HistoryItemMetadata } from 'api/astrology-chat/constants';
import { formatTextWithoutTags } from 'utils/text-format-replace-tags';
import { isValidCatalogBlock } from 'screens/advisors/screens/catalog/utils';
import { ADVISORS_CATALOG_CUSTOM_BLOCK_IDS, ADVISORS_CATEGORIES, ADVISORS_CATALOG_BLOCK_IDS } from 'screens/advisors/screens/catalog/types';

const getPurchased = (state: RootState) => state.billing.purchased;
const getAstrologers = (state: RootState) => state.astrologers.core.allAstrologers;
const getCurrentChats = (state: RootState) => state.astrologers.chat.currentChats;
const getAstrologersChats = (state: RootState) => state.astrologers.chat.chats;
const getConsultationTime = (state: RootState) => state.astrologers.time.seconds;
const getDailyTips = (state: RootState) => state.dailyTips.tips;
const getCompatibilityResult = (state: RootState) => state.compatibility.result;
const getAdvisorsCatalogBlocks = (state: RootState) => state.remoteConfig.remoteConfigParams.advisorsCatalog.catalogBlocks;
const getRemoteFreeMinutes = (state: RootState) => state.remoteConfig.remoteConfigParams?.astrologistConfig?.freeMinutes;
const getRemoteAstrologistConfig = (state: RootState) => state.remoteConfig.remoteConfigParams?.astrologistConfig;
const getCategories = (state: RootState) => state.astrologers.catalog.categories;
const getPalmReadingData = (state: RootState) => state.astrologers.core.palmReadingData;
const getUnlimitedMode = (state: RootState) => state.astrologers.core.isUnlimitedMode;
const getUnlimitedChat = (state: RootState) => state.astrologers.chat.isUnlimited;
const getAwaitingNewTimeBalance = (state: RootState) => state.astrologers.time.isAwaitingNewTimeBalance;

export const getLastConnectedAstrologer = createSelector([getAstrologers, getCurrentChats], (astrologers, chats) => {
  if (isEmpty(chats)) {
    return null;
  }

  // remove chats without messages
  const filteredActiveChats = chats.filter(i => !isEmpty(i.lastMessage));

  if (isEmpty(filteredActiveChats)) {
    return null;
  }

  const lastActiveChat = filteredActiveChats.reduce((acc, chat): Chats => {
    const dateA = moment(acc.lastMessage.created_at).format('YYYY-MM-DD HH:mm:ss').valueOf();
    const dateB = moment(chat.lastMessage.created_at).format('YYYY-MM-DD HH:mm:ss').valueOf();

    return dateA < dateB ? chat : acc;
  });

  return astrologers.find((a: Astrologist) => +a?.astrologer_id === +lastActiveChat?.astrologer_id);
});

export const getFreeMinutesMessageViabilityStatus = createSelector(
  [getAstrologersChats, getRemoteFreeMinutes, getConsultationTime],
  (chats, freeMinutes, seconds) => {
    const isChatsEmpty = isEmpty(chats);
    return isChatsEmpty && seconds > 0 && freeMinutes > 0;
  },
);

export const getAstrologersPromoDailyTipsContext = createSelector([getDailyTips], tips => {
  let dailyTipsContext = '';
  if (tips.length > 0) {
    dailyTipsContext = tips.reduce((acc, item) => {
      if (item.category === 'daily_tips') {
        return acc;
      }

      const text = `${item.category}: ${formatTextWithoutTags(item.text)};`;

      if (acc.length > 0) {
        return `${acc} ${text}`;
      }

      return `${text}`;
    }, '');
  }

  return dailyTipsContext;
});

export const getAstrologersPromoCompatibilityContext = createSelector([getCompatibilityResult], compatibilityResult => {
  if (!compatibilityResult) {
    return '';
  }

  const { overview, toxicity, compatibilityLevel, aspects, wheelOfBalance, signs } = compatibilityResult;
  const overviewContext = `Relationship Overview: ${overview}`;
  const compatibilityLevelContext = `Compatibility Level:\nName: ${compatibilityLevel.name};\nDescription: ${compatibilityLevel.description};\nPercent: ${compatibilityLevel.percent};\nSigns: ${signs.first.sign} - ${signs.second.sign}`;
  const toxicityContext = `Toxicity score: ${toxicity.percent}; Toxicity level: ${t2(
    `COMPATIBILITY.PERCENTS_TITLE.${toxicity.name.toUpperCase()}`,
  )}; Toxicity description: ${toxicity.description}`;

  const aspectContext = aspects.reduce((acc, item) => {
    if (acc.length > 0) {
      return `${acc}\n\n${item.id}: Description: ${item.description}; Percent: ${item.percent}`;
    }

    return `Aspects:\n\n${item.id}: Description: ${item.description}; Percent: ${item.percent}`;
  }, '');

  const wheelOfBalanceContext = wheelOfBalance.reduce((acc, item) => {
    if (acc.length > 0) {
      return `${acc}\n${item.type} - Description: ${item.description}; Level: ${item.name}; Percent: ${item.percent};`;
    }

    return `Wheel Of Balance: ${item.type} - Description: ${item.description}; Level: ${item.name}; Percent: ${item.percent};`;
  }, '');

  return `Compatibility:\n${compatibilityLevelContext};\n${overviewContext};\n${wheelOfBalanceContext};\n${toxicityContext};\n${aspectContext}`;
});

export const selectActiveChats = createSelector([getAstrologersChats], chats => {
  const chatsHistory = Object.keys(chats).map(id => {
    return {
      chat_id: id,
      messages: chats[id]?.messages,
    };
  });

  const activeChats = chatsHistory.filter(chat => {
    return chat?.messages?.length && !!chat?.messages[0] && !isArray(chat?.messages[0]);
  });

  return sortBy(activeChats, chat => chat.messages[0]?.created_at).reverse();
});

export const getAvailableAdvisorsCatalogBlocks = createSelector(
  [getAdvisorsCatalogBlocks, selectActiveChats],
  (blocks, chats): ADVISORS_CATALOG_BLOCK_IDS[] => {
    let availableBlocks = blocks.filter(isValidCatalogBlock);

    if (!chats.length) {
      availableBlocks = without(availableBlocks, ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.CHATS);
    }
    return availableBlocks;
  },
);

export const getActiveAdvisors = createSelector([getAstrologers], (astrologers): Astrologist[] => {
  return astrologers.filter(astrologer => astrologer.is_active);
});

export const getAdvisorsByCategoryId = (state: RootState, categoryId: ADVISORS_CATEGORIES): Astrologist[] => {
  const advisors = getActiveAdvisors(state);
  const categories = getCategories(state);

  const category = categories[categoryId];

  return advisors.filter(astrologer => category?.includes?.(astrologer.astrologer_id));
};

export const getAdvisorStatus = createSelector(
  [getAstrologers, (_state, id: Astrologist['astrologer_id']) => id],
  (allAstrologers, id) => !!allAstrologers.find(ai => `${ai.astrologer_id}` === `${id}`)?.is_active,
);

export const getFreeMinutes = createSelector([getPurchased, getRemoteAstrologistConfig], (purchased, astrologistConfig) => {
  const freeMinutes = purchased ? astrologistConfig.freeMinutes : astrologistConfig.freeMinutesForFreeUsers;
  return freeMinutes;
});

export const getPalmReadingMetadata = createSelector(
  [getAstrologers, getPalmReadingData, (_state, chatId: Astrologist['astrologer_id']) => chatId],
  (allAstrologers, palmReadingData, chatId): [string, HistoryItemMetadata] => {
    if (!palmReadingData) {
      return ['', {}];
    }

    const advisorName = allAstrologers.find(item => `${item.astrologer_id}` === `${chatId}`)?.name || '';
    const message = t('ADVISOR_PALM_READING.MESSAGE', { name: advisorName });

    const metadata: HistoryItemMetadata = {
      url: palmReadingData.image,
      hiddenMessage: palmReadingData.hiddenMessage,
      type: 'palmReadingLeftHandData',
    };

    return [message, metadata];
  },
);

export const getAwaitingNewTimeBalanceStatus = createSelector(
  [getAwaitingNewTimeBalance, getUnlimitedMode, getUnlimitedChat],
  (isAwaitingNewTimeBalance, isUnlimitedMode, isUnlimitedChat) => isAwaitingNewTimeBalance && (!isUnlimitedMode || !isUnlimitedChat),
);
