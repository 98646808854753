import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  activeWidgets: [],
  isHoroscopeWidgetPressed: false,
  isHoroscopeBannerPressed: false,
  isHoroscopeBannerMoved: false,
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_WIDGETS:
      return {
        ...state,
        activeWidgets: action.payload,
      };
    case TYPES.SET_HOROSCOPE_WIDGET_PRESSED:
      return {
        ...state,
        isHoroscopeWidgetPressed: action.payload,
      };
    case TYPES.SET_HOROSCOPE_BANNER_PRESSED:
      return {
        ...state,
        isHoroscopeBannerPressed: action.payload,
      };
    case TYPES.SET_HOROSCOPE_BANNER_MOVED:
      return {
        ...state,
        isHoroscopeBannerMoved: true,
      };
    default:
      return state;
  }
};
