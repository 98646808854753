import seedRandom from 'seedrandom';
import moment from 'moment';

export const sortRandomForPeriod = array => {
  if (!array || array.length === 0) {
    return null;
  }
  const daysCount = Math.floor(moment.duration(moment().diff(moment(0))).as('days'));
  const randArray = sortRandom(array, daysCount);
  return randArray[daysCount % array.length];
};

export const sortRandomForDay = (array, seed, updateDate) => {
  if (!array || array.length === 0) {
    return { array: [], value: null };
  }
  const daysCount = updateDate ? updateDate : Math.floor(moment.duration(moment().diff(moment(0))).as('days'));
  const randArray = sortRandom(array, `${seed}_${daysCount}`);
  return { array: randArray, value: randArray[0] };
};

export const sortRandom = (array, seed) => {
  if (!Array.isArray(array) || !array?.length) {
    return [];
  }

  const arrayCopy = array.slice();
  const randFunction = seed ? seedRandom(seed) : Math.random;
  const sortFunction = () => (randFunction() < 0.5 ? -1 : 1);

  return arrayCopy.sort(sortFunction);
};

export const getRandomInRangeForDay = (min, max, seed) => {
  const daysCount = Math.floor(moment.duration(moment().diff(moment(0))).as('days'));
  return getRandomInRange(min, max, `${daysCount}_${seed}`);
};

export const getRandomInRange = (min, max, seed) => {
  const randFunction = seed ? seedRandom(seed) : Math.random;
  return randFunction() * (max - min) + min;
};

export const sortRandomWithoutSeed = array => {
  if (!array || array.length === 0) {
    return [];
  }
  const arrayCopy = array.slice();
  const sortFunction = () => (Math.random() < 0.5 ? -1 : 1);
  const randArray = arrayCopy.sort(sortFunction);
  return randArray;
};

export const getRandomUniqueValue = array => {
  const set = new Set();

  while (set.size !== array.length) {
    const index = Math.floor(Math.random() * array.length);
    set.add(array[index]);
  }

  return [...set];
};

export const getRandomSize = (array, seed, size) => {
  const randomValue = sortRandom(array, seed);

  return randomValue.slice(0, size);
};
