import { ASTRO_CALENDAR_ORIGIN } from 'screens/astro-calendar/constants';
import type { AstroCalendarData, RetrogradeDBItem, AstroCalendarActiveTodo, AstroCalendarDataTodo } from 'screens/astro-calendar/interfaces';

export enum TYPES {
  UPDATE_DATA = 'ASTRO_CALENDAR/UPDATE_DATA',
  SET_RETROGRADES_CONTENT = 'ASTRO_CALENDAR/SET_RETROGRADES_CONTENT',
  SET_TUTORIAL_SHOWN = 'ASTRO_CALENDAR/SET_TUTORIAL_SHOWN',
  ADD_PENDING_TODO = 'ASTRO_CALENDAR/ADD_PENDING_TODO',
  DELETE_PENDING_TODO = 'ASTRO_CALENDAR/DELETE_PENDING_TODO',
  ADD_ACTIVE_TODO = 'ASTRO_CALENDAR/ADD_ACTIVE_TODO',
  RESET_ACTIVE_TODOS = 'ASTRO_CALENDAR/RESET_ACTIVE_TODOS',
  SET_ACTIVE_NOTIFICATION = 'ASTRO_CALENDAR/SET_ACTIVE_NOTIFICATION',
  SET_ORIGIN_PLACE = 'ASTRO_CALENDAR/SET_ORIGIN_PLACE',
}

export interface State {
  activeNotification: AstroCalendarDataTodo | null;
  activeTodos: AstroCalendarActiveTodo[];
  data: AstroCalendarData;
  pendingTodos: AstroCalendarDataTodo[];
  retrogradesContent: RetrogradeDBItem[];
  tutorialShown: boolean;
  originPlace: ASTRO_CALENDAR_ORIGIN | null;
}

interface UpdateData {
  type: typeof TYPES.UPDATE_DATA;
  payload: AstroCalendarData;
}

interface SetRetrogradesContent {
  type: typeof TYPES.SET_RETROGRADES_CONTENT;
  payload: RetrogradeDBItem[];
}
interface SetTutorialShown {
  type: typeof TYPES.SET_TUTORIAL_SHOWN;
}

interface AddPendingTodos {
  type: typeof TYPES.ADD_PENDING_TODO;
  payload: AstroCalendarDataTodo;
}
interface DeletePendingTodo {
  type: typeof TYPES.DELETE_PENDING_TODO;
  payload: AstroCalendarDataTodo;
}
interface AddActiveTodo {
  type: typeof TYPES.ADD_ACTIVE_TODO;
  payload: AstroCalendarActiveTodo;
}
interface ResetActiveTodos {
  type: typeof TYPES.RESET_ACTIVE_TODOS;
}
interface SetActiveNotification {
  type: typeof TYPES.SET_ACTIVE_NOTIFICATION;
  payload: AstroCalendarDataTodo | null;
}

interface SetOriginPlace {
  type: typeof TYPES.SET_ORIGIN_PLACE;
  payload: ASTRO_CALENDAR_ORIGIN | null;
}

export type ActionTypes =
  | UpdateData
  | SetRetrogradesContent
  | SetTutorialShown
  | AddPendingTodos
  | AddActiveTodo
  | ResetActiveTodos
  | DeletePendingTodo
  | SetActiveNotification
  | SetOriginPlace;
