import React, { FC } from 'react';
import { ImageSourcePropType, ImageStyle, StyleProp, ViewStyle } from 'react-native';
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated';

import useTimeOut from 'hooks/use-timeout';

import { CLOSE_ICON } from '../constants/icons';

import CloseButton from './close-button';

interface Props {
  onPress: () => void;
  timeout: number;
  source?: ImageSourcePropType;
  style?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ImageStyle>;
}

const CloseButtonWithTimeout: FC<Props> = ({ timeout, onPress, source = CLOSE_ICON, style, iconStyle }) => {
  const timer = useTimeOut(timeout);

  const animatedStyles = useAnimatedStyle(
    () => ({
      opacity: withTiming(timer > 0 ? 0 : 1),
      pointerEvents: timer > 0 ? 'none' : 'auto',
    }),
    [timer],
  );

  return (
    <Animated.View style={animatedStyles}>
      <CloseButton onPress={onPress} source={source} style={style} iconStyle={iconStyle} />
    </Animated.View>
  );
};

export default CloseButtonWithTimeout;
