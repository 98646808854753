import { getMessaging as getFirebaseMessaging, getToken as getMessagingToken } from 'firebase/messaging';

export default {
  init() { },

  onOpenNotificationHandler() { },

  clearNotifications() { },

  async requestPermissions() {
    try {
      const status = await window?.Notification?.requestPermission?.();
      return status === 'granted';
    } catch (e) {
      console.log('> request notification permissions error', e);
      return false;
    }
  },

  async getToken() {
    try {
      const messaging = getFirebaseMessaging();
      const token = await getMessagingToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_TOKEN });
      // console.log('>>> token', token);
    } catch (e) {
      console.log('> get token error', e);
    }
  },

  checkPermissions() {
    return Promise.resolve();
  },

  setApplicationIconBadgeNumber() {},

  cancelLocalNotification() {},

  schedulePlannedNotificationInternal() { },

  scheduleRepeatedNotificationInternal() { },

  scheduleDailyNotification() { },

  removeAllDeliveredNotifications() { },

  stringToDate() { },

  scheduleEveryDayNotification() { },

  scheduleRollUpProfileNotification() { },

  scheduleRollUpOnboardingSubscriptionNotification() { },

  scheduleRollUpDashboardNotification() { },

  scheduleFinishedAnalizedFaceNotification() { },
  scheduleFinishedAnalizedHandNotification() { },

  scheduleFirstSessionFirstNotification() { },

  scheduleFirstSessionSecondNotification() { },

  scheduleFirstSessionThirdNotification() { },

  unscheduleAllNotifications() { },

  scheduleFirstSessionNotifications() { },
};
