import api from 'api';

import { GET_CODE } from './constants';

const getPromocode = async (code: string): Promise<{ code: string; isExist: boolean; isUsed: boolean; seconds: number } | undefined> => {
  return api.fetch(`${GET_CODE}?code=${code}`, {
    method: 'GET',
  });
};

export default getPromocode;
