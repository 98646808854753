import { isString } from 'lodash';

const regex = /<[^>]*>/gi;

export const formatTextWithoutTags = (text: string): string => {
  if (!isString(text)) {
    return '';
  }

  const formattedText = text.split('\n').join('').replace(regex, '\n').trim();

  return formattedText;
};
