import React, { FC, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ScrollView, Platform } from 'react-native';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';

import Analytics from 'analytics';
import { t2 } from 'localization';
import { AppDispatch, RootState } from 'store';
import { cancelSubscriptions } from 'store/billing/actions';
import { closeCurrentModal } from 'store/modals/actions';
import { navigate } from 'store/navigation/actions';
import Text from 'components/text';
import Button from 'components/button';
import SubBenefits from 'components/sub-benefits';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { windowHeight } from 'constants/sizes';
import { BENEFITS_TRIGGERS } from 'constants/sub-benefits';
import * as ROUTES from 'constants/routes';
import PRO_ICON from 'assets/icons/pro/pro.png';

import ICON_CROSS from '../images/beige-cross.png';
import STARS_IMG from '../images/stars.png';

interface Props {}

const SubBenefitsModal: FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.SUB_BENEFITS);
  const trigger = useSelector((state: RootState) => state.modals.params?.trigger as unknown as BENEFITS_TRIGGERS);
  const isUnsubscribe = trigger === BENEFITS_TRIGGERS.MANAGE_SUBSCRIPTION;

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('Sub_Benefits', 'Modal_Open', { trigger });
    }
  }, [isVisible]);

  const handleClose = () => {
    dispatch(closeCurrentModal());
  };

  const handleButtonPress = () => {
    Analytics.trackEvent('Sub_Benefits', 'Modal_Button_Click');
    handleClose();
  };

  const handleLinkPress = () => {
    Analytics.trackEvent('Sub_Benefits', 'Modal_Link_Click');
    handleClose();

    if (Platform.OS !== 'web') {
      return navigate(ROUTES.MANAGE_SUBSCRIPTION);
    } else {
      dispatch(cancelSubscriptions());
    }
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <View style={styles.header}>
          <CloseButtonWithTimeout timeout={0} onPress={handleClose} source={ICON_CROSS} style={styles.cross} />

          <View style={styles.top} pointerEvents="none">
            <Image style={styles.image} source={STARS_IMG} resizeMode="contain" />
            <Image source={PRO_ICON} style={styles.pro} />
          </View>

          <View style={styles.headerContainer}>
            <Text font="Philosopher" style={styles.title}>
              {t2('SUB_BENEFITS.TITLE')}
            </Text>

            <Text style={styles.subTitle}>{t2('SUB_BENEFITS.MODAL.SUB_TITLE')}</Text>
          </View>
        </View>

        <ScrollView style={styles.scrollView} contentContainerStyle={styles.contentContainer}>
          <SubBenefits />
        </ScrollView>

        <View style={styles.footer}>
          <Button
            title={!isUnsubscribe ? t2('SUB_BENEFITS.MODAL.BTN_TITLE') : t2('SUB_BENEFITS.MODAL.BTN_UNSUBSCRIBE_TITLE')}
            style={styles.button}
            onPress={handleButtonPress}
          />

          {isUnsubscribe && (
            <TouchableOpacity onPress={handleLinkPress} style={styles.link}>
              <Text style={styles.linkText}>{t2('SUB_BENEFITS.MODAL.BTN_UNSUBSCRIBE_LINK_TITLE')}</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default SubBenefitsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: 'auto',
    flexGrow: 0,
    maxHeight: windowHeight * 0.9,
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
  },
  scrollView: {
    width: '100%',
  },
  cross: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingVertical: 0,
    paddingRight: paddingHorizontal(20),
    zIndex: 1,
  },
  header: {
    marginTop: paddingVertical(20),
    width: '100%',
    paddingBottom: paddingVertical(10),
  },
  headerContainer: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(20),
  },
  top: {
    width: '100%',
    height: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    position: 'absolute',
    width: '100%',
  },
  pro: {},
  title: {
    color: COLORS.BEIGE_2,
    marginTop: -paddingVertical(5),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(33),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(10),
    fontSize: fs(17),
    lineHeight: fs(22),
    color: color(COLORS.GOLDEN, 0.8),
    textAlign: 'center',
  },
  footer: {
    paddingHorizontal: paddingHorizontal(20),
    paddingTop: paddingVertical(20),
  },
  contentContainer: {
    paddingTop: paddingVertical(10),
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  button: {},
  link: {
    marginTop: paddingVertical(10),
    paddingVertical: paddingVertical(5),
  },
  linkText: {
    fontSize: fs(16),
    lineHeight: fs(18),
    color: COLORS.AQUA,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
});
