import { Platform } from 'react-native';

import api from '..';
import { ProfileData } from '../profile/interfaces';

const AUTH = '/auth';

export const auth = (device_id_new: string): Promise<ProfileData> => {
  return api.fetch(AUTH, {
    method: 'POST',
    data: {
      device_id_new,
      increment_cross_session: 1,
    },
  });
};

export const requestOTP = (email: string): Promise<[]> => {
  return api.fetch(AUTH + '/otp-requests', {
    method: 'POST',
    data: {
      email,
      payment_system_project: Platform.OS === 'web' ? process.env.REACT_APP_PAYMENT_SYSTEM_PROJECT : undefined,
    },
  });
};

export const authWithOTP = (code: string, email: string): Promise<ProfileData> => {
  return api.fetch(AUTH + '/otp-requests/auth', {
    method: 'POST',
    data: {
      code,
      email,
    },
  });
};
