import { LocaleConfig } from 'react-native-calendars';
import dayjs from 'dayjs';
import { startCase } from 'lodash';

export const initCalendars = (lng: string) => {
  const localeData = dayjs.localeData();

  LocaleConfig.locales[lng] = {
    monthNames: localeData.months().map(month => startCase(month)),
    monthNamesShort: localeData.monthsShort().map(month => startCase(month)),
    dayNames: localeData.weekdays().map(day => startCase(day)),
    dayNamesShort: localeData.weekdaysShort().map(day => startCase(day)),
  };

  LocaleConfig.defaultLocale = lng;
};
