/* eslint-disable no-useless-escape */

import type { Chunk } from './types';

/**
 * Creates an array of chunk objects representing both highlightable and non
 * highlightable pieces of text that match each search word.
 *
 * @returns Array of "chunks" (where a Chunk is { start: number, end: number,
 *   highlight: boolean })
 */
export const findAll = ({
  autoEscape,
  caseSensitive = false,
  findChunks = defaultFindChunks,
  sanitize,
  searchWords,
  textToHighlight,
}: {
  searchWords: Array<string>;
  textToHighlight: string;
  autoEscape?: boolean;
  caseSensitive?: boolean;
  findChunks?: typeof defaultFindChunks;
  sanitize?: typeof defaultSanitize;
}): Array<Chunk> =>
  fillInChunks({
    chunksToHighlight: combineChunks({
      chunks: findChunks({
        autoEscape,
        caseSensitive,
        sanitize,
        searchWords,
        textToHighlight,
      }),
    }),
    totalLength: textToHighlight ? textToHighlight.length : 0,
  });

/**
 * Takes an array of {start: number, end: number} objects and combines chunks
 * that overlap into single chunks.
 *
 * @returns {start:number, end:number}
 */
export const combineChunks = ({ chunks }: { chunks: Array<Chunk> }): Array<Chunk> => {
  chunks = chunks
    .sort((first, second) => first.start - second.start)
    .reduce((processedChunks: Chunk[], nextChunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk];
      } else {
        // ... subsequent chunks get checked to see if they overlap...
        const prevChunk = processedChunks.pop() as Chunk;
        if (nextChunk.start <= prevChunk?.end) {
          // It may be the case that prevChunk completely surrounds nextChunk, so take the
          // largest of the end indices.
          const endIndex = Math.max(prevChunk.end, nextChunk.end);
          processedChunks.push({
            highlight: false,
            start: prevChunk.start,
            end: endIndex,
          });
        } else {
          processedChunks.push(prevChunk, nextChunk);
        }
        return processedChunks;
      }
    }, []);

  return chunks;
};

const defaultFindChunks = ({
  autoEscape,
  caseSensitive,
  sanitize = defaultSanitize,
  searchWords,
  textToHighlight,
}: {
  searchWords: Array<string>;
  textToHighlight: string;
  autoEscape?: boolean;
  caseSensitive?: boolean;
  sanitize?: typeof defaultSanitize;
}): Array<Chunk> => {
  textToHighlight = sanitize(textToHighlight);

  return searchWords
    .filter(searchWord => searchWord) // Remove empty words
    .reduce((chunks: Chunk[], searchWord) => {
      searchWord = sanitize(searchWord);

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }

      const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi');

      let match;
      while ((match = regex.exec(textToHighlight))) {
        const start = match.index;
        const end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({
            highlight: false,
            start,
            end,
          });
        }

        // Prevents getting stuck in an infinite loop
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, []);
};

/**
 * Given a set of chunks to highlight, create an additional set of chunks to
 * represent the bits of text between the highlighted text.
 *
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength Number
 * @returns {start: number, end: number, highlight: boolean}
 */
export const fillInChunks = ({ chunksToHighlight, totalLength }: { chunksToHighlight: Array<Chunk>; totalLength: number }): Array<Chunk> => {
  const allChunks: Chunk[] = [];
  const append = (start: number, end: number, highlight: boolean) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight,
      });
    }
  };

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach(chunk => {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
};

const defaultSanitize = (string: string): string => string;

const escapeRegExpFn = (string: string): string => string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
