import { PROFILE_TYPES } from '../profile/types';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  widgets: [],
  visited: [],
  visitedOn: null,
  seed: null, //seed for widgets sort
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        ...action.payload,
      };
    case TYPES.SHOWN:
      if (!state.visited.includes(action.payload.widget)) {
        return {
          ...state,
          visited: [...state.visited, action.payload.widget],
        };
      }
      return state;

    case TYPES.RESET_VISITED:
    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...state,
        visited: [],
        visitedOn: new Date(),
      };
    default:
      return state;
  }
}
