import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { AppDispatch, RootState } from 'store';
import { loadTodaysMatches } from 'store/matches/actions';
import { selectUserProfileIsLoaded } from 'store/profile/selectors';
import { loadTodaysFeatures } from 'store/todays-features/actions';

const useLoadTodaysFeatures = () => {
  const dispatch = useDispatch<AppDispatch>();
  const systemLocale = useSelector((state: RootState) => state.app.initialSystemLocale);
  const userProfileIsLoaded = useSelector(selectUserProfileIsLoaded);

  useEffect(() => {
    if (systemLocale && userProfileIsLoaded) {
      dispatch(loadTodaysMatches());
      dispatch(loadTodaysFeatures());
    }
  }, [dispatch, systemLocale, userProfileIsLoaded]);
};

export default useLoadTodaysFeatures;
