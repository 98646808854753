import dayjs from 'dayjs';
import uuid from 'react-native-uuid';

import { t2 } from 'localization';
import type { ISODate } from 'interfaces/date';
import { isStandalone } from 'utils/pwa';

import type {
  AstroCalendarConfig,
  AstroCalendarConfigDay,
  AstroCalendarConfigTodo,
  AstroCalendarConfigBonus,
  AstroCalendarDBDay,
  AstroCalendarDBTodo,
  AstroCalendarDBBonus,
  AstroCalendarData,
  AstroCalendarDataDay,
  AstroCalendarDataTodo,
  AstroCalendarDataBonus,
  AstroCalendarDayInputData,
  AstroCalendarTodoInputData,
} from '../interfaces';
import { ASTRO_CALENDAR_BONUS_TYPE, ASTRO_CALENDAR_TODO_TYPE, ASTRO_CALENDAR_NAVIGATE_TODO_DATA, ASTRO_CALENDAR_OTHER_TODO_DATA } from '../constants';

import { getAstroEventStatus, generateAstroEventsForDataDay } from './events';
import { checkConfigBonuses, checkDBBonuses, getMinutesBonusTexts } from './bonuses';
import { checkConfigTodos, checkDBTodos } from './todos';

/* Todo */

export const convertTodoFromConfigToDB = (todo: AstroCalendarConfigTodo): AstroCalendarDBTodo => {
  return {
    ...todo,
    id: `${uuid.v4()}`,
    completed: false,
  };
};

export const convertTodoFromDBToData = (todo: AstroCalendarDBTodo, date: ISODate, inputs: AstroCalendarTodoInputData): AstroCalendarDataTodo => {
  const { type, completed } = todo;

  switch (type) {
    case ASTRO_CALENDAR_TODO_TYPE.NAVIGATE: {
      const { contentId } = todo;

      return {
        ...todo,
        date,
        title: t2(`ASTRO_CALENDAR.TODO.NAVIGATE.${contentId.toUpperCase()}.TITLE`),
        subtitle: t2(`ASTRO_CALENDAR.TODO.NAVIGATE.${contentId.toUpperCase()}.SUBTITLE`),
        img: ASTRO_CALENDAR_NAVIGATE_TODO_DATA[contentId].image,
      };
    }

    case ASTRO_CALENDAR_TODO_TYPE.PWA: {
      return {
        ...todo,
        date,
        title: t2(`ASTRO_CALENDAR.TODO.OTHER.${type.toUpperCase()}.TITLE`),
        subtitle: t2(`ASTRO_CALENDAR.TODO.OTHER.${type.toUpperCase()}.SUBTITLE`),
        img: ASTRO_CALENDAR_OTHER_TODO_DATA[ASTRO_CALENDAR_TODO_TYPE.PWA].image,
        completed: completed || !!inputs.userParams?.pwa_installed || isStandalone(),
      };
    }

    case ASTRO_CALENDAR_TODO_TYPE.RATE_US:
    case ASTRO_CALENDAR_TODO_TYPE.DAILY_FORECAST: {
      return {
        ...todo,
        date,
        title: t2(`ASTRO_CALENDAR.TODO.OTHER.${type.toUpperCase()}.TITLE`),
        subtitle: t2(`ASTRO_CALENDAR.TODO.OTHER.${type.toUpperCase()}.SUBTITLE`),
        img: ASTRO_CALENDAR_OTHER_TODO_DATA[type].image,
      };
    }
  }
};

export const convertTodoFromDataToDB = (todo: AstroCalendarDataTodo): AstroCalendarDBTodo => {
  const { id, type, completed } = todo;

  switch (type) {
    case ASTRO_CALENDAR_TODO_TYPE.NAVIGATE: {
      const { contentId } = todo;
      return { id, type, contentId, completed };
    }

    default: {
      return { id, type, completed };
    }
  }
};

/* Bonus */

export const convertBonusFromConfigToDB = (bonus: AstroCalendarConfigBonus): AstroCalendarDBBonus => {
  return {
    ...bonus,
    id: `${uuid.v4()}`,
    completed: false,
  };
};

export const convertBonusFromDBToData = (bonus: AstroCalendarDBBonus, date: ISODate): AstroCalendarDataBonus => {
  const { type } = bonus;

  switch (type) {
    case ASTRO_CALENDAR_BONUS_TYPE.MINUTES: {
      const { amount } = bonus;
      const texts = getMinutesBonusTexts(amount);
      return {
        ...bonus,
        date,
        ...texts,
      };
    }

    case ASTRO_CALENDAR_BONUS_TYPE.GUIDE: {
      const { contentId } = bonus;
      return {
        ...bonus,
        date,
        title: t2(`ASTRO_CALENDAR.BONUS.GUIDE.${contentId.toUpperCase()}.TITLE`),
        subtitle: t2(`ASTRO_CALENDAR.BONUS.GUIDE.${contentId.toUpperCase()}.SUBTITLE`),
      };
    }

    case ASTRO_CALENDAR_BONUS_TYPE.PDF_GUIDE: {
      const { contentId } = bonus;
      return {
        ...bonus,
        date,
        title: t2(`ASTRO_CALENDAR.BONUS.PDF_GUIDE.${contentId.toUpperCase()}.TITLE`),
        subtitle: t2(`ASTRO_CALENDAR.BONUS.PDF_GUIDE.${contentId.toUpperCase()}.SUBTITLE`),
      };
    }
  }
};

export const convertBonusFromDataToDB = (bonus: AstroCalendarDataBonus): AstroCalendarDBBonus => {
  const { id, type, completed } = bonus;

  switch (type) {
    case ASTRO_CALENDAR_BONUS_TYPE.MINUTES: {
      const { amount, code } = bonus;
      return { id, type, completed, amount, code };
    }

    case ASTRO_CALENDAR_BONUS_TYPE.GUIDE: {
      const { contentId } = bonus;
      return { id, type, completed, contentId };
    }

    case ASTRO_CALENDAR_BONUS_TYPE.PDF_GUIDE: {
      const { contentId } = bonus;
      return { id, type, completed, contentId };
    }
  }
};

export const convertBonusFromDataToConfig = (bonus: AstroCalendarDataBonus): AstroCalendarConfigBonus => {
  const { type } = bonus;

  switch (type) {
    case ASTRO_CALENDAR_BONUS_TYPE.MINUTES: {
      const { amount, code } = bonus;
      return { type, amount, code };
    }

    case ASTRO_CALENDAR_BONUS_TYPE.GUIDE: {
      const { contentId } = bonus;
      return { type, contentId };
    }

    case ASTRO_CALENDAR_BONUS_TYPE.PDF_GUIDE: {
      const { contentId } = bonus;
      return { type, contentId };
    }
  }
};

/* Day */

export const convertDayFromConfigToDB = (day: AstroCalendarConfigDay | undefined, date: ISODate): AstroCalendarDBDay => {
  const { todos = [], bonuses = [] } = day || {};

  return {
    date,
    data: {
      astroEventsCompleted: false,
      bonusesAnimationShown: false,
      todos: checkConfigTodos(todos).map(convertTodoFromConfigToDB),
      bonuses: checkConfigBonuses(bonuses).map(convertBonusFromConfigToDB),
    },
    version: 'v1',
  };
};

export const convertDayFromDBToData = (day: AstroCalendarDBDay, inputs: AstroCalendarDayInputData): AstroCalendarDataDay => {
  const { date, data: { astroEventsCompleted = false, todos = [], bonuses = [], bonusesAnimationShown = false } = {} } = day;

  return {
    date,
    astroEvents: generateAstroEventsForDataDay(date, inputs),
    astroEventsCompleted,
    astroEventsStatus: getAstroEventStatus(date),
    todos: checkDBTodos(todos).map(todo => convertTodoFromDBToData(todo, date, inputs)),
    bonuses: checkDBBonuses(bonuses, inputs).map(bonus => convertBonusFromDBToData(bonus, date)),
    isAvailable: dayjs(date).isSameOrBefore(dayjs(), 'day'),
    bonusesAnimationShown,
  };
};

export const convertDayFromDataToDB = (day: AstroCalendarDataDay): AstroCalendarDBDay => {
  const { date, astroEventsCompleted, todos, bonuses, bonusesAnimationShown } = day;

  return {
    date,
    data: {
      astroEventsCompleted,
      todos: todos.map(convertTodoFromDataToDB),
      bonuses: bonuses.map(convertBonusFromDataToDB),
      bonusesAnimationShown,
    },
    version: 'v1',
  };
};

/* Days */

export const convertDaysFromConfigToDB = (params: { config: AstroCalendarConfig; dates: ISODate[]; firstDay: ISODate }): AstroCalendarDBDay[] => {
  const { config, dates, firstDay } = params;

  const dbDays: AstroCalendarDBDay[] = [];
  const first = dayjs(firstDay);

  dates.forEach(date => {
    const current = dayjs(date);
    const dayNumber = current.diff(first, 'day') + 1;

    dbDays.push(convertDayFromConfigToDB(config[dayNumber], date));
  });

  return dbDays;
};

export const convertDaysFromDBToData = (days: AstroCalendarDBDay[], inputs: AstroCalendarDayInputData): AstroCalendarData => {
  return days.reduce((acc, day) => {
    const { date } = day;

    return {
      ...acc,
      [date]: convertDayFromDBToData(day, inputs),
    };
  }, {} as AstroCalendarData);
};
