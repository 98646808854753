export const SET_CURRENT_ROUTE_NAME = 'NAVIGATION/SET_CURRENT_ROUTE_NAME';
export const SET_PREV_ROUTE_NAME = 'NAVIGATION/SET_PREV_ROUTE_NAME';
export const SET_NAVIGATED_AFTER_ONBOARDING = 'NAVIGATION/SET_NAVIGATED_AFTER_ONBOARDING';

export interface State {
  currentRoute: string;
  previousRoute: string;
  isNavigatedAfterOnboarding: boolean;
}

interface setCurrentRouteName {
  type: typeof SET_CURRENT_ROUTE_NAME;
  payload: string;
}

interface setPreviousRouteName {
  type: typeof SET_PREV_ROUTE_NAME;
  payload: string;
}

interface setNavigatedAfterOnboarding {
  type: typeof SET_NAVIGATED_AFTER_ONBOARDING;
  payload: boolean;
}

export type ActionTypes = setCurrentRouteName | setPreviousRouteName | setNavigatedAfterOnboarding;
