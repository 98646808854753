import { Platform, ImageSourcePropType } from 'react-native';

import { APP_ICON_DEFAULT, APP_ICON_PRO } from './icons';

export enum APP_ICONS_TYPES {
  DEFAULT = 'DEFAULT',
  PRO = 'PRO',
}

type Names = {
  [key in APP_ICONS_TYPES]: string;
};

export const APP_ICONS_NAMES: Names = {
  [APP_ICONS_TYPES.DEFAULT]: Platform.select({
    ios: 'AppIcon-2',
    android: 'default',
  }) as string,
  [APP_ICONS_TYPES.PRO]: Platform.select({
    ios: 'AppProIcon',
    android: 'pro',
  }) as string,
};

type Icons = {
  [key in APP_ICONS_TYPES]: ImageSourcePropType;
};

export const APP_ICONS: Icons = {
  [APP_ICONS_TYPES.DEFAULT]: APP_ICON_DEFAULT,
  [APP_ICONS_TYPES.PRO]: APP_ICON_PRO,
};
