import { TIPS_IDS } from 'constants/tips-categories';

export enum TYPES {
  SET_TIPS = 'DAILY_TIPS/SET',
}

export interface Tip {
  id?: number;
  category: TIPS_IDS;
  text: string;
}
export interface State {
  tips: Tip[];
}

interface SetTips {
  type: typeof TYPES.SET_TIPS;
  payload: Tip[];
}

export type ActionTypes = SetTips;
