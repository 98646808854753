import React, { FC } from 'react';
import { I18nManager, Image, ImageSourcePropType, ImageStyle, StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { fs, paddingHorizontal } from '@wowmaking/ui/src/utils';

import { BEIGE_2 } from 'constants/colors';

import Touchable from '../reusable/touchable';

import Text from './text';

interface Props {
  onPress: () => void;
  source: ImageSourcePropType;
  text?: string;
  style?: StyleProp<ViewStyle>;
  iconStyle?: StyleProp<ImageStyle>;
}

const CloseButton: FC<Props> = ({ onPress, source, style, iconStyle, text }) => {
  return (
    <Touchable style={[styles.container, style]} onPress={onPress}>
      <Image source={source} resizeMode={'contain'} style={[styles.icon, iconStyle]} />
      {!!text?.length && <Text style={styles.text}>{text}</Text>}
    </Touchable>
  );
};

export default CloseButton;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
  },
  text: {
    fontSize: fs(17),
    lineHeight: fs(20),
    letterSpacing: -0.41,
    color: BEIGE_2,
    opacity: 0.8,
    marginLeft: paddingHorizontal(15),
  },
});
