export const CATEGORIES = {
  LOCKED_SECTION: 'locked_section',
  FIRST_SESSION: 'first_session',
  TAB_BAR: 'tab_bar',
  DEEP_SESSION: 'deep_session',
};

export const ACTIONS = {
  CLICK: 'click',
  OPEN: 'open',
  CLOSE: 'close',
  START: 'start',
  SUCCESS: 'success',
};
