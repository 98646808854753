import React, { FC, useEffect, useState } from 'react';
import { StyleSheet, View, StatusBar, Platform } from 'react-native';
import LottieView from 'lottie-react-native';
import { useDispatch, useSelector } from 'react-redux';
import { sw, fs, paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';
import Animated, { useAnimatedStyle, useSharedValue, withTiming, withDelay } from 'react-native-reanimated';
import SplashScreen from 'react-native-splash-screen';

import Analytics from 'analytics';
import Text from 'components/text';
import { LIGHT_TAN, WHITE, DARK_BLUE_GREY_2 } from 'constants/colors';
import { APP_NAME } from 'constants/general';
import { RootState, AppDispatch } from 'store';
import { initApp } from 'store/app/actions';
import { showBackgroundPlacement } from 'store/unlock-content/actions';
import DisconnectModal from 'reusable/disconnect-modal';
import { t } from 'localization';

import ANIMATION_BG from './animations/bg.json';

const AnimatedText = Animated.createAnimatedComponent(Text);
const DELAY_ANIMATION_TEXT = 1000;
const DURATION_ANIMATION_TEXT = 1500;

interface Props {}

const LoadingScreen: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isFirstLoadingDid, setFirstLoadingDid] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const isInternetConnected = useSelector((state: RootState) => state.app.isInternetConnected);
  const animation = useSharedValue(0);

  const handleStartAnimation = () => {
    animation.value = 1;
  };

  const animatedTextStyle = useAnimatedStyle(() => {
    const opacity = withDelay(DELAY_ANIMATION_TEXT, withTiming(animation.value, { duration: DURATION_ANIMATION_TEXT }));
    return {
      opacity,
    };
  });

  const handleCatch = (err: any) => {
    console.warn('[Loading Screen] handleCatch', err);
    Analytics.trackEvent('Loading', 'Error', {
      message: String(err?.message || err),
    });
    setError(err);
  };

  const handleLoadedApp = async () => {
    try {
      await dispatch(initApp());
      Analytics.trackEvent('init_app', 'success');
      setFirstLoadingDid(true);
      dispatch(showBackgroundPlacement());
    } catch (err: any) {
      return handleCatch(err);
    }
  };

  const handleErrorModal = async () => {
    Analytics.trackEvent('Disconnect_Retry', 'Click');

    try {
      await dispatch(initApp());
      return setError(null);
    } catch (err) {
      return handleCatch(err);
    }
  };

  useEffect(() => {
    SplashScreen.hide();
    handleStartAnimation();
    handleLoadedApp();
  }, []);

  useEffect(() => {
    if (!isFirstLoadingDid && !isInternetConnected) {
      setFirstLoadingDid(true);
      handleLoadedApp();
    }
  }, [isInternetConnected, isFirstLoadingDid]);

  return error ? (
    <View style={styles.problems}>
      <DisconnectModal onPress={handleErrorModal} />
    </View>
  ) : Platform.OS === 'web' ? (
    <div className="page-loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  ) : (
    <View style={styles.root}>
      <StatusBar barStyle="light-content" backgroundColor="black" animated />
      <LottieView progress={1} style={styles.bg} autoPlay loop={true} source={ANIMATION_BG} resizeMode="cover" />
      <View style={styles.oval}>
        <Text style={styles.title} font="Philosopher">
          {APP_NAME}
        </Text>
        <AnimatedText style={[styles.desc, animatedTextStyle]} font="Philosopher">
          {t('LOADING_SCREEN.TEXT')}
        </AnimatedText>
      </View>
    </View>
  );
};

export default LoadingScreen;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bg: {
    ...StyleSheet.absoluteFillObject,
  },
  problems: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(20),
  },
  title: {
    color: WHITE,
    fontSize: Platform.select({
      ios: fs(40),
      android: fs(36),
      web: fs(45),
    }),
    fontWeight: 'bold',
  },
  desc: {
    color: LIGHT_TAN,
    fontSize: Platform.select({
      ios: fs(18),
      android: fs(17),
      web: fs(18),
    }),
    marginTop: paddingVertical(10),
    fontWeight: 'bold',
  },
  oval: {
    position: 'absolute',
    width: sw(271),
    height: sw(271),
    borderRadius: sw(261),
    backgroundColor: DARK_BLUE_GREY_2,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
