import React, { FC } from 'react';
import { StyleProp, ViewStyle, TextStyle, StyleSheet, View, TouchableOpacity } from 'react-native';
import { sw } from '@wowmaking/ui/src/utils';
import { t } from 'localization';

import Text from 'components/text';
import { WHITE } from 'constants/colors';

type Props = {
  onPress: () => void;
  text?: string;
  style?: StyleProp<ViewStyle>;
  styleLabel?: StyleProp<TextStyle>;
};

const RestoreButton: FC<Props> = ({ onPress, text = t('RP.TITLE_SHORT'), style, styleLabel }) => {
  const handlePress = () => {
    if (typeof onPress === 'function') {
      onPress();
    }
  };

  return (
    <View style={[styles.wrapper, style]}>
      <TouchableOpacity style={styles.root} onPress={handlePress}>
        <Text style={[styles.label, styleLabel]}>{text}</Text>
      </TouchableOpacity>
    </View>
  );
};

export default RestoreButton;

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: sw(10),
    paddingRight: sw(10),
  },
  wrapper: {
    top: sw(2),
    position: 'absolute',
    right: sw(6),
  },
  label: {
    fontSize: 16,
    color: WHITE,
    textAlign: 'right',
  },
});
