import { ImageSourcePropType } from 'react-native';

import UNLIMITED_ADVISORS_ICON from 'assets/icons/benefits/UNLIMITED_ADVISORS/icon.png';
import PERSONAL_HOROSCOPES_ICON from 'assets/icons/benefits/PERSONAL_HOROSCOPES/icon.png';
import COMPATIBILITY_REPORT_ICON from 'assets/icons/benefits/COMPATIBILITY_REPORT/icon.png';
import BIRTH_CHART_ICON from 'assets/icons/benefits/BIRTH_CHART/icon.png';
import PALM_READING_ICON from 'assets/icons/benefits/PALM_READING/icon.png';
import DAILY_TIPS_ICON from 'assets/icons/benefits/DAILY_TIPS/icon.png';
import TAROT_READINGS_ICON from 'assets/icons/benefits/TAROT_READINGS/icon.png';
import UNLIMITED_ADVISORS_SMALL_ICON from 'assets/icons/benefits/UNLIMITED_ADVISORS/icon-small.png';
import PERSONAL_HOROSCOPES_SMALL_ICON from 'assets/icons/benefits/PERSONAL_HOROSCOPES/icon-small.png';
import COMPATIBILITY_REPORT_SMALL_ICON from 'assets/icons/benefits/COMPATIBILITY_REPORT/icon-small.png';
import BIRTH_CHART_SMALL_ICON from 'assets/icons/benefits/BIRTH_CHART/icon-small.png';
import PALM_READING_SMALL_ICON from 'assets/icons/benefits/PALM_READING/icon-small.png';
import DAILY_TIPS_SMALL_ICON from 'assets/icons/benefits/DAILY_TIPS/icon-small.png';
import TAROT_READINGS_SMALL_ICON from 'assets/icons/benefits/TAROT_READINGS/icon-small.png';

export enum BENEFITS {
  UNLIMITED_ADVISORS = 'UNLIMITED_ADVISORS',
  PERSONAL_HOROSCOPES = 'PERSONAL_HOROSCOPES',
  COMPATIBILITY_REPORT = 'COMPATIBILITY_REPORT',
  BIRTH_CHART = 'BIRTH_CHART',
  PALM_READING = 'PALM_READING',
  DAILY_TIPS = 'DAILY_TIPS',
  TAROT_READINGS = 'TAROT_READINGS',
}

export const ICONS_MAP: { [key in BENEFITS]: ImageSourcePropType } = {
  [BENEFITS.UNLIMITED_ADVISORS]: UNLIMITED_ADVISORS_ICON,
  [BENEFITS.PERSONAL_HOROSCOPES]: PERSONAL_HOROSCOPES_ICON,
  [BENEFITS.COMPATIBILITY_REPORT]: COMPATIBILITY_REPORT_ICON,
  [BENEFITS.BIRTH_CHART]: BIRTH_CHART_ICON,
  [BENEFITS.PALM_READING]: PALM_READING_ICON,
  [BENEFITS.DAILY_TIPS]: DAILY_TIPS_ICON,
  [BENEFITS.TAROT_READINGS]: TAROT_READINGS_ICON,
};

export const SMALL_ICONS_MAP: { [key in BENEFITS]: ImageSourcePropType } = {
  [BENEFITS.UNLIMITED_ADVISORS]: UNLIMITED_ADVISORS_SMALL_ICON,
  [BENEFITS.PERSONAL_HOROSCOPES]: PERSONAL_HOROSCOPES_SMALL_ICON,
  [BENEFITS.COMPATIBILITY_REPORT]: COMPATIBILITY_REPORT_SMALL_ICON,
  [BENEFITS.BIRTH_CHART]: BIRTH_CHART_SMALL_ICON,
  [BENEFITS.PALM_READING]: PALM_READING_SMALL_ICON,
  [BENEFITS.DAILY_TIPS]: DAILY_TIPS_SMALL_ICON,
  [BENEFITS.TAROT_READINGS]: TAROT_READINGS_SMALL_ICON,
};

export enum BENEFITS_TRIGGERS {
  BADGE = 'badge',
  START_APP = 'start_app',
  MANAGE_SUBSCRIPTION = 'manage_subscription',
}
