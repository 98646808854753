import { createAction } from 'redux-actions';
import _ from 'lodash';

import { AppDispatch, AppGetState } from 'store';
import Analytics from 'analytics';
import { getAstrologersConsultationsPurchases } from 'store/astrologers/monetization/selectors';
import { navigate } from 'store/navigation/actions';
import { ADVISORS_CONNECTION, MODALS_EXCLUDE_ROUTES } from 'constants/routes';
import { showModal, closeCurrentModal } from 'store/modals/actions';
import { isSubBenefitsEnabled } from 'store/billing/selectors';
import * as MODALS from 'constants/modals';

import { TEMPLATE_IDS, ASTROLOGERS_OPEN_PLACE } from '../../../screens/advisors/constants';
import { getLastConnectedAstrologer } from '../selectors';
import { addPendingChat, setChatMessages } from '../chat/actions';
import { addFreeTime } from '../time/actions';
import { setCompleted } from '../onboarding/actions';

import { TYPES } from './types';

export const setAstroeventCounters = createAction(TYPES.SET_ASTROEVENT_COUNTERS);
const setFreeConsultationShowed = createAction(TYPES.SET_FREE_CONSULTATION_SHOWED);
const setAstroeventModalShowed = createAction(TYPES.SET_ASTROEVENT_MODAL_SHOWED);

export const startAstroeventChat = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const randomAstrologist = state.astrologers.core.astrologist;
    const lastAstrologist = getLastConnectedAstrologer(state);
    const consultant = lastAstrologist ? lastAstrologist : randomAstrologist;
    const chatId = consultant.astrologer_id;
    const isChatHistoryExist = state.astrologers.chat.chats?.[chatId]?.messages?.length;
    const activeModal = state.modals.activeModal;

    dispatch(setCompleted());
    navigate(ADVISORS_CONNECTION, {
      id: consultant.astrologer_id,
      needResponse: 0,
      templateId: TEMPLATE_IDS.ASTROEVENT,
      place: ASTROLOGERS_OPEN_PLACE.ASTROEVENT,
    });

    if (!isChatHistoryExist) {
      dispatch(setChatMessages({ chatId, messages: [] }));
    }

    dispatch(addPendingChat(`${chatId}`));

    if (activeModal === MODALS.ADVISOR_ASTRO_EVENT) {
      dispatch(closeCurrentModal());
    }
  };
};

export const closeAstroeventModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        modals: {
          astroeventCounters: { withConnections, withoutConnections },
        },
        chat: { chats },
      },
    } = getState();

    const isChatsEmpty = _.isEmpty(chats);
    const payload = isChatsEmpty ? { withoutConnections: withoutConnections + 1 } : { withConnections: withConnections + 1 };

    dispatch(setAstroeventCounters(payload));
    dispatch(closeCurrentModal());
  };
};

export const showAstroeventModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      astrologers: {
        modals: { astroeventCounters, isAstroeventModalShowed },
        core: { lastConnectionSessionNumber },
        chat: { chats },
      },
    } = state;
    const astroeventModalConfig = state.remoteConfig?.remoteConfigParams?.astroeventModalConfig;
    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    if (!astroeventModalConfig?.enable || isAstroeventModalShowed) {
      return false;
    }

    const isChatsEmpty = _.isEmpty(chats);

    if (isChatsEmpty) {
      if (currentSession < astroeventModalConfig.noChatShowSession) {
        return false;
      }

      if (astroeventCounters.withoutConnections >= astroeventModalConfig.noChatCloseRepeat) {
        return false;
      }
    } else {
      if (currentSession - astroeventModalConfig.hasChatShowSession < lastConnectionSessionNumber) {
        return false;
      }

      if (astroeventCounters.resetCount >= astroeventModalConfig.hasChatResetShow) {
        return false;
      }

      if (astroeventCounters.withConnections >= astroeventModalConfig.hasChatCloseRepeat) {
        return false;
      }
    }

    return setTimeout(() => {
      const currentRoute = getState().navigation.currentRoute;
      if (MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
        return false;
      }

      dispatch(showModal(MODALS.ADVISOR_ASTRO_EVENT));
      dispatch(setAstroeventModalShowed(true));
      return true;
    }, astroeventModalConfig?.timeout * 1000);
  };
};

export const rewardForRenewHandler = (needsToReward = false) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      navigation: { currentRoute },
      billing: { purchased },
      astrologers: {
        chat: { chats },
        modals: { freeConsultationShowed },
      },
    } = state;

    const freeMinutes = state?.remoteConfig?.remoteConfigParams?.astrologerFreeMinutesForRenew;
    const session = state?.remoteConfig?.remoteConfigParams?.astrologerFreeMinutesModalSession;
    const astrologersConsultationsPurchases = getAstrologersConsultationsPurchases(state);
    const isBenefitsEnabled = isSubBenefitsEnabled(state);

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;

    if (needsToReward && freeMinutes > 0 && !isBenefitsEnabled) {
      dispatch(showModal(MODALS.FREE_CONSULTATION));
      await dispatch(addFreeTime(freeMinutes * 60, purchased));
      return true;
    }

    if (freeConsultationShowed) {
      return false;
    }

    if (Object.values(chats).length) {
      return false;
    }

    if (astrologersConsultationsPurchases.length) {
      return false;
    }

    if (currentSession !== session) {
      return false;
    }

    if (MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
      return false;
    }

    dispatch(setFreeConsultationShowed(true));
    dispatch(showModal(MODALS.FREE_CONSULTATION));
    return true;
  };
};

export const showAutoRefillModal = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      astrologers: {
        monetization: { renewData },
      },
    } = getState();

    if (!renewData) {
      return false;
    }

    dispatch(showModal(MODALS.AUTO_REFILL));

    return true;
  };
};
