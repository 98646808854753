import format from 'string-format';

import { t2 } from 'localization';

import type { Product } from '../interfaces/product';

export const getPriceText = (product: Product, priceText: string = '') => {
  const p = { ...product };
  if (p?.period) {
    p.period = t2(`SUBSCRIPTION.PERIODS.${p.period}`);
  }

  return format(priceText, p);
};
