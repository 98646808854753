import { useCallback, useEffect, useState } from 'react';

const useTimeOut = (defaultValue: number): number => {
  const [counter, setValue] = useState<number>(defaultValue);

  const countdown = useCallback((): any => {
    return counter > 0 && setInterval(() => setValue(counter - 1), 1000);
  }, [counter]);

  useEffect(() => {
    const interval = countdown();
    return () => clearInterval(interval);
  }, [countdown]);

  return counter;
};

export default useTimeOut;
