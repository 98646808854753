import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addShortcutListener, ShortcutInfo } from 'react-native-siri-shortcut';

import { AppDispatch } from 'store';
import { receiveNsUserActivityType } from 'store/siri-shorcuts/actions';

import SiriShortcuts from '../siri-shortcuts';

const useSiriShortcut = () => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (SiriShortcuts.supported) {
      const subscription = addShortcutListener((shortcut: ShortcutInfo) => {
        dispatch(receiveNsUserActivityType(shortcut));
      });

      return () => {
        subscription.remove();
      };
    }
  }, []);
};

export default useSiriShortcut;
