import dayjs from 'dayjs';

import { ISODate } from 'interfaces/date';

import { ASTRO_CALENDAR_BONUS_TYPE, ASTRO_CALENDAR_EVENT_TYPE, ASTRO_CALENDAR_TODO_TYPE } from '../constants';

export * from './converters';
export * from './events';
export * from './todos';
export * from './bonuses';

export const getAnalyticsParams = (params: {
  type: ASTRO_CALENDAR_EVENT_TYPE | ASTRO_CALENDAR_BONUS_TYPE | ASTRO_CALENDAR_TODO_TYPE;
  firstDay: ISODate | undefined;
  date: ISODate;
}) => {
  const { type, firstDay, date } = params;

  return {
    type,
    date: getEventTime(date),
    day: getDayNumber(date, firstDay),
  };
};

export const getEventTime = (date: ISODate) => {
  if (dayjs(date).isBefore(dayjs(), 'day')) {
    return 'previous';
  }

  if (dayjs(date).isAfter(dayjs(), 'day')) {
    return 'future';
  }

  if (dayjs(date).isSame(dayjs(), 'day')) {
    return 'today';
  }

  return 'unknown';
};

export const getDayNumber = (date: ISODate, firstDay: ISODate | undefined) => {
  return dayjs(date).diff(dayjs(firstDay).subtract(1, 'day'), 'day');
};
