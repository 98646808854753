export enum TYPES {
  SET_BOT_ID = 'MESSENGERS/SET_BOT_ID',
  SET_WHATS_APP_NOTIFICATION_PRESS = 'MESSENGERS/SET_WHATS_APP_NOTIFICATION_PRESS',
  SET_WHATS_APP_NOTIFICATION_SKIP_COUNTER = 'MESSENGERS/SET_NOTIFICATION_SKIP_COUNTER',
  SET_MESSENGERS_NOTIFICATION_PRESS = 'MESSENGERS/SET_MESSENGERS_NOTIFICATION_PRESS',
  SET_MESSENGERS_NOTIFICATION_SKIP_COUNTER = 'MESSENGERS/SET_MESSENGERS_NOTIFICATION_SKIP_COUNTER',
}

export interface State {
  botId: string;
  isWhatsAppNotificationPressed: boolean;
  isMessengersNotificationPressed: boolean;
  whatsAppNotificationSkipCounter: number;
  messengersNotificationSkipCounter: number;
}

interface SetBotId {
  type: typeof TYPES.SET_BOT_ID;
  payload: string;
}

interface SetWhatsAppNotificationPress {
  type: typeof TYPES.SET_WHATS_APP_NOTIFICATION_PRESS;
}

interface SetWhatsAppNotificationSkipCounter {
  type: typeof TYPES.SET_WHATS_APP_NOTIFICATION_SKIP_COUNTER;
  payload: number;
}

interface SetMessengersNotificationPress {
  type: typeof TYPES.SET_MESSENGERS_NOTIFICATION_PRESS;
}

interface SetMessengersNotificationSkipCounter {
  type: typeof TYPES.SET_MESSENGERS_NOTIFICATION_SKIP_COUNTER;
  payload: number;
}

export type ActionType =
  | SetBotId
  | SetWhatsAppNotificationPress
  | SetWhatsAppNotificationSkipCounter
  | SetMessengersNotificationPress
  | SetMessengersNotificationSkipCounter;
