import * as MODALS from './modals';

export enum REACTIVATION_MODAL_TYPES {
  REACTIVATION = 'reactivation',
  REACTIVATION_BENEFITS = 'reactivation_benefits',
}

export const ReactivationModalsRoutes: { [key in REACTIVATION_MODAL_TYPES]: string } = {
  [REACTIVATION_MODAL_TYPES.REACTIVATION]: MODALS.REACTIVATION,
  [REACTIVATION_MODAL_TYPES.REACTIVATION_BENEFITS]: MODALS.REACTIVATION_BENEFITS,
};
