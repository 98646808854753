import {
  CompatibilitySign,
  CompatibilityToxicityScore,
  CompatibilityWheelOfBalance,
  CompatibilityLevel,
  CompatibilityBiggestChallenges,
} from 'api/compatibility/interfaces';
import { PROFILE_TYPES } from 'store/profile/types';

export const SET_COMPATIBILITY_CHOICE_PICK = 'SET_COMPATIBILITY_CHOICE_PICK';
export const SET_COMPATIBILITY_RESULT = 'SET_COMPATIBILITY_RESULT';
export const SET_COMPATIBILITY_PENDING = 'SET_COMPATIBILITY_PENDING';
export const SET_COMPATIBILITY_ERROR = 'SET_COMPATIBILITY_ERROR';
export const SET_CONTENT_BLURRED = 'SET_CONTENT_BLURRED';

interface Aspects {
  description: string;
  percent: number;
  id: string;
  progressBarColor: string;
}

interface Toxicity extends CompatibilityToxicityScore {
  percent: number;
}

export interface WheelOfBalance extends CompatibilityWheelOfBalance {
  percent: number;
  id: string;
}

export interface CompatibilityResult {
  overview: string;
  compatibilityLevel: CompatibilityLevel;
  aspects: Aspects[];
  toxicity: Toxicity;
  wheelOfBalance: WheelOfBalance[];
  biggestChallenges: CompatibilityBiggestChallenges[];
  signs: {
    first: CompatibilitySign;
    second: CompatibilitySign;
  };
}

export interface State {
  pick: {
    leftPick: string;
    rightPick: string;
  };
  pending: boolean;
  result: CompatibilityResult;
  error: boolean;
  isBlurred: boolean;
}

interface SetCompatibilityResult {
  type: typeof SET_COMPATIBILITY_RESULT;
  payload: CompatibilityResult;
}

interface SetCompatibilityChoicePick {
  type: typeof SET_COMPATIBILITY_CHOICE_PICK;
  payload: {
    leftPick: string;
    rightPick: string;
  };
}

interface SetCompatibilityPending {
  type: typeof SET_COMPATIBILITY_PENDING;
  payload: boolean;
}

interface SetCompatibilityError {
  type: typeof SET_COMPATIBILITY_ERROR;
  payload: boolean;
}

interface ResetUser {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface SetContentBlurred {
  type: typeof SET_CONTENT_BLURRED;
  payload: boolean;
}

export type ActionType =
  | SetCompatibilityChoicePick
  | SetCompatibilityResult
  | SetCompatibilityPending
  | SetCompatibilityError
  | SetContentBlurred
  | ResetUser;
