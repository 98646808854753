import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';

import { getWebOneTimePurchaseProducts } from 'store/billing/selectors';
import { PDF_REPORTS_BY_PRODUCT, ULTRA_2_PDF_REPORTS, PDF_REPORTS } from 'constants/upsale';

export const getAvailablePDFReports = createSelector([getWebOneTimePurchaseProducts], (onetimeProducts): PDF_REPORTS[] => {
  return onetimeProducts.reduce((reportProducts: PDF_REPORTS[], onetimeProduct) => {
    const codes = PDF_REPORTS_BY_PRODUCT[onetimeProduct as keyof typeof PDF_REPORTS_BY_PRODUCT];

    if (!isEmpty(codes)) {
      for (const code in codes) {
        if (!reportProducts?.includes?.(codes[code])) {
          reportProducts.push(codes[code] as unknown as PDF_REPORTS);
        }
      }
    }

    return reportProducts;
  }, []);
});

export const getUnavailablePDFReportProducts = createSelector([getWebOneTimePurchaseProducts], (products): PDF_REPORTS[] => {
  const availableReportsProducts = products.flatMap(productCode => PDF_REPORTS_BY_PRODUCT[productCode as keyof typeof PDF_REPORTS_BY_PRODUCT] || []);

  const notAvailableReportsProducts = ULTRA_2_PDF_REPORTS.filter(reportProduct => !availableReportsProducts?.includes?.(reportProduct));

  return notAvailableReportsProducts;
});
