import React, { FC, useMemo } from 'react';
import { StyleSheet, View, Image, StyleProp, ViewStyle } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, color, sw } from '@wowmaking/ui/src/utils';
import LinearGradient from 'react-native-linear-gradient';

import { useAppSelector } from 'store';
import { t2 } from 'localization';
import * as COLORS from 'constants/colors';
import { BENEFITS, ICONS_MAP } from 'constants/sub-benefits';
import Text from 'components/text';
import ICON_CHECK from 'assets/icons/check/check-aqua.png';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const SubBenefits: FC<Props> = ({ style }) => {
  const isUnlimitedChatEnabled = useAppSelector(state => state.remoteConfig.remoteConfigParams?.enableUnlimitedChat);
  const isUnlimitedMode = useAppSelector(state => state.astrologers.core.isUnlimitedMode);

  const items = useMemo(() => {
    if (isUnlimitedMode || isUnlimitedChatEnabled) {
      return Object.keys(BENEFITS);
    } else {
      return Object.keys(BENEFITS).filter(i => i !== BENEFITS.UNLIMITED_ADVISORS);
    }
  }, [isUnlimitedMode, isUnlimitedChatEnabled]);

  const renderGiftBadge = () => (
    <LinearGradient style={styles.gift} start={{ x: 0, y: 1 }} end={{ x: 0, y: 1 }} useAngle angle={150} colors={COLORS.GRADIENT_AQUA_TO_PURPLE}>
      <Text style={styles.giftTitle}>{t2('SUB_BENEFITS.GIFT')}</Text>
    </LinearGradient>
  );

  return (
    <View style={[styles.wrap, style]}>
      {items.map(key => (
        <View key={key} style={styles.item}>
          {key === BENEFITS.UNLIMITED_ADVISORS && renderGiftBadge()}
          <Image source={ICONS_MAP[key]} style={styles.itemIcon} />

          <View style={styles.itemRight}>
            <View style={styles.itemTexts}>
              <Text font="Philosopher" style={styles.itemTitle}>
                {t2(`SUB_BENEFITS.ITEMS.${key}.TITLE`)}
              </Text>
              <Text style={styles.itemText}>{t2(`SUB_BENEFITS.ITEMS.${key}.TEXT`)}</Text>
            </View>

            <Image source={ICON_CHECK} style={styles.itemIconCheck} />
          </View>
        </View>
      ))}
    </View>
  );
};

export default SubBenefits;

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
  },
  item: {
    marginTop: paddingVertical(5),
    width: '100%',
    borderRadius: 15,
    backgroundColor: COLORS.DARK_BLUE_GREY,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(15),
    paddingVertical: paddingVertical(10),
  },
  itemIcon: {
    width: 32,
    height: 32,
  },
  itemRight: {
    marginLeft: paddingHorizontal(15),
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemTexts: {
    flexShrink: 1,
    paddingRight: paddingHorizontal(10),
  },
  itemTitle: {
    fontSize: fs(17),
    lineHeight: fs(18),
    fontWeight: '700',
    color: COLORS.GOLDEN,
  },
  itemText: {
    marginTop: paddingVertical(5),
    fontSize: fs(13),
    color: color(COLORS.WHITE, 0.7),
  },
  itemIconCheck: {
    width: 20,
    height: 20,
  },
  gift: {
    position: 'absolute',
    right: paddingHorizontal(10),
    top: -sw(10),
    paddingHorizontal: paddingHorizontal(10),
    height: sw(20),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: sw(15),
  },
  giftTitle: {
    fontSize: fs(13),
    lineHeight: sw(20),
    fontWeight: '600',
    textAlign: 'center',
    color: COLORS.WHITE,
  },
});
