import { TYPES, InitialState, ActionTypes } from './types';

const initialState: InitialState = {
  flow: [],
  data: {
    skills: [],
    question: null,
  },
  isCompleted: false,
  step: null,
};

export default function (state = initialState, action: ActionTypes): InitialState {
  switch (action.type) {
    case TYPES.SET_ONBOARDING_FLOW:
      return {
        ...state,
        step: action.payload[0],
        flow: action.payload,
      };

    case TYPES.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case TYPES.SET_IS_COMPLETED:
      return {
        ...state,
        isCompleted: true,
      };

    case TYPES.UPDATE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}
