import { DefaultParams } from './interfaces';

export interface State {
  error: boolean;
  remoteConfigParams: DefaultParams;
  defaultParams: DefaultParams;
}

export const FETCH_REMOTE_CONFIG_FAIL = 'REMOTE_CONFIG/FETCH_REMOTE_CONFIG_FAIL';
export const SET_REMOTE_CONFIG = 'REMOTE_CONFIG/SET_REMOTE_CONFIG';
export const SET_ONBOARDING_FLOW = 'REMOTE_CONFIG/SET_ONBOARDING_FLOW';

interface FetchRemoteConfigFail {
  type: typeof FETCH_REMOTE_CONFIG_FAIL;
  payload: boolean;
}

interface SetRemoteConfig {
  type: typeof SET_REMOTE_CONFIG;
  payload: DefaultParams;
}

interface SetOnboardingFlow {
  type: typeof SET_ONBOARDING_FLOW;
  payload: Array<string>;
}

export type ActionTypes = FetchRemoteConfigFail | SetRemoteConfig | SetOnboardingFlow;
