import React, { FC } from 'react';

import vibrate from 'utils/vibration';

interface Props {
  haptic?: boolean;
  onPress?: (...args: any) => void;
}

const withHaptic = <P extends object>(Component: FC<P>) => {
  const withHapticEffect: FC<P & Props> = props => {
    const { haptic = true, onPress } = props;

    const handlePressWithVibration = (...params: any) => {
      if (haptic) {
        vibrate();
      }
      if (typeof onPress === 'function') {
        onPress(...params);
      }
    };

    return <Component {...(props as P)} onPress={handlePressWithVibration} />;
  };
  return withHapticEffect;
};

export default withHaptic;
