import { PDF_REPORTS } from 'constants/upsale';

export enum TYPES {
  SET_REPORT_VIEWED = 'UP_SALES/SET_REPORT_VIEWED',
}

export interface State {
  isViewed: boolean;
  viewedReports: PDF_REPORTS[];
}

interface SetReportViewed {
  type: typeof TYPES.SET_REPORT_VIEWED;
  payload: PDF_REPORTS;
}

export type ActionTypes = SetReportViewed;
