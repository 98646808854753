import { Platform } from 'react-native';
import { isEmpty, every } from 'lodash';
import dayjs from 'dayjs';

import type { AstroCalendarConfigTodo, AstroCalendarDBTodo, AstroCalendarDataDay } from '../interfaces';
import { ASTRO_CALENDAR_TODO_TYPE } from '../constants';

export const checkConfigTodos = (todos: AstroCalendarConfigTodo[]): AstroCalendarConfigTodo[] => {
  return todos.filter(todo => !!todo && !isEmpty(todo) && Object.values(ASTRO_CALENDAR_TODO_TYPE).includes(todo.type));
};

export const checkDBTodos = (todos: AstroCalendarDBTodo[]): AstroCalendarDBTodo[] => {
  return todos.filter(todo => {
    if (!todo || isEmpty(todo)) {
      return false;
    }

    const { type } = todo;

    switch (type) {
      case ASTRO_CALENDAR_TODO_TYPE.PWA: {
        return Platform.OS === 'web';
      }

      default: {
        return Object.values(ASTRO_CALENDAR_TODO_TYPE).includes(type);
      }
    }
  });
};

export const getDayTodosCompleted = (data: AstroCalendarDataDay): boolean => {
  if (dayjs(data.date).isAfter(dayjs(), 'day')) {
    return false;
  }

  if (!data.todos.length) {
    return true;
  }

  return every(data.todos, todo => todo.completed);
};
