import { Product } from '@wowmaking/react-native-billing';
import React, { memo, FC } from 'react';
import { ViewStyle, StyleProp, StyleSheet, TextStyle, View, Platform } from 'react-native';
import { InAppBrowser } from 'react-native-inappbrowser-reborn';
import { useSelector } from 'react-redux';
import UI from '@wowmaking/ui';
import { fs, sw, paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';

import Analytics from 'analytics';
import { PAYMENT_EVENTS_CATEGORIES } from 'analytics/const';
import { RootState } from 'store';
import type { SUB_SCREENS } from 'constants/subscription-screens';
import * as COLORS from 'constants/colors';

import RestoreButton from '../restore-button';
import Shimmer from '../shimmer';

type Props = {
  showTerms: boolean;
  showShimmer: boolean;
  product: Product | null;
  styleText?: StyleProp<TextStyle>;
  style?: StyleProp<ViewStyle>;
  linkTextStyle?: StyleProp<TextStyle>;
  onRestorePress?: () => void;
};

const TermsBlock: FC<Props> = ({ styleText, product, linkTextStyle, style, showTerms, showShimmer, onRestorePress }) => {
  const { termsLink, policyLink } = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams);
  const currentRoute = useSelector((state: RootState) => state.navigation.currentRoute);
  const trigger = useSelector((state: RootState) => state.unlockContent.currentTrigger?.trigger);

  const handlePolicyPress = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'PrivacyPolicy_Click', { trigger, screen: currentRoute as SUB_SCREENS });
    InAppBrowser.open(policyLink);
  };

  const handleTermsPress = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'TermsOfUse_Click', { trigger, screen: currentRoute as SUB_SCREENS });
    InAppBrowser.open(termsLink);
  };

  const textStyle: any = [styles.text, styleText];
  const rootStyle: any = [styles.root, style];

  if (!product || !showTerms) {
    return <View style={styles.hideTerms} />;
  }

  if (showShimmer) {
    return <Shimmer style={styles.shimmer} duration={1200} />;
  }

  const handleRestorePress = () => {
    if (typeof onRestorePress === 'function') {
      onRestorePress();
    }
  };

  return (
    <UI.Terms
      product={product}
      style={rootStyle}
      linkTextStyle={linkTextStyle}
      textStyle={textStyle}
      onTermsPress={handleTermsPress}
      onPrivacyPress={handlePolicyPress}
      divider={<RestoreButton style={styles.restoreButton} styleLabel={linkTextStyle} onPress={handleRestorePress} />}
    />
  );
};

export default memo(TermsBlock);

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    width: '100%',
    alignSelf: 'center',
  },
  text: {
    fontSize: Platform.OS === 'ios' ? fs(14) : fs(12),
    textAlign: 'center',
    marginRight: Platform.OS === 'ios' ? paddingHorizontal(5) : 0,
    color: COLORS.WHITE,
    opacity: 0.79,
  },
  hideTerms: {
    width: '100%',
    paddingVertical: Platform.OS === 'ios' ? paddingVertical(8) : paddingVertical(12),
  },
  shimmer: {
    height: sw(20),
    width: sw(200),
    borderRadius: sw(50),
    alignSelf: 'center',
  },
  restoreButton: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
  },
});
