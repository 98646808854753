import { forEach } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';
import type { AppDispatch, RootState } from 'store';
import { showNotification, setTodoCompleted } from 'store/astro-calendar/actions';

const useTrackAstroCalendarTodos = () => {
  const dispatch = useDispatch<AppDispatch>();
  const activeTodos = useSelector((state: RootState) => state.astroCalendar.activeTodos);
  const pendingTodos = useSelector((state: RootState) => state.astroCalendar.pendingTodos);

  useEffect(() => {
    if (activeTodos.length && pendingTodos.length) {
      forEach(activeTodos, activeTodo => {
        const todoData = pendingTodos.find(todo => {
          if (todo.type === activeTodo.type) {
            if (todo.type === ASTRO_CALENDAR_TODO_TYPE.NAVIGATE && activeTodo.type === ASTRO_CALENDAR_TODO_TYPE.NAVIGATE) {
              return todo.contentId === activeTodo.contentId;
            }
            return true;
          }
          return false;
        });

        if (todoData) {
          dispatch(setTodoCompleted(todoData));
          dispatch(showNotification(todoData));
          return false;
        }
      });
    }
  }, [activeTodos, dispatch, pendingTodos]);
};

export default useTrackAstroCalendarTodos;
