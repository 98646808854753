import { I18N_NAMESPACE } from '@wowmaking/ui/src/constants/general';
import wowUiAr from '@wowmaking/ui/src/i18n/locales/ar.json';
import wowUiDe from '@wowmaking/ui/src/i18n/locales/de.json';
import wowUiEn from '@wowmaking/ui/src/i18n/locales/en.json';
import wowUiEs from '@wowmaking/ui/src/i18n/locales/es.json';
import wowUifr from '@wowmaking/ui/src/i18n/locales/fr.json';
import wowUiPt from '@wowmaking/ui/src/i18n/locales/pt.json';
import wowUiJa from '@wowmaking/ui/src/i18n/locales/ja.json';

import { DEFAULT_LNG, DEFAULT_NS } from './const';
import coreAr from './translations/ar/core.json';
import coreDe from './translations/de/core.json';
import coreEn from './translations/en/core.json';
import coreEs from './translations/es-ES/core.json';
import coreFr from './translations/fr/core.json';
import corePt from './translations/pt-BR/core.json';
import coreJa from './translations/ja/core.json';
import horoscopesAr from './translations/ar/compatibility-love-horoscopes.json';
import horoscopesDe from './translations/de/compatibility-love-horoscopes.json';
import horoscopesEn from './translations/en/compatibility-love-horoscopes.json';
import horoscopesEs from './translations/es-ES/compatibility-love-horoscopes.json';
import horoscopesFr from './translations/fr/compatibility-love-horoscopes.json';
import horoscopesPt from './translations/pt-BR/compatibility-love-horoscopes.json';
import horoscopesJa from './translations/ja/compatibility-love-horoscopes.json';

export const resources = {
  ar: {
    [DEFAULT_NS]: {
      ...coreAr,
      ...horoscopesAr,
      [I18N_NAMESPACE]: wowUiAr,
    },
  },
  de: {
    [DEFAULT_NS]: {
      ...coreDe,
      ...horoscopesDe,
      [I18N_NAMESPACE]: wowUiDe,
    },
  },
  en: {
    [DEFAULT_NS]: {
      ...coreEn,
      ...horoscopesEn,
      [I18N_NAMESPACE]: wowUiEn,
    },
  },
  es: {
    [DEFAULT_NS]: {
      ...coreEs,
      ...horoscopesEs,
      [I18N_NAMESPACE]: wowUiEs,
    },
  },
  fr: {
    [DEFAULT_NS]: {
      ...coreFr,
      ...horoscopesFr,
      [I18N_NAMESPACE]: wowUifr,
    },
  },
  pt: {
    [DEFAULT_NS]: {
      ...corePt,
      ...horoscopesPt,
      [I18N_NAMESPACE]: wowUiPt,
    },
  },
  ja: {
    [DEFAULT_NS]: {
      ...coreJa,
      ...horoscopesJa,
      [I18N_NAMESPACE]: wowUiJa,
    },
  },
};

const DEFAULT_TRANSLATION = resources[DEFAULT_LNG][DEFAULT_NS];

export type DefaultTranslation = typeof DEFAULT_TRANSLATION;

export type SupportedLngs = keyof typeof resources;
