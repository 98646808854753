import React, { FC } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import { getAvailablePDFReports } from 'store/upsails/selectors';
import { t2 } from 'localization';
import Text from 'components/text';
import PersonalReportsList from 'components/personal-reports';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { OPEN_REPORTS_PLACES } from 'constants/personal-reports';

import ICON_CROSS from '../images/beige-cross.png';

const PersonalReportsModal: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.PERSONAL_REPORTS);
  const reports = useSelector(getAvailablePDFReports);

  if (!reports.length) {
    return null;
  }

  const handleClosePress = () => {
    dispatch(closeCurrentModal());
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <TouchableOpacity style={styles.cross} onPress={handleClosePress}>
          <Image source={ICON_CROSS} />
        </TouchableOpacity>
        <Text font="Philosopher" style={styles.title}>
          {t2('PERSONAL_REPORTS.TITLE')}
        </Text>
        <PersonalReportsList place={OPEN_REPORTS_PLACES.NOTIFICATION} reports={reports} onPress={handleClosePress} style={styles.list} />
        <Text style={styles.text}>{t2('PERSONAL_REPORTS.LABEL')}</Text>
      </View>
    </Modal>
  );
};

export default PersonalReportsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(40),
    paddingTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(32),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
  },
  cross: {
    position: 'absolute',
    top: paddingVertical(20),
    right: paddingHorizontal(20),
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: paddingVertical(20),
    fontSize: fs(24),
    fontWeight: '700',
    lineHeight: 28,
    textAlign: 'center',
  },
  list: {
    marginTop: paddingVertical(32),
  },
  text: {
    fontSize: fs(12),
    textAlign: 'center',
    color: COLORS.WHITE,
    opacity: 0.5,
    marginTop: paddingVertical(10),
  },
});
