import { PROFILE_TYPES } from '../profile/types';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  guides: [],
  surveyPassedFor: [],
  seed: null,
  followersIcons: [],
  sessions: [],
  sessionsLoading: false,
  userAnswers: {},
  hasMedal: false,
  podcastVoiceLevel: 1,
  podcastMusicLevel: 0.2,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        guides: action.payload.guides,
        seed: action.payload.seed,
      };

    case TYPES.SURVEY_PASSED:
      return {
        ...state,
        surveyPassedFor: state.surveyPassedFor.concat(action.payload),
      };

    case TYPES.SET_FOLLOWERS:
      return {
        ...state,
        followersIcons: action.payload,
      };

    case TYPES.SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
        sessionsLoading: false,
      };

    case TYPES.SAVE_ANSWERS:
      return {
        ...state,
        userAnswers: {
          ...state.userAnswers,
          [action.payload.sessionId]: action.payload.answers,
        },
      };
    case TYPES.CLEAR_SESSIONS:
      return {
        ...state,
        sessions: [],
      };
    case TYPES.SESSIONS_LOADING:
      return {
        ...state,
        sessionsLoading: true,
      };

    case TYPES.SET_MEDAL:
      return {
        ...state,
        hasMedal: true,
      };

    case TYPES.SET_AUDIO_LEVEL:
      return {
        ...state,
        podcastVoiceLevel: action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
