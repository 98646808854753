import React, { FC, useEffect } from 'react';
import { StyleSheet, View, Modal, Image, TouchableOpacity } from 'react-native';
import { changeIcon } from 'react-native-change-icon';
import { useDispatch, useSelector } from 'react-redux';
import { sw, paddingVertical, paddingHorizontal, fs } from '@wowmaking/ui/src/utils';

import Analytics from 'analytics';
import Button from 'components/button';
import Text from 'components/text';
import { APP_ICONS, APP_ICONS_TYPES, APP_ICONS_NAMES } from 'constants/app-icons';
import * as MODALS from 'constants/modals';
import * as COLORS from 'constants/colors';
import * as ICONS from 'constants/icons';
import { t } from 'localization';
import { AppDispatch, RootState } from 'store';
import { setAppIcon } from 'store/custom-app-icon/actions';
import { closeCurrentModal } from 'store/modals/actions';

const category = 'pro_icon_modal';

interface Props {}

const ChangeIconPremiumModal: FC<Props> = () => {
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal) === MODALS.CUSTOM_APP_ICON;

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent(category, 'open');
    }
  }, [isVisible]);

  const handleModalClose = () => {
    dispatch(closeCurrentModal());
  };

  const handleModalDecline = () => {
    Analytics.trackEvent(category, 'decline');
    dispatch(closeCurrentModal());
  };

  const handleChange = () => {
    Analytics.trackEvent(category, 'accept');
    changeIcon(APP_ICONS_NAMES[APP_ICONS_TYPES.PRO] as string).then(() => {
      dispatch(setAppIcon(APP_ICONS_TYPES.PRO));
      dispatch(closeCurrentModal());
    });
  };

  return (
    <Modal transparent hardwareAccelerated animationType={'slide'} onRequestClose={handleModalClose} visible={isVisible}>
      <View style={styles.modalContainer}>
        <View style={styles.modal}>
          <TouchableOpacity style={styles.closeIconWrapper} onPress={handleModalClose}>
            <Image resizeMode={'cover'} style={styles.closeIcon} source={ICONS.CLOSE_ICON} />
          </TouchableOpacity>
          <Text font="Philosopher" style={styles.modalTitle}>
            {t('PRO_ICON.TITLE')}
          </Text>
          <Text style={styles.modalSubtitle}>{t('PRO_ICON.SUBTITLE')}</Text>
          <Image source={APP_ICONS[APP_ICONS_TYPES.PRO]} style={styles.icon} resizeMode={'contain'} />
          <Text style={styles.modalText}>{t('PRO_ICON.TEXT')}</Text>

          <View style={styles.buttons}>
            <Button style={styles.button} titleStyle={styles.buttonText} title={t('PRO_ICON.BUTTON')} onPress={handleChange} />
            <Button
              fontFamily="OpenSans"
              isTransparent
              style={styles.skipButton}
              titleStyle={styles.skipButtonTitle}
              title={t('PRO_ICON.BUTTON_SKIP')}
              onPress={handleModalDecline}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ChangeIconPremiumModal;

const styles = StyleSheet.create({
  modalContainer: {
    backgroundColor: COLORS.SURVEY_MODAL_BACKGROUND,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: paddingHorizontal(15),
  },
  modal: {
    backgroundColor: COLORS.MODAL_BG_COLOR,
    borderRadius: sw(20),
    padding: paddingHorizontal(20),
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalTitle: {
    fontSize: fs(24),
    paddingTop: paddingVertical(20),
    color: COLORS.BEIGE_2,
    fontWeight: '700',
    alignSelf: 'center',
    textAlign: 'center',
  },
  modalSubtitle: {
    color: COLORS.WHITE,
    paddingTop: paddingVertical(10),
    alignSelf: 'center',
    fontSize: fs(16),
    lineHeight: fs(22),
    textAlign: 'center',
    paddingHorizontal: paddingHorizontal(10),
  },
  modalText: {
    color: COLORS.WHITE,
    opacity: 0.6,
    paddingTop: paddingVertical(25),
    alignSelf: 'center',
    fontSize: fs(11),
    lineHeight: fs(18),
    textAlign: 'center',
  },
  icon: {
    marginTop: paddingVertical(25),
    width: sw(140),
    height: sw(140),
    borderRadius: sw(15),
  },
  buttons: {
    marginTop: paddingVertical(15),
    paddingHorizontal: paddingHorizontal(10),
    alignItems: 'center',
  },
  button: {},
  buttonText: {
    fontSize: fs(20),
    lineHeight: fs(22),
    color: COLORS.WHITE,
    width: '100%',
  },
  closeIconWrapper: {
    top: paddingHorizontal(20),
    right: paddingHorizontal(20),
    position: 'absolute',
  },
  closeIcon: {
    width: sw(20),
    height: sw(20),
  },
  skipButton: {
    marginTop: paddingVertical(15),
  },
  skipButtonTitle: {
    fontWeight: '400',
    fontSize: fs(18),
    lineHeight: fs(20),
    color: COLORS.WHITE,
    paddingHorizontal: paddingHorizontal(20),
  },
});
