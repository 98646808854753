import RNSGP from 'react-native-shared-group-preferences';
import RNWidgetCenter from 'react-native-widget-center';

import { WIDGETS_GROUP } from 'constants/native-widgets';

export default {
  fetchData() {
    return RNSGP.getItem('data', WIDGETS_GROUP);
  },

  pushData(data: any) {
    return RNSGP.setItem('data', data, WIDGETS_GROUP);
  },

  pushConfig(config: any) {
    return RNSGP.setItem('config', config, WIDGETS_GROUP);
  },

  pushOrders(orderIds: any) {
    return RNSGP.setItem('orders', orderIds, WIDGETS_GROUP);
  },

  getCurrentConfigurations() {
    return RNWidgetCenter.getCurrentConfigurations();
  },

  reloadTimelines() {
    return RNWidgetCenter.reloadTimelines(WIDGETS_GROUP);
  },

  reloadAllTimelines() {
    return RNWidgetCenter.reloadAllTimelines();
  },
};
