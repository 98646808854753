import type { LanguageDetectorAsyncModule } from 'i18next';
import { getLocales } from 'react-native-localize';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { STORAGE_LNG_KEY } from './const';

export const languageDetector: LanguageDetectorAsyncModule = {
  type: 'languageDetector',
  async: true,
  async detect() {
    let lng: string | null = null;

    try {
      lng = await AsyncStorage.getItem(STORAGE_LNG_KEY);
    } catch (err) {
      console.warn(err);
    }

    if (lng) {
      return lng;
    }

    return getLocales()[0].languageTag;
  },
  async cacheUserLanguage(lng) {
    try {
      await AsyncStorage.setItem(STORAGE_LNG_KEY, lng);
    } catch (err) {
      console.warn(err);
    }
  },
};
