import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getBiorhythms } from 'api/biorhythms';

import { TYPES } from './types';

const setBiorhythms = createAction(TYPES.SET);

export const initBiorhythms = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const biorhythms = await getBiorhythms();
      dispatch(setBiorhythms(biorhythms));
    } catch (error) {
      console.log('[ERROR BIORHYTHMS]: ', error);
    }
  };
};
