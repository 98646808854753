import { Platform } from 'react-native';

import * as NSUserActivityTypes from '../constants/ns-user-activity-types';
import { t } from 'localization';

export default {
  supported: Platform.OS === 'ios' && +Platform.Version.split('.').shift() >= 12,

  getHoroscopeShortcut: () => {
    return {
      activityType: NSUserActivityTypes.HOROSCOPE, // Has to be set in `NSUserActivityTypes` in Info.plist first
      title: t('SIRI_SHORTCUTS.HOROSCOPE.TITLE'),
      persistentIdentifier: NSUserActivityTypes.HOROSCOPE,
      isEligibleForSearch: true,
      isEligibleForPrediction: true,
      suggestedInvocationPhrase: t('SIRI_SHORTCUTS.HOROSCOPE.PHRASE'),
    };
  },
};
