import { createSelector } from 'reselect';
import moment from 'moment';

import { FADED_ORANGE, AQUA, LIGHT_MUSTARD, LILAC } from 'constants/colors';
import { ADVISORS_CHAT } from 'constants/routes';
import {
  SEEDS,
  LIFE_FROM_TO_MAP,
  TraitsMap,
  TRAITS_ARRAY,
  TraitsType,
  RIGHT_HAND_DIFF,
  MARRIAGE_AGE_DIFF,
  MIN_MARRIAGE_AGE,
  MAX_MARRIAGE_AGE,
  EARNINGS_PERCENTAGES,
  EARNINGS_PERCENTAGE_FIRST,
  EARNINGS_PERCENTAGE_NEXT,
  MARRIAGE_PERCENTAGES,
  RISK_PERCENTAGES,
  FINGERS,
  FINGER_IMAGES,
} from 'constants/palm-reading-daily';
import { t, t2 } from 'localization';
import {
  getRandomPercentage,
  percentageToDescVariant,
  calcLanguageDistribution,
  percentageToHealthDescVariant,
  calcDominantTraits,
} from 'screens/palm-reading-daily/components/report/util';
import { RootState } from 'store';
import { getRandomInRange } from 'utils/seedrandom';
import languages from 'screens/palm-reading-daily/components/report/sections/love/languages';
import { selectAge } from 'store/profile/selectors';
import { LONG_PATTERN } from 'constants/moment';

type Career = {
  percentage: number;
  description: string;
  careerLists: {
    strengths: Array<{
      id: TraitsType;
      label: string;
    }>;
    weaknesses: Array<{
      id: TraitsType;
      label: string;
    }>;
  };
};

const I18N_SCOPE = 'PALM_READING_DAILY.OVERVIEW.LEFT_HAND';

const getLeftHandData = (state: RootState) => state.palmReadingDaily.data?.leftHandOverviewResults;
const getWasWebDataShown = (state: RootState) => state.palmReadingDaily.wasWebDataShowed;
const getCompletedSteps = (state: RootState) => state.palmReadingDaily.completedSteps;
const isAdvisorChat = (state: RootState) => state.navigation.currentRoute === ADVISORS_CHAT;
const getLeftPalmImage = (state: RootState) => state.palmReadingDaily.left?.image;
const getAuthId = (state: RootState) => state.auth.id;
const getRelationshipStatus = (state: RootState) => state.profile.profileData.relationship;

export const selectShowLeftHandWebData = createSelector([getLeftHandData, getWasWebDataShown], (leftHandDataFromWeb, wasWebDataShowed) => {
  return leftHandDataFromWeb && !wasWebDataShowed;
});

const getAstrologersLeftHandMessage = (career: Career, dominantTraits: any, health: any, love: any, overview: any) => {
  const overviewTexts = overview.texts;
  const loveHiddenMessage = `Love: Children: ${love.children.description}; Language: ${love.language.description}`;
  const healthHiddenMessage = `Life: Description: ${health.description}; ${health.title};`;
  const dominantTraitsHiddenMessage = dominantTraits.traitsDescriptions.reduce((acc, { description }) => `${acc} ${description};`, 'Wisdom: ');
  const careerWeaknesses = Object.values(career.careerLists.weaknesses).reduce((acc, { label }) => `${acc} ${label};`, 'Weaknesses: ');
  const careerStrengths = Object.values(career.careerLists.strengths).reduce((acc, { label }) => `${acc} ${label};`, 'Strengths: ');
  const careerHiddenMessage = `Career: ${career.description}; ${careerStrengths}; ${careerWeaknesses}`;
  const overviewHiddenMessage = overviewTexts.reduce(
    (acc, { title, percentage, description }) => `${acc} Title: ${title}, text: ${description}, percentage: ${percentage};`,
    'Overview: ',
  );

  const message = `${overviewHiddenMessage} ${loveHiddenMessage} ${healthHiddenMessage} ${dominantTraitsHiddenMessage} ${careerHiddenMessage}`;

  return message;
};

export const selectLeftHandData = createSelector(
  [getLeftHandData, getCompletedSteps, getAuthId, getLeftPalmImage],
  (leftHandDataFromWeb, completedSteps, authId, leftPalmImage) => {
    const date = completedSteps.LEFT_HAND || moment().format(LONG_PATTERN);

    if (!date) {
      return undefined;
    }

    const seed = String(authId) + date;

    const getOverview = () => {
      const heartLine = leftPalmImage ? getRandomPercentage(seed + SEEDS.HEART) : leftHandDataFromWeb?.love;
      const lifeLine = leftPalmImage ? getRandomPercentage(seed + SEEDS.LIFE) : leftHandDataFromWeb?.health;
      const headLine = leftPalmImage ? getRandomPercentage(seed + SEEDS.HEAD) : leftHandDataFromWeb?.wisdom;
      const fateLine = leftPalmImage ? getRandomPercentage(seed + SEEDS.FATE) : leftHandDataFromWeb?.career;

      const texts = [
        {
          title: t(`${I18N_SCOPE}.HEART_LINE.TITLE`),
          description: t(`${I18N_SCOPE}.HEART_LINE.DESC_${percentageToDescVariant(heartLine)}`),
          color: FADED_ORANGE,
          percentage: heartLine,
        },
        {
          title: t(`${I18N_SCOPE}.LIFE_LINE.TITLE`),
          description: t(`${I18N_SCOPE}.LIFE_LINE.DESC_${percentageToDescVariant(lifeLine)}`),
          color: AQUA,
          percentage: lifeLine,
        },
        {
          title: t(`${I18N_SCOPE}.HEAD_LINE.TITLE`),
          description: t(`${I18N_SCOPE}.HEAD_LINE.DESC_${percentageToDescVariant(headLine)}`),
          color: LIGHT_MUSTARD,
          percentage: headLine,
        },
        {
          title: t(`${I18N_SCOPE}.FATE_LINE.TITLE`),
          description: t(`${I18N_SCOPE}.FATE_LINE.DESC_${percentageToDescVariant(fateLine)}`),
          color: LILAC,
          percentage: fateLine,
        },
      ];

      return {
        heartLine,
        lifeLine,
        headLine,
        fateLine,
        texts,
      };
    };

    const getLove = () => {
      const languageChart = calcLanguageDistribution(seed);
      const languageIndex = languageChart.findIndex(item => item === Math.max(...languageChart));
      const languageKey = Object.keys(languages)[languageIndex];

      const childrenChart = [
        Math.round(getRandomInRange(3, 5, seed + SEEDS.CHILDREN_0)),
        Math.round(getRandomInRange(34, 45, seed + SEEDS.CHILDREN_1)),
        Math.round(getRandomInRange(41, 46, seed + SEEDS.CHILDREN_2)),
        Math.round(getRandomInRange(10, 12, seed + SEEDS.CHILDREN_3)),
        Math.round(getRandomInRange(1, 3, seed + SEEDS.CHILDREN_X)),
      ];
      const numOfChildren = childrenChart.indexOf(Math.max(...childrenChart));

      return {
        language: {
          chart: languageChart,
          description: t2(`PALM_READING_DAILY.LOVE.LANGUAGE.${languageKey}.DESC`),
          searchWords: t2(`PALM_READING_DAILY.LOVE.LANGUAGE.${languageKey}.TITLE`),
        },
        children: {
          chart: childrenChart,
          description: t2(`PALM_READING_DAILY.LOVE.CHILDREN.${numOfChildren}.DESC`),
          searchWords: t2(`PALM_READING_DAILY.LOVE.CHILDREN.${numOfChildren}.HIGHLIGHTED_WORDS`),
        },
      };
    };

    const getHealth = () => {
      const lifeLine = getRandomPercentage(seed + SEEDS.LIFE);
      const lifeFromTo = LIFE_FROM_TO_MAP[percentageToHealthDescVariant(lifeLine)];
      const label = t('PALM_READING_DAILY.HEALTH.LEFT_HAND.LIFE_EXPECTANCY.LABEL');
      const value = t('PALM_READING_DAILY.HEALTH.LEFT_HAND.LIFE_EXPECTANCY.VALUE', {
        count: lifeFromTo.to,
        from: lifeFromTo.from,
        to: lifeFromTo.to,
      }).other;

      return {
        percentage: lifeLine,
        lifeFromTo,
        title: `${label}${value}`,
        description: t(`PALM_READING_DAILY.HEALTH.LEFT_HAND.DESC_${percentageToHealthDescVariant(lifeLine)}`),
      };
    };

    const getDominantTraits = () => {
      const transits = calcDominantTraits(seed) as TraitsMap;

      const traitsDescriptions = TRAITS_ARRAY.reduce<{ searchWords: string; description: string }[]>((acc, id) => {
        if (transits[id as TraitsType]) {
          return [
            ...acc,
            {
              searchWords: t2(`PALM_READING_DAILY.WISDOM.LEFT_HAND.TRAITS.${id}.HIGHLIGHTED_WORDS`),
              description: t2(`PALM_READING_DAILY.WISDOM.LEFT_HAND.TRAITS.${id}.DESC`),
            },
          ];
        }

        return acc;
      }, []);

      return {
        transits,
        traitsDescriptions,
      };
    };

    const getCareer = (): Career => {
      const transits = calcDominantTraits(seed) as TraitsMap;
      const fateLine = getRandomPercentage(seed + SEEDS.FATE);
      const description = t(`PALM_READING_DAILY.CAREER.LEFT_HAND.DESC_${percentageToDescVariant(fateLine)}`);
      const careerLists = (TRAITS_ARRAY as Array<TraitsType>).reduce<{
        strengths: Array<{
          id: TraitsType;
          label: string;
        }>;
        weaknesses: Array<{
          id: TraitsType;
          label: string;
        }>;
      }>(
        (acc, id) => {
          if (transits[id]) {
            acc.strengths.push({
              id,
              label: t(`PALM_READING_DAILY.CAREER.LEFT_HAND.TRAITS.${id}.STRENGTH`),
            });
            acc.weaknesses.push({
              id,
              label: t(`PALM_READING_DAILY.CAREER.LEFT_HAND.TRAITS.${id}.WEAKNESS`),
            });
          }
          return acc;
        },
        {
          strengths: [],
          weaknesses: [],
        },
      );

      return {
        careerLists,
        description,
        percentage: fateLine,
      };
    };

    const overview = getOverview();
    const love = getLove();
    const health = getHealth();
    const dominantTraits = getDominantTraits();
    const career = getCareer();

    return {
      overview,
      love,
      health,
      dominantTraits,
      career,
      astrologersMessage: getAstrologersLeftHandMessage(career, dominantTraits, health, love, overview),
    };
  },
);

const getAstrologersRightHandContext = (overview, marriage, health, wisdom, career) => {
  const marriageHIddenMessage = `Marriage: ${marriage.description}`;
  const healthRisks = health.risks.reduce((acc, item) => `${acc} ${item.label} - ${item.percentage};`, 'Risk of:');
  const overviewHiddenMessage = overview.texts.reduce(
    (acc, { percentage, description }) => `${acc} Text: ${description}, percentage: ${percentage};`,
    'Overview:',
  );
  const healthHiddenMessage = `Health: ${healthRisks} Description: ${health.description};`;
  const wisdomWeaknesses = Object.values(wisdom.lists.weaknesses).reduce((acc, item) => `${acc} ${item};`, 'Weaknesses:');
  const wisdomStrengths = Object.values(wisdom.lists.strengths).reduce((acc, item) => `${acc} ${item};`, 'Strengths:');
  const wisdomHiddenMessage = `Wisdom: ${wisdom.description}; ${wisdomStrengths}; ${wisdomWeaknesses}`;
  const careerHiddenMessage = `${career.description}`;
  const message = `${overviewHiddenMessage} ${marriageHIddenMessage} ${healthHiddenMessage} ${wisdomHiddenMessage} ${careerHiddenMessage}`;

  return message;
};

export const selectRightHandData = createSelector(
  [getCompletedSteps, getAuthId, selectAge, getRelationshipStatus, isAdvisorChat],
  (completedSteps, authId, age, relationship, isChat) => {
    const date = isChat && !completedSteps.RIGHT_HAND ? moment().format(LONG_PATTERN) : completedSteps.RIGHT_HAND;
    const leftHandDate = isChat && !completedSteps.LEFT_HAND ? moment().format(LONG_PATTERN) : completedSteps.LEFT_HAND;

    if (!date || !leftHandDate) {
      return undefined;
    }

    const seed = String(authId) + date;
    const leftHandSeed = String(authId) + date;

    const getStatistics = () => {
      const getRandomDiffPercentage = (uniqSeed: string) => {
        return Math.round(getRandomInRange(RIGHT_HAND_DIFF.MIN, RIGHT_HAND_DIFF.MAX, uniqSeed));
      };

      const heartDiff = getRandomDiffPercentage(seed + SEEDS.HEART_DIFF);
      const lifeDiff = getRandomDiffPercentage(seed + SEEDS.LIFE_DIFF);
      const headDiff = getRandomDiffPercentage(seed + SEEDS.HEAD_DIFF);
      const fateDiff = getRandomDiffPercentage(seed + SEEDS.FATE_DIFF);

      const heartLine = getRandomPercentage(leftHandSeed + SEEDS.HEART) + heartDiff;
      const lifeLine = getRandomPercentage(leftHandSeed + SEEDS.LIFE) + headDiff;
      const headLine = getRandomPercentage(leftHandSeed + SEEDS.HEAD) + lifeDiff;
      const fateLine = getRandomPercentage(leftHandSeed + SEEDS.FATE) + fateDiff;

      return {
        lines: {
          heartLine,
          lifeLine,
          headLine,
          fateLine,
        },
        diffs: {
          heartDiff,
          lifeDiff,
          headDiff,
          fateDiff,
        },
      };
    };

    const getOverview = () => {
      const { lines, diffs } = getStatistics();
      const texts = [
        {
          description: t(`PALM_READING_DAILY.OVERVIEW.RIGHT_HAND.HEART_LINE.DESC_${percentageToDescVariant(lines.heartLine)}`),
          percentage: lines.heartLine,
        },
        {
          description: t(`PALM_READING_DAILY.OVERVIEW.RIGHT_HAND.LIFE_LINE.DESC_${percentageToDescVariant(lines.lifeLine)}`),
          percentage: lines.lifeLine,
        },
        {
          description: t(`PALM_READING_DAILY.OVERVIEW.RIGHT_HAND.HEAD_LINE.DESC_${percentageToDescVariant(lines.headLine)}`),
          percentage: lines.headLine,
        },
        {
          description: t(`PALM_READING_DAILY.OVERVIEW.RIGHT_HAND.FATE_LINE.DESC_${percentageToDescVariant(lines.fateLine)}`),
          percentage: lines.fateLine,
        },
      ];

      return {
        diffs,
        lines,
        texts,
      };
    };

    const getMarriage = () => {
      const calcMarriageAge = (uniqSeed: string, userAge: number, relationshipStatus: string) => {
        const marriageAge =
          userAge +
          Math.round(
            getRandomInRange(
              MARRIAGE_AGE_DIFF[relationshipStatus]?.MIN || 0,
              MARRIAGE_AGE_DIFF[relationshipStatus]?.MAX || 0,
              uniqSeed + SEEDS.MARRIAGE_AGE,
            ),
          );
        return Math.min(Math.max(marriageAge, MIN_MARRIAGE_AGE), MAX_MARRIAGE_AGE);
      };

      const marriageAge = calcMarriageAge(seed, age, relationship);
      const positionX = age / 20 - 1;
      const tooltipPositionX = positionX >= 0 ? positionX : 0;
      const languageChart = calcLanguageDistribution(leftHandSeed);
      const percentages = [SEEDS.MARRIAGE_20, SEEDS.MARRIAGE_40, SEEDS.MARRIAGE_60, SEEDS.MARRIAGE_80].map(marriageSeed =>
        Math.round(getRandomInRange(MARRIAGE_PERCENTAGES[marriageSeed].MIN, MARRIAGE_PERCENTAGES[marriageSeed].MAX, seed + marriageSeed)),
      );

      const description = `${t(`PALM_READING_DAILY.LOVE.MARRIAGE_AGE.DESC_START`, { age: marriageAge })} ${t(
        `PALM_READING_DAILY.LOVE.MARRIAGE_AGE.DESC_END.${percentageToDescVariant(languageChart)}`,
      )}`;
      return {
        age: marriageAge,
        chart: {
          percentages,
          tooltipPositionX,
        },
        languageChart,
        description,
      };
    };

    const getHealth = () => {
      const description = t(`PALM_READING_DAILY.HEALTH.RIGHT_HAND.DESC_${percentageToHealthDescVariant(getStatistics().lines.lifeLine)}`);
      const risks = [
        SEEDS.RISK_RESPIRATORY_DISEASE,
        SEEDS.RISK_ACCIDENT,
        SEEDS.RISK_HEART_DISEASE,
        SEEDS.RISK_CANCER,
        SEEDS.RISK_ALZHEIMER,
        SEEDS.RISK_STROKE,
      ].map(seedItem => ({
        id: seedItem,
        percentage: Math.round(getRandomInRange(0, RISK_PERCENTAGES[seedItem] || 0, seedItem)),
        label: t2(`PALM_READING_DAILY.HEALTH.RIGHT_HAND.RISKS.${seedItem.toUpperCase()}`),
      }));

      return {
        risks,
        description,
      };
    };

    const getWisdom = () => {
      const dominantTraits = calcDominantTraits(leftHandSeed);
      const lists = (TRAITS_ARRAY as Array<TraitsType>).reduce<{
        strengths: Array<{
          id: TraitsType;
          label: string;
        }>;
        weaknesses: Array<{
          id: TraitsType;
          label: string;
        }>;
      }>(
        (acc, id) => {
          if (dominantTraits[id]) {
            acc.strengths.push({
              id,
              label: t(`PALM_READING_DAILY.CAREER.LEFT_HAND.TRAITS.${id}.STRENGTH`),
            });
            acc.weaknesses.push({
              id,
              label: t(`PALM_READING_DAILY.CAREER.LEFT_HAND.TRAITS.${id}.WEAKNESS`),
            });
          }
          return acc;
        },
        {
          strengths: [],
          weaknesses: [],
        },
      );

      return {
        lists,
        description: t(`PALM_READING_DAILY.WISDOM.RIGHT_HAND.DESC_${percentageToDescVariant(getStatistics().lines.headLine)}`),
      };
    };

    const getCareer = () => {
      const positionX = age / 20 - 1;
      const tooltipPositionX = Math.ceil(positionX >= 0 ? positionX : 0);
      const descVariant = getStatistics().lines.fateLine ? '1' : '0';
      const percentages = [SEEDS.EARNINGS_20, SEEDS.EARNINGS_40, SEEDS.EARNINGS_60, SEEDS.EARNINGS_80].map((earningsSeed, index) => {
        if (index !== EARNINGS_PERCENTAGE_FIRST && index === tooltipPositionX) {
          return EARNINGS_PERCENTAGE_NEXT;
        }
        return Math.round(getRandomInRange(EARNINGS_PERCENTAGES[earningsSeed].MIN, EARNINGS_PERCENTAGES[earningsSeed].MAX, seed + earningsSeed));
      });

      return {
        percentages,
        tooltipPositionX,
        description: t(`PALM_READING_DAILY.CAREER.RIGHT_HAND.DESC_${descVariant}`),
      };
    };

    const statistics = getStatistics();
    const overview = getOverview();
    const marriage = getMarriage();
    const health = getHealth();
    const wisdom = getWisdom();
    const career = getCareer();

    return {
      statistics,
      overview,
      marriage,
      health,
      wisdom,
      career,
      astrologersMessage: getAstrologersRightHandContext(overview, marriage, health, wisdom, career),
    };
  },
);

export const selectFingersData = createSelector([getCompletedSteps, getAuthId], (completedSteps, authId) => {
  const date = completedSteps.FINGERS;

  if (!date) {
    return undefined;
  }

  const seed = String(authId) + date;

  const getThumbLabel = (percentage: number) => {
    if (percentage >= 90) {
      return 'PERFECT';
    }

    if (percentage >= 80) {
      return 'EXCELLENT';
    }

    if (percentage >= 60) {
      return 'GOOD';
    }

    return 'GENERAL';
  };

  const fingers = Object.keys(FINGERS).map((id, index) => {
    const percentage = Math.round(getRandomInRange(60, 100, seed + id));
    const label = t2(`PALM_READING_DAILY.FINGERS.PROGRESS_LABEL.${getThumbLabel(percentage)}`);
    const description = t2(`PALM_READING_DAILY.FINGERS.${id}.DESC`);
    const title = t2(`PALM_READING_DAILY.FINGERS.${id}.TITLE`);
    const subtitle = t2(`PALM_READING_DAILY.FINGERS.${id}.SUBTITLE`);

    return {
      id,
      percentage,
      title,
      subtitle,
      label,
      description,
      image: FINGER_IMAGES[id],
      num: index + 1,
    };
  });

  const astrologersMessage = fingers.reduce(
    (acc, item) => `${acc} ${item.title} - ${item.subtitle}: Percentage: ${item.percentage} - ${item.label}; Description: ${item.description};`,
    'Fingers:',
  );

  return {
    fingers,
    astrologersMessage,
  };
});
