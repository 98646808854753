import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  icons: [],
  count: 0,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        icons: action.payload.icons,
        count: action.payload.count,
      };
    default:
      return state;
  }
}
