import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  step: 0,
  currentTrigger: null,
  lastTrigger: null,
  isBackgroundPlacementVisible: false,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case TYPES.SET_CURRENT_TRIGGER:
      return {
        ...state,
        currentTrigger: action.payload,
      };
    case TYPES.SET_LAST_TRIGGER:
      return {
        ...state,
        lastTrigger: action.payload,
      };

    case TYPES.SET_BACKGROUND_PLACEMENT_VISIBILITY:
      return {
        ...state,
        isBackgroundPlacementVisible: action.payload,
      };
    default:
      return state;
  }
}
