import type { AppDispatch, AppGetState } from 'store';

export const increaseShowPromoCount = () => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {};
};

export const activatePromoOffers = () => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {};
};

export const schedulePromoOfferNotifications = () => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {};
};

export const deactivatePromoOffer = () => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {};
};

export const isShowPromoLimitReached = () => {
  return (_dispatch: AppDispatch, _getState: AppGetState) => {};
};

export const initPromoOffers = () => {
  return (_dispatch: AppDispatch, _getState: AppGetState) => {};
};
