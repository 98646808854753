import api from '..';

import { BestMatch, BestMatches, Rate } from './interfaces';

const BEST_MATCHES = '/best-matches';

export const getBestMatches = (): Promise<BestMatches> => {
  return api.fetch(BEST_MATCHES, {
    method: 'GET',
  });
};

export const rateBestMatches = (id: BestMatch['id'], mark: 'like' | 'dislike'): Promise<Rate> => {
  return api.fetch(`${BEST_MATCHES}/${mark}/${id}`, {
    method: 'POST',
  });
};
