import { createAction } from 'redux-actions';

import { AppDispatch, AppGetState } from 'store';
import Analytics from 'analytics';
import { ONBOARDING_SCREENS } from 'constants/onboarding-types';

import { whereToNavigate, reset } from '../navigation/actions';
import { requestReviewOnOnboarding } from '../rate-us/actions';

import { selectOnboardingSteps } from './selectors';
import { TYPES } from './types';

export const setOnboardingProfile = createAction(TYPES.SET_ONBOARDING_PROFILE);
export const setOnboardingStep = createAction(TYPES.SET_ONBOARDING_STEP);
export const setOnboardingCompleted = createAction(TYPES.SET_IS_ONBOARDING_COMPLETED);
export const setOnboardingStepPassed = createAction(TYPES.SET_ONBOARDING_STEP_PASSED);
export const resetOnboardingData = createAction(TYPES.RESET_ONBOARDING_DATA);

export function stepNavigate(isInitApp = false) {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const onboardingSteps = selectOnboardingSteps(getState());
    const firstStep = onboardingSteps[0];

    if (onboardingSteps?.length && Object.keys(ONBOARDING_SCREENS).includes(firstStep)) {
      if (isInitApp) {
        Analytics.trackEvent('init_app', 'navigation_onboarding_first_screen', { screen: firstStep });
      }
      dispatch(setOnboardingStep({ step: firstStep }));
      reset(ONBOARDING_SCREENS[firstStep]);

      dispatch(requestReviewOnOnboarding(firstStep));
    } else {
      if (isInitApp) {
        Analytics.trackEvent('init_app', 'navigation_onboarding_go_next');
      }
      dispatch(goToNextStep());
    }
  };
}

export function goToNextStep() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      onboarding: { step, isOnboardingCompleted },
    } = getState();
    const onboardingSteps = selectOnboardingSteps(getState());
    const firstStep = onboardingSteps[0];

    if (isOnboardingCompleted) {
      return dispatch(whereToNavigate());
    }

    if (onboardingSteps.length <= 0) {
      dispatch(onboardingCompleted());
      return dispatch(whereToNavigate());
    }

    dispatch(setOnboardingStep({ step: firstStep }));
    dispatch(setOnboardingStepPassed({ step }));

    dispatch(requestReviewOnOnboarding(firstStep));

    return reset(ONBOARDING_SCREENS[firstStep]);
  };
}

export function onboardingCompleted() {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    if (getState().onboarding.isOnboardingCompleted) {
      return;
    }

    Analytics.trackEvent('onboarding_flow', 'success');
    dispatch(setOnboardingCompleted(true));
  };
}
