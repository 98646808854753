import { useEffect } from 'react';

import { getAnswers } from 'api/astrology-chat';
import { useAppDispatch, useAppSelector } from 'store';
import { addNewMessage, countUnreadMessages, updateChatInteractionStatus, setMessagesRead } from 'store/astrologers/chat/actions';
import { ADVISORS_CHAT } from 'constants/routes';

const useChatAnswers = () => {
  const dispatch = useAppDispatch();
  const pendingChats = useAppSelector(state => state.astrologers.chat.pendingChats);
  const currentRoute = useAppSelector(state => state.navigation.currentRoute);
  const currentAstrologerId = useAppSelector(state => state.astrologers.chat.currentAstrologerId);
  const receiveTarot = useAppSelector(state => state.remoteConfig.remoteConfigParams?.receiveTarot);

  useEffect(() => {
    let interval: any;
    const intervalTime = currentRoute !== ADVISORS_CHAT ? 5000 : 1000;
    if (pendingChats.length) {
      interval = setInterval(() => {
        pendingChats.forEach(async id => {
          const res = await getAnswers(id, !!receiveTarot);
          const messages = [...res.messages.reverse()];

          dispatch(updateChatInteractionStatus(id, !!res?.isTyping, !!res?.isRecording));

          if (messages?.length) {
            dispatch(addNewMessage(id, messages));
            if (currentRoute === ADVISORS_CHAT && currentAstrologerId === id) {
              dispatch(setMessagesRead(id));
            } else {
              dispatch(countUnreadMessages());
            }
          }
        });
      }, intervalTime);
    }

    return () => {
      clearInterval(interval);
    };
  }, [pendingChats, currentRoute, receiveTarot]);
};

export default useChatAnswers;
