import React, { FC } from 'react';
import { Image, ImageSourcePropType, ImageStyle, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import Text from 'components/text';
import { BEIGE, DARK_LAVENDER } from 'constants/colors';
import ASTROLOGERS from 'assets/images/astrologers-promo/astrologers-promo-small.png';
import HighLightText from 'components/hight-light-text';

import ARROW from './image/arrow.png';
import LIGHT_IMG from './image/light.png';

interface Props {
  onPress: () => void;
  title: string;
  subTitle?: string;
  style?: StyleProp<ViewStyle>;
  image?: ImageSourcePropType;
  withLight?: boolean;
  imageStyle?: StyleProp<ImageStyle>;
}

const BannerSmall: FC<Props> = ({ onPress, title, subTitle, image = ASTROLOGERS, withLight = false, imageStyle, style }) => {
  const handlePress = () => {
    onPress();
  };

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={handlePress}>
      {withLight ? <Image style={styles.lightImg} source={LIGHT_IMG} /> : null}
      <Image source={image} style={imageStyle} />
      <View style={styles.texts}>
        <Text font="Philosopher" style={styles.title}>
          {title}
        </Text>
        {!!subTitle && (
          <HighLightText style={styles.subTitle} highlightedTextStyle={styles.subTitleHightLight}>
            {subTitle}
          </HighLightText>
        )}
      </View>
      <Image source={ARROW} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: sw(10),
    backgroundColor: DARK_LAVENDER,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: paddingHorizontal(10),
    paddingLeft: paddingHorizontal(5),
    overflow: 'hidden',
  },
  lightImg: {
    position: 'absolute',
    height: '100%',
    left: 0,
  },
  texts: {
    flex: 1,
    marginLeft: paddingHorizontal(10),
    paddingVertical: paddingVertical(14),
  },
  title: {
    width: '100%',
    flexWrap: 'wrap',
    fontWeight: '700',
    color: BEIGE,
    fontSize: fs(15),
    lineHeight: fs(18),
  },
  subTitle: {
    marginTop: paddingVertical(3),
    fontSize: fs(12),
    lineHeight: fs(16),
    color: BEIGE,
    opacity: 0.8,
  },
  subTitleHightLight: {
    fontWeight: '600',
  },
});

export default BannerSmall;
