import React, { FC } from 'react';
import { StyleSheet, Image, StyleProp, ViewStyle, TouchableOpacity, View, ImageSourcePropType, Dimensions } from 'react-native';
import * as Animatable from 'react-native-animatable';
import { fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';

import * as COLORS from 'constants/colors';
import Icon from 'components/icon';
import Text from 'components/text';
import { PDF_REPORTS } from 'constants/upsale';

import LOADING_LINE_IMG from '../img/loading-line.png';

const { width } = Dimensions.get('window');

const pulse: Animatable.CustomAnimation<{ [key: string]: number }> = {
  0: {
    scale: 0.1,
    opacity: 0,
  },
  0.75: {
    scale: 1,
    opacity: 1,
  },
  1: {
    opacity: 0,
  },
};

const slideRightOut: Animatable.CustomAnimation<{ [key: string]: number }> = {
  0: {
    translateX: -100,
  },
  1: {
    translateX: width,
  },
};

interface Props {
  id: PDF_REPORTS;
  title: string;
  image: ImageSourcePropType;
  withAnimation?: boolean;
  style?: StyleProp<ViewStyle>;
  onPress: (id: PDF_REPORTS) => void;
}

const PersonalReportsItem: FC<Props> = ({ id, title, image, style, withAnimation, onPress }) => {
  const handlePress = () => {
    onPress(id);
  };

  return (
    <TouchableOpacity style={[styles.wrap, style]} onPress={handlePress}>
      {withAnimation && (
        <Animatable.View
          animation={slideRightOut}
          iterationCount="infinite"
          iterationDelay={2000}
          duration={2000}
          easing="ease-in-out-cubic"
          style={styles.loadingAnimation}>
          <Image source={LOADING_LINE_IMG} />
        </Animatable.View>
      )}

      <View style={styles.imageWrap}>
        <Image source={image} style={styles.image} />
      </View>
      <Text style={styles.title} font="Philosopher">
        {title}
      </Text>

      <View style={styles.arrowWrap}>
        {withAnimation && (
          <Animatable.View
            animation={pulse}
            duration={1500}
            iterationDelay={500}
            easing="ease-in-out"
            iterationCount="infinite"
            style={styles.animationArrow}
          />
        )}

        <Icon name={'arrow'} size={sw(16)} color={COLORS.BEIGE} />
      </View>
    </TouchableOpacity>
  );
};

export default PersonalReportsItem;

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    backgroundColor: COLORS.DARK_GREY_BLUE,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    height: 50,
    paddingRight: paddingHorizontal(40),
    marginBottom: paddingVertical(5),
    overflow: 'hidden',
  },
  loadingAnimation: {
    width: 120,
    height: '100%',
    position: 'absolute',
    left: 0,
  },
  imageWrap: {
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  image: {
    resizeMode: 'contain',
  },
  title: {
    paddingLeft: paddingHorizontal(76),
    flexShrink: 1,
    color: COLORS.BEIGE,
    fontSize: fs(17),
    fontWeight: '600',
  },
  arrowWrap: {
    width: 50,
    height: '100%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: -paddingHorizontal(5),
  },
  animationArrow: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: color(COLORS.TEAL2, 0.05),
  },
});
