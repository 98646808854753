import api from '..';

import { Courses, Course, CoursesSessions, Session, CourseFormats } from './interfaces';

const COURSES = '/courses';
const COURSES_SESSIONS = '/courses-sessions';
const COURSES_SESSIONS_EMOTIONS = '/courses-sessions-users-emotions';

export const getGuides = (): Promise<Courses> => {
  return api.fetch(COURSES, {
    method: 'GET',
  });
};

export const sendEmail = (id: Course['id'], email: string): Promise<Course> => {
  return api.fetch(`${COURSES}/${id}/add-user`, {
    method: 'POST',
    data: {
      email,
    },
  });
};

export const getGuideSession = (id: Course['id']): Promise<CoursesSessions> => {
  return api.fetch(`${COURSES_SESSIONS}/${id}`, {
    method: 'GET',
  });
};

export const completeSession = (sessionId: Session['id']): Promise<Session> => {
  return api.fetch(`${COURSES_SESSIONS}/end/${sessionId}`, {
    method: 'POST',
  });
};

export const rankSession = (sessionId: Session['id'], rank: number): Promise<Session> => {
  return api.fetch(`${COURSES_SESSIONS}/rank/${sessionId}`, {
    method: 'POST',
    data: {
      rank,
    },
  });
};

export const getEmotionsForGuide = (guideId: Session['guide_id']): Promise<any> => {
  return api.fetch(`${COURSES_SESSIONS_EMOTIONS}/${guideId}`, {
    method: 'GET',
  });
};

export const setMedalForSession = (sessionId: Session['id']): Promise<Session> => {
  return api.fetch(`${COURSES_SESSIONS}/medal/${sessionId}`, {
    method: 'POST',
  });
};

export const sendFeedbackForSession = (
  sessionId: Session['id'],
  emotions: string[],
  preferable_format: CourseFormats,
  review: string,
): Promise<Session> => {
  return api.fetch(`${COURSES_SESSIONS}/feedback/${sessionId}`, {
    method: 'POST',
    data: {
      review,
      emotions,
      preferable_format,
    },
  });
};

export const clearSessionProgress = (): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${COURSES_SESSIONS}/clear-progress`, {
    method: 'POST',
  });
};
