import { STEPS } from 'constants/palm-reading-daily';
import { ImageProcessed } from 'components/palm-reading/types';

import { PROFILE_TYPES } from '../profile/types';

export enum TYPES {
  SET_PREVIEW_PHOTO = 'PALM_READING_DAILY/SET_PREVIEW_PHOTO',
  SET_PHOTO = 'PALM_READING_DAILY/SET_PHOTO',
  SET_RESCAN_TIMER = 'PALM_READING_DAILY/SET_RESCAN_TIMER',
  SCHEDULE_NEXT_REPORT = 'PALM_READING_DAILY/SCHEDULE_NEXT_REPORT',
  CLEAR_REPORT_SCHEDULE = 'PALM_READING_DAILY/CLEAR_REPORT_SCHEDULE',
  COMPLETE_STEP = 'PALM_READING_DAILY/COMPLETE_STEP',
  SET_DAILY_NEXT_SCAN_DATE = 'PALM_READING_DAILY/SET_DAILY_SCAN_DATE',
  SET_DAILY_AVAILABLE_TO_SCAN = 'PALM_READING_DAILY/SET_DAILY_AVAILABLE_TO_SCAN',
  SET_RESCAN_COUNTER = 'PALM_READING_DAILY/SET_RESCAN_COUNTER',
  SET_WEB_DATA_SHOWED = 'PALM_READING_DAILY/SET_WEB_DATA_SHOWED',
  SET_WEB_DATA = 'PALM_READING_DAILY/SET_WEB_DATA',
}

export enum KEYS {
  LEFT = 'left',
  RIGHT = 'right',
  FINGERS = 'fingers',
}

export interface PalmReadingData {
  left_hand_path?: string;
  leftHandImageUrl: string;
  leftHandOverviewResults: {
    career: number;
    health: number;
    love: number;
    wisdom: number;
  };
}

interface CompletedSteps {
  [STEPS.LEFT_HAND]: string | null;
  [STEPS.RIGHT_HAND]: string | null;
  [STEPS.FINGERS]: string | null;
}

export interface RescanTimers {
  [STEPS.LEFT_HAND]: string | null;
  [STEPS.RIGHT_HAND]: string | null;
  [STEPS.FINGERS]: string | null;
}

export interface RescanCounter {
  [STEPS.LEFT_HAND]: number;
  [STEPS.RIGHT_HAND]: number;
  [STEPS.FINGERS]: number;
}

export interface Photo {
  image: ImageProcessed;
  hand: any;
}

export interface State {
  preview: Photo | null;
  [KEYS.LEFT]: Photo | null;
  [KEYS.RIGHT]: Photo | null;
  [KEYS.FINGERS]: Photo | null;
  completedSteps: CompletedSteps;
  rescanTimers: RescanTimers;
  rescanCounters: RescanCounter;
  scheduledReportTime: any | null;
  nextDailyScanDate: Date | null;
  isDailyAvailableToScan: boolean;
  wasWebDataShowed: boolean;
  data: PalmReadingData | null;
}

interface CompleteStepAction {
  type: typeof TYPES.COMPLETE_STEP;
  payload: {
    stepId: keyof CompletedSteps;
  };
}

interface SetPreviewPhotoAction {
  type: typeof TYPES.SET_PREVIEW_PHOTO;
  payload: Photo;
}

interface SetPhotoAction {
  type: typeof TYPES.SET_PHOTO;
  payload: Photo & {
    key: KEYS;
  };
}

interface ClearReportScheduleAction {
  type: typeof TYPES.CLEAR_REPORT_SCHEDULE;
}

interface ScheduleNextReportAction {
  type: typeof TYPES.SCHEDULE_NEXT_REPORT;
  payload: {
    minutes: string;
  };
}

interface SetRescanTimerAction {
  type: typeof TYPES.SET_RESCAN_TIMER;
  payload: Partial<RescanTimers>;
}

interface SetRescanCounterAction {
  type: typeof TYPES.SET_RESCAN_COUNTER;
  payload: {
    stepId: keyof CompletedSteps;
    counter: number;
  };
}

interface ResetUserDataAction {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface SetNextDailyScanDate {
  type: typeof TYPES.SET_DAILY_NEXT_SCAN_DATE;
  payload: Date;
}

interface SetDailyAvailableToScan {
  type: typeof TYPES.SET_DAILY_AVAILABLE_TO_SCAN;
  payload: boolean;
}

interface SetWebDataShowed {
  type: typeof TYPES.SET_WEB_DATA_SHOWED;
  payload: boolean;
}

interface SetWebData {
  type: typeof TYPES.SET_WEB_DATA;
  payload: PalmReadingData;
}

export type ActionTypes =
  | CompleteStepAction
  | SetPreviewPhotoAction
  | SetPhotoAction
  | ClearReportScheduleAction
  | ScheduleNextReportAction
  | ResetUserDataAction
  | SetRescanTimerAction
  | SetNextDailyScanDate
  | SetDailyAvailableToScan
  | SetRescanCounterAction
  | SetWebDataShowed
  | SetWebData;
