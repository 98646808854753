import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from 'react-native';
import type { ImageSourcePropType } from 'react-native';
import { sw } from '@wowmaking/ui/src/utils';

import { t } from 'localization';

import ICON_FAMILY from '../../img/pic-family.png';
import ICON_SETTINGS from '../../img/pic-settings.png';

import Item from './item';

type Props = {
  style?: StyleProp<ViewStyle>;
  itemTextStyle?: StyleProp<TextStyle>;
  onLinkPress: () => void;
};

const Steps: FC<Props> = ({ style, itemTextStyle, onLinkPress }) => {
  const steps = t('FAMILY_GUIDE.GUIDE.STEPS') as unknown as string[];
  const highlightWords = t('FAMILY_GUIDE.GUIDE.HIGHLIGHT_WORDS') as unknown as string[];

  return (
    <View style={styles.root}>
      <View style={[styles.list, style]}>
        {steps.map((item: string, index: number) => {
          let icon: ImageSourcePropType | null = null;

          if (index === 0) {
            icon = ICON_SETTINGS;
          } else if (index === 2) {
            icon = ICON_FAMILY;
          }

          return (
            <Item
              title={item}
              key={item}
              icon={icon}
              index={index + 1}
              textStyle={itemTextStyle}
              highlightWords={highlightWords}
              onPress={onLinkPress}
            />
          );
        })}
      </View>
    </View>
  );
};

export default Steps;

const styles = StyleSheet.create({
  root: {
    width: '100%',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
  },
  list: {
    width: '100%',
    maxWidth: sw(275),
  },
});
