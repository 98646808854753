import { PROFILE_TYPES } from '../profile/types';

import { ActionType, State, TYPES } from './types';

const initialState: State = {
  color: null,
  number: null,
  time: null,
};

export default function (state = initialState, action: ActionType) {
  switch (action.type) {
    case TYPES.SET_FEATURES:
      return {
        ...state,
        ...action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
