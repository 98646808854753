import { Platform } from 'react-native';
import { URL as RNUrl } from 'react-native-url-polyfill';

import * as ROUTES from 'constants/routes';

import { navigate, reset } from './actions';

export const getLinkParams = (link?: string | null): [ROUTES.PATH_NAMES | undefined, string | undefined] => {
  if (!link) {
    return [undefined, undefined];
  }

  const URLControl = Platform.OS === 'web' ? URL : RNUrl;

  const url = new URLControl(link);
  const advisorId = url.searchParams.get('advisor_id') || undefined;

  const pathname = Platform.OS === 'web' ? url.pathname.replace(/^\/|\/$/g, '') : url.searchParams?.get('pathname') || '';
  const destination = pathname as ROUTES.PATH_NAMES;

  return [destination, advisorId];
};

export const navigateToSpecificRouteWithTimeout = (route: string, params?: any, timeout = 100) => {
  if (ROUTES.TAB_SCREENS.includes(route)) {
    reset(ROUTES.MAIN, { initial: false, screen: route });
  } else {
    reset(ROUTES.MAIN);
    setTimeout(() => {
      navigate(route, params);
    }, timeout);
  }

  if (Platform.OS === 'web') {
    const searchQueries = new URLSearchParams(window.location.search);
    searchQueries.delete('advisor_id');
    history.replaceState(null, '', window.location.origin + '?' + searchQueries + window.location.hash);
  }

  return;
};
