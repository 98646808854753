import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, View, Text, ImageSourcePropType, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { fs, sw, paddingVertical, paddingHorizontal } from '@wowmaking/ui/src/utils';

import * as COLORS from 'constants/colors';
import Highlighter from 'components/text-highlighter';

interface Props {
  title: string;
  icon?: ImageSourcePropType | null;
  highlightWords: string[];
  index: number;
  textStyle?: StyleProp<TextStyle>;
  onPress: () => void;
}

const startGradient = { x: 0, y: 0 };
const endGradient = { x: 1, y: 0 };

const Item: FC<Props> = ({ title, textStyle, index, highlightWords, onPress, icon }) => {
  const props = index === 5 ? { onPress } : {};
  return (
    <View style={styles.wrap}>
      <View style={styles.col}>
        <LinearGradient start={startGradient} end={endGradient} colors={COLORS.SAND_GRADIENT} style={[styles.line, index === 1 && styles.lineTr]} />

        <LinearGradient style={styles.numberWrap} start={startGradient} end={endGradient} colors={COLORS.SAND_GRADIENT}>
          <Text style={styles.number}>{index}</Text>
        </LinearGradient>
        <LinearGradient start={startGradient} end={endGradient} colors={COLORS.SAND_GRADIENT} style={[styles.line, index === 5 && styles.lineTr]} />
      </View>
      <View style={styles.titleWrap}>
        <Highlighter
          {...props}
          style={[styles.title, textStyle]}
          highlightStyle={[styles.boldText, index === 5 && styles.textDecoration]}
          textToHighlight={title}
          searchWords={highlightWords}
        />
        {icon && <Image source={icon} style={styles.icon} />}
      </View>
    </View>
  );
};

export default Item;

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  col: {
    width: sw(18),
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    width: 2,
    flex: 1,
  },
  lineTr: {
    opacity: 0,
  },
  numberWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    width: sw(18),
    height: sw(18),
    borderRadius: sw(20),
    zIndex: 1,
  },
  number: {
    fontSize: fs(10),
    fontWeight: '700',
    color: COLORS.DARK_BLUE_GREY_5,
  },
  titleWrap: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: paddingVertical(10),
  },
  title: {
    fontSize: fs(14),
    lineHeight: fs(18),
    marginLeft: paddingHorizontal(15),
    color: COLORS.WHITE,
    flexWrap: 'wrap',
  },
  boldText: {
    fontWeight: '600',
  },
  textDecoration: {
    textDecorationLine: 'underline',
  },
  icon: {
    width: sw(35),
    height: sw(35),
    marginLeft: paddingHorizontal(12),
  },
});
