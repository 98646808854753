import { createSelector } from 'reselect';

import { RootState } from 'store';
import { RECENT_READINGS, TAROT_LAYOUT_IDS } from 'screens/tarot/constants';
import { TarotCard } from 'api/tarot/interfaces';
import { isTodayDate } from 'screens/tarot/utils';

import { TarotCardOfTheDay } from './types';

const getSelectedCards = (state: RootState) => state.tarot.selectedCards;
const getCards = (state: RootState) => state.tarot.cards;
const getCardsOfTheDayHistory = (state: RootState) => state.tarot.cardsOfTheDayHistory;

export const selectAvailableRecentReadings = createSelector([getSelectedCards, getCards], (tarotCardsLayouts, tarotCards) => {
  const availableReadings = RECENT_READINGS.filter(layout => tarotCardsLayouts[layout]?.length);

  if (!availableReadings?.length) {
    return [];
  }

  return availableReadings.map(item => ({
    id: item,
    cardImages: tarotCardsLayouts[item].map(cardId => {
      const cardData = tarotCards.find(card => card.id === cardId);
      return { uri: cardData?.tarot_image?.image_path };
    }),
  }));
});

export const selectCardsOfTheDay = createSelector([getCards, getCardsOfTheDayHistory], (tarotCards, tarotCardsOfTheDayHistory): TarotCardOfTheDay[] =>
  tarotCardsOfTheDayHistory?.map(item => {
    const tarotCard = tarotCards?.find(card => card.id === item.data.cardID);

    return {
      ...item,
      image: tarotCard?.tarot_image?.image_path,
    };
  }),
);

export const selectCardImageByPosition = createSelector(
  [getCards, getSelectedCards, (_state, layout: TAROT_LAYOUT_IDS) => layout, (_state, _layout, positionNumber: number) => positionNumber],
  (tarotCards, tarotCardsLayouts, layout, positionNumber): string | null => {
    const cardId = tarotCardsLayouts[layout]?.[positionNumber];
    return tarotCards.find(card => card.id === cardId)?.tarot_image?.image_path || null;
  },
);

export const getCardById = (state: RootState, id: number | null): TarotCard | null => {
  if (!id) {
    return null;
  }
  const cards = getCards(state);
  return cards.find(card => card.id === id) || null;
};

export const getTodaysCardId = createSelector([getCardsOfTheDayHistory], (tarotCardsOfTheDayHistory): number | null => {
  const cardID = tarotCardsOfTheDayHistory?.find(item => isTodayDate(item.date))?.data?.cardID ?? null;
  return cardID;
});
