import React, { FC, memo } from 'react';
import { StyleSheet, Image, TouchableOpacity, ImageSourcePropType, StyleProp, ImageStyle } from 'react-native';

interface Props {
  index: number;
  title: string;
  style: StyleProp<ImageStyle> | undefined;
  icon: ImageSourcePropType;
  onPress: (index: number) => void;
}

const Star: FC<Props> = ({ onPress, icon, index, style }) => {
  const handlePress = () => onPress(index);
  return (
    <TouchableOpacity style={styles.button} onPress={handlePress}>
      <Image style={[styles.star, style]} resizeMode="contain" source={icon} />
    </TouchableOpacity>
  );
};

export default memo(Star);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
  },
  star: {
    marginRight: 10,
  },
});
