import { REPORTS_PRODUCTS } from 'modules/payments/constants/product';

export enum PDF_REPORTS {
  NUMEROLOGY_ANALYSIS = 'numerology_analysis',
  PALMISTRY_GUIDE = 'palmistry_guide',
  BIRTH_CHART = 'birth_chart',
  COMPATIBILITY = 'compatibility',
}

type ReportProducts = {
  [key in Exclude<REPORTS_PRODUCTS, REPORTS_PRODUCTS.ASTROLOGER_CONSULTATION>]: PDF_REPORTS[];
};

export const ULTRA_PDF_REPORTS = [PDF_REPORTS.NUMEROLOGY_ANALYSIS, PDF_REPORTS.PALMISTRY_GUIDE];
export const ULTRA_2_PDF_REPORTS = [PDF_REPORTS.BIRTH_CHART, PDF_REPORTS.COMPATIBILITY];

export const PDF_REPORTS_BY_PRODUCT: ReportProducts = {
  [REPORTS_PRODUCTS.NUMEROLOGY_ANALYSIS]: [PDF_REPORTS.NUMEROLOGY_ANALYSIS],
  [REPORTS_PRODUCTS.PALMISTRY_GUIDE]: [PDF_REPORTS.PALMISTRY_GUIDE],
  [REPORTS_PRODUCTS.ULTRA]: ULTRA_PDF_REPORTS,
  [REPORTS_PRODUCTS.BIRTH_CHART]: [PDF_REPORTS.BIRTH_CHART],
  [REPORTS_PRODUCTS.COMPATIBILITY]: [PDF_REPORTS.COMPATIBILITY],
  [REPORTS_PRODUCTS.ULTRA_2]: ULTRA_2_PDF_REPORTS,
};
