import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  voiceCommandAdded: false,
  suggestionVisible: false,
  seenSuggestionTimes: 0,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.VOICE_COMMAND_ADDED:
      return {
        ...state,
        voiceCommandAdded: action.payload,
        suggestionVisible: false,
      };

    case TYPES.SHOW_SUGGESTION:
      return {
        ...state,
        suggestionVisible: true,
        seenSuggestionTimes: state.seenSuggestionTimes + 1,
      };

    case TYPES.HIDE_SUGGESTION:
      return {
        ...state,
        suggestionVisible: false,
      };
  }

  return state;
}
