import { TYPES, State, ActionType } from './types';

const initialState: State = {
  surveyPassed: false,
  nextShowSession: 0,
  showCount: 0,
  lastVersion: 1,
};

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
    case TYPES.SET_SURVEY_PASSED:
      return {
        ...state,
        surveyPassed: true,
      };

    case TYPES.SET_SURVEY_NEXT_SHOW:
      return {
        ...state,
        nextShowSession: action.payload,
      };

    case TYPES.SET_SHOWN_VERSION:
      return {
        ...state,
        lastVersion: action.payload,
        surveyPassed: false,
      };

    case TYPES.SET_QUANTITY_SURVEY_SHOWN:
      return {
        ...state,
        showCount: action.payload,
      };

    default:
      return state;
  }
};
