import { Text, Image, Dimensions, Alert, } from 'react-native-web';

export * from 'react-native-web';

Text.propTypes = {
  style: () => { }
}
Image.propTypes = {
  style: () => { }
}

Alert.alert = Alert.prompt = (title, message, callbackOrButtons) => {
  if (typeof callbackOrButtons == 'function') {
    window.alert(message);
    callbackOrButtons();
  } else if (callbackOrButtons?.length === 1) {
    window.alert(message);
    callbackOrButtons?.[0]?.onPress?.();
  } else if (callbackOrButtons?.length === 2) {
    if (window.confirm(message)) {
      callbackOrButtons?.[1]?.onPress?.();
    } else {
      callbackOrButtons?.[0]?.onPress?.();
    }
  }

}

const _dimensionsGet = Dimensions.get;
Dimensions.get = (...args) => {
  const r = _dimensionsGet(...args);
  if (r) {
    r.width = Math.min(r.width, 410);
    r.height = Math.min(window.innerHeight, 820);
  }
  return r;
}


export const ViewPropTypes = {
  style: null,
}

export const ImageStyle = {};
export const TextStyle = {};

export {
  Text,
  Image,
  Dimensions,
}
