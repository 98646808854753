import moment from 'moment';
import _ from 'lodash';

import { BIORHYTHM_TYPES, PERIODS_DAYS } from '../constants/charts';
import { PERIODS_IDS } from '../constants/periods';

const UPPER_NUMERICAL_BORDER = 100;
const SEGMENT_LENGTH = 200;
const MILLISECONDS_PER_DAY = 86400000;

export const getChartsData = (date, counts) => {
  const firstDate = moment(date, 'YYYY-MM-DD').toDate();
  const now = new Date();
  const diffDays = Math.floor(Math.abs((firstDate.getTime() - now.getTime()) / MILLISECONDS_PER_DAY));

  const days = Array(counts)
    .fill(-Math.floor(counts / 2))
    .map((element, index) => index + element);

  const chartsData = {};

  BIORHYTHM_TYPES.forEach(periodName => {
    const period = PERIODS_DAYS[`${periodName}`];
    chartsData[`${periodName}`] = days.map(day => Math.round(Math.sin(((2 * Math.PI) / period) * (((diffDays + day) % period) + 1)) * 100) || 0);
  });

  return chartsData;
};

export const getAverageBiorhythms = date => {
  const chartsData = getChartsData(date, 5);
  const bufferPeriod = 'BUFFER';
  const periods = [bufferPeriod, PERIODS_IDS.YESTERDAY, PERIODS_IDS.TODAY, PERIODS_IDS.TOMORROW];
  const periodsIndexes = [0, 1, 2, 3];

  const result = {};
  let lastPeriod = null;

  periods.forEach((period, index) => {
    result[`${period}`] = {};
    result[`${period}`].average = 0;

    BIORHYTHM_TYPES.forEach(type => {
      const periodIndex = periodsIndexes[index];
      const data = chartsData[`${type}`];
      const average = data[periodIndex];
      const isMore = average > data[periodIndex - 1];
      const isEqual = average === data[periodIndex - 1];

      result[`${period}`].average += average;
      result[`${period}`][`${type}`] = { average, isMore, isEqual };
    });

    result[`${period}`].average = Math.round(result[`${period}`].average / BIORHYTHM_TYPES.length);

    if (lastPeriod) {
      result[`${period}`].isEqual = result[`${lastPeriod}`].average === result[`${period}`].average;
      result[`${period}`].isMore = result[`${lastPeriod}`].average < result[`${period}`].average;
    }

    lastPeriod = period;
  });

  delete result[`${bufferPeriod}`];

  return result;
};

export const getSortedBiorhythms = biorhythms => {
  const sortedBiorhythms = _.groupBy(biorhythms, 'category');

  return _.mapKeys(sortedBiorhythms, (item, key) => {
    return _.upperCase(key);
  });
};

export const getFullBiorhythms = (date, biorhythms) => {
  const averageBiorithm = getAverageBiorhythms(date);
  const sortedBiorhythms = getSortedBiorhythms(biorhythms);

  _.mapKeys(averageBiorithm, (biorithm, day) => {
    _.mapKeys(biorithm, (item, categoryName) => {
      if (_.isObject(item)) {
        const average = item.average;
        const category = sortedBiorhythms[categoryName];

        if (category) {
          const categoryLength = average === UPPER_NUMERICAL_BORDER ? category.length - 1 : category.length;
          const numericalSpan = Math.floor(((UPPER_NUMERICAL_BORDER + average) * categoryLength) / SEGMENT_LENGTH);
          averageBiorithm[day][categoryName].categoryText = category[numericalSpan];
        }
      }
    });
  });

  return averageBiorithm;
};
