import React, { memo } from 'react';
import type { FC } from 'react';
import { StyleSheet } from 'react-native';
import { Image } from 'react-native';
import { sw } from '@wowmaking/ui/src/utils';

import BG from '../images/bg.png';

const Background: FC = () => {
  return <Image style={styles.root} source={BG} />;
};

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    width: '100%',
    height: sw(415),
  },
});

export default memo(Background);
