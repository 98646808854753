import React, { FC, memo, useEffect } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import { sw, paddingVertical, paddingHorizontal, fs, color } from '@wowmaking/ui/src/utils';

import { t } from 'localization';
import Analytics from 'analytics';
import { useAppDispatch, useAppSelector } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import { handleTrustPilotReviewSuccess } from 'store/rate-us/actions';
import * as COLORS from 'constants/colors';
import * as GENERAL from 'constants/general';
import * as MODALS from 'constants/modals';
import Button from 'components/button';
import HighLightText from 'components/hight-light-text';
import Icon from 'components/icon';
import Text from 'components/text';
import useModalIsVisible from 'hooks/use-modal-is-visibly';

import IMAGE from './images/image.png';

const TrustPilotPromoModal: FC = () => {
  const dispatch = useAppDispatch();
  const trustPilotPromoConfig = useAppSelector(state => state.remoteConfig.remoteConfigParams.trustPilotPromoConfig);
  const trustPilotReviewLink = useAppSelector(state => state.rateUs.trustPilotReviewLink);
  const freeMinutes = useAppSelector(state => state.rateUs.trustPilotPromoFreeMinutes);
  const isVisible = useModalIsVisible(MODALS.RATE_TRUST_PILOT);

  const { title, btnTitle } = trustPilotPromoConfig || {};

  useEffect(() => {
    // Post message handling for trust pilot review success
    const bc = new BroadcastChannel('astro_broadcast_channel'); // the same name as in web/public/review-success.html
    bc.onmessage = event => {
      if (event?.data?.trustPilotReviewSuccess) {
        Analytics.track('TrustPilot_ReviewSuccess');
        dispatch(handleTrustPilotReviewSuccess());
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      Analytics.track('TrustPilotPromoModal_Open');
    }
  }, [isVisible]);

  const handleClosePress = () => {
    Analytics.track('TrustPilotPromoModal_CloseClick');
    dispatch(closeCurrentModal());
  };

  const handleContinuePress = () => {
    Analytics.track('TrustPilotPromoModal_ContinueClick');
    const link = trustPilotReviewLink?.length ? trustPilotReviewLink : GENERAL.TRUST_PILOT_URL;
    window.open(link, '_blank');
    dispatch(closeCurrentModal());
  };

  const items = t(`MODALS.RATE_TRUST_PILOT.LIST`, { time: freeMinutes }) as unknown as string[];

  return (
    <Modal isVisible={isVisible} backdropOpacity={0.8} style={styles.wrapper} animationIn="slideInUp" animationOut="slideOutDown">
      <View style={styles.root}>
        <TouchableOpacity style={styles.iconWr} onPress={handleClosePress}>
          <Icon name={'cross'} color={COLORS.GOLDEN} size={sw(18)} />
        </TouchableOpacity>

        <View style={styles.wrImg}>
          <Image source={IMAGE} style={styles.img} />
        </View>

        <Text font="Philosopher" style={styles.title}>
          {title ? title : t(`MODALS.RATE_TRUST_PILOT.TITLE`)}
        </Text>

        <View style={styles.content}>
          {items.map((text: string, index: number) => (
            <View key={text}>
              <View style={styles.wrNumberList}>
                <Text style={styles.titleList}>{index + 1}.</Text>
              </View>

              <HighLightText style={[styles.titleList, styles.titleItem]} highlightedTextStyle={styles.titleListBold}>
                {text}
              </HighLightText>
            </View>
          ))}

          <Button style={styles.btn} title={btnTitle ? btnTitle : t(`MODALS.RATE_TRUST_PILOT.BUTTON`)} onPress={handleContinuePress} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  root: {
    borderTopRightRadius: sw(40),
    borderTopLeftRadius: sw(40),
    paddingHorizontal: paddingHorizontal(20),
    paddingBottom: paddingVertical(40),
    paddingTop: paddingVertical(50),
    backgroundColor: COLORS.DARK_BLUE_GREY,
  },
  iconWr: {
    position: 'absolute',
    right: paddingHorizontal(25),
    top: paddingVertical(25),
  },
  wrImg: {
    alignSelf: 'center',
    width: sw(220),
    height: sw(120),
    marginBottom: paddingVertical(25),
  },
  img: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: fs(26),
    lineHeight: fs(32),
    fontWeight: '700',
    textAlign: 'center',
    color: COLORS.GOLDEN,
  },
  content: {
    marginTop: paddingVertical(20),
    paddingHorizontal: paddingHorizontal(10),
  },
  wrNumberList: {
    position: 'absolute',
    left: 0,
  },
  titleList: {
    fontSize: fs(16),
    lineHeight: fs(22),
    fontWeight: '400',
    textAlign: 'left',
    color: color(COLORS.BEIGE, 0.8),
    marginBottom: paddingVertical(10),
  },
  titleListBold: {
    color: COLORS.BEIGE,
    fontWeight: '700',
  },
  titleItem: {
    paddingLeft: paddingHorizontal(20),
  },
  btn: {
    marginTop: paddingVertical(20),
  },
});

export default memo(TrustPilotPromoModal);
