export enum TYPES {
  SET = 'COMPATIBILITY_REPORTS/SET',
}

export interface State {
  icons: number[];
  count: number;
}

interface Set {
  type: typeof TYPES.SET;
  payload: {
    icons: number[];
    count: number;
  };
}

export type ActionTypes = Set;
