import { ImageSourcePropType } from 'react-native';

import EMOTIONAL_LOW from 'assets/icons/compatibility/emotional-low.png';
import EMOTIONAL_MEDIUM from 'assets/icons/compatibility/emotional-medium.png';
import EMOTIONAL_HIGH from 'assets/icons/compatibility/emotional-high.png';
import EMOTIONAL_MAX from 'assets/icons/compatibility/emotional-max.png';
import INTELLECTUAL_LOW from 'assets/icons/compatibility/intellectual-low.png';
import INTELLECTUAL_MEDIUM from 'assets/icons/compatibility/intellectual-medium.png';
import INTELLECTUAL_HIGH from 'assets/icons/compatibility/intellectual-high.png';
import INTELLECTUAL_MAX from 'assets/icons/compatibility/intellectual-max.png';
import SPIRITUAL_LOW from 'assets/icons/compatibility/spiritual-low.png';
import SPIRITUAL_MEDIUM from 'assets/icons/compatibility/spiritual-medium.png';
import SPIRITUAL_HIGH from 'assets/icons/compatibility/spiritual-high.png';
import SPIRITUAL_MAX from 'assets/icons/compatibility/spiritual-max.png';
import SEXUAL_LOW from 'assets/icons/compatibility/sexual-low.png';
import SEXUAL_MEDIUM from 'assets/icons/compatibility/sexual-medium.png';
import SEXUAL_HIGH from 'assets/icons/compatibility/sexual-high.png';
import SEXUAL_MAX from 'assets/icons/compatibility/sexual-max.png';
import EMOTIONAL_ICON from 'assets/icons/compatibility/emotional.png';
import INTELLECTUAL_ICON from 'assets/icons/compatibility/intellectual.png';
import SEXUAL_ICON from 'assets/icons/compatibility/sexual.png';
import SPIRITUAL_ICON from 'assets/icons/compatibility/stars.png';

import { BLUE, BLUE_02, FADED_ORANGE, LIGHT_MUSTARD, LIGHT_MUSTARD_02, LILAC, LILAC_02, ORANGE, TOPAZ, TOPAZ_02 } from './colors';

export const YOUR_DAY = 'YOUR_DAY';
export const LOVE = 'LOVE';
export const HEALTH = 'HEALTH';
export const CAREER = 'CAREER';
export const MARRIAGE = 'MARRIAGE';

export const PERSONAL_READINGS = 'PERSONAL_READINGS';

export const MONEY = 'MONEY';
export const NEAR_FUTURE = 'NEAR_FUTURE';

export enum COMPATIBILITY_SECTIONS_ID {
  LOVE = 'love',
  MARRIAGE = 'marriage',
  TRUST = 'trust',
  HUMOR = 'humor',
  TEAMWORK = 'teamwork',
  COMMUNICATION = 'communication',
}

export const COMPATIBILITY_SECTIONS_COLORS: { [key: string]: string } = {
  love: ORANGE,
  marriage: BLUE,
  trust: FADED_ORANGE,
  humor: TOPAZ,
  teamwork: LIGHT_MUSTARD,
  communication: LILAC,
};

export enum BalanceWheelSections {
  EMOTIONAL = 'emotional',
  INTELLECTUAL = 'intellectual',
  SPIRITUAL = 'spiritual',
  SEXUAL = 'sexual',
}

export enum BalanceWheelImageTypes {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  MAX = 'max',
}

interface EmotionBalanceImages {
  [key: string]: {
    [key: string]: ImageSourcePropType;
  };
}

export const EMOTION_BALANCE_IMAGE_MAP: EmotionBalanceImages = {
  [BalanceWheelSections.EMOTIONAL]: {
    low: EMOTIONAL_LOW,
    medium: EMOTIONAL_MEDIUM,
    high: EMOTIONAL_HIGH,
    max: EMOTIONAL_MAX,
  },
  [BalanceWheelSections.INTELLECTUAL]: {
    low: INTELLECTUAL_LOW,
    medium: INTELLECTUAL_MEDIUM,
    high: INTELLECTUAL_HIGH,
    max: INTELLECTUAL_MAX,
  },
  [BalanceWheelSections.SPIRITUAL]: {
    low: SPIRITUAL_LOW,
    medium: SPIRITUAL_MEDIUM,
    high: SPIRITUAL_HIGH,
    max: SPIRITUAL_MAX,
  },
  [BalanceWheelSections.SEXUAL]: {
    low: SEXUAL_LOW,
    medium: SEXUAL_MEDIUM,
    high: SEXUAL_HIGH,
    max: SEXUAL_MAX,
  },
};

interface BalanceWheelStyles {
  [key: string]: {
    active: string;
    inactive: string;
    icon: ImageSourcePropType;
  };
}

export const BALANCE_WHEEL_STYLES: BalanceWheelStyles = {
  [BalanceWheelSections.EMOTIONAL]: {
    active: TOPAZ,
    inactive: TOPAZ_02,
    icon: EMOTIONAL_ICON,
  },
  [BalanceWheelSections.INTELLECTUAL]: {
    active: LIGHT_MUSTARD,
    inactive: LIGHT_MUSTARD_02,
    icon: INTELLECTUAL_ICON,
  },
  [BalanceWheelSections.SPIRITUAL]: {
    active: BLUE,
    inactive: BLUE_02,
    icon: SPIRITUAL_ICON,
  },
  [BalanceWheelSections.SEXUAL]: {
    active: LILAC,
    inactive: LILAC_02,
    icon: SEXUAL_ICON,
  },
};
