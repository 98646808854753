import { TYPES, initialState, ActionTypes } from './types';

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TYPES.SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload.name,
        params: action.payload.params,
      };

    case TYPES.CLOSE_MODAL:
      return {
        ...state,
        activeModal: '',
        params: {},
      };

    case TYPES.SET_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };

    default:
      return state;
  }
};
