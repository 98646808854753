import { ActionType, TYPES, State } from './types';

const initialState: State = {
  botId: '',
  isWhatsAppNotificationPressed: false,
  whatsAppNotificationSkipCounter: 0,
  isMessengersNotificationPressed: false,
  messengersNotificationSkipCounter: 0,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_BOT_ID:
      return {
        ...state,
        botId: action.payload,
      };
    case TYPES.SET_WHATS_APP_NOTIFICATION_PRESS:
      return {
        ...state,
        isWhatsAppNotificationPressed: true,
      };
    case TYPES.SET_WHATS_APP_NOTIFICATION_SKIP_COUNTER:
      return {
        ...state,
        whatsAppNotificationSkipCounter: action.payload,
      };
    case TYPES.SET_MESSENGERS_NOTIFICATION_PRESS:
      return {
        ...state,
        isMessengersNotificationPressed: true,
      };
    case TYPES.SET_MESSENGERS_NOTIFICATION_SKIP_COUNTER:
      return {
        ...state,
        messengersNotificationSkipCounter: action.payload,
      };
    default:
      return state;
  }
}
