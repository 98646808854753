import { PROFILE_TYPES } from '../profile/types';

import { SET, SET_FIRST_TIME, State, ActionType } from './types';

const initialState: State = {
  stories: [],
  isFirstTime: true,
};

export default function (state = initialState, action: ActionType) {
  switch (action.type) {
    case SET:
      return {
        ...state,
        stories: action.payload,
      };

    case SET_FIRST_TIME:
      return {
        ...state,
        isFirstTime: false,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...state,
        isFirstTime: true,
      };

    default:
      return state;
  }
}
