import { CALENDAR_TYPES } from 'constants/calendar-types';
import { Calendar } from 'api/calendars/interfaces';

export type CalendarItem = {
  [key in CALENDAR_TYPES]: Calendar['src'];
};

export enum TYPES {
  SET = 'CALENDARS/SET',
}

export interface State {
  [CALENDAR_TYPES.LUNAR]: string | null;
  [CALENDAR_TYPES.DATING]: string | null;
}

interface Set {
  type: typeof TYPES.SET;
  payload: CalendarItem;
}

export type ActionTypes = Set;
