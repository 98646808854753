const CROSS = 'cross';
const BACKDROP = 'backdrop';
const BUTTON = 'button';

export interface ModalCloseType {
  CROSS: string;
  BACKDROP: string;
  BUTTON: string;
}

export const MODAL_CLOSE_TYPES: ModalCloseType = {
  CROSS,
  BACKDROP,
  BUTTON,
};
