import React, { FC, useEffect, useMemo, useRef } from 'react';
import { StyleSheet, View, TouchableOpacity, ScrollView, Image, Platform } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { Product } from '@wowmaking/react-native-billing';

import Analytics from 'analytics';
import { t2 } from 'localization';
import { PAYMENT_EVENTS_CATEGORIES } from 'analytics/const';
import { OTHERS_PAYMENT_SCREENS } from 'modules/payments/constants';
import { prepareProductToPaymentProduct } from 'modules/payments/utils';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';
import Payments, { PaymentsRef } from 'modules/payments';
import { useAppSelector, useAppDispatch } from 'store';
import { getPlatformProducts, getPrevCurrency } from 'store/billing/selectors';
import { closeCurrentModal } from 'store/modals/actions';
import { navigateWithMonetization, resetMonetizationFlow } from 'store/unlock-content/actions';
import Text from 'components/text';
import SubBenefits from 'components/sub-benefits';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import Button from 'components/button';
import Terms from 'screens/subscription/ts-components/terms';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { windowHeight } from 'constants/sizes';
import { PRICE_LOCATION } from 'constants/subscription';
import * as FONTS from 'constants/fonts';
import PRO_ICON from 'assets/icons/pro/pro.png';

import ICON_CROSS from '../images/beige-cross.png';
import STARS_IMG from '../images/stars.png';

interface Props {}

const SubWithBenefitsModal: FC<Props> = () => {
  const paymentsRef = useRef<PaymentsRef>(null);
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(state => state.modals.activeModal === MODALS.SUB_WITH_BENEFITS);
  const config = useAppSelector(state => state.remoteConfig.remoteConfigParams?.subWithBenefitsModal);
  const trigger = useAppSelector(state => state.unlockContent.currentTrigger?.trigger);
  const products = useAppSelector(getPlatformProducts);
  const titleText = config?.title ? config.title : t2('SUBSCRIPTION.BENEFITS_MODAL.TITLE');
  const buttonTitleText = config?.btnTitle ? config.btnTitle : t2('SUBSCRIPTION.BENEFITS_MODAL.BTN_TITLE');
  const prevCurrency = useAppSelector(getPrevCurrency);

  const eventParams = useMemo(() => {
    return { trigger, screen: OTHERS_PAYMENT_SCREENS.SUB_BENEFITS_POPUP };
  }, [trigger]);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'Open', eventParams);
    }
  }, [isVisible]);

  const product = useMemo(() => {
    return prepareProductToPaymentProduct(config, products, prevCurrency);
  }, [config, products, prevCurrency]);

  const isPriceTextVisible = config?.priceLocation === PRICE_LOCATION.ABOVE && !!product?.priceText;

  if (!isVisible) {
    return null;
  }

  const handleClosePress = () => {
    paymentsRef.current?.onClose();
    dispatch(resetMonetizationFlow());
    dispatch(closeCurrentModal());
  };

  const handleSuccess = () => {};

  const handleSuccessModalClose = () => {
    handleClosePress();
  };

  const handleButtonPress = () => {
    if (product?.type === PRODUCT_TYPES.SUBSCRIPTION) {
      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'button_click', {
        ...eventParams,
        productType: PRODUCT_TYPES.SUBSCRIPTION,
        currency: product?.currency,
        amount: product?.price,
      });
    }

    if (config?.priceLocation !== PRICE_LOCATION.ABOVE) {
      handleClosePress();
      dispatch(
        navigateWithMonetization({
          trigger: config.btnTrigger,
          skipReactivation: true,
        }),
      );
    } else {
      paymentsRef.current?.onSubmit();
    }
  };

  const handleRestorePress = () => {
    paymentsRef.current?.onRestore();
  };

  const handleLinkBtnPress = () => {
    const eventAction = !config.linkBtnTrigger ? 'Skip' : 'Other_Plans';
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, eventAction, eventParams);

    handleClosePress();

    if (config.linkBtnTrigger) {
      dispatch(
        navigateWithMonetization({
          trigger: config.linkBtnTrigger,
          skipReactivation: true,
        }),
      );
    }
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <View style={styles.header}>
          {!paymentsRef.current?.isPaymentsModalVisible && (
            <CloseButtonWithTimeout timeout={config.closeBtnTimeout} onPress={handleClosePress} source={ICON_CROSS} style={styles.cross} />
          )}

          <View style={styles.top} pointerEvents="none">
            <Image style={styles.image} source={STARS_IMG} resizeMode="contain" />
            <Image source={PRO_ICON} style={styles.pro} />
          </View>

          <View style={styles.headerContainer}>
            <Text font="Philosopher" style={styles.title}>
              {titleText}
            </Text>

            <Text style={styles.subTitle}>{t2('SUBSCRIPTION.BENEFITS_MODAL.SUB_TITLE')}</Text>
          </View>
        </View>

        <ScrollView style={styles.scrollView} contentContainerStyle={styles.contentContainer}>
          <SubBenefits />
        </ScrollView>

        <View style={[styles.footer, isPriceTextVisible && styles.footerWithPriceText]}>
          {isPriceTextVisible && <Text style={styles.priceText}>{product.priceText}</Text>}

          <Button fontFamily="Philosopher" title={buttonTitleText} onPress={handleButtonPress} style={styles.button} />

          {config?.linkBtnEnabled && (
            <TouchableOpacity onPress={handleLinkBtnPress} style={styles.link}>
              <Text style={styles.linkText}>
                {!config.linkBtnTrigger ? t2('SUBSCRIPTION.BENEFITS_MODAL.DON_T') : t2('SUBSCRIPTION.BENEFITS_MODAL.OTHER_PLANS')}
              </Text>
            </TouchableOpacity>
          )}

          {!config?.linkBtnEnabled && Platform.OS === 'ios' && (
            <View style={styles.footerBtn}>
              <Terms
                showShimmer={!product}
                showTerms={true}
                product={product as unknown as Product}
                linkTextStyle={styles.termsLink}
                onRestorePress={handleRestorePress}
              />
            </View>
          )}
        </View>
      </View>

      {product && (
        <Payments
          ref={paymentsRef}
          product={product}
          productTitle={product.title || ''}
          productTrigger={''}
          metadata={{}}
          withSuccessModal
          isReactivate={false}
          eventCategory={PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION}
          eventParams={{}}
          onSuccess={handleSuccess}
          onSuccessModalClose={handleSuccessModalClose}
        />
      )}
    </Modal>
  );
};

export default SubWithBenefitsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: 'auto',
    flexGrow: 0,
    maxHeight: windowHeight * 0.9,
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
  },
  scrollView: {
    width: '100%',
  },
  cross: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingVertical: 0,
    paddingRight: paddingHorizontal(20),
    zIndex: 1,
  },
  header: {
    marginTop: paddingVertical(20),
    width: '100%',
    paddingBottom: paddingVertical(10),
  },
  headerContainer: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(20),
  },
  top: {
    width: '100%',
    height: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    position: 'absolute',
    alignSelf: 'center',
    width: '100%',
  },
  pro: {},
  contentContainer: {
    paddingTop: paddingVertical(10),
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: -paddingVertical(5),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(33),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(10),
    fontSize: fs(17),
    lineHeight: fs(22),
    color: color(COLORS.GOLDEN, 0.8),
    textAlign: 'center',
  },
  priceText: {
    marginBottom: paddingVertical(10),
    fontSize: fs(12),
    fontWeight: '400',
    textAlign: 'center',
    color: color(COLORS.BEIGE, 0.8),
  },
  footer: {
    paddingHorizontal: paddingHorizontal(20),
    paddingTop: paddingVertical(20),
    alignItems: 'center',
  },
  footerWithPriceText: {
    paddingTop: paddingVertical(10),
  },
  button: {
    alignSelf: 'center',
    maxWidth: sw(310),
  },
  link: {
    marginTop: paddingVertical(10),
    paddingVertical: paddingVertical(5),
  },
  linkText: {
    fontSize: fs(16),
    lineHeight: fs(18),
    color: COLORS.AQUA,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  footerBtn: {
    marginTop: paddingVertical(15),
    alignItems: 'center',
  },
  termsLink: {
    fontFamily: FONTS.OpenSans,
    fontSize: fs(12),
    textDecorationLine: 'underline',
    color: COLORS.WHITE,
    opacity: 0.79,
  },
});
