import { createAction } from 'redux-actions';

import { isRetrograde } from 'utils/retrograde';
import { AppDispatch, AppGetState } from 'store';

import { TYPES, Planet } from './types';

const setRetrogradePlanets = createAction(TYPES.SET);

export const initRetrograde = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const retrogradePlanets: Planet[] = [];
    state.retrograde.planets.forEach(planet => {
      if (isRetrograde(planet)) {
        retrogradePlanets.push(planet);
      }
    });
    dispatch(setRetrogradePlanets(retrogradePlanets));
  };
};
