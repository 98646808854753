import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from 'analytics';
import { AppDispatch, RootState } from 'store';
import { getAvailableTime, setAwaitingNewTimeBalance, setLastTransactionDate } from 'store/astrologers/time/actions';
import { getAwaitingNewTimeBalanceStatus } from 'store/astrologers/selectors';

import usePrevious from './use-previous';

const useChatTimeBalance = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isAwaitingNewTimeBalance = useSelector(getAwaitingNewTimeBalanceStatus);
  const freeBalance = useSelector((state: RootState) => state.astrologers.time.balances?.freeBalance);
  const lastTransactionDate = useSelector((state: RootState) => state.astrologers.time.lastTransactionDate);
  const prevFreeBalance = usePrevious(freeBalance);
  const counter = useRef(0);

  useEffect(() => {
    let interval: any;

    if (isAwaitingNewTimeBalance) {
      interval = setInterval(async () => {
        if (counter.current >= 10) {
          Analytics.trackEvent('GetBalance', 'Delay', { lastTransactionDate });
          counter.current = 0;
          dispatch(setAwaitingNewTimeBalance(false));
          return clearInterval(interval);
        }

        const time = await dispatch(getAvailableTime());
        if (time?.lastTransactionDate !== lastTransactionDate) {
          Analytics.trackEvent('GetBalance', 'Success');
          counter.current = 0;
          dispatch(setLastTransactionDate(time?.lastTransactionDate));
          dispatch(setAwaitingNewTimeBalance(false));
          clearInterval(interval);
        } else {
          counter.current += 1;
        }
      }, 2000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isAwaitingNewTimeBalance, lastTransactionDate]);

  useEffect(() => {
    if (prevFreeBalance && prevFreeBalance > 0 && freeBalance === 0) {
      Analytics.trackEvent('Astrologist', 'Sub_Benefits_Over');
    }
  }, [freeBalance, prevFreeBalance]);
};

export default useChatTimeBalance;
