import { IS_SMALL } from 'constants/sizes';
import Localization from 'localization';
import { ADVISORS_ONB_PAGES_IDS } from 'store/astrologers/onboarding/types';

export const isValidOnboardingPage = (route: ADVISORS_ONB_PAGES_IDS): route is ADVISORS_ONB_PAGES_IDS => {
  return Object.values(ADVISORS_ONB_PAGES_IDS).includes(route);
};

export const getTitle = (minutes: number) => {
  if (minutes >= 3 && minutes <= 9) {
    return 'BONUS-1';
  }

  if (minutes >= 10) {
    return 'BONUS-2';
  }

  return null;
};

export const checkIsNeedResizeStyles = () => {
  return Localization.getLngCode() !== 'en' && IS_SMALL;
};
