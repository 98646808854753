import { PERIODS_IDS } from 'constants/periods';

import { UserHoroscopeV2Period } from './types';

export const MAP_HOROSCOPE_PERIOD_TO_ITEM_PERIOD = {
  [PERIODS_IDS.TODAY]: 'day',
  [PERIODS_IDS.TOMORROW]: 'day',
  [PERIODS_IDS.WEEK]: 'week',
  [PERIODS_IDS.MONTH]: 'month',
  [PERIODS_IDS.YEAR]: 'year',
} as const;

export const SUMMARY_WIDGET_PERIODS: UserHoroscopeV2Period[] = [
  PERIODS_IDS.TODAY,
  PERIODS_IDS.TOMORROW,
  PERIODS_IDS.WEEK,
  PERIODS_IDS.MONTH,
  PERIODS_IDS.YEAR,
];

export enum HOROSCOPE_REQUEST_STATUSES {
  DATA_ALREADY_LOADED = 'data_already_loaded',
  DATA_NOT_RECEIVED = 'data_not_received',
  NOT_ENOUGH_DATA = 'not_enough_data',
  REQUEST_ERROR = 'request_error',
  WAITING_TIMEOUT_EXPIRED = 'waiting_timeout_expired',
  SUCCESS = 'success',
}
