import { Courses, Course, CoursesSessions, Session, Answer } from 'api/guides/interfaces';

import { PROFILE_TYPES } from '../profile/types';

export enum TYPES {
  SET = 'GUIDES/SET',
  SURVEY_PASSED = 'GUIDES/SURVEY_PASSED',
  SET_FOLLOWERS = 'GUIDES/SET_FOLLOWERS',
  SET_SESSIONS = 'GUIDES/SET_SESSIONS',
  SAVE_ANSWERS = 'GUIDES/SAVE_ANSWERS',
  CLEAR_SESSIONS = 'GUIDES/CLEAR_SESSIONS',
  SESSIONS_LOADING = 'GUIDES/SESSIONS_LOADING',
  SET_MEDAL = 'GUIDES/SET_MEDAL',
  SET_AUDIO_LEVEL = 'GUIDES/SET_AUDIO_LEVEL',
}

export interface State {
  guides: Courses;
  surveyPassedFor: Course['id'][];
  seed: null | number;
  followersIcons: number[];
  sessions: CoursesSessions;
  sessionsLoading: boolean;
  userAnswers: {};
  hasMedal: boolean;
  podcastVoiceLevel: number;
  podcastMusicLevel: number;
}

interface Set {
  type: typeof TYPES.SET;
  payload: {
    guides: Courses;
    seed: null | number;
  };
}

interface SetSurveyPassed {
  type: typeof TYPES.SURVEY_PASSED;
  payload: Course['id'][];
}

interface SetFollowers {
  type: typeof TYPES.SET_FOLLOWERS;
  payload: number[];
}

interface SetSessions {
  type: typeof TYPES.SET_SESSIONS;
  payload: CoursesSessions;
}

interface SetAnswers {
  type: typeof TYPES.SAVE_ANSWERS;
  payload: {
    sessionId: Session['id'];
    answers: Answer[];
  };
}

interface ClearSessions {
  type: typeof TYPES.CLEAR_SESSIONS;
}

interface SessionsLoading {
  type: typeof TYPES.SESSIONS_LOADING;
}

interface SetMedal {
  type: typeof TYPES.SET_MEDAL;
}

interface SetAudioLevel {
  type: typeof TYPES.SET_AUDIO_LEVEL;
  payload: number;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionTypes =
  | Set
  | SetSurveyPassed
  | SetFollowers
  | SetSessions
  | SetAnswers
  | ClearSessions
  | SetMedal
  | SetAudioLevel
  | ResetUserData
  | SessionsLoading;
