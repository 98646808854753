import React, { FC, useEffect, memo, useMemo } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { sw, paddingVertical, paddingHorizontal, fs } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';

import { t2 } from 'localization';
import Analytics from 'analytics';
import Button from 'components/button';
import Text from 'components/text';
import HighLightText from 'components/hight-light-text';
import * as COLORS from 'constants/colors';
import * as ICONS from 'constants/icons';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';

import BEST_IMG from './images/image.png';

interface Props {
  isVisible: boolean;
  isReactivate?: boolean;
  type?: PRODUCT_TYPES;
  onClose: () => void;
}

const PaymentsSuccessModal: FC<Props> = ({ isVisible, type, isReactivate, onClose }) => {
  useEffect(() => {
    if (isVisible) {
      Analytics.track('Payment_Success_Modal_Open');
    }
  }, [isVisible]);

  const text = useMemo(() => {
    if (type === PRODUCT_TYPES.SUBSCRIPTION) {
      if (isReactivate) {
        return t2('SUBSCRIPTION.REACTIVATION.THANK_YOU.TEXT');
      }

      return t2('SUBSCRIPTION.THANK_YOU.TEXT');
    }

    return t2('PAYMENTS_MODALS.SUCCESS.TEXT');
  }, [type, isReactivate]);

  return (
    <Modal style={styles.modalContainer} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND} isVisible={isVisible} animationInTiming={500}>
      <View style={styles.modal}>
        <TouchableOpacity style={styles.closeIconWrapper} onPress={onClose}>
          <Image resizeMode={'contain'} style={styles.closeIcon} source={ICONS.CLOSE_ICON} />
        </TouchableOpacity>

        <Image source={BEST_IMG} style={styles.image} resizeMode={'cover'} />
        <Text font="Philosopher" style={styles.title}>
          {t2('PAYMENTS_MODALS.SUCCESS.TITLE')}
        </Text>
        <HighLightText style={styles.text} highlightedTextStyle={styles.highlightedText}>
          {text}
        </HighLightText>
        <View style={styles.buttonWrap}>
          <Button
            style={styles.button}
            titleStyle={styles.buttonTitle}
            title={
              isReactivate && type === PRODUCT_TYPES.SUBSCRIPTION
                ? t2('SUBSCRIPTION.REACTIVATION.THANK_YOU.BTN_TITLE')
                : t2('PAYMENTS_MODALS.SUCCESS.BTN_TITLE')
            }
            onPress={onClose}
          />
        </View>
      </View>
    </Modal>
  );
};

export default memo(PaymentsSuccessModal);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    width: '100%',
    margin: 0,
    justifyContent: 'flex-end',
  },
  modal: {
    backgroundColor: COLORS.MODAL_BG_COLOR,
    borderTopLeftRadius: sw(20),
    borderTopRightRadius: sw(20),
    paddingHorizontal: paddingHorizontal(20),
    paddingBottom: paddingVertical(40),
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIconWrapper: {
    top: paddingHorizontal(20),
    right: paddingHorizontal(20),
    position: 'absolute',
    zIndex: 2,
  },
  closeIcon: {
    width: sw(20),
    height: sw(20),
    tintColor: COLORS.BEIGE_2,
  },
  title: {
    fontSize: fs(26),
    marginTop: paddingVertical(15),
    color: COLORS.BEIGE_2,
    fontWeight: '700',
    alignSelf: 'center',
    textAlign: 'center',
  },
  text: {
    color: COLORS.LIGHT_BEIGE,
    paddingTop: paddingVertical(25),
    fontWeight: '400',
    alignSelf: 'center',
    fontSize: fs(16),
    textAlign: 'center',
  },
  highlightedText: {
    color: COLORS.BEIGE_2,
    fontWeight: '400',
  },
  image: {
    width: '100%',
    height: sw(140),
  },
  buttonWrap: {
    width: '100%',
    marginTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(10),
    alignItems: 'center',
  },
  button: {},
  buttonTitle: {
    fontSize: fs(20),
    lineHeight: fs(22),
    color: COLORS.WHITE,
    width: '100%',
  },
});
