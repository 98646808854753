import defaultParams from './default-params';
import { ActionTypes, FETCH_REMOTE_CONFIG_FAIL, SET_REMOTE_CONFIG, State } from './types';

const initialState: State = {
  error: false,
  remoteConfigParams: defaultParams,
  defaultParams,
};

export default function (state = initialState, action: ActionTypes) {
  switch (action.type) {
    case SET_REMOTE_CONFIG:
      return {
        ...state,
        error: false,
        remoteConfigParams: {
          ...state.remoteConfigParams,
          ...action.payload,
        },
      };

    case FETCH_REMOTE_CONFIG_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
