import api from 'api';

import { GET_ANSWERS, ChatAnswer } from './constants';

const getAnswers = async (id: string, receiveTarot: boolean = true): Promise<ChatAnswer> => {
  try {
    const response = await api.fetch(`${GET_ANSWERS}?astrologer_id=${id}&receiveTarot=${receiveTarot}`, { method: 'GET' });
    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] getAnswers', error);
    return {
      isBlurred: false,
      isTyping: 0,
      isRecording: 0,
      messages: [],
    };
  }
};

export default getAnswers;
