import api from 'api';
import { getImageWithUrl } from 'utils/image';

import { Astrologist, GET_ASTROLOGERS_PROFILES } from './constants';

const getAstrologersProfile = async (): Promise<Astrologist[]> => {
  try {
    const response = await api.fetch(GET_ASTROLOGERS_PROFILES, { method: 'GET' });
    return response.map((astrologist: any) => ({
      ...astrologist,
      avatar: {
        uri: getImageWithUrl(astrologist.photo),
      },
    }));
  } catch (error) {
    console.log('ERROR [Astrologist chat] getAstrologersProfile', error);
    return [];
  }
};

export default getAstrologersProfile;
