import api from '..';

import type { TodayMatches, TodayFeatures, GetTodayFeaturesResponse } from './interfaces';

const TODAY_FEATURES = '/today-features';

let getTodayFeaturesRequestPromise: Promise<GetTodayFeaturesResponse> | null = null;

export const getTodayMatches = async (): Promise<TodayMatches | null> => {
  try {
    if (getTodayFeaturesRequestPromise === null) {
      getTodayFeaturesRequestPromise = api.fetch(TODAY_FEATURES, {
        method: 'GET',
      });
    }

    const response = await getTodayFeaturesRequestPromise;

    getTodayFeaturesRequestPromise = null;

    if (response?.today_matches) {
      return response.today_matches;
    }

    return null;
  } catch (error) {
    console.log('[ERROR LOAD TODAYS MATCHES]', error);
    return null;
  }
};

export const getTodayFeatures = async (): Promise<TodayFeatures | null> => {
  try {
    if (getTodayFeaturesRequestPromise === null) {
      getTodayFeaturesRequestPromise = api.fetch(TODAY_FEATURES, {
        method: 'GET',
      });
    }

    const response = await getTodayFeaturesRequestPromise;

    getTodayFeaturesRequestPromise = null;

    if (response?.today_features) {
      return response.today_features;
    }

    return null;
  } catch (error) {
    console.log('[ERROR LOAD TODAYS FEATURES]', error);
    return null;
  }
};
