import { useSelector } from 'react-redux';

import { RootState } from 'store';

/**
 * The useModalIsVisible function returns a boolean value indicating whether a specific modal is
 * currently active.
 * @param {string} modal - The `modal` parameter is a string that represents the name or identifier of
 * the modal you want to check if it is visible or active.
 * @returns Returns a boolean value indicating whether the specified
 * modal is currently active or visible.
 */

const useModalIsVisible = (modal: string) => {
  const activeModal = useSelector((state: RootState) => state.modals.activeModal === modal);

  return activeModal;
};

export default useModalIsVisible;
