import api from '..';

import { StoryItem, Answer, Story } from './interfaces';

const STORIES = '/stories';

// Add ?show_next_after_minutes=0 after /stories, to get all the stories
let getStoriesRequestPromise: Promise<Story[]> | null = null;

export const getStories = async (): Promise<Story[]> => {
  try {
    if (getStoriesRequestPromise === null) {
      getStoriesRequestPromise = api.fetch(`${STORIES}`, {
        method: 'GET',
      });
    }

    const response = await getStoriesRequestPromise;

    getStoriesRequestPromise = null;

    return response?.length ? response : [];
  } catch (err) {
    console.log('[ERROR GET STORIES]: ', err);
    return [];
  }
};

export const sendView = (
  storyId: StoryItem['id'],
): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${STORIES}/view`, {
    method: 'POST',
    data: {
      story_id: storyId,
    },
  });
};

export const sendAnswer = (
  answer_id: Answer['id'],
): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${STORIES}/choose-answer`, {
    method: 'POST',
    data: {
      answer_id,
    },
  });
};

export const sendLike = (
  storyId: StoryItem['id'],
): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${STORIES}/like`, {
    method: 'POST',
    data: {
      story_id: storyId,
    },
  });
};

export const sendDislike = (
  storyId: StoryItem['id'],
): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${STORIES}/dislike`, {
    method: 'POST',
    data: {
      story_id: storyId,
    },
  });
};

export const sendEmoji = (storyId: StoryItem['id'], emoji: string) => {
  return api.fetch(`${STORIES}/reaction`, {
    method: 'POST',
    data: {
      story_id: storyId,
      reaction: emoji,
    },
  });
};

export const share = (
  storyId: StoryItem['id'],
): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${STORIES}/share`, {
    method: 'POST',
    data: {
      story_id: storyId,
    },
  });
};

// Remove all views, likes, dislikes etc.
export const clearActivity = (): Promise<{
  success: boolean;
}> => {
  return api.fetch(`${STORIES}/clear-activity`, {
    method: 'DELETE',
  });
};
