import React, { FC, useEffect, useMemo } from 'react';
import { StyleSheet, View, TouchableOpacity, ScrollView, Image } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw, color } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';

import Analytics from 'analytics';
import { t2 } from 'localization';
import { PAYMENT_EVENTS_CATEGORIES } from 'analytics/const';
import { WEB_SUBSCRIPTION_STATUS, OTHERS_PAYMENT_SCREENS } from 'modules/payments/constants';
import { prepareWebSubscriptionToProduct } from 'modules/payments/utils';
import { getPriceText } from 'modules/payments/utils/price-text';
import { useAppSelector, useAppDispatch } from 'store';
import { verifyWebSubscriptions } from 'store/billing/general-actions';
import { getWebSubscriptionToReactivate } from 'store/billing/selectors';
import { showModal, closeCurrentModal } from 'store/modals/actions';
import { navigateWithMonetization } from 'store/unlock-content/actions';
import Text from 'components/text';
import SubBenefits from 'components/sub-benefits';
import HighLightText from 'components/hight-light-text';
import ReactivateSubscriptionButton from 'components/web-payment-iframe-buttons/reactivate-subscription';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { windowHeight } from 'constants/sizes';
import { PRICE_LOCATION } from 'constants/subscription';
import PRO_ICON from 'assets/icons/pro/pro.png';

import ICON_CROSS from '../images/beige-cross.png';
import STARS_IMG from '../images/stars.png';

interface Props {}

const WebReactivationBenefitsModal: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(state => state.modals.activeModal === MODALS.REACTIVATION_BENEFITS);
  const params = useAppSelector(state => state.modals.params);
  const purchased = useAppSelector(state => state.billing.purchased);
  const subsToReactivate = useAppSelector(getWebSubscriptionToReactivate);
  const config = useAppSelector(state => state.remoteConfig.remoteConfigParams?.reactivationModal);
  const status = subsToReactivate ? subsToReactivate?.status : 0;
  const eventParams = { trigger: params?.trigger, screen: OTHERS_PAYMENT_SCREENS.POPUP };

  useEffect(() => {
    if (isVisible) {
      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Open', eventParams);
    }
  }, [isVisible]);

  const priceText = useMemo(() => {
    if (!subsToReactivate || config.priceLocation !== PRICE_LOCATION.ABOVE) {
      return '';
    }

    return getPriceText(prepareWebSubscriptionToProduct(subsToReactivate), '{priceText}/{period}');
  }, [subsToReactivate, config]);

  const handleClose = (skipCallback = false) => {
    dispatch(closeCurrentModal());

    if (!skipCallback && purchased && config.showByTriggers && typeof params?.onClose === 'function') {
      params.onClose();
    }
  };

  const handlePress = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Submit', {
      ...eventParams,
      status,
    });
  };

  const handleSkip = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Skip', {
      ...eventParams,
      status,
    });

    handleClose();
  };

  const handleClosePress = () => {
    handleClose();
  };

  const handleLinkBtnPress = () => {
    const eventAction = !config.linkBtnTrigger ? 'NotRenew_Click' : 'Other_Plans';
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, eventAction, eventParams);

    handleClose(!!config.linkBtnTrigger);

    if (config.linkBtnTrigger) {
      dispatch(
        navigateWithMonetization({
          trigger: config.linkBtnTrigger,
          skipReactivation: true,
        }),
      );
    }
  };

  const handleSuccess = () => {
    Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.REACTIVATION, 'Success', {
      ...eventParams,
      status,
    });

    dispatch(verifyWebSubscriptions());
    handleClose();
    dispatch(showModal(MODALS.REACTIVATION_THX));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <View style={styles.header}>
          <CloseButtonWithTimeout timeout={config.closeBtnTimeout} onPress={handleClosePress} source={ICON_CROSS} style={styles.cross} />

          <View style={styles.top} pointerEvents="none">
            <Image style={styles.image} source={STARS_IMG} resizeMode="contain" />
            <Image source={PRO_ICON} style={styles.pro} />
          </View>

          <View style={styles.headerContainer}>
            <Text font="Philosopher" style={styles.title}>
              {t2('SUBSCRIPTION.REACTIVATION.BENEFITS.TITLE')}
            </Text>

            <HighLightText style={styles.subTitle} highlightedTextStyle={styles.subTitleHightLight}>
              {status === WEB_SUBSCRIPTION_STATUS.EXPIRED
                ? t2('SUBSCRIPTION.REACTIVATION.BENEFITS.SUB_TITLE_EXPIRED')
                : t2('SUBSCRIPTION.REACTIVATION.BENEFITS.SUB_TITLE_CANCELED')}
            </HighLightText>
          </View>
        </View>

        <ScrollView style={styles.scrollView} contentContainerStyle={styles.contentContainer}>
          <SubBenefits />
        </ScrollView>

        <View style={styles.footer}>
          {!!priceText && <Text style={styles.priceText}>{priceText}</Text>}

          <ReactivateSubscriptionButton
            style={styles.button}
            buttonTitle={t2('SUBSCRIPTION.REACTIVATION.BENEFITS.BTN_TITLE')}
            onPress={handlePress}
            onSkip={handleSkip}
            onSuccess={handleSuccess}
            isReactivate={true}
          />

          {config?.linkBtnEnabled && (
            <TouchableOpacity onPress={handleLinkBtnPress} style={styles.link}>
              <Text style={styles.linkText}>
                {!config.linkBtnTrigger ? t2('SUBSCRIPTION.REACTIVATION.BENEFITS.DON_T_RENEW') : t2('SUBSCRIPTION.REACTIVATION.BENEFITS.OTHER_PLANS')}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </Modal>
  );
};

export default WebReactivationBenefitsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    height: 'auto',
    flexGrow: 0,
    maxHeight: windowHeight * 0.9,
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
  },
  scrollView: {
    width: '100%',
  },
  cross: {
    position: 'absolute',
    top: 0,
    right: 0,
    paddingVertical: 0,
    paddingRight: paddingHorizontal(20),
    zIndex: 1,
  },
  header: {
    marginTop: paddingVertical(20),
    width: '100%',
    paddingBottom: paddingVertical(10),
  },
  headerContainer: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(20),
  },
  top: {
    width: '100%',
    height: 71,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    position: 'absolute',
    alignSelf: 'center',
    width: '100%',
  },
  pro: {},
  title: {
    color: COLORS.BEIGE_2,
    marginTop: -paddingVertical(5),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(33),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(10),
    fontSize: fs(17),
    lineHeight: fs(22),
    color: color(COLORS.GOLDEN, 0.8),
    textAlign: 'center',
  },
  subTitleHightLight: {
    color: COLORS.FADED_ORANGE,
  },
  footer: {
    paddingHorizontal: paddingHorizontal(20),
    paddingTop: paddingVertical(20),
  },
  contentContainer: {
    paddingTop: paddingVertical(10),
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  button: {},
  link: {
    marginTop: paddingVertical(10),
    paddingVertical: paddingVertical(5),
  },
  linkText: {
    fontSize: fs(16),
    lineHeight: fs(18),
    color: COLORS.AQUA,
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
  priceText: {
    marginBottom: paddingVertical(10),
    fontSize: fs(12),
    fontWeight: '400',
    textAlign: 'center',
    color: color(COLORS.BEIGE, 0.8),
  },
});
