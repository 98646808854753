import { TAROT_LAYOUT_IDS } from 'screens/tarot/constants';
import { TarotCard, TarotCardOfTheDayHistoryItem } from 'api/tarot/interfaces';

export const SET_PENDING = 'TAROT/SET_PENDING';
export const SET_CARDS = 'TAROT/SET_CARDS';
export const SET_CARDS_OF_THE_DAY_HISTORY = 'TAROT/SET_CARDS_OF_THE_DAY_HISTORY';
export const SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR = 'TAROT/SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR';
export const RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR = 'TAROT/RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR';
export const SET_CARDS_FETCH_DATE = 'TAROT/SET_CARDS_FETCH_DATE';
export const SET_SELECTED_CARDS = 'TAROT/SET_SELECTED_CARDS';
export const SET_IMAGES_CACHED = 'TAROT/SET_IMAGES_CACHED';
export const SET_FORCE_RELOAD = 'TAROT/SET_FORCE_RELOAD';
export const RESET_CARDS = 'TAROT/RESET_CARDS';

type CardsLayouts = {
  [key in TAROT_LAYOUT_IDS]: number[];
};

export interface State {
  cards: TarotCard[];
  cardsOfTheDayHistory: TarotCardOfTheDayHistoryItem[];
  cardsOfTheDayRandAcc: number[];
  cardsFetchDate: string | null;
  imagesCached: boolean;
  selectedCards: CardsLayouts;
  loading: boolean;
  forceReload: boolean;
}

interface setPending {
  type: typeof SET_PENDING;
  payload: boolean;
}

interface setCards {
  type: typeof SET_CARDS;
  payload: TarotCard[];
}

interface setCardsOfTheDayHistory {
  type: typeof SET_CARDS_OF_THE_DAY_HISTORY;
  payload: TarotCardOfTheDayHistoryItem[];
}

interface setCardsOfTheDayRandomizationsAccumulator {
  type: typeof SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR;
  payload: number;
}

interface resetCardsOfTheDayRandomizationsAccumulator {
  type: typeof RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR;
  payload: number;
}

interface setCardsFetchDate {
  type: typeof SET_CARDS_FETCH_DATE;
  payload: string | null;
}

interface setImagesCached {
  type: typeof SET_IMAGES_CACHED;
  payload: boolean;
}

interface setSelectedCards {
  type: typeof SET_SELECTED_CARDS;
  payload: {
    layout: TAROT_LAYOUT_IDS;
    cards: number[];
  };
}

interface setForceReload {
  type: typeof SET_FORCE_RELOAD;
}

interface resetCards {
  type: typeof RESET_CARDS;
}

export type ActionTypes =
  | setCards
  | setCardsOfTheDayHistory
  | setCardsOfTheDayRandomizationsAccumulator
  | resetCardsOfTheDayRandomizationsAccumulator
  | setCardsFetchDate
  | setSelectedCards
  | setPending
  | setImagesCached
  | setForceReload
  | resetCards;

export interface TarotCardOfTheDay extends TarotCardOfTheDayHistoryItem {
  image: string | undefined;
}
