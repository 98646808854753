export enum ASTRO_CALENDAR_BONUS_TYPE {
  MINUTES = 'minutes',
  GUIDE = 'guide',
  PDF_GUIDE = 'pdf_guide',
}

export enum ASTRO_CALENDAR_GUIDE_SLUG {
  BETTER_AT_DATING = 'better_at_dating',
  BIORHYTHMS = 'biorhythms',
  BIRTH_CHART = 'birth_chart',
  FIND_TRUE_LOVE = 'find_true_love',
  HEALTHY_RELATIONSHIP = 'healthy_relationship',
  LONG_DISTANCE_RELATIONSHIP = 'long_distance_relationship',
  MANAGE_YOUR_MONEY = 'manage_your_money',
  MARRIAGE_COMPATIBILITY = 'marriage_compatibility',
  PALM_READING = 'palm_reading',
  SIGNS_COMPATIBILITY = 'signs_compatibility',
  SUCCEED_AT_WORK = 'succeed_at_work',
}
