import { ImageSourcePropType } from 'react-native';

import FEMALE_SMALL_ICON from 'assets/icons/user/female.png';
import MALE_SMALL_ICON from 'assets/icons/user/male.png';
import NON_BINARY_SMALL_ICON from 'assets/icons/user/non_binary.png';

import SETTINGS_GENDER_MALE from '../screens/settings/images/icon-user-man.png';
import SETTINGS_GENDER_NON_BINARY from '../screens/settings/images/icon-user-nonbinary.png';
import SETTINGS_GENDER_FEMALE from '../screens/settings/images/icon-user-woman.png';

export enum GENDERS {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NON_BINARY = 'NON_BINARY',
}

interface GendersConfig {
  [key: string]: ImageSourcePropType;
}

export const GENDER_ICONS: GendersConfig = {
  [GENDERS.MALE]: SETTINGS_GENDER_MALE,
  [GENDERS.FEMALE]: SETTINGS_GENDER_FEMALE,
  [GENDERS.NON_BINARY]: SETTINGS_GENDER_NON_BINARY,
};

export const GENDER_SMALL_ICONS: GendersConfig = {
  [GENDERS.MALE]: MALE_SMALL_ICON,
  [GENDERS.FEMALE]: FEMALE_SMALL_ICON,
  [GENDERS.NON_BINARY]: NON_BINARY_SMALL_ICON,
};

export const SINGLE = 'SINGLE';
export const MARRIED = 'MARRIED';
export const SOULMATE = 'SOULMATE';
export const ENGAGED = 'ENGAGED';
export const DIFFICULT = 'DIFFICULT';

export type RelationshipStatus = 'SINGLE' | 'MARRIED' | 'SOULMATE' | 'ENGAGED' | 'DIFFICULT';

export const GENDERS_IDS = [GENDERS.FEMALE, GENDERS.MALE, GENDERS.NON_BINARY];

export const MARITAL_STATUS_IDS = [SOULMATE, ENGAGED, MARRIED, DIFFICULT, SINGLE];

export const MAX_NAME_LENGTH = 15;
