import api from 'api';

import { ADD_MESSAGE, Astrologist } from './constants';

const addMessage = async (id: Astrologist['astrologer_id'], text: string, hiddenMessage: string = '', metadata = {}): Promise<undefined> => {
  try {
    const response = await api.fetch(ADD_MESSAGE, {
      method: 'POST',
      data: {
        astrologer_id: id,
        message: text,
        hiddenMessage,
        metadata,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] addMessage', error);
  }
};

export default addMessage;
