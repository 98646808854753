import { BROWSERS_NAMES } from 'constants/pwa';

export const SET_INSTALLED = 'PWA/SET_INSTALLED';
export const SET_BANNER_SESSION_STARTED = 'PWA/SET_BANNER_SESSION_STARTED';
export const SET_BROWSER_NAME = 'PWA/SET_BROWSER_NAME';
export const SET_PWA_AUTO_INSTALL_AVAILABLE = 'PWA/SET_PWA_AUTO_INSTALL_AVAILABLE';
export const SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS = 'PWA/SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS';
export const SET_INSTALL_MOBILE_APP_SHOW_COUNT = 'PWA/SET_INSTALL_MOBILE_APP_SHOW_COUNT';

export interface State {
  installed: boolean;
  bannerSessionStarted: number;
  browserName: BROWSERS_NAMES;
  isPWAAutoInstallAvailable: boolean;
  installMobileAppPromocodeSeconds: number;
  installMobileAppShowCount: number;
}

interface SetInstalled {
  type: typeof SET_INSTALLED;
  payload: boolean;
}

interface SetBannerSessionStarted {
  type: typeof SET_BANNER_SESSION_STARTED;
  payload: number;
}

interface SetBrowserName {
  type: typeof SET_BROWSER_NAME;
  payload: BROWSERS_NAMES;
}

interface SetPWAAutoInstallAvailable {
  type: typeof SET_PWA_AUTO_INSTALL_AVAILABLE;
  payload: boolean;
}

interface SetInstallMobileAppPromocodeSeconds {
  type: typeof SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS;
  payload: number;
}

interface SetInstallMobileAppShowCount {
  type: typeof SET_INSTALL_MOBILE_APP_SHOW_COUNT;
  payload: number;
}

export type ActionTypes =
  | SetInstalled
  | SetBannerSessionStarted
  | SetBrowserName
  | SetPWAAutoInstallAvailable
  | SetInstallMobileAppShowCount
  | SetInstallMobileAppPromocodeSeconds;
