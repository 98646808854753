import { ImageMaps } from 'interfaces/image';
import NEW_MOON_ICON from 'components/widgets/lunar-calendar/icons/new-moon.png';
import WAXING_CRESCENT_ICON from 'components/widgets/lunar-calendar/icons/wax-gres-moon.png';
import FIRST_QUARTER_ICON from 'components/widgets/lunar-calendar/icons/first-qua-moon.png';
import WAXING_GIBBOUS_ICON from 'components/widgets/lunar-calendar/icons/wax-gib-moon.png';
import FULL_MOON_ICON from 'components/widgets/lunar-calendar/icons/full-moon.png';
import WANING_GIBBOUS_ICON from 'components/widgets/lunar-calendar/icons/wan-gib-moon.png';
import LAST_QUARTER_ICON from 'components/widgets/lunar-calendar/icons/last-qua-moon.png';
import WANING_CRESCENT_ICON from 'components/widgets/lunar-calendar/icons/wan-gre-moon.png';

const YELLOW = '#e0e339';
const GREEN = '#1ad386';
const BLUE = '#0fccff';
const BROWN = '#94593f';
const WHITE = '#ffffff';
const RED = '#d9193c';
const ORANGE = '#e79111';
const PINK = '#ff4ac9';
const GRAY = '#919294';
const BLACK = '#272727';
const BEIGE = '#dfb79a';
const GOLDEN = '#d1b30d';
const EMERALD = '#147d84';
const LIGHT_MAGENTA = '#d00d68';
const BURGUNDY = '#ab2115';
const CORAL = '#e3323c';
const COPPER = '#bd6524';
const OLIVE = '#aab53a';
const SILVER = '#bcc2c5';
const PURPLE = '#8738ec';
const KHAKI = '#4c6a25';
const LILAC = '#977ce2';
const TURQUOISE = '#4ab4c8';
const MARRON = '#ae3149';
const COQUELICOT = '#e54926';
const HONEYDEW = '#cae9b7';
const CHARCOAL = '#484d4e';
const BRONZE = '#b57c3e';
const AZURE = '#3782fe';
const RED_BERRY = '#721322';

export const CALENDAR_COLORS = {
  1: YELLOW,
  2: GREEN,
  3: BLUE,
  4: BROWN,
  5: WHITE,
  6: RED,
  7: ORANGE,
  8: PINK,
  9: GRAY,
  10: BLACK,
  11: BEIGE,
  12: GOLDEN,
  13: EMERALD,
  14: LIGHT_MAGENTA,
  15: BURGUNDY,
  16: CORAL,
  17: COPPER,
  18: OLIVE,
  19: SILVER,
  20: PURPLE,
  21: KHAKI,
  22: LILAC,
  23: TURQUOISE,
  24: MARRON,
  25: COQUELICOT,
  26: HONEYDEW,
  27: CHARCOAL,
  28: BRONZE,
  29: AZURE,
  30: RED_BERRY,
};

export const FAVORABLE = 'favorable';
export const UNFAVORABLE = 'unfavorable';
export const DEFAULT = 'default';
export const NEUTRAL = 'neutral';
export const CURRENT = 'current';

export const IDEAS = 'ideas';
export const TIPS = 'tips';
export const QUESTIONS = 'questions';
export const FOOD = 'food';
export const OUTFIT = 'outfit';
export const CHEMISTRY = 'physical_chemistry';

export const NEW_MOON = 'new-moon';
export const WAXING_CRESCENT = 'waxing-crescent';
export const FIRST_QUARTER = 'first-quarter';
export const WAXING_GIBBOUS = 'waxing-gibbous';
export const FULL_MOON = 'full-moon';
export const WANING_GIBBOUS = 'waning-gibbous';
export const LAST_QUARTER = 'last-quarter';
export const WANING_CRESCENT = 'waning-crescent';

interface MoonPhaseNameMap {
  [key: string]: string;
}

export const MOON_PHASE_ICONS: ImageMaps = {
  [NEW_MOON]: NEW_MOON_ICON,
  [WAXING_CRESCENT]: WAXING_CRESCENT_ICON,
  [FIRST_QUARTER]: FIRST_QUARTER_ICON,
  [WAXING_GIBBOUS]: WAXING_GIBBOUS_ICON,
  [FULL_MOON]: FULL_MOON_ICON,
  [WANING_GIBBOUS]: WANING_GIBBOUS_ICON,
  [LAST_QUARTER]: LAST_QUARTER_ICON,
  [WANING_CRESCENT]: WANING_CRESCENT_ICON,
};

export const MOON_PHASE_SHORT_NAME: MoonPhaseNameMap = {
  [NEW_MOON]: 'MOON_PHASES.NEW_MOON',
  [WAXING_CRESCENT]: 'MOON_PHASES.WAXING_CRESCENT',
  [FIRST_QUARTER]: 'MOON_PHASES.FIRST_QUARTER',
  [WAXING_GIBBOUS]: 'MOON_PHASES.WAXING_GIBBOUS',
  [FULL_MOON]: 'MOON_PHASES.FULL_MOON',
  [WANING_GIBBOUS]: 'MOON_PHASES.WANING_GIBBOUS',
  [LAST_QUARTER]: 'MOON_PHASES.LAST_QUARTER',
  [WANING_CRESCENT]: 'MOON_PHASES.WANING_CRESCENT',
};
