import moment from 'moment';
import _ from 'lodash';

import { PERIODS_IDS } from '../constants/periods';

const format = (isLong, reverse) => {
  if (reverse) {
    return isLong ? 'MMMM D' : 'MMM D';
  }
  return isLong ? 'D MMMM' : 'D MMM';
};

export const generateDatesToString = (date, isLong, reverse) => {
  let res = '';

  for (let i = 0; i < date.length; i++) {
    if (date[i].start === date[i].end) {
      res += moment(date[i].start, 'DD:MM')
        .format(format(isLong, reverse))
        .split(' ')
        .map(el => _.capitalize(el))
        .join(' ');
    } else {
      if (date[i].start) {
        res += moment(date[i].start, 'DD:MM')
          .format(format(isLong, reverse))
          .split(' ')
          .map(el => _.capitalize(el))
          .join(' ');
      }

      if (date[i].end) {
        res += ' - ';
        res += moment(date[i].end, 'DD:MM')
          .format(format(isLong, reverse))
          .split(' ')
          .map(el => _.capitalize(el))
          .join(' ');
      }
    }

    if (i !== date.length - 1) {
      res += ', ';
    }
  }

  return res;
};

export function isToday(date) {
  const today = new Date();
  return today.toLocaleDateString('en-US') === new Date(date).toLocaleDateString('en-US');
}

export const getDateString = currentPeriod => {
  const today = moment();

  switch (currentPeriod) {
    case PERIODS_IDS.TODAY:
      return _.capitalize(today.format('MMM D, YYYY'));

    case PERIODS_IDS.BEFORE_YESTERDAY:
      return _.capitalize(today.add(-2, 'days').format('MMM D, YYYY'));

    case PERIODS_IDS.YESTERDAY:
      return _.capitalize(today.add(-1, 'days').format('MMM D, YYYY'));

    case PERIODS_IDS.TOMORROW:
      return _.capitalize(today.add(+1, 'days').format('MMM D, YYYY'));

    case PERIODS_IDS.AFTER_TOMORROW:
      return _.capitalize(today.add(+2, 'days').format('MMM D, YYYY'));

    case PERIODS_IDS.WEEK: {
      const from_date = today.startOf('week');
      const to_date = moment().endOf('week');

      return _.capitalize(from_date.format('MMM D, YYYY')) + ' - ' + _.capitalize(to_date.format('MMM D, YYYY'));
    }

    case PERIODS_IDS.MONTH: {
      return _.capitalize(today.format('MMMM, YYYY'));
    }

    case PERIODS_IDS.YEAR: {
      return today.format('YYYY');
    }

    case PERIODS_IDS.NEXT_YEAR: {
      return today.add(1, 'year').format('YYYY');
    }
  }

  return '';
};
