export enum TYPES {
  SET_STEP = 'UNLOCK/SET_STEP',
  SET_CURRENT_TRIGGER = 'UNLOCK/SET_CURRENT_TRIGGER',
  SET_LAST_TRIGGER = 'UNLOCK/SET_LAST_TRIGGER',
  SET_BACKGROUND_PLACEMENT_VISIBILITY = 'UNLOCK/SET_BACKGROUND_PLACEMENT_VISIBILITY',
}

export interface State {
  step: number;
  currentTrigger: any;
  lastTrigger: any;
  isBackgroundPlacementVisible: boolean;
}

interface SetStep {
  type: typeof TYPES.SET_STEP;
  payload: number;
}

interface SetCurrentTrigger {
  type: typeof TYPES.SET_CURRENT_TRIGGER;
  payload: string;
}

interface SetLastTrigger {
  type: typeof TYPES.SET_LAST_TRIGGER;
  payload: string;
}

interface SetBackgroundPlacementVisibility {
  type: typeof TYPES.SET_BACKGROUND_PLACEMENT_VISIBILITY;
  payload: boolean;
}

export type ActionTypes = SetStep | SetCurrentTrigger | SetLastTrigger | SetBackgroundPlacementVisibility;
