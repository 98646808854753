import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { initBiorhythms } from 'store/biorhythms/action';
import { initDatingCalendar, initDatingWidgets } from 'store/dating-calendar/actions';
import { loadZodiacForUser, reloadUserHoroscopesV2 } from 'store/horoscope/actions';
import { loadTodaysMatches } from 'store/matches/actions';
import { selectUserProfileIsLoaded } from 'store/profile/selectors';
import { ProfileData, ProfileParamName } from 'store/profile/types';
import { loadTodaysFeatures } from 'store/todays-features/actions';
import { setForceReload } from 'store/tarot/actions';

const useUpdateAppContent = () => {
  const dispatch = useAppDispatch();
  const profileData = useAppSelector(state => state.profile.profileData);
  const birthPlace = useAppSelector(state => state.birthChart.birthPlace);
  const birthTime = useAppSelector(state => state.birthChart.birthTime);
  const userProfileIsLoaded = useAppSelector(selectUserProfileIsLoaded);

  const prevProfileData = useRef<ProfileData>({ ...profileData, place: birthPlace.name, time: birthTime || '00:00' });

  const updateAppContent = useCallback(
    (params: ProfileParamName[]) => {
      if (params.includes('lang')) {
        dispatch(setForceReload());
      }

      if (userProfileIsLoaded) {
        if (params.includes('signs') && params.includes('date')) {
          dispatch(initBiorhythms());
          dispatch(loadZodiacForUser());
          dispatch(loadTodaysMatches());
          dispatch(loadTodaysFeatures());
          dispatch(initDatingCalendar());
          dispatch(initDatingWidgets());
          dispatch(reloadUserHoroscopesV2());
          return;
        }

        if (params.includes('time') || params.includes('date') || params.includes('place')) {
          dispatch(reloadUserHoroscopesV2());
          return;
        }
      }
    },
    [dispatch, userProfileIsLoaded],
  );

  useEffect(() => {
    const fullProfileParams: ProfileData = { ...profileData, place: birthPlace.name, time: birthTime || '00:00' };
    const updatedParams = Object.keys(fullProfileParams).filter(key => {
      if (!isEqual(fullProfileParams[key], prevProfileData.current[key])) {
        prevProfileData.current[key] = fullProfileParams[key];
        return true;
      }
    }) as ProfileParamName[];

    if (updatedParams.length) {
      updateAppContent(updatedParams);
    }
  }, [birthPlace, birthTime, profileData, updateAppContent]);
};

export default useUpdateAppContent;
