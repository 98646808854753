export enum TIPS_IDS {
  DAILY_TIPS = 'daily_tips',
  LOVE = 'love',
  RECOMMENDATIONS = 'recommendations',
  WARNINGS = 'warnings',
  WORK = 'work',
}

export const PRIORITIES = {
  [TIPS_IDS.LOVE]: 1,
  [TIPS_IDS.WARNINGS]: 2,
  [TIPS_IDS.WORK]: 3,
  [TIPS_IDS.RECOMMENDATIONS]: 4,
};
