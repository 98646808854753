import React, { FC, useEffect, useMemo, memo } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { sw, paddingVertical, paddingHorizontal, fs } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';

import Analytics from 'analytics';
import { t } from 'localization';
import Button from 'components/button';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import * as ICONS from 'constants/icons';
import { PAYMENT_METHODS } from 'modules/payments/constants';

import { type Product } from '../../../interfaces/product';
import { ERROR_CODES } from '../../../constants/error';
import PayPalIframeButton from '../../buttons/paypal/iframe';

import CARD_IMG from './images/card.png';
import CLOUD_IMG from './images/cloud.png';

interface Props {
  isVisible: boolean;
  withPaypal: boolean;
  product: Product;
  title?: string;
  idfm: string;
  productTrigger?: string;
  metadata?: {
    [key: string]: any;
  };
  statusCode: string | number;
  onButtonPress: () => void;
  onClose: () => void;
  onSuccess: (method: PAYMENT_METHODS.PAYPAL, transactionDetails: any) => void;
}

const PaymentsErrorModal: FC<Props> = ({
  isVisible,
  withPaypal,
  idfm,
  product,
  metadata,
  productTrigger,
  onClose,
  onButtonPress,
  onSuccess,
  statusCode,
}) => {
  useEffect(() => {
    if (isVisible) {
      Analytics.track('Payment_Error_Modal_Open', { statusCode });
    }
  }, [isVisible]);

  const code = useMemo(() => {
    const isInsufficentFunds = statusCode === '3.02' || statusCode === '302' || statusCode === 302 || statusCode === 3.02;
    if (statusCode && isInsufficentFunds) {
      return ERROR_CODES.INSUFFICENT_FUNDS;
    } else {
      return ERROR_CODES.CARD;
    }
  }, [statusCode]);

  return (
    <Modal style={styles.modalContainer} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND} isVisible={isVisible} animationInTiming={500}>
      <View style={styles.modal}>
        <TouchableOpacity style={styles.closeIconWrapper} onPress={onClose}>
          <Image resizeMode={'contain'} style={styles.closeIcon} source={ICONS.CLOSE_ICON} />
        </TouchableOpacity>
        <Image source={code === ERROR_CODES.INSUFFICENT_FUNDS ? CLOUD_IMG : CARD_IMG} style={styles.image} resizeMode={'cover'} />

        <View style={styles.container}>
          <Text font="Philosopher" style={styles.title}>
            {t(`PAYMENTS_MODALS.ERROR.${code}.TITLE`)}
          </Text>
          <Text style={styles.text}>{t(`PAYMENTS_MODALS.ERROR.${code}.TEXT`)}</Text>
          <View style={styles.buttonsWrap}>
            <Button
              style={styles.button}
              titleStyle={styles.buttonTitle}
              title={t(`PAYMENTS_MODALS.ERROR.${code}.BTN_TITLE`)}
              onPress={onButtonPress}
            />

            {withPaypal && (
              <>
                <View style={styles.orWrap}>
                  <View style={styles.orLine} />
                  <View style={styles.orTextWrap}>
                    <Text style={styles.orText}>{t(`OR`)}</Text>
                  </View>
                </View>

                {!!product && (
                  <PayPalIframeButton idfm={idfm} productTrigger={productTrigger} product={product} metadata={metadata} onSuccess={onSuccess} />
                )}
              </>
            )}
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default memo(PaymentsErrorModal);

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    width: '100%',
    margin: 0,
    justifyContent: 'flex-end',
  },
  modal: {
    backgroundColor: COLORS.MODAL_BG_COLOR,
    borderTopLeftRadius: sw(20),
    borderTopRightRadius: sw(20),
    paddingTop: paddingVertical(20),
    paddingBottom: paddingVertical(40),
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    paddingHorizontal: paddingHorizontal(20),
    alignItems: 'center',
  },
  closeIconWrapper: {
    top: paddingHorizontal(20),
    right: paddingHorizontal(20),
    position: 'absolute',
    zIndex: 2,
  },
  closeIcon: {
    width: sw(20),
    height: sw(20),
    tintColor: COLORS.BEIGE_2,
  },
  title: {
    fontSize: fs(26),
    paddingHorizontal: paddingHorizontal(10),
    marginTop: paddingVertical(25),
    color: COLORS.BEIGE_2,
    fontWeight: '700',
    alignSelf: 'center',
    textAlign: 'center',
  },
  text: {
    color: COLORS.LIGHT_BEIGE,
    paddingTop: paddingVertical(25),
    fontWeight: '400',
    alignSelf: 'center',
    fontSize: fs(16),
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: sw(85),
  },
  buttonsWrap: {
    width: '100%',
    marginTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(10),
    alignItems: 'center',
  },
  button: {},
  buttonTitle: {
    fontSize: fs(20),
    lineHeight: fs(22),
    color: COLORS.WHITE,
    width: '100%',
  },
  orWrap: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: paddingVertical(10),
  },
  orLine: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 1,
    backgroundColor: COLORS.SEMI_LIGHT_BEIGE,
  },
  orTextWrap: {
    backgroundColor: COLORS.DARK_TEAL,
    paddingHorizontal: paddingHorizontal(8),
  },
  orText: {
    textAlign: 'center',
    fontSize: fs(15),
    fontWeight: '600',
    color: COLORS.BEIGE_WITH_OPACITY_07_2,
  },
});
