import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  addressesList: [],
  loading: false,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        addressesList: action.payload,
        loading: false,
      };
    case TYPES.LOADING:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CLEAR:
      return {
        ...state,
        addressesList: [],
        loading: false,
      };
    default:
      return state;
  }
}
