import React, { FC, useEffect, useMemo } from 'react';
import { StyleSheet, DimensionValue, View } from 'react-native';
import queryString from 'query-string';

import Analytics from 'analytics';
import { PAYMENT_METHODS } from 'modules/payments/constants';
import { PRODUCT_TYPES } from 'modules/payments/constants/product';
import { useAppSelector } from 'store';
import Iframe from 'components/web/iframe';
import { ASB_URL } from 'constants/general';
import useMessageListener from 'hooks/use-message-listener';

import { type Product } from '../../../interfaces/product';

interface Props {
  isVisible: boolean;
  availableMethods: PAYMENT_METHODS[];
  product: Product;
  metadata?: {
    [key: string]: any;
  };
  productTrigger?: string;
  idfm: string;
  title: string;
  eventParams?: any;
  onButtonPress: (method: PAYMENT_METHODS) => void;
  onSuccess: (method: PAYMENT_METHODS, transactionDetails: any) => void;
  onError: (method: PAYMENT_METHODS, error: any) => void;
  onDismiss?: () => void;
  onLoaded?: () => void;
}

const PaymentsMethodsModal: FC<Props> = ({
  isVisible,
  product,
  idfm,
  title,
  eventParams = {},
  metadata,
  productTrigger,
  onSuccess,
  onError,
  onDismiss,
  onLoaded,
}) => {
  const webMode = useAppSelector(state => state.app.webMode);
  const email = useAppSelector(state => state.profile.profileData?.email);
  const path = product.type === PRODUCT_TYPES.ONE_TIME ? 'one-time-payment' : 'subscription';
  useMessageListener({
    source: path,
    onSuccess,
    onError,
    onDismiss,
    onLoaded,
  });

  useEffect(() => {
    if (isVisible) {
      Analytics.track('Payment_Method_Modal_Open', { ...eventParams, productType: product.type });
      const iframe = document.getElementById('astrologersOneTimeIframe');
      if (iframe) {
        //@ts-ignore
        iframe?.contentWindow?.postMessage('openModal', '*');
      }
    }
  }, [isVisible]);

  const url = useMemo(() => {
    if (!product) {
      return '';
    }

    const query = {
      title: !product.title ? title : product.title,
      amount: product.price,
      currency: product.currency,
      idfm,
      product_trigger: productTrigger,
      metadata: JSON.stringify({
        ...metadata,
      }),
      product: product.id,
      textLineThrough: product.textLineThrough,
      priceText: product.priceText,
      period: product?.period,
      email,
      mode: webMode,
    };

    const uri = queryString.stringifyUrl({
      url: `${ASB_URL}/${path}` as unknown as string,
      query,
    });

    return uri;
  }, [metadata, email, idfm, product, webMode, productTrigger, path, title]);

  const handleClose = () => {
    if (typeof onDismiss === 'function') {
      onDismiss();
    }
  };

  return (
    <View style={[styles.iframe_modal, isVisible ? { zIndex: 999, height: '100%' } : { zIndex: -100, height: 0 }]}>
      <Iframe id="astrologersOneTimeIframe" src={url} onError={handleClose} />
    </View>
  );
};

export default PaymentsMethodsModal;

const styles = StyleSheet.create({
  iframe_modal: {
    width: '100%' as DimensionValue,
    ...StyleSheet.absoluteFillObject,
  },
});
