import * as COLORS from './colors';

export const EMOTIONAL = 'EMOTIONAL';
export const PHYSICAL = 'PHYSICAL';
export const INTELLECTUAL = 'INTELLECTUAL';
export const BIORHYTHM_TYPES = [PHYSICAL, EMOTIONAL, INTELLECTUAL];

export const PERIODS_DAYS = {
  EMOTIONAL: 28,
  PHYSICAL: 23,
  INTELLECTUAL: 33,
};

export const COLOR_MAP: { [key: string]: string } = {
  [PHYSICAL]: COLORS.FADED_ORANGE,
  [EMOTIONAL]: COLORS.AQUA,
  [INTELLECTUAL]: COLORS.LIGHT_MUSTARD,
};
