import api from 'api';

import { GenerateLinkResponse } from './interfaces';

const GENERATE_LINK = '/trustpilot/generate-link';

export const generateTrustPilotLink = async (redirect_uri: string): Promise<GenerateLinkResponse> => {
  return api.fetch(`${GENERATE_LINK}`, {
    method: 'POST',
    data: {
      redirect_uri,
    },
  });
};
