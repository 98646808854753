import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from 'analytics';
import { AppDispatch, RootState } from 'store';
import { handleNotificationPostMessage } from 'store/navigation/actions';
import { LOADING } from 'constants/routes';
import { Astrologist } from 'api/astrology-chat/constants';

// TODO Transform in hook
interface Notification {
  action: 'redirect-from-notificationclick';
  astrologer_id: Astrologist['astrologer_id'];
  isInitial: boolean;
}

const NotificationHandler: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [notification, setNotification] = useState<Notification | null>(null);
  const currentRoute = useSelector((state: RootState) => state.navigation.currentRoute);
  const isReady = currentRoute && currentRoute !== LOADING;

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('message', (event: MessageEvent<Notification>) => {
        if (event?.data?.action) {
          console.log('> post message: ', event?.data);
          switch (event.data.action) {
            case 'redirect-from-notificationclick':
              Analytics.trackEvent('notification', 'clicked', { isInitial: !!event.data?.isInitial });
              setNotification(event.data);
              break;
            // no default
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isReady && notification?.astrologer_id) {
      dispatch(handleNotificationPostMessage(notification));
    }
  }, [isReady, notification]);

  return null;
};

export default NotificationHandler;
