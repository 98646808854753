import * as ROUTES from '../../constants/routes';

import {
  SET_CURRENT_ROUTE_NAME,
  SET_PREV_ROUTE_NAME,
  SET_NAVIGATED_AFTER_ONBOARDING,
  State,
  ActionTypes,
} from './types';

const initialState: State = {
  currentRoute: ROUTES.LOADING,
  previousRoute: '',
  isNavigatedAfterOnboarding: false,
};

export default function (state: State = initialState, action: ActionTypes): State {
  switch (action.type) {
    case SET_CURRENT_ROUTE_NAME:
      return {
        ...state,
        currentRoute: action.payload,
      };

    case SET_PREV_ROUTE_NAME:
      return {
        ...state,
        previousRoute: action.payload,
      };

    case SET_NAVIGATED_AFTER_ONBOARDING:
      return {
        ...state,
        isNavigatedAfterOnboarding: action.payload,
      };

    default:
      return state;
  }
}
