import React, { FC } from 'react';
import { StyleProp, ViewStyle, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { sw } from '@wowmaking/ui/src/utils';

import ICON_CLOSE from 'assets/icons/close.png';

export type Props = {
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
};

const HIT_SLOP = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

const CloseButton: FC<Props> = ({ onPress, style }) => {
  const handlePress = () => {
    if (typeof onPress === 'function') {
      onPress();
    }
  };

  return (
    <TouchableOpacity style={[styles.root, style]} onPress={handlePress} hitSlop={HIT_SLOP}>
      <View style={styles.container}>
        <Image source={ICON_CLOSE} style={styles.icon} />
      </View>
    </TouchableOpacity>
  );
};

export default CloseButton;

const styles = StyleSheet.create({
  root: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: sw(12),
  },
  container: {
    width: sw(30),
    height: sw(30),
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {},
});
