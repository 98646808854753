import { Linking, Platform } from 'react-native';
import { createAction } from 'redux-actions';
import moment from 'moment';
import _ from 'lodash';

import Analytics from 'analytics';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { addNotificationToQueue } from 'store/notification-center/actions';
import { showModal } from 'store/modals/actions';
import { navigate } from 'store/navigation/actions';
import { getWebOneTimePurchaseProducts } from 'store/billing/selectors';
import * as MODALS from 'constants/modals';
import { REPORTS_PRODUCTS } from 'modules/payments/constants/product';
import { PDF_REPORTS } from 'constants/upsale';
import { NOTIFICATIONS } from 'constants/notification-center';
import { selectZodiacSign } from 'store/profile/selectors';
import * as ROUTES from 'constants/routes';
import { prepareReportDownloadLink } from 'utils/upsale';
import { PERSONAL_REPORTS, OPEN_REPORTS_PLACES } from 'constants/personal-reports';
import { PersonalReport } from 'interfaces/personal-reports';

import { getAvailablePDFReports } from './selectors';
import { TYPES } from './types';

const isWeb = Platform.OS === 'web';

const setReportViewed = createAction(TYPES.SET_REPORT_VIEWED);

export const checkBirthChartAvailableForInApp = (): AppThunk<boolean> => {
  return (_dispatch: AppDispatch, getState: AppGetState) => {
    const store = getState();
    const {
      billing: { webTransactions },
      remoteConfig: {
        remoteConfigParams: { birthChartAvailableForInAppFromDate: fromDate },
      },
    } = store;

    const onetimeProducts = getWebOneTimePurchaseProducts(store);

    const isOldSubscriber = !!webTransactions.find((tr: any) => moment(fromDate).isAfter(tr.created_at, 'day'));

    const purchasedBirthChart = !!onetimeProducts.find((p: any) => [REPORTS_PRODUCTS.BIRTH_CHART, REPORTS_PRODUCTS.ULTRA_2].includes(p));

    return purchasedBirthChart || isOldSubscriber;
  };
};

export const unlockBirthChart = (onSuccess: () => void): AppThunk => {
  return (dispatch: AppDispatch) => {
    const isAvailable = dispatch(checkBirthChartAvailableForInApp());

    if (isAvailable) {
      return onSuccess();
    }

    dispatch(showModal(MODALS.WEB_BIRTH_CHART));
  };
};

export const initUpsails = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const types = getAvailablePDFReports(state);

    const {
      upsails: { isViewed },
      remoteConfig: {
        remoteConfigParams: { notificationCenter: config },
      },
    } = state;

    let isEnabled = false;

    if (!_.isEmpty(config)) {
      isEnabled = config?.pdfReports?.enabled;
    }

    if (isEnabled && types.length && !isViewed) {
      dispatch(addNotificationToQueue(NOTIFICATIONS.PERSONAL_REPORTS));
    }

    dispatch(showGetPDFReportsModal());
  };
};

export const downloadReport = (report: PDF_REPORTS, eventParams?: object): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    try {
      const idfm = getState().auth.webUUID;
      const fileUrl = prepareReportDownloadLink(report, idfm);

      Linking.openURL(fileUrl);

      Analytics.trackEvent('Download_Report', 'Success', { ...eventParams });

      dispatch(setReportViewed(report));
    } catch (error) {
      console.log('[ERROR DOWNLOAD REPORTS]', error);
    }
  };
};

export const openReport = (report: PDF_REPORTS, place: OPEN_REPORTS_PLACES): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    Analytics.track('Personal_Report_Link_Click', { report, place });
    const state = getState();
    const openOnNewTabEnabled = state.remoteConfig.remoteConfigParams?.pdfReportsBannerConfig?.openOnNewTabEnabled;
    const idfm = state.auth.webUUID;
    const sign = selectZodiacSign(state);
    const personalReport: PersonalReport = PERSONAL_REPORTS[report];

    const pathName = personalReport.pathName;
    const partUrl = report === PDF_REPORTS.COMPATIBILITY ? sign : 'report';

    const link = `https://${pathName}.astroline.today/${partUrl}?idfm=${idfm}&hideAppButton=true&isNativeApp=${!isWeb}`;

    dispatch(setReportViewed(report));

    if (openOnNewTabEnabled) {
      Linking.openURL(link);
    } else {
      navigate(ROUTES.REPORTS_WEB_VIEW, { url: link, place, report });
    }
  };
};

export const openReports = (place: OPEN_REPORTS_PLACES): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const reports = getAvailablePDFReports(state);

    // SHOW MODAL FOR OPEN MULTIPLE FILES
    if (reports.length > 1) {
      dispatch(showModal(MODALS.PERSONAL_REPORTS));
    } else {
      dispatch(openReport(reports[0], place));
    }
  };
};

export const showGetPDFReportsModal = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    if (!isWeb) {
      return false;
    }

    const state = getState();
    const { sessionNumber, showSessionsLimit, timeout } = state.remoteConfig.remoteConfigParams?.pdfReportsModalConfig || {};
    const isOnboardingCompleted = state.onboarding.isOnboardingCompleted;
    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;
    const endSession = sessionNumber + showSessionsLimit;

    const types = getAvailablePDFReports(state);

    if (!types.length && currentSession >= sessionNumber && currentSession <= endSession && isOnboardingCompleted) {
      setTimeout(() => {
        const {
          navigation: { currentRoute },
          modals: { activeModal },
        } = getState();

        if (!activeModal && !ROUTES.MODALS_EXCLUDE_ROUTES.includes(currentRoute)) {
          dispatch(showModal(MODALS.GET_PDF_GUIDE));
        }
      }, timeout * 1000);
    }
  };
};
