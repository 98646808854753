import { paddingVertical, sw } from '@wowmaking/ui/src/utils';
import { times } from 'lodash';

import { windowHeight } from 'constants/sizes';

import CARD_OF_THE_DAY_IMAGE from './images/card-of-the-day.png';
import NEAR_FUTURE_IMAGE from './images/near-future.png';
import LOVE_IMAGE from './images/love.png';
import YES_OR_NO_IMAGE from './images/yes-no.png';
import { CardsLayoutItem, DescriptionCategories } from './types';

export enum TAROT_LAYOUT_IDS {
  CARD_OF_THE_DAY = 'card_of_the_day',
  NEAR_FUTURE = 'near_future',
  LOVE = 'love',
  YES_OR_NO = 'yes_or_no',
}

export enum DESCRIPTION_CATEGORY_IDS {
  CAREER = 'career',
  HEALTH = 'health',
  MONEY = 'money',
  LOVE = 'love',
  FUTURE = 'future',
  YOU = 'you',
  PARTNER = 'partner',
  PAST = 'past',
  PRESENT = 'present',
}

export const CARDS_LAYOUTS: CardsLayoutItem[] = [
  {
    id: TAROT_LAYOUT_IDS.CARD_OF_THE_DAY,
    image: CARD_OF_THE_DAY_IMAGE,
    layoutScheme: [[1]],
    topIndent: paddingVertical(76),
  },
  {
    id: TAROT_LAYOUT_IDS.NEAR_FUTURE,
    image: NEAR_FUTURE_IMAGE,
    layoutScheme: [[1, 2, 3, 4], [5]],
    topIndent: paddingVertical(70),
  },
  {
    id: TAROT_LAYOUT_IDS.LOVE,
    image: LOVE_IMAGE,
    layoutScheme: [[3], [1, 4, 2], [5]],
    topIndent: paddingVertical(15),
  },
  {
    id: TAROT_LAYOUT_IDS.YES_OR_NO,
    image: YES_OR_NO_IMAGE,
    layoutScheme: [[1]],
    topIndent: paddingVertical(45),
  },
];

export const RECENT_READINGS: TAROT_LAYOUT_IDS[] = [TAROT_LAYOUT_IDS.NEAR_FUTURE, TAROT_LAYOUT_IDS.LOVE];
export const LAYOUTS_WITH_SUBTITLE: TAROT_LAYOUT_IDS[] = [TAROT_LAYOUT_IDS.CARD_OF_THE_DAY, TAROT_LAYOUT_IDS.YES_OR_NO];

export const IS_SMALL_SCREEN = windowHeight < 600;

export const DEFAULT_CARD_HEIGHT = IS_SMALL_SCREEN ? sw(127) : sw(155);
export const DEFAULT_CARD_WIDTH = IS_SMALL_SCREEN ? sw(70) : sw(85);
export const DEFAULT_CARD_PLACEHOLDER_HEIGHT = sw(165);
export const DEFAULT_CARD_PLACEHOLDER_WIDTH = sw(90);
export const DEFAULT_OPEN_CARD_HEIGHT = sw(275);
export const DEFAULT_OPEN_CARD_WIDTH = sw(150);

export const CARDS_COUNT = 60; // default number of cards in the deck
export const CARD_DESK: number[] = times(CARDS_COUNT, i => i + 1);
export const MAX_VIEWING_ANGLE = 300; // the angle at which a deck of cards is opened
export const RADIUS = sw(600);
export const ROTATION_SPEED_LIMITER = 7.5;

export const DESCRIPTION_CATEGORIES: DescriptionCategories = {
  [TAROT_LAYOUT_IDS.NEAR_FUTURE]: [
    DESCRIPTION_CATEGORY_IDS.HEALTH,
    DESCRIPTION_CATEGORY_IDS.MONEY,
    DESCRIPTION_CATEGORY_IDS.LOVE,
    DESCRIPTION_CATEGORY_IDS.CAREER,
    DESCRIPTION_CATEGORY_IDS.FUTURE,
  ],
  [TAROT_LAYOUT_IDS.LOVE]: [
    DESCRIPTION_CATEGORY_IDS.YOU,
    DESCRIPTION_CATEGORY_IDS.PARTNER,
    DESCRIPTION_CATEGORY_IDS.PAST,
    DESCRIPTION_CATEGORY_IDS.PRESENT,
    DESCRIPTION_CATEGORY_IDS.FUTURE,
  ],
};

export const NUMBER_DAYS_TO_SCROLL = 15;
