import React, { FC, useEffect, useState } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';

import Analytics from 'analytics';
import { AppDispatch, RootState } from 'store';
import { fetchWebPurchases } from 'store/billing/general-actions';
import { closeCurrentModal } from 'store/modals/actions';
import { t } from 'localization';
import Text from 'components/text';
import PurchasePDFReportsButton from 'components/web-payment-iframe-buttons/pdf-reports';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';

import ICON_CROSS from '../images/beige-cross.png';

import IMAGE from './images/image.png';

interface Props {}

const DEFAULT_CONF = {};

const WebGetPDFReportsModal: FC<Props> = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.GET_PDF_GUIDE);
  const config = useSelector((state: RootState) => state.remoteConfig?.remoteConfigParams?.pdfReportsModalConfig) || DEFAULT_CONF;

  let { title, text, btnTitle } = config;
  title = title?.length ? title : t('GET_PDF.MODAL.TITLE');
  text = text?.length ? text : t('GET_PDF.SUB_TITLE');
  btnTitle = btnTitle?.length ? btnTitle : t('GET_PDF.BTN_TITLE');

  useEffect(() => {
    let timeout: any;
    if (isSuccess) {
      timeout = setTimeout(() => {
        dispatch(fetchWebPurchases());
        dispatch(closeCurrentModal());
      }, 1500);
    }

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [isSuccess]);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('PDF_Get_Modal', 'Show');
    }
  }, [isVisible]);

  const handleClosePress = () => {
    dispatch(closeCurrentModal());
  };

  const handlePress = () => {
    Analytics.trackEvent('PDF_Get_Modal', 'Button_Click');
  };

  const handleSkip = () => {
    Analytics.trackEvent('PDF_Get_Modal', 'Skip');
    dispatch(closeCurrentModal());
  };

  const handleSuccess = () => {
    setIsSuccess(true);
    Analytics.trackEvent('PDF_Get_Modal', 'Success');
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <Image style={styles.image} source={IMAGE} resizeMode="contain" />

        <Text font="Philosopher" style={styles.title}>
          {title}
        </Text>

        <Text style={styles.subTitle}>{text}</Text>

        <View style={styles.button}>
          <PurchasePDFReportsButton buttonTitle={btnTitle} onPress={handlePress} onSuccess={handleSuccess} onSkip={handleSkip} />
        </View>

        <TouchableOpacity style={styles.cross} onPress={handleClosePress}>
          <Image source={ICON_CROSS} />
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default WebGetPDFReportsModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
    paddingTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(32),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
  },
  image: {
    width: sw(285),
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: paddingVertical(20),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(34),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(15),
    fontSize: fs(16),
    lineHeight: fs(25),
    color: COLORS.WHITE_WITH_FIFTH_TRANSPARENCY,
    textAlign: 'center',
  },
  cross: {
    position: 'absolute',
    top: paddingVertical(20),
    right: paddingHorizontal(20),
  },
  button: {
    width: '100%',
    marginTop: paddingVertical(58),
  },
});
