import { PROFILE_TYPES } from 'store/profile/types';

import { TYPES, ActionType, State } from './types';

const initialState: State = {
  notification: null,
  isPressed: false,
  lastContextId: null,
  randomCounter: 0,
  triggerNotifications: [],
  isPromocodeAddedInPwa: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        notification: action.payload,
      };
    case TYPES.SET_PRESSED:
      return {
        ...state,
        isPressed: action.payload,
      };
    case TYPES.SET_LAST_CONTEXT_ID:
      return {
        ...state,
        lastContextId: action.payload,
      };
    case TYPES.INC_RANDOM_COUNTER:
      return {
        ...state,
        randomCounter: (state.randomCounter += 1),
      };
    case TYPES.RESET_RANDOM_COUNTER:
      return {
        ...state,
        randomCounter: initialState.randomCounter,
      };
    case TYPES.SET_EXTERNAL_TRIGGER_NOTIFICATION:
      return {
        ...state,
        triggerNotifications: action.payload,
      };
    case TYPES.SET_PROMOCODE_ADDED_IN_PWA:
      return {
        ...state,
        isPromocodeAddedInPwa: true,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
