import moment from 'moment';
import { is24HourFormat } from 'react-native-device-time-format';

export const getTimeZone = (lon, onlyPositive) => {
  let timeZone = 0;
  lon = normalize_angle(lon, true);
  if (lon < 7.5) {
    timeZone = 0;
  } else if (lon < 22.5) {
    timeZone = 1;
  } else if (lon < 37.5) {
    timeZone = 2;
  } else if (lon < 52.5) {
    timeZone = 3;
  } else if (lon < 67.5) {
    timeZone = 4;
  } else if (lon < 82.5) {
    timeZone = 5;
  } else if (lon < 97.5) {
    timeZone = 6;
  } else if (lon < 112.5) {
    timeZone = 7;
  } else if (lon < 127.5) {
    timeZone = 8;
  } else if (lon < 142.5) {
    timeZone = 9;
  } else if (lon < 157.5) {
    timeZone = 10;
  } else if (lon < 172.5) {
    timeZone = 11;
  } else if (lon < 180.0) {
    timeZone = 12;
  } else if (lon < 187.5) {
    timeZone = -12;
  } else if (lon < 202.5) {
    timeZone = -11;
  } else if (lon < 217.5) {
    timeZone = -10;
  } else if (lon < 232.5) {
    timeZone = -9;
  } else if (lon < 247.5) {
    timeZone = -8;
  } else if (lon < 262.5) {
    timeZone = -7;
  } else if (lon < 277.5) {
    timeZone = -6;
  } else if (lon < 292.5) {
    timeZone = -5;
  } else if (lon < 307.5) {
    timeZone = -4;
  } else if (lon < 322.5) {
    timeZone = -3;
  } else if (lon < 337.5) {
    timeZone = -2;
  } else if (lon < 352.5) {
    timeZone = -1;
  } else {
    timeZone = 0;
  }
  return onlyPositive ? (timeZone < 0 ? 12 - timeZone : timeZone) : timeZone;
};

const normalize_angle = angle => {
  angle = angle % 360;
  angle = angle < 0 ? angle + 360 : angle;
  return angle;
};

export const getCurrentHourFormat = async (date, format = 'h:mm') => {
  let is24Hour;
  try {
    is24Hour = await is24HourFormat();
  } catch (e) {
    is24Hour = true;
  }

  return moment(date, format).format(is24Hour ? 'HH:mm' : 'h:mm A');
};

export const getTimeWithoutLocale = date => {
  const currentHour = moment(date).toDate().getHours();
  const currentMinute = moment(date).toDate().getMinutes();
  return `${currentHour}:${String(currentMinute).padStart(2, '0')}`;
};
