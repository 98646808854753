import { ASTROLOGER_PRODUCTS } from 'screens/advisors/screens/monetization/constants/interfaces';

export enum WEB_SUBSCRIPTION_IDS {
  P90D_A_MULTI = '0ba38605-c7c4-4211-a528-be818e5f03a9',
  P30D_USD_A = '8e6e5ba5-c150-4c41-86bf-91fce9188a18',
  P30D_EUR_A = 'e6c0f083-5363-46df-950d-3f9a6a81bc91',
  P30D_CAD_A = '378ea828-a808-4693-be6d-9fb4e7fb7c49',
  P30D_AUD_A = '5723e783-6424-49f9-a089-8fdd88bf0aba',
}

export enum PRODUCT_TYPES {
  ONE_TIME = 'one_time',
  SUBSCRIPTION = 'subscription',
}

export enum REPORTS_PRODUCTS {
  NUMEROLOGY_ANALYSIS = 'numerology_analysis',
  PALMISTRY_GUIDE = 'palmistry_guide',
  ULTRA = 'numerology_analysis+palmistry_guide',
  ASTROLOGER_CONSULTATION = 'astrologer_consultation',
  BIRTH_CHART = 'birth_chart',
  COMPATIBILITY = 'compatibility',
  ULTRA_2 = 'birth_chart+compatibility',
}

export const PRODUCTS = {
  ...ASTROLOGER_PRODUCTS,
  ...REPORTS_PRODUCTS,
  // Define additional onetime products
} as const;

export type ONE_TIME_PRODUCTS = (typeof PRODUCTS)[keyof typeof PRODUCTS];
