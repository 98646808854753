import moment from 'moment';

import { LONG_PATTERN } from 'constants/moment';
import { STEPS } from 'constants/palm-reading-daily';

import { PROFILE_TYPES } from '../profile/types';

import { TYPES, State, ActionTypes, KEYS } from './types';

const initialState: State = {
  preview: null,
  [KEYS.LEFT]: null,
  [KEYS.RIGHT]: null,
  [KEYS.FINGERS]: null,
  completedSteps: {
    [STEPS.LEFT_HAND]: null, // example '2020-12-30'
    [STEPS.RIGHT_HAND]: null,
    [STEPS.FINGERS]: null,
  },
  rescanTimers: {
    [STEPS.LEFT_HAND]: null, // date when rescan will be available '2022-12-30 11:10:00'
    [STEPS.RIGHT_HAND]: null,
    [STEPS.FINGERS]: null,
  },
  rescanCounters: {
    [STEPS.LEFT_HAND]: 0,
    [STEPS.RIGHT_HAND]: 0,
    [STEPS.FINGERS]: 0,
  },
  scheduledReportTime: null,
  nextDailyScanDate: null,
  isDailyAvailableToScan: true,
  wasWebDataShowed: false,
  data: null,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_PREVIEW_PHOTO:
      return {
        ...state,
        preview: {
          image: action.payload.image,
          hand: action.payload.hand,
        },
      };

    case TYPES.SET_PHOTO:
      return {
        ...state,
        [action.payload.key]: {
          image: action.payload.image,
          hand: action.payload.hand,
        },
        preview: null,
      };

    case TYPES.COMPLETE_STEP:
      return {
        ...state,
        completedSteps: {
          ...state.completedSteps,
          [action.payload.stepId]: moment().format(LONG_PATTERN),
        },
      };

    case TYPES.SET_RESCAN_COUNTER:
      return {
        ...state,
        rescanCounters: {
          ...state.rescanCounters,
          [action.payload.stepId]: action.payload.counter,
        },
      };

    case TYPES.SCHEDULE_NEXT_REPORT:
      return {
        ...state,
        scheduledReportTime: moment().add(action.payload.minutes, 'minutes').valueOf(),
      };

    case TYPES.CLEAR_REPORT_SCHEDULE:
      return {
        ...state,
        scheduledReportTime: initialState.scheduledReportTime,
      };

    case TYPES.SET_RESCAN_TIMER:
      return {
        ...state,
        rescanTimers: {
          ...state.rescanTimers,
          ...action.payload,
        },
      };

    case TYPES.SET_DAILY_NEXT_SCAN_DATE:
      return {
        ...state,
        nextDailyScanDate: action.payload,
        isDailyAvailableToScan: false,
      };

    case TYPES.SET_DAILY_AVAILABLE_TO_SCAN:
      return {
        ...state,
        isDailyAvailableToScan: action.payload,
      };

    case TYPES.SET_WEB_DATA_SHOWED:
      return {
        ...state,
        wasWebDataShowed: action.payload,
      };

    case TYPES.SET_WEB_DATA:
      return {
        ...state,
        data: action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
