import { SignInfo, UserParams } from 'api/profile/interfaces';
import { GENDERS, RelationshipStatus } from 'constants/profile';
import { PlaceValue } from 'interfaces/birth-chart';

export enum PROFILE_TYPES {
  SET_PROFILE = 'PROFILE/SET_PROFILE',
  UPDATE_USER_PARAMS = 'PROFILE/UPDATE_USER_PARAMS',
  INIT_FAIL = 'PROFILE/INIT_FAIL',
  SET_PENDING = 'PROFILE/SET_PENDING',
  SET_UTC_OFFSET = 'PROFILE/SET_UTC_OFFSET',
  SET_USER_LOYAL = 'PROFILE/SET_USER_LOYAL',
  RESET_USER_DATA = 'RESET_USER_DATA',
}

export type ProfileData = {
  name: string;
  email?: string;
  date: string;
  time: string;
  gender: GENDERS;
  relationship: RelationshipStatus;
  place: string;
  error: any;
  palmImage: any;
  signs: SignInfo[];
  id: number;
  lang: string;
  device_id: string;
  utc_offset?: number;
  userParams: UserParams | null;
};

export type UpdatingProfileParams = {
  name?: string | null;
  date?: string | null;
  time?: string | null;
  gender?: GENDERS | null;
  relationship?: RelationshipStatus | null;
  place?: PlaceValue | null;
  utc_offset?: number;
};

export type ProfileParamName = keyof ProfileData;

export interface State {
  isProfile: boolean;
  profileData: ProfileData;
  isLoading: boolean;
  isUtcOffsetSet: boolean;
  isError: boolean;
  isUserLoyal: boolean;
}

interface SetProfile {
  type: typeof PROFILE_TYPES.SET_PROFILE;
  payload: ProfileData;
}

interface UpdateUserParams {
  type: typeof PROFILE_TYPES.UPDATE_USER_PARAMS;
  payload: UserParams;
}

interface SetPending {
  type: typeof PROFILE_TYPES.SET_PENDING;
}

interface SetUtcOffset {
  type: typeof PROFILE_TYPES.SET_UTC_OFFSET;
}

interface SetInitFail {
  type: typeof PROFILE_TYPES.INIT_FAIL;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

interface SetUserLoyal {
  type: typeof PROFILE_TYPES.SET_USER_LOYAL;
}

export type ActionType = SetProfile | UpdateUserParams | SetPending | SetUtcOffset | SetInitFail | ResetUserData | SetUserLoyal;
