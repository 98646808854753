import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  calendar: [],
  widgets: [],
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        calendar: action.payload,
      };
    case TYPES.SET_WIDGETS:
      return {
        ...state,
        widgets: action.payload,
      };
    default:
      return state;
  }
}
