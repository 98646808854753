import React, { FC, memo } from 'react';
import { StyleSheet, View } from 'react-native';
import { sw, paddingVertical, fs, color } from '@wowmaking/ui/src/utils';
import { useDispatch } from 'react-redux';

import { t } from 'localization';
import * as COLORS from 'constants/colors';
import Text from 'components/text';
import Button from 'components/button';
import Analytics from 'analytics';
import { AppDispatch } from 'store';
import { installPWA } from 'store/pwa/actions';

interface Props {}

const SaveHome: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handleAddButtonPress = () => {
    Analytics.trackEvent('PWA_Instructions', 'Add_Button_Click');
    dispatch(installPWA());
  };

  return (
    <View style={styles.root}>
      <Button
        fontFamily="OpenSans"
        style={styles.btn}
        titleStyle={styles.btnTitle}
        title={t('PWA_INSTRUCTIONS_V2.SAVE_HOME_BTN')}
        onPress={handleAddButtonPress}
      />

      <View style={styles.wrLine}>
        <View style={styles.line} />
        <Text style={styles.text}>{t('PWA_INSTRUCTIONS_V2.SAVE_HOME_OR')}</Text>
      </View>

      <View style={styles.manually}>
        <Text style={styles.manuallyTitle} font="Philosopher">
          {t('PWA_INSTRUCTIONS_V2.SAVE_HOME_TITLE')}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  btn: {
    marginTop: paddingVertical(20),
    minHeight: sw(48),
  },
  btnTitle: {
    fontSize: fs(16),
    fontWeight: '700',
    color: COLORS.WHITE,
  },
  wrLine: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: paddingVertical(15),
    marginBottom: paddingVertical(12),
  },
  line: {
    position: 'absolute',
    height: sw(1.2),
    width: '100%',
    backgroundColor: color(COLORS.GOLDEN, 0.1),
    zIndex: -1,
  },
  text: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '400',
    color: color(COLORS.BEIGE, 0.8),
    backgroundColor: COLORS.DARK_TEAL,
    width: 32,
    textAlign: 'center',
  },
  manually: {
    alignItems: 'center',
  },
  manuallyTitle: {
    fontSize: fs(18),
    fontWeight: '700',
    color: COLORS.BEIGE_2,
    textAlign: 'center',
  },
});

export default memo(SaveHome);
