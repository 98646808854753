import React, { FC, memo, useEffect, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { sw, BOTTOM_OFFSET, paddingVertical, fs, paddingHorizontal, color } from '@wowmaking/ui/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import Modal from 'react-native-modal';
import Clipboard from '@react-native-clipboard/clipboard';

import Analytics from 'analytics';
import { AppDispatch, RootState } from 'store';
import Localization, { t } from 'localization';
import useModalIsVisibly from 'hooks/use-modal-is-visibly';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import Text from 'components/text';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import Icon from 'components/icon';
import { isAllowedBrowserByType, isSafariBrowser } from 'utils/pwa';
import { ASTRO_CALENDAR_PLACE } from 'screens/astro-calendar/constants';
import { closePwaModal } from 'store/pwa/actions';

import HighlightText from './components/highlight-text';
import ClipBoardTooltip from './components/clipboard-tooltip';
import ClipboardButton from './components/clipboard-button';
import CLOSE_ICON from './img/ic-cancel.png';
import SaveHome from './components/save-home';
import { getBrowserType, getInstructionGif, getInstructionImage } from './utils';
import { IS_SMALL_SCREEN } from './constants';

interface Props {}

const HIDE_COPIED_TIME = 3000;

const PWAInstructionsV2: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();

  const isVisible = useModalIsVisibly(MODALS.PWA_INSTRUCTION);
  const browserName = useSelector((state: RootState) => state.pwa.browserName);
  const idfm = useSelector((state: RootState) => state.auth.webUUID);
  const closeTimeout = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.pwaInstructionsV2?.closeBtnTimeout);
  const gifIconEnabled = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.pwaInstructionsV2?.gifIconEnabled);
  const title = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.pwaInstructionsV2?.title);
  const subtitle = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.pwaInstructionsV2?.subtitle);
  const isPWAAutoInstallAvailable = useSelector((state: RootState) => state.pwa.isPWAAutoInstallAvailable);
  const modalParams = useSelector((state: RootState) => state.modals.params);
  const isAstroCalendarOrigin = modalParams?.place === ASTRO_CALENDAR_PLACE;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const currentLocale = Localization.getLngCode();
  const browserType = getBrowserType(browserName);
  const isAllowedForPWA = isAllowedBrowserByType(browserType);
  const gifIconAllowed = gifIconEnabled && isAllowedForPWA;
  const instructions = t(`PWA_INSTRUCTIONS_V2.INSTRUCTIONS.${browserType}`) as unknown as string[];
  const titleText = title?.length ? title : t(isAllowedForPWA ? 'PWA_INSTRUCTIONS_V2.TITLE' : 'PWA_INSTRUCTIONS_V2.TITLE_OTHER');
  const subtitleText = subtitle?.length ? subtitle : t(isAllowedForPWA ? 'PWA_INSTRUCTIONS_V2.TEXT' : 'PWA_INSTRUCTIONS_V2.TEXT_OTHER');
  const instructionsImage = gifIconAllowed ? getInstructionGif(browserType, currentLocale ?? 'en') : getInstructionImage(browserType);
  const applySmallStyles = IS_SMALL_SCREEN && isPWAAutoInstallAvailable;

  const handleClosePress = () => {
    dispatch(closePwaModal());
  };

  const handleCopyLinkPress = () => {
    Analytics.trackEvent('PWA_Instructions', 'Copied');

    const url = window.location.origin + window.location.pathname;
    const query = queryString.parse(window?.location?.search);
    query.idfm = idfm;
    const link = queryString.stringifyUrl({ url, query });

    Clipboard.setString(String(link));
    setIsCopied(true);
  };

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    if (isCopied) {
      timeout = setTimeout(() => {
        setIsCopied(false);
      }, HIDE_COPIED_TIME);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isCopied]);

  useEffect(() => {
    if (isVisible) {
      const browser = browserType.replace(/IOS_|ANDROID_/, '').toLowerCase();
      Analytics.trackEvent('PWA_Instructions', 'Open', {
        browser,
        type: isPWAAutoInstallAvailable ? 'autoinstall' : 'instruction',
        place: modalParams?.place,
      });
    }
  }, [browserType, isPWAAutoInstallAvailable, isVisible, modalParams?.place]);

  return (
    <Modal style={styles.root} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={[styles.wrap, isSafariBrowser(browserName) && styles.wrapMargin, applySmallStyles && styles.wrapSmallScreen]}>
        <View style={styles.topControls}>
          <CloseButtonWithTimeout
            timeout={isAstroCalendarOrigin ? 0 : closeTimeout}
            onPress={handleClosePress}
            source={CLOSE_ICON}
            style={styles.closeButton}
          />
        </View>

        <View style={styles.container}>
          <View style={styles.textWrap}>
            <Text style={[styles.title, applySmallStyles && styles.titleSmallScreen]} font="Philosopher">
              {titleText}
            </Text>
            <Text style={[styles.subtitle, applySmallStyles && styles.subtitleSmallScreen]}>{subtitleText}</Text>
          </View>

          {gifIconAllowed ? null : (
            <View style={styles.appIconContainer}>
              <Image source={instructionsImage} />
            </View>
          )}

          <View style={styles.instructionsContainer}>
            {isPWAAutoInstallAvailable ? <SaveHome /> : null}

            {instructions.map((text: string, idx: number) => (
              <View style={styles.instructionsItem} key={text}>
                {text.indexOf('@@CLIPBOARD@@') >= 0 ? (
                  <ClipboardButton style={styles.btn} titleStyle={styles.btnText} onPress={handleCopyLinkPress} />
                ) : (
                  <>
                    <View style={styles.instructionsItemDotWrap}>
                      {isAllowedForPWA ? (
                        <Text style={[styles.numberText, applySmallStyles && styles.textSmallScreen]}>{`${idx + 1}.`}</Text>
                      ) : (
                        <View style={styles.instructionsItemDot} />
                      )}
                    </View>

                    <HighlightText
                      textStyle={[styles.text, applySmallStyles && styles.textSmallScreen]}
                      highlightTextStyle={[styles.highlightText, applySmallStyles && styles.textSmallScreen]}
                      iconStyle={styles.icon}>
                      {text}
                    </HighlightText>
                  </>
                )}
              </View>
            ))}
          </View>

          {gifIconAllowed ? (
            <View style={styles.appIconContainer}>
              <Image style={[styles.gifImage, applySmallStyles && styles.gifImageSmallScreen]} source={instructionsImage} />
            </View>
          ) : null}

          {isSafariBrowser(browserName) && !gifIconEnabled ? <View style={styles.placeholder} /> : null}
        </View>
      </View>

      <ClipBoardTooltip isVisible={isCopied} />

      {isSafariBrowser(browserName) && !gifIconEnabled ? (
        <View style={styles.arrow}>
          <Icon name={'pwa-arrow'} size={sw(50)} color={COLORS.AQUA} />
        </View>
      ) : null}
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  wrap: {
    width: '100%',
    paddingBottom: BOTTOM_OFFSET + paddingVertical(40),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
    position: 'relative',
    maxHeight: '97%',
  },
  wrapMargin: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(60),
  },
  wrapSmallScreen: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
  },
  container: {
    width: '100%',
    paddingHorizontal: paddingHorizontal(15),
    alignItems: 'center',
  },
  topControls: {
    paddingTop: paddingVertical(10),
    alignItems: 'flex-end',
    width: '100%',
  },
  closeButton: {
    padding: paddingHorizontal(20),
  },
  title: {
    fontSize: fs(26),
    lineHeight: fs(32),
    fontWeight: '700',
    textAlign: 'center',
    color: COLORS.GOLDEN,
  },
  titleSmallScreen: {
    fontSize: fs(24),
    lineHeight: fs(28),
  },
  textWrap: {
    width: '100%',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: fs(16),
    lineHeight: fs(24),
    color: COLORS.GOLDEN,
    marginTop: paddingVertical(10),
  },
  subtitleSmallScreen: {
    fontSize: fs(14),
    lineHeight: fs(20),
  },
  appIconContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: paddingVertical(20),
    marginBottom: paddingVertical(5),
  },
  gifImage: {
    height: sw(120),
    width: sw(282),
  },
  gifImageSmallScreen: {
    height: sw(100),
    width: sw(235),
  },
  instructionsContainer: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: paddingVertical(5),
    paddingHorizontal: paddingHorizontal(15),
  },
  instructionsItem: {
    flex: 1,
    marginTop: paddingVertical(15),
    paddingLeft: paddingHorizontal(30),
    position: 'relative',
  },
  btn: {
    marginLeft: paddingHorizontal(-30),
    alignSelf: 'center',
  },
  btnText: {
    textAlign: 'center',
    fontSize: fs(15),
    fontWeight: '600',
    color: COLORS.WHITE,
  },
  text: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '400',
    color: color(COLORS.BEIGE, 0.8),
  },
  textSmallScreen: {
    fontSize: fs(14),
    lineHeight: fs(20),
  },
  highlightText: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '600',
    color: COLORS.BEIGE,
  },
  icon: {
    width: 34,
  },
  instructionsItemDotWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: 24,
    left: 0,
  },
  instructionsItemDot: {
    width: sw(8),
    height: sw(8),
    borderRadius: sw(4),
    backgroundColor: COLORS.AQUA,
  },
  numberText: {
    fontSize: fs(16),
    lineHeight: fs(24),
    fontWeight: '700',
    color: COLORS.AQUA,
  },
  arrow: {
    position: 'absolute',
    bottom: 10,
    marginLeft: -paddingHorizontal(45),
    alignSelf: 'center',
  },
  placeholder: {
    width: '100%',
    height: sw(40),
  },
});

export default memo(PWAInstructionsV2);
