import { isNumber } from 'lodash';
import React from 'react';
import {
  Animated,
  Image,
  ImageProps,
  ImageURISource,
  StyleSheet,
  View,
  Platform,
} from 'react-native';
import Reanimated from 'react-native-reanimated';

import CacheManager from 'utils/cache-manager';

const IS_ANDROID = Platform.OS === 'android';

interface Props extends ImageProps {
  source: number | ImageURISource;
  isAnimated?: boolean;
  isReanimated?: boolean;
  pointerEvents?: 'none' | 'auto';
  preview?: React.ReactNode;
}

interface State {
  cachePath?: string | null;
}

export default class CachedImage extends React.PureComponent<Props, State> {
  isMount: boolean;

  state: State;

  constructor(props: Props) {
    super(props);

    if (!isNumber(props.source)) {
      const uri = props.source?.uri;
      const isRemoteUri = this.isRemoteUri(uri);
      this.state = {
        cachePath: isRemoteUri ? null : uri,
      };

      if (isRemoteUri) {
        this.handleUriChange(uri, { needClear: false });
      }
    } else {
      this.state = {
        cachePath: null,
      };
    }

    this.isMount = true;
  }

  componentDidUpdate(prevProps: Props): void {
    if (!isNumber(this.props.source)) {
      const uri = this.props.source?.uri;
      if (isNumber(prevProps.source) || uri !== prevProps.source?.uri) {
        if (this.isRemoteUri(uri)) {
          this.handleUriChange(uri);
        } else {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            cachePath: uri,
          });
        }
      }
    }
  }

  componentWillUnmount(): void {
    this.isMount = false;
  }

  isRemoteUri(uri: string | undefined): '' | RegExpMatchArray | null | undefined {
    return uri && uri.match(/^https?/);
  }

  handleUriChange(
    uri: string | undefined,
    { needClear = true }: { needClear?: boolean } = {},
  ): Promise<void> {
    if (!uri) {
      console.warn('No found image: uri = undefined (check CachedImage.handleUriChange)');
      return Promise.resolve();
    }

    if (this.state.cachePath && needClear) {
      this.setState({
        cachePath: null,
      });
    }

    return CacheManager.downloadAndCacheUrl(uri, { inBg: false })
      .then(cachePath => {
        if (this.isMount) {
          Image.prefetch(cachePath);
        }

        return cachePath;
      })
      .then(cachePath => {
        if (this.isMount) {
          this.setState({
            cachePath,
          });
        }
      })
      .catch(() => {
        if (this.isMount) {
          this.setState({
            cachePath: uri,
          });
        }
      });
  }

  render(): React.ReactNode {
    const { cachePath } = this.state;
    const { style, isAnimated, isReanimated, source, pointerEvents, preview, ...other } =
      this.props;

    const isLocal = isNumber(source) || !this.isRemoteUri(source?.uri);

    const isLoad = !isLocal && !cachePath;

    const imageSource = isLocal ? source : cachePath ? { uri: cachePath } : null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Wrapper: any = isReanimated
      ? Reanimated.View
      : isAnimated
      ? Animated.View
      : View;

    const showPreview = (!isLocal && !!preview && isLoad) || !imageSource;

    if (IS_ANDROID) {
      return (
        <Wrapper style={[style, styles.wrapper]} pointerEvents={pointerEvents}>
          {showPreview ? (
            <View {...other} style={styles.image}>
              {preview}
            </View>
          ) : (
            <Image
              {...other}
              fadeDuration={0}
              style={styles.image}
              source={imageSource}
            />
          )}
        </Wrapper>
      );
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const Component: any = isReanimated
        ? Reanimated.Image
        : isAnimated
        ? Animated.Image
        : Image;

      return showPreview ? (
        <Wrapper {...other} style={[style, styles.wrapper]} pointerEvents={pointerEvents}>
          {preview}
        </Wrapper>
      ) : (
        <Component
          {...other}
          style={style}
          fadeDuration={0}
          source={imageSource}
          pointerEvents={pointerEvents}
        />
      );
    }
  }
}

const styles = StyleSheet.create({
  image: {
    ...StyleSheet.absoluteFillObject,
    height: '100%',
    width: '100%',
  },
  wrapper: {
    overflow: 'hidden',
  },
});
