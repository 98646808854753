import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';

import ErrorModal from '../components/error-modal';
import { t } from 'localization';
import { checkInternetConnection } from '../store/app/actions';

interface Props {}

const NoInternetModal: FC<Props> = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const isInternetConnected = useSelector((state: RootState) => state.app.isInternetConnected);

  const handlePress = async () => {
    setLoading(true);
    await dispatch(checkInternetConnection());
    setLoading(false);
  };

  if (isInternetConnected) {
    return null;
  }

  return (
    <ErrorModal
      onPress={handlePress}
      isLoading={isLoading}
      buttonTitle={t('ERROR_MODAL.RETRY')}
      title={t('ERROR_MODAL.NOT_CONNECT')}
      subtitle={t('ERROR_MODAL.PLEASE_CHECK')}
      style={{ paddingVertical: 0, paddingHorizontal: 0 }}
    />
  );
};

export default NoInternetModal;
