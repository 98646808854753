import { addEventListener } from '@react-native-community/netinfo';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setInternetConnection } from 'store/app/actions';

const useEventListeners = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const netStateListener = addEventListener(({ isConnected }) => {
      console.log('[useEventListeners] netStateListener', `is internet connected: ${isConnected}`);
      dispatch(setInternetConnection(isConnected));
    });

    return () => {
      netStateListener();
    };
  }, []);
};

export default useEventListeners;
