import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getTodayFeatures } from 'api/todays-features';

import { TYPES } from './types';

const setFeatures = createAction(TYPES.SET_FEATURES);

export const loadTodaysFeatures = () => {
  return async (dispatch: AppDispatch) => {
    const response = await getTodayFeatures();

    if (response) {
      dispatch(
        setFeatures({
          color: response?.color ?? null,
          number: response?.number ?? null,
          time: response?.time ?? null,
        }),
      );
    }
  };
};
