import { Story } from 'api/stories/interfaces';
import { PROFILE_TYPES } from 'store/profile/types';

export const SET = 'STORIES/SET';
export const SET_FIRST_TIME = 'STORIES/SET_FIRST_TIME';

export interface State {
  stories: Story[];
  isFirstTime: boolean;
}

interface SetStories {
  type: typeof SET;
  payload: Story[];
}

interface SetFirstTime {
  type: typeof SET_FIRST_TIME;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionType = SetStories | SetFirstTime | ResetUserData;
