import api from '..';

import { Calendars, DatingCalendar, DatingPredictions } from './interfaces';

const CALENDAR = '/calendar';
const CALENDARS = '/calendars';
const DATING = '/dating';

export const getCalendar = (category: string, year: number, month: number): Promise<Calendars> => {
  return api.fetch(`${CALENDARS}?category=${category}&year=${year}&month=${month}`, {
    method: 'GET',
  });
};

export const getCalendars = (year: number, month: number): Promise<Calendars> => {
  return api.fetch(`${CALENDARS}?year=${year}&month=${month}&theme=DENIM`, {
    method: 'GET',
  });
};

export const getDatingCalendar = (): Promise<DatingCalendar> => {
  return api.fetch(`${DATING}${CALENDAR}`, {
    method: 'GET',
  });
};

export const getDatingWidgets = (): Promise<DatingPredictions> => {
  return api.fetch(`${DATING}/predictions`, {
    method: 'GET',
  });
};
