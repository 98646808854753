import { State, ActionTypes, SET_QUEUE, SET_PRESSED, SET_HIDDEN, SET_CURRENT_NOTIFICATION } from './types';

const initialState: State = {
  queue: [],
  wasPressed: [],
  isHidden: false,
  currentNotification: null,
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case SET_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };
    case SET_PRESSED:
      return {
        ...state,
        wasPressed: action.payload,
      };
    case SET_HIDDEN:
      return {
        ...state,
        isHidden: action.payload,
      };
    case SET_CURRENT_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.payload,
      };

    default:
      return state;
  }
};
