import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getMoonPhase } from 'utils/moon-phase';

import { TYPES } from './types';

const setMoonPhases = createAction(TYPES.SET);

export const initMoonPhase = () => {
  return (dispatch: AppDispatch) => {
    const now = new Date();
    const phase = getMoonPhase(now.getFullYear(), now.getMonth() + 1, now.getDate());
    dispatch(setMoonPhases(phase ? phase.name : ''));
  };
};
