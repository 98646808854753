import { OTHERS_PAYMENT_SCREENS } from 'modules/payments/constants';
import { SUB_SCREENS } from 'constants/subscription-screens';

const SUBSCRIPTION = 'sub';
const IN_APP = 'in_app';

export interface MonetizationConfig {
  [key: string]: Array<{ type: string; screen: SUB_SCREENS | OTHERS_PAYMENT_SCREENS }>;
}

export const MONETIZATION_TYPES = {
  SUBSCRIPTION,
  IN_APP,
};
