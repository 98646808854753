import api from 'api';

import { GET_CHATS, Chats } from './constants';

const getChats = async (): Promise<Chats[]> => {
  try {
    let response = await api.fetch(GET_CHATS, { method: 'GET' });
    if (!Array.isArray(response)) {
      response = [];
    }
    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] getChats', error);
    return [];
  }
};

export default getChats;
