import { Linking, Platform } from 'react-native';
import DeviceProps from '@magnus/react-native-device-props';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { PAYMENT_EVENTS_CATEGORIES } from 'analytics/const';
import { AppDispatch, AppGetState } from 'store';
import { showModal, addToQueue } from 'store/modals/actions';
import { addTriggerNotification } from 'store/astrologers/notifications/actions';
import {
  createOneClickPayment,
  createSubscriptionByOneClick,
  getPaymentMethod,
  getWebSubs,
  getWebOneTimePurchases,
  getWebAllSubs,
  getProducts,
} from 'api/purchases';
import {
  PaymentData,
  OneClickPaymentData,
  SubscriptionPaymentData,
  SubscriptionPaymentResponse,
  OnetimePaymentResponse,
  WebPurchase,
} from 'api/purchases/interfaces';
import * as MODALS from 'constants/modals';
import { SUB_BENEFITS_MODAL_EXCLUDE_ROUTES } from 'constants/routes';
import { WEB_SUBSCRIPTION_STATUS } from 'modules/payments/constants';
import { SUB_CANCEL_LINK, WEB_SUB_CANCEL_LINK } from 'constants/general';
import { BENEFITS_TRIGGERS } from 'constants/sub-benefits';
import { EXTERNAL_TRIGGER_NOTIFICATIONS } from 'components/trigger-notification/components/const';
import { mapWebSubscription, mapWebProduct } from 'modules/payments/utils';
import { initUnlimitedMode } from 'store/astrologers/core/actions';

import { setupPurchasedState } from './actions';
import { BILLING_TYPES } from './types';
import { getWebActiveSubscription } from './selectors';

const setPurchased = createAction(BILLING_TYPES.SET_PURCHASED);
const setWebPaymentMethod = createAction(BILLING_TYPES.SET_WEB_PAYMENT_METHOD);
const setWebTransactions = createAction(BILLING_TYPES.SET_WEB_TRANSACTIONS);
const setWebProducts = createAction(BILLING_TYPES.SET_WEB_PRODUCTS);
const setWebOneTimePurchases = createAction(BILLING_TYPES.SET_WEB_ONE_TIME_PURCHASES);
const setWebAllSubscriptions = createAction(BILLING_TYPES.SET_WEB_ALL_SUBSCRIPTIONS);
const setRedemptionSubscription = createAction(BILLING_TYPES.SET_WEB_REDEMPTION_SUBSCRIPTION);

export const trackEvent = (event: string, params?: any) => Analytics.trackEvent('billing', event, params);

export const initSubBenefits = () => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const { enabled, sessionNumber, showSessionsLimit } = state.remoteConfig.remoteConfigParams?.subBenefitsConfig || {};
    const { purchased } = state.billing;

    if (!enabled || !purchased) {
      return;
    }

    const currentSession = (Analytics.getSessionNumber() ?? 0) + 1;
    const endSession = sessionNumber + showSessionsLimit;

    if (currentSession >= sessionNumber && currentSession < endSession) {
      dispatch(addToQueue(MODALS.SUB_BENEFITS, { trigger: BENEFITS_TRIGGERS.START_APP, excludeRoutes: SUB_BENEFITS_MODAL_EXCLUDE_ROUTES }));
    }
  };
};

export const cancelSubscription = () => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      auth: { webUUID },
      billing: { webTransactions },
    } = getState();

    const webSubEmail = webTransactions?.find(sub => sub?.customer_email)?.customer_email || '';

    const idfm = webUUID ? webUUID : await DeviceProps.getIDFM();
    const link = webSubEmail ? `${WEB_SUB_CANCEL_LINK}${encodeURIComponent(webSubEmail)}&idfm=${idfm}&platform=${Platform.OS}` : SUB_CANCEL_LINK;
    Linking.openURL(link || '');
  };
};

export const fetchPaymentMethod = () => {
  return async (dispatch: AppDispatch) => {
    const paymentMethod = await getPaymentMethod();
    return dispatch(setWebPaymentMethod(paymentMethod));
  };
};

export const payByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: PaymentData;
  onSuccess?: (res: OnetimePaymentResponse['one_time_payment']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const responseData = await createOneClickPayment(data as OneClickPaymentData);
      const { verify_url, one_time_payment } = responseData;

      if (verify_url) {
        //TODO 3DS temporary solution
        const error: any = new Error('3DS Skip');
        Analytics.trackEvent('OneClick', '3DS', { isOnetime: true });
        throw error;
      } else {
        Analytics.trackEvent('OneClick', 'Success', { isOnetime: true });

        if (typeof onSuccess === 'function') {
          onSuccess(one_time_payment);
        }
      }

      return responseData;
    } catch (error) {
      Analytics.trackEvent('OneClick', 'Error', { isOnetime: true });
      console.log('[ERROR ONETIME PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};

export const subscribeByOneClick = ({
  data,
  onSuccess,
  onError,
}: {
  data: PaymentData;
  onSuccess?: (res: SubscriptionPaymentResponse['data']) => void;
  onError?: (error: any) => void;
}) => {
  return async (_dispatch: AppDispatch, _getState: AppGetState) => {
    try {
      const responseData = await createSubscriptionByOneClick({
        productId: data.productCode,
        metadata: data.metadata,
        description: data.description,
        productTrigger: data.productTrigger,
        solidMetadata: data.solidMetadata,
      } as SubscriptionPaymentData);

      if (responseData?.verify_url) {
        //TODO 3DS temporary solution
        const error: any = new Error('3DS Skip');
        Analytics.trackEvent('OneClick', '3DS', { isOnetime: false });
        throw error;
      } else {
        Analytics.trackEvent('OneClick', 'Success', { isOnetime: false });
        if (typeof onSuccess === 'function') {
          onSuccess(responseData.data);
        }
      }
      return responseData;
    } catch (error) {
      Analytics.trackEvent('OneClick', 'Error', { isOnetime: false });
      console.log('[ERROR SUBSCRIPTION PAY BY ONE CLICK]: ', error);
      if (typeof onError === 'function') {
        onError(error);
      }
    }
  };
};

export const verifyWebSubscriptions = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: { remoteConfigParams },
      billing: { redemptionSubscription },
    } = getState();

    const isPaymentFailedTriggerNotificationEnabled = remoteConfigParams?.paymentFailedTriggerNotificationEnabled;
    const isLockTrialCanceledSub = remoteConfigParams?.isLockTrialCanceledSub;

    const allSubscriptions = await getWebAllSubs();
    const products = await dispatch(fetchWebProducts(allSubscriptions));
    const subscriptions = allSubscriptions.map((p: WebPurchase) => mapWebSubscription(p, products));
    dispatch(setWebAllSubscriptions(subscriptions));

    const redemption = subscriptions.find(s => s.status === WEB_SUBSCRIPTION_STATUS.REDEMPTION);

    if (redemption) {
      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'Redemption_In');
      dispatch(setRedemptionSubscription(redemption));

      if (isPaymentFailedTriggerNotificationEnabled) {
        Analytics.trackEvent('TopNotification', 'Show', {
          type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED,
        });

        dispatch(addTriggerNotification({ type: EXTERNAL_TRIGGER_NOTIFICATIONS.PAYMENT_FAILED }));
      }
    } else if (redemptionSubscription) {
      const changedStatusRedemptionSubscription = subscriptions.find(s => s.external_id === redemptionSubscription.external_id);

      let status = 'unknown';

      if (changedStatusRedemptionSubscription) {
        status = changedStatusRedemptionSubscription.status;
      }

      Analytics.trackPaymentEvent(PAYMENT_EVENTS_CATEGORIES.SUBSCRIPTION, 'Redemption_Out', { status });

      dispatch(setRedemptionSubscription(null));
    }

    // IT NEEDS BECAUSE ACTUALIZED REQUEST UPDATES ACTUALLY STATES OF PURCHASES
    await dispatch(fetchWebPurchases());

    const activeCanceledTrialSubscription = subscriptions.find(i => i.is_active_trial_period && i?.canceled_at);

    const activeSubscription = getWebActiveSubscription(getState());

    if (isLockTrialCanceledSub && activeCanceledTrialSubscription && !activeSubscription) {
      dispatch(setPurchased({ purchased: false, purchasedAstrologers: false }));
    }

    await dispatch(initUnlimitedMode());
    return subscriptions;
  };
};

const fetchWebProducts = (subscriptions: WebPurchase[]) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();
    const {
      remoteConfig: {
        remoteConfigParams: { webSubscriptions },
      },
    } = state;

    const ids = webSubscriptions ?? [];

    if (subscriptions.length) {
      subscriptions.map(i => {
        if (!ids.includes(i.product)) {
          return ids.push(i.product);
        }
      });
    }

    const products = await getProducts(ids);
    const preparedProducts = products.map(mapWebProduct);
    dispatch(setWebProducts(preparedProducts));
    return products;
  };
};

export const fetchWebPurchases = () => {
  return async (dispatch: AppDispatch) => {
    const [transactions, oneTimePurchases] = await Promise.all([getWebSubs(), getWebOneTimePurchases()]);

    dispatch(setWebTransactions(transactions));
    dispatch(setWebOneTimePurchases(oneTimePurchases));
    dispatch(fetchPaymentMethod());
    dispatch(setupPurchasedState());

    return true;
  };
};

export const openSubscriptionModal = () => {
  return (dispatch: AppDispatch, _getState: AppGetState) => {
    dispatch(showModal(MODALS.SUB_WITH_BENEFITS));
  };
};
