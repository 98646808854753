import {
  donateShortcut as pkgDonateShortcut,
  presentShortcut,
  getShortcuts,
  type ShortcutOptions,
} from 'react-native-siri-shortcut';
import { createAction } from 'redux-actions';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { fillInProfile, navigate } from 'store/navigation/actions';
import * as NS_USER_ACTIVITY_TYPES from 'constants/ns-user-activity-types';
import * as ROUTES from 'constants/routes';

import SiriShortcuts from '../../siri-shortcuts';

import { TYPES } from './types';

const voiceCommandAddedAction = createAction(TYPES.VOICE_COMMAND_ADDED);
const showSuggestionAction = createAction(TYPES.SHOW_SUGGESTION);
const hideSuggestionAction = createAction(TYPES.HIDE_SUGGESTION);

export function addHoroscopeVoiceCommand() {
  return function (dispatch: AppDispatch) {
    dispatch(addVoiceCommand(SiriShortcuts.getHoroscopeShortcut()));
  };
}

export function addVoiceCommand(shortcut: ShortcutOptions) {
  return function (dispatch: AppDispatch) {
    if (shortcut) {
      presentShortcut(shortcut, ({ status }) => {
        if (status !== 'cancelled') {
          Analytics.trackEvent('siri', 'shortcut_add');
          dispatch(voiceCommandAddedAction(true));
        } else {
          dispatch(showSuggestionAction());
        }
      });
    }
  };
}

export function showSuggestion() {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const state = getState();

    const { suggestionVisible, voiceCommandAdded, seenSuggestionTimes } = state.siriShortcuts;
    const { voiceCommandSuggestionMaxSeenTimes } = state.remoteConfig.remoteConfigParams;

    if (
      !SiriShortcuts.supported ||
      suggestionVisible ||
      voiceCommandAdded ||
      seenSuggestionTimes >= voiceCommandSuggestionMaxSeenTimes
    ) {
      return;
    }

    dispatch(showSuggestionAction());

    setTimeout(
      () => dispatch(hideSuggestionAction()),
      state.remoteConfig.remoteConfigParams.voiceCommandSuggestionDisplayTimeout,
    );
  };
}

export function donateHoroscopeShortcut() {
  return function (dispatch: AppDispatch) {
    dispatch(donateShortcut(SiriShortcuts.getHoroscopeShortcut()));
  };
}

export function donateShortcut(shortcut: ShortcutOptions) {
  return function () {
    if (!SiriShortcuts.supported) {
      supportWarning();
      return;
    }
    pkgDonateShortcut(shortcut);
  };
}

function supportWarning() {
  console.warn('Siri Shortcuts only supported on iOS >= 12');
}

export function checkActiveShortcuts() {
  return async function (dispatch: AppDispatch, getState: AppGetState) {
    const { voiceCommandAdded } = getState().siriShortcuts;
    const recorded = await getShortcuts();

    if (voiceCommandAdded && !recorded.length) {
      dispatch(voiceCommandAddedAction(false));
    }
  };
}

export function receiveNsUserActivityType(shortcut: ShortcutOptions) {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const { isLoaded } = getState().app;
    if (isLoaded) {
      dispatch(handleNsUserActivityType(shortcut));
    }
  };
}

export function handleNsUserActivityType({ activityType }: { activityType: string }) {
  return function (dispatch: AppDispatch, getState: AppGetState) {
    const {
      app: { isFullProfile },
    } = getState();

    if (activityType === NS_USER_ACTIVITY_TYPES.HOROSCOPE) {
      Analytics.trackEvent('siri', 'shortcut_call');
      if (isFullProfile) {
        setTimeout(() => {
          navigate(ROUTES.PERSONAL_READINGS, { narrationEnabled: true });
        }, 500);
      } else {
        dispatch(fillInProfile());
      }
    }
  };
}
