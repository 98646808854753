import React, { FC, useEffect, useState } from 'react';
import { View, StyleSheet, Text, Image, Dimensions, Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fs, HEADER_OFFSET, paddingVertical, sh, BOTTOM_OFFSET, sw } from '@wowmaking/ui/src/utils';

import Button from 'components/button';
import FadeView from 'components/fade-view';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { t } from 'localization';
import { AppDispatch, RootState } from 'store';
import { restore } from 'store/billing/actions';
import { closeCurrentModal } from 'store/modals/actions';
import { logEvent } from 'utils/events';
import Loader from 'components/loader/overlay';

import CloseButton from '../components/close-button';
import Title from '../components/title';
import IMG from '../img/image.jpg';

const ANIMATION_DURATION = 400;

const { height } = Dimensions.get('window');

interface Props {}

const OfferWelcome: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [visible, setVisible] = useState<boolean>(false);
  const pending = useSelector((state: RootState) => state.billing.pending);
  const showModal = useSelector((state: RootState) => state.modals.activeModal) === MODALS.OFFER_WELCOME;

  useEffect(() => {
    if (showModal) {
      logEvent('offer_welcome', 'open');
      setVisible(true);
    }
  }, [showModal]);

  const handleClose = () => {
    setVisible(false);

    setTimeout(() => {
      dispatch(closeCurrentModal());
    }, ANIMATION_DURATION);
  };

  const handleClosePress = () => {
    logEvent('offer_welcome', 'close_click');
    handleClose();
  };

  const handleCancelPress = () => {
    logEvent('offer_welcome', 'try_later_click');
    handleClose();
  };

  const handleButtonPress = () => {
    dispatch(restore(true)).then((success: any) => {
      if (success) {
        logEvent('offer_welcome', 'success');
        handleClose();
      } else {
        Alert.alert(
          t('OFFER_GUIDE.WELCOME.ALERT.TITLE'),
          t('OFFER_GUIDE.WELCOME.ALERT.TEXT'),
          [
            {
              text: t('OFFER_GUIDE.WELCOME.ALERT.OK'),
            },
            { text: t('OFFER_GUIDE.WELCOME.ALERT.CANCEL'), onPress: handleCancelPress },
          ],
          { cancelable: true },
        );
      }
    });
  };

  if (!showModal) {
    return null;
  }

  return (
    <FadeView style={styles.root} visible={visible} duration={ANIMATION_DURATION}>
      <Image source={IMG} style={styles.image} />
      <View style={styles.core}>
        <View style={styles.topControls}>
          <CloseButton onPress={handleClosePress} />
        </View>
        <View style={styles.container}>
          <Title text={t('OFFER_GUIDE.WELCOME.TITLE')} style={styles.titleWrapper} />

          <Text style={styles.text}>{t('OFFER_GUIDE.WELCOME.TEXT')}</Text>
          <View style={styles.buttonContainer}>
            <Button title={t('OFFER_GUIDE.WELCOME.BUTTON_TITLE')} onPress={handleButtonPress} style={styles.button} titleStyle={styles.buttonTitle} />
          </View>
        </View>
      </View>
      <Loader visible={pending} />
    </FadeView>
  );
};

export default OfferWelcome;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: COLORS.DARK_BLUE_GREY_2,
    ...StyleSheet.absoluteFillObject,
  },
  core: {
    flex: 1,
    minHeight: '100%',
    justifyContent: 'flex-end',
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
    height: (height / 100) * 42,
  },
  topControls: {
    alignItems: 'flex-start',
    width: '100%',
    position: 'absolute',
    top: sh(HEADER_OFFSET + 16),
    zIndex: 9,
  },
  container: {
    flex: 0.55,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    width: '100%',
    alignItems: 'center',
  },
  buttonContainer: {
    alignItems: 'center',
    width: '80%',
    marginTop: paddingVertical(55),
    marginBottom: BOTTOM_OFFSET + paddingVertical(40),
  },
  button: {
    width: '100%',
    maxWidth: sw(278),
    alignSelf: 'center',
    height: 55,
    borderRadius: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTitle: {
    fontSize: fs(22),
    textShadowColor: COLORS.BLACK_OPACITY,
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 0,
  },
  text: {
    fontSize: fs(18),
    lineHeight: fs(24),
    color: COLORS.WHITE,
    textAlign: 'center',
  },
});
