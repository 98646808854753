export enum WIDGETS_TYPES {
  HOROSCOPE = 'HOROSCOPE',
}

export enum WIDGETS_THEMES {
  H1 = 'H1',
}

export enum WIDGETS_SIZES {
  sm = 'systemSmall',
  md = 'systemMedium',
  lg = 'systemLarge',
}

export const WIDGETS_GROUP = 'group.com.horoastroline.widget';

export enum WIDGETS_DEEP_LINKS {
  HOROSCOPE = 'widget-horoscope://',
}
