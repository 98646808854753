import React, { FC, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { paddingVertical, paddingHorizontal, fs, BOTTOM_OFFSET } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';

import Analytics from 'analytics';
import Button from 'components/button';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import * as ICONS from 'constants/icons';
import { t } from 'localization';
import { AppDispatch, RootState } from 'store';
import { closeCurrentModal } from 'store/modals/actions';

interface Props {}

const AdvisorServiceInfoModal: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.ADVISOR_SERVICE_INFO);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('Advisors_Service_Modal', 'Open');
    }
  }, [isVisible]);

  const handleModalClose = () => {
    dispatch(closeCurrentModal());
  };

  const handleClosePress = () => {
    Analytics.trackEvent('Advisors_Service_Modal', 'Close_Click');
    handleModalClose();
  };

  const handleButtonPress = () => {
    Analytics.trackEvent('Advisors_Service_Modal', 'Button_Click');
    handleModalClose();
  };

  return (
    <Modal style={styles.modal} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND} isVisible={isVisible} animationInTiming={500}>
      <View style={styles.container}>
        <TouchableOpacity style={styles.closeIconWrapper} onPress={handleClosePress}>
          <Image resizeMode={'cover'} style={styles.closeIcon} source={ICONS.CLOSE_ICON} />
        </TouchableOpacity>
        <Text font="Philosopher" style={styles.title}>
          {t('SERVICES.MODAL.TITLE')}
        </Text>
        <Text style={styles.text}>{t('SERVICES.MODAL.SUBTITLE')}</Text>

        <View style={styles.buttonWrap}>
          <Button style={styles.button} titleStyle={styles.buttonTitle} title={t('SERVICES.MODAL.BTN_TITLE')} onPress={handleButtonPress} />
        </View>
      </View>
    </Modal>
  );
};

export default AdvisorServiceInfoModal;

const styles = StyleSheet.create({
  modal: {
    flex: 1,
    width: '100%',
    margin: 0,
    justifyContent: 'flex-end',
  },
  container: {
    backgroundColor: COLORS.MODAL_BG_COLOR,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    paddingHorizontal: paddingHorizontal(32),
    paddingTop: paddingVertical(20),
    paddingBottom: BOTTOM_OFFSET + paddingVertical(40),
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: fs(24),
    lineHeight: fs(30),
    marginTop: paddingVertical(34),
    color: COLORS.BEIGE_2,
    fontWeight: '700',
    alignSelf: 'center',
    textAlign: 'center',
  },
  text: {
    color: COLORS.LIGHT_BEIGE,
    paddingTop: paddingVertical(10),
    fontWeight: '400',
    alignSelf: 'center',
    fontSize: fs(16),
    textAlign: 'center',
  },
  buttonWrap: {
    width: '100%',
    marginTop: paddingVertical(40),
    alignItems: 'center',
  },
  button: {},
  buttonTitle: {
    fontSize: fs(20),
    lineHeight: fs(22),
    color: COLORS.WHITE,
    width: '100%',
  },
  closeIconWrapper: {
    top: paddingHorizontal(5),
    right: paddingHorizontal(5),
    padding: paddingHorizontal(15),
    position: 'absolute',
  },
  closeIcon: {
    tintColor: COLORS.BEIGE_2,
  },
});
