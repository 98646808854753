import { ImageMaps } from 'interfaces/image';

//elements s size
import AIR_ELEMENT_S from '../../assets/icons/elements/air.png';
import EARTH_ELEMENT_S from '../../assets/icons/elements/earth.png';
import FIRE_ELEMENT_S from '../../assets/icons/elements/fire.png';
import WATER_ELEMENT_S from '../../assets/icons/elements/water.png';
//elements m size
import AIR_ELEMENT_M from '../../assets/icons/elements-m/air.png';
import EARTH_ELEMENT_M from '../../assets/icons/elements-m/earth.png';
import FIRE_ELEMENT_M from '../../assets/icons/elements-m/fire.png';
import WATER_ELEMENT_M from '../../assets/icons/elements-m/water.png';
//
import AQUARIUS_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/aquarius.png';
import ARIES_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/aries.png';
import CANCER_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/cancer.png';
import CAPRICORN_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/capricorn.png';
import GEMINI_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/gemini.png';
import LEO_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/leo.png';
import LIBRA_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/libra.png';
import PISCES_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/pisces.png';
import SAGITTARIUS_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/sagittarius.png';
import SCORPION_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/scorpio.png';
import TAURUS_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/taurus.png';
import VIRGO_ICON_102x102_GOLD from '../../assets/icons/signs-icons/102x102/gold/virgo.png';
import AQUARIUS_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/aquarius.png';
import ARIES_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/aries.png';
import CANCER_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/cancer.png';
import CAPRICORN_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/capricorn.png';
import GEMINI_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/gemini.png';
import LEO_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/leo.png';
import LIBRA_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/libra.png';
import PISCES_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/pisces.png';
import SAGITTARIUS_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/sagittarius.png';
import SCORPION_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/scorpio.png';
import TAURUS_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/taurus.png';
import VIRGO_ICON_122x130_LIGHT from '../../assets/icons/signs-icons/122x130/light/virgo.png';
import AQUARIUS_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/aquarius.png';
import ARIES_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/aries.png';
import CANCER_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/cancer.png';
import CAPRICORN_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/capricorn.png';
import GEMINI_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/gemini.png';
import LEO_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/leo.png';
import LIBRA_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/libra.png';
import PISCES_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/pisces.png';
import SAGITTARIUS_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/sagittarius.png';
import SCORPION_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/scorpio.png';
import TAURUS_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/taurus.png';
import VIRGO_ICON_75x80_DARK from '../../assets/icons/signs-icons/75x80/dark/virgo.png';
import AQUARIUS_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/aquarius.png';
import ARIES_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/aries.png';
import CANCER_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/cancer.png';
import CAPRICORN_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/capricorn.png';
import GEMINI_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/gemini.png';
import LEO_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/leo.png';
import LIBRA_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/libra.png';
import PISCES_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/pisces.png';
import SAGITTARIUS_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/sagittarius.png';
import SCORPION_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/scorpio.png';
import TAURUS_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/taurus.png';
import VIRGO_ICON_75x80_LIGHT from '../../assets/icons/signs-icons/75x80/light/virgo.png';
import AQUARIUS_ICON from '../../assets/icons/signs-icons/aqua/Aquarius.png';
import ARIES_ICON from '../../assets/icons/signs-icons/aqua/Aries.png';
import CANCER_ICON from '../../assets/icons/signs-icons/aqua/Cancer.png';
import CAPRICORN_ICON from '../../assets/icons/signs-icons/aqua/Capricorn.png';
import GEMINI_ICON from '../../assets/icons/signs-icons/aqua/Gemini.png';
import LEO_ICON from '../../assets/icons/signs-icons/aqua/Leo.png';
import LIBRA_ICON from '../../assets/icons/signs-icons/aqua/Libra.png';
import PISCES_ICON from '../../assets/icons/signs-icons/aqua/Pisces.png';
import SAGITTARIUS_ICON from '../../assets/icons/signs-icons/aqua/Sagittarius.png';
import SCORPION_ICON from '../../assets/icons/signs-icons/aqua/Scorpion.png';
import TAURUS_ICON from '../../assets/icons/signs-icons/aqua/Taurus.png';
import VIRGO_ICON from '../../assets/icons/signs-icons/aqua/Virgo.png';
//
import AQUARIUS_ICON_MATTE from '../../assets/icons/signs-icons/matte/aquarius.png';
import ARIES_ICON_MATTE from '../../assets/icons/signs-icons/matte/aries.png';
import CANCER_ICON_MATTE from '../../assets/icons/signs-icons/matte/cancer.png';
import CAPRICORN_ICON_MATTE from '../../assets/icons/signs-icons/matte/capricorn.png';
import GEMINI_ICON_MATTE from '../../assets/icons/signs-icons/matte/gemini.png';
import LEO_ICON_MATTE from '../../assets/icons/signs-icons/matte/leo.png';
import LIBRA_ICON_MATTE from '../../assets/icons/signs-icons/matte/libra.png';
import PISCES_ICON_MATTE from '../../assets/icons/signs-icons/matte/pisces.png';
import SAGITTARIUS_ICON_MATTE from '../../assets/icons/signs-icons/matte/sagittarius.png';
import SCORPION_ICON_MATTE from '../../assets/icons/signs-icons/matte/scorpion.png';
import TAURUS_ICON_MATTE from '../../assets/icons/signs-icons/matte/taurus.png';
import VIRGO_ICON_MATTE from '../../assets/icons/signs-icons/matte/virgo.png';
//
import AQUARIUS_ICON_SAND from '../../assets/icons/signs-icons/sand/Aquarius.png';
import ARIES_ICON_SAND from '../../assets/icons/signs-icons/sand/Aries.png';
import CANCER_ICON_SAND from '../../assets/icons/signs-icons/sand/Cancer.png';
import CAPRICORN_ICON_SAND from '../../assets/icons/signs-icons/sand/Capricorn.png';
import GEMINI_ICON_SAND from '../../assets/icons/signs-icons/sand/Gemini.png';
import LEO_ICON_SAND from '../../assets/icons/signs-icons/sand/Leo.png';
import LIBRA_ICON_SAND from '../../assets/icons/signs-icons/sand/Libra.png';
import PISCES_ICON_SAND from '../../assets/icons/signs-icons/sand/Pisces.png';
import SAGITTARIUS_ICON_SAND from '../../assets/icons/signs-icons/sand/Sagittarius.png';
import SCORPION_ICON_SAND from '../../assets/icons/signs-icons/sand/Scorpion.png';
import TAURUS_ICON_SAND from '../../assets/icons/signs-icons/sand/Taurus.png';
import VIRGO_ICON_SAND from '../../assets/icons/signs-icons/sand/Virgo.png';
//
import AQUARIUS_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/aquarius.png';
import ARIES_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/aries.png';
import CANCER_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/cancer.png';
import CAPRICORN_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/capricorn.png';
import GEMINI_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/gemini.png';
import LEO_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/leo.png';
import LIBRA_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/libra.png';
import PISCES_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/pisces.png';
import SAGITTARIUS_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/sagittarius.png';
import SCORPION_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/scorpio.png';
import TAURUS_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/taurus.png';
import VIRGO_ICON_56x60_DARK from '../../assets/icons/signs-icons/56x60/dark/virgo.png';

export const ARIES = 'aries';
export const TAURUS = 'taurus';
export const GEMINI = 'gemini';
export const CANCER = 'cancer';
export const LEO = 'leo';
export const VIRGO = 'virgo';
export const LIBRA = 'libra';
export const SCORPIO = 'scorpio';
export const SAGITTARIUS = 'sagittarius';
export const CAPRICORN = 'capricorn';
export const AQUARIUS = 'aquarius';
export const PISCES = 'pisces';
export const FIRE = 'fire';
export const WATER = 'water';
export const EARTH = 'earth';
export const AIR = 'air';

export const SIGNS_ICONS: ImageMaps = {
  [ARIES]: ARIES_ICON,
  [TAURUS]: TAURUS_ICON,
  [GEMINI]: GEMINI_ICON,
  [CANCER]: CANCER_ICON,
  [LEO]: LEO_ICON,
  [VIRGO]: VIRGO_ICON,
  [LIBRA]: LIBRA_ICON,
  [SCORPIO]: SCORPION_ICON,
  [SAGITTARIUS]: SAGITTARIUS_ICON,
  [CAPRICORN]: CAPRICORN_ICON,
  [AQUARIUS]: AQUARIUS_ICON,
  [PISCES]: PISCES_ICON,
};

export const SIGNS_ICONS_SAND: ImageMaps = {
  [ARIES]: ARIES_ICON_SAND,
  [TAURUS]: TAURUS_ICON_SAND,
  [GEMINI]: GEMINI_ICON_SAND,
  [CANCER]: CANCER_ICON_SAND,
  [LEO]: LEO_ICON_SAND,
  [VIRGO]: VIRGO_ICON_SAND,
  [LIBRA]: LIBRA_ICON_SAND,
  [SCORPIO]: SCORPION_ICON_SAND,
  [SAGITTARIUS]: SAGITTARIUS_ICON_SAND,
  [CAPRICORN]: CAPRICORN_ICON_SAND,
  [AQUARIUS]: AQUARIUS_ICON_SAND,
  [PISCES]: PISCES_ICON_SAND,
};

export const SIGNS_ICONS_MATTE: ImageMaps = {
  [ARIES]: ARIES_ICON_MATTE,
  [TAURUS]: TAURUS_ICON_MATTE,
  [GEMINI]: GEMINI_ICON_MATTE,
  [CANCER]: CANCER_ICON_MATTE,
  [LEO]: LEO_ICON_MATTE,
  [VIRGO]: VIRGO_ICON_MATTE,
  [LIBRA]: LIBRA_ICON_MATTE,
  [SCORPIO]: SCORPION_ICON_MATTE,
  [SAGITTARIUS]: SAGITTARIUS_ICON_MATTE,
  [CAPRICORN]: CAPRICORN_ICON_MATTE,
  [AQUARIUS]: AQUARIUS_ICON_MATTE,
  [PISCES]: PISCES_ICON_MATTE,
};

export const SIGNS_ICONS_56x60_DARK: ImageMaps = {
  [ARIES]: ARIES_ICON_56x60_DARK,
  [TAURUS]: TAURUS_ICON_56x60_DARK,
  [GEMINI]: GEMINI_ICON_56x60_DARK,
  [CANCER]: CANCER_ICON_56x60_DARK,
  [LEO]: LEO_ICON_56x60_DARK,
  [VIRGO]: VIRGO_ICON_56x60_DARK,
  [LIBRA]: LIBRA_ICON_56x60_DARK,
  [SCORPIO]: SCORPION_ICON_56x60_DARK,
  [SAGITTARIUS]: SAGITTARIUS_ICON_56x60_DARK,
  [CAPRICORN]: CAPRICORN_ICON_56x60_DARK,
  [AQUARIUS]: AQUARIUS_ICON_56x60_DARK,
  [PISCES]: PISCES_ICON_56x60_DARK,
};

export const SIGNS_ICONS_75x80_LIGHT: ImageMaps = {
  [ARIES]: ARIES_ICON_75x80_LIGHT,
  [TAURUS]: TAURUS_ICON_75x80_LIGHT,
  [GEMINI]: GEMINI_ICON_75x80_LIGHT,
  [CANCER]: CANCER_ICON_75x80_LIGHT,
  [LEO]: LEO_ICON_75x80_LIGHT,
  [VIRGO]: VIRGO_ICON_75x80_LIGHT,
  [LIBRA]: LIBRA_ICON_75x80_LIGHT,
  [SCORPIO]: SCORPION_ICON_75x80_LIGHT,
  [SAGITTARIUS]: SAGITTARIUS_ICON_75x80_LIGHT,
  [CAPRICORN]: CAPRICORN_ICON_75x80_LIGHT,
  [AQUARIUS]: AQUARIUS_ICON_75x80_LIGHT,
  [PISCES]: PISCES_ICON_75x80_LIGHT,
};

export const SIGNS_ICONS_75x80_DARK: ImageMaps = {
  [ARIES]: ARIES_ICON_75x80_DARK,
  [TAURUS]: TAURUS_ICON_75x80_DARK,
  [GEMINI]: GEMINI_ICON_75x80_DARK,
  [CANCER]: CANCER_ICON_75x80_DARK,
  [LEO]: LEO_ICON_75x80_DARK,
  [VIRGO]: VIRGO_ICON_75x80_DARK,
  [LIBRA]: LIBRA_ICON_75x80_DARK,
  [SCORPIO]: SCORPION_ICON_75x80_DARK,
  [SAGITTARIUS]: SAGITTARIUS_ICON_75x80_DARK,
  [CAPRICORN]: CAPRICORN_ICON_75x80_DARK,
  [AQUARIUS]: AQUARIUS_ICON_75x80_DARK,
  [PISCES]: PISCES_ICON_75x80_DARK,
};

export const SIGNS_ICONS_122x130_LIGHT: ImageMaps = {
  [ARIES]: ARIES_ICON_122x130_LIGHT,
  [TAURUS]: TAURUS_ICON_122x130_LIGHT,
  [GEMINI]: GEMINI_ICON_122x130_LIGHT,
  [CANCER]: CANCER_ICON_122x130_LIGHT,
  [LEO]: LEO_ICON_122x130_LIGHT,
  [VIRGO]: VIRGO_ICON_122x130_LIGHT,
  [LIBRA]: LIBRA_ICON_122x130_LIGHT,
  [SCORPIO]: SCORPION_ICON_122x130_LIGHT,
  [SAGITTARIUS]: SAGITTARIUS_ICON_122x130_LIGHT,
  [CAPRICORN]: CAPRICORN_ICON_122x130_LIGHT,
  [AQUARIUS]: AQUARIUS_ICON_122x130_LIGHT,
  [PISCES]: PISCES_ICON_122x130_LIGHT,
};

export const SIGNS_ICONS_102x102_GOLD: ImageMaps = {
  [ARIES]: ARIES_ICON_102x102_GOLD,
  [TAURUS]: TAURUS_ICON_102x102_GOLD,
  [GEMINI]: GEMINI_ICON_102x102_GOLD,
  [CANCER]: CANCER_ICON_102x102_GOLD,
  [LEO]: LEO_ICON_102x102_GOLD,
  [VIRGO]: VIRGO_ICON_102x102_GOLD,
  [LIBRA]: LIBRA_ICON_102x102_GOLD,
  [SCORPIO]: SCORPION_ICON_102x102_GOLD,
  [SAGITTARIUS]: SAGITTARIUS_ICON_102x102_GOLD,
  [CAPRICORN]: CAPRICORN_ICON_102x102_GOLD,
  [AQUARIUS]: AQUARIUS_ICON_102x102_GOLD,
  [PISCES]: PISCES_ICON_102x102_GOLD,
};

export const ELEMENTS_S: ImageMaps = {
  [FIRE]: FIRE_ELEMENT_S,
  [WATER]: WATER_ELEMENT_S,
  [EARTH]: EARTH_ELEMENT_S,
  [AIR]: AIR_ELEMENT_S,
};

export const ELEMENTS_M: ImageMaps = {
  [FIRE]: FIRE_ELEMENT_M,
  [WATER]: WATER_ELEMENT_M,
  [EARTH]: EARTH_ELEMENT_M,
  [AIR]: AIR_ELEMENT_M,
};

export const ZODIAC_EMOJI_MAP: { [key: string]: string } = {
  [ARIES]: '\u2648',
  [TAURUS]: '\u2649',
  [GEMINI]: '\u264A',
  [CANCER]: '\u264B',
  [LEO]: '\u264C',
  [VIRGO]: '\u264D',
  [LIBRA]: '\u264E',
  [SCORPIO]: '\u264F',
  [SAGITTARIUS]: '\u2650',
  [CAPRICORN]: '\u2651',
  [AQUARIUS]: '\u2652',
  [PISCES]: '\u2653',
};

export const SIGN_CARDINAL = [ARIES, CANCER, LIBRA, CAPRICORN];
export const SIGN_FIXED = [TAURUS, LEO, SCORPIO, AQUARIUS];
export const SIGN_MUTABLE = [GEMINI, VIRGO, SAGITTARIUS, PISCES];

export const SIGNS_ZODIAC = [
  'ARIES',
  'TAURUS',
  'GEMINI',
  'CANCER',
  'LEO',
  'VIRGO',
  'LIBRA',
  'SCORPIO',
  'SAGITTARIUS',
  'CAPRICORN',
  'AQUARIUS',
  'PISCES',
];
