import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import { IS_DEV_BUILD } from 'constants/general';

export let DOMAIN: any = IS_DEV_BUILD ? 'https://astrology-stage.astroline.app/api/v1' : 'https://astrology.astroline.app/api/v1';
export const APP_URL = IS_DEV_BUILD ? 'https://astrology-stage.astroline.app' : 'https://astrology.astroline.app';

const isLogEnabled = false;

if (Platform.OS === 'web') {
  DOMAIN = process.env.REACT_APP_API_BASE_URL;
}

const api = {
  token: '',

  setToken(newToken: string) {
    this.token = newToken;
  },

  fetch(url: string, options: any, pureBodyData = false) {
    const body = options.data ? (pureBodyData ? options.data : JSON.stringify(options.data)) : undefined;

    return fetch(`${DOMAIN}${url}`, {
      ...options,
      body,
      headers: {
        Accept: 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${this.token}` || undefined,
        'Content-Type': 'application/json; charset=UTF-8',
        ...options.headers,
        'X-platform': Platform.OS,
        'X-version': DeviceInfo.getVersion(),
      },
    })
      .then(response => {
        return response.json().then(r => {
          if (response.ok) {
            return r;
          } else {
            throw r;
          }
        });
      })
      .catch(err => {
        if (isLogEnabled) {
          console.warn('Network Response:', err);
        }
        throw err;
      });
  },
};

export default api;
