import { TODAY_FEATURES_IDS, TodayColor, TodayNumber, TodayTime } from 'api/todays-features/interfaces';
import { PROFILE_TYPES } from 'store/profile/types';

export enum TYPES {
  SET_FEATURES = 'TODAYS_FEATURES/SET_FEATURES',
}

export interface State {
  [TODAY_FEATURES_IDS.COLOR]: TodayColor | null;
  [TODAY_FEATURES_IDS.NUMBER]: TodayNumber | null;
  [TODAY_FEATURES_IDS.TIME]: TodayTime | null;
}

interface SetFeatures {
  type: typeof TYPES.SET_FEATURES;
  payload: {
    [TODAY_FEATURES_IDS.COLOR]: TodayColor | null;
    [TODAY_FEATURES_IDS.NUMBER]: TodayNumber | null;
    [TODAY_FEATURES_IDS.TIME]: TodayTime | null;
  };
}

interface Reset {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionType = SetFeatures | Reset;
