import api from '..';

import { Tip } from './interfaces';

const TIPS = '/tip';

export const getDailyTips = (): Promise<Tip[]> => {
  return api.fetch(`${TIPS}`, {
    method: 'GET',
  });
};

export const rateDailyTip = (id: Tip['id'], mark: 'like' | 'dislike'): Promise<Tip> => {
  return api.fetch(`${TIPS}/${mark}/${id}`, {
    method: 'POST',
  });
};
