import { BalanceWheelSections } from 'constants/compatibility-sections';
import { PROFILE_TYPES } from 'store/profile/types';
import { CompatibilitySign } from 'api/compatibility/interfaces';

import {
  ActionType,
  State,
  SET_CONTENT_BLURRED,
  SET_COMPATIBILITY_ERROR,
  SET_COMPATIBILITY_RESULT,
  SET_COMPATIBILITY_PENDING,
  SET_COMPATIBILITY_CHOICE_PICK,
  CompatibilityResult,
} from './types';

const initialResult: CompatibilityResult = {
  overview: '',
  compatibilityLevel: {
    percent: 0,
    description: '',
    name: '',
  },
  aspects: [
    {
      description: '',
      percent: 0,
      id: '',
      progressBarColor: '',
    },
  ],
  toxicity: {
    name: '',
    description: '',
    percent: 0,
  },
  wheelOfBalance: [
    {
      description: '',
      name: '',
      type: BalanceWheelSections.EMOTIONAL,
      percent: 0,
      id: '',
    },
    {
      description: '',
      name: '',
      type: BalanceWheelSections.INTELLECTUAL,
      percent: 0,
      id: '',
    },
    {
      description: '',
      name: '',
      type: BalanceWheelSections.SPIRITUAL,
      percent: 0,
      id: '',
    },
    {
      description: '',
      name: '',
      type: BalanceWheelSections.SEXUAL,
      percent: 0,
      id: '',
    },
  ],
  biggestChallenges: [
    {
      name: '',
      description: '',
      solution: '',
    },
  ],
  signs: {
    first: {} as CompatibilitySign,
    second: {} as CompatibilitySign,
  },
};

const initialState: State = {
  pick: {
    leftPick: '',
    rightPick: '',
  },
  pending: false,
  result: initialResult,
  error: false,
  isBlurred: true,
};

export default function (state = initialState, action: ActionType) {
  switch (action.type) {
    case SET_COMPATIBILITY_CHOICE_PICK:
      return {
        ...state,
        pick: action.payload,
      };

    case SET_COMPATIBILITY_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case SET_COMPATIBILITY_RESULT:
      return {
        ...state,
        pending: false,
        error: false,
        result: {
          ...state.result,
          ...action.payload,
        },
      };

    case SET_COMPATIBILITY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };

    case SET_CONTENT_BLURRED:
      return {
        ...state,
        isBlurred: action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...state,
        pick: { ...initialState.pick },
      };

    default:
      return state;
  }
}
