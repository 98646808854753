import { isEmpty } from 'lodash';

import { t } from 'localization';

import type {
  AstroCalendarBonusInputData,
  AstroCalendarConfigBonus,
  AstroCalendarDBBonus,
  AstroCalendarDataBonus,
  AstroCalendarDataDay,
} from '../interfaces';
import { ASTRO_CALENDAR_BONUS_TYPE } from '../constants';

export const checkConfigBonuses = (bonuses: AstroCalendarConfigBonus[]): AstroCalendarConfigBonus[] => {
  return bonuses.filter(bonus => !!bonus && !isEmpty(bonus) && Object.values(ASTRO_CALENDAR_BONUS_TYPE).includes(bonus.type));
};

export const checkDBBonuses = (bonuses: AstroCalendarDBBonus[], inputs: AstroCalendarBonusInputData): AstroCalendarDBBonus[] => {
  const { guides } = inputs;

  return bonuses.filter(bonus => {
    if (!bonus || isEmpty(bonus)) {
      return false;
    }

    const { type } = bonus;

    switch (type) {
      case ASTRO_CALENDAR_BONUS_TYPE.GUIDE: {
        const { contentId } = bonus;
        return !!guides.find(guide => guide.slug === contentId);
      }

      default: {
        return Object.values(ASTRO_CALENDAR_BONUS_TYPE).includes(type);
      }
    }
  });
};

export const getBonusesReceived = (bonuses: AstroCalendarDataBonus[]): boolean => bonuses.every(bonus => bonus.completed);

export const getDayBonusesReceived = (data: AstroCalendarDataDay): boolean => {
  return !!data.bonuses.length && data.bonuses.every(bonus => bonus.completed);
};

export const getDayBonusesAvailable = (data: AstroCalendarDataDay | null): boolean => {
  if (!data) {
    return false;
  }

  if (data.todos.length) {
    return data.todos.every(todo => todo.completed);
  }

  return data.astroEventsCompleted;
};

export const getMinutesBonusTexts = (amount: number): { title: string; subtitle: string } => {
  if (amount === 1) {
    return {
      title: t('ASTRO_CALENDAR.BONUS.MINUTES.1_MIN.TITLE'),
      subtitle: t('ASTRO_CALENDAR.BONUS.MINUTES.1_MIN.SUBTITLE'),
    };
  }

  if (amount <= 5) {
    return {
      title: t('ASTRO_CALENDAR.BONUS.MINUTES.5_MIN.TITLE', { amount }),
      subtitle: t('ASTRO_CALENDAR.BONUS.MINUTES.5_MIN.SUBTITLE'),
    };
  }

  return {
    title: t('ASTRO_CALENDAR.BONUS.MINUTES.10_MIN.TITLE', { amount }),
    subtitle: t('ASTRO_CALENDAR.BONUS.MINUTES.10_MIN.SUBTITLE'),
  };
};

export const getDayHasBonusWithMinutes = (data: AstroCalendarDataDay): boolean => {
  return data.bonuses.some(bonus => bonus.type === ASTRO_CALENDAR_BONUS_TYPE.MINUTES);
};
