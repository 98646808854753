import { Platform } from 'react-native';

import Analytics from 'analytics';
import { ChatPlatforms, HistoryItem, HistoryItemMetadata } from 'api/astrology-chat/constants';
import { isStandalone } from 'utils/pwa';

const isTheMessageWithSelectedType = (messages: HistoryItem[], type: HistoryItemMetadata['type']) => {
  const isExist = messages.find(mess => {
    if (mess?.metadata) {
      let metadata = mess.metadata;

      if (typeof metadata === 'string') {
        metadata = JSON.parse(metadata);
      }
      const meta = metadata as HistoryItemMetadata;
      return meta?.type === type;
    }

    return undefined;
  });

  return !!isExist;
};

export const handleEventsForSpecificMessages = (messages: HistoryItem[]) => {
  const isTarotCard = isTheMessageWithSelectedType(messages, 'tarotCard');
  const isVoiceMessage = isTheMessageWithSelectedType(messages, 'voiceMessage');

  if (isTarotCard) {
    Analytics.trackEvent('Astrologist', 'Tarot_Card_Received');
  }

  if (isVoiceMessage) {
    Analytics.trackEvent('Voice_Message', 'Received');
  }
};

export const platform = Platform.select<ChatPlatforms>({
  ios: 'ios',
  android: 'android',
  web: isStandalone() ? 'pwa' : 'rnw',
});
