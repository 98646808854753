import { APP_URL } from 'api';

export const getImageWithUrl = (uri?: string) => {
  if (!uri) {
    return '';
  }
  const reg = /https:\/\/astrology-stage.astroline.app|https:\/\/astrology.astroline.app/i;

  const result = uri.match(reg);

  if (result === null) {
    return APP_URL + uri;
  }

  return uri;
};
