import { BROWSERS_NAMES } from 'constants/pwa';

import {
  State,
  ActionTypes,
  SET_INSTALLED,
  SET_BANNER_SESSION_STARTED,
  SET_BROWSER_NAME,
  SET_PWA_AUTO_INSTALL_AVAILABLE,
  SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS,
  SET_INSTALL_MOBILE_APP_SHOW_COUNT,
} from './types';

const initialState: State = {
  installed: false,
  bannerSessionStarted: 0,
  browserName: BROWSERS_NAMES.UNKNOWN,
  isPWAAutoInstallAvailable: false,
  installMobileAppPromocodeSeconds: 0,
  installMobileAppShowCount: 0,
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case SET_INSTALLED:
      return {
        ...state,
        installed: action.payload,
      };

    case SET_BANNER_SESSION_STARTED:
      return {
        ...state,
        bannerSessionStarted: action.payload,
      };

    case SET_BROWSER_NAME:
      return {
        ...state,
        browserName: action.payload,
      };

    case SET_PWA_AUTO_INSTALL_AVAILABLE:
      return {
        ...state,
        isPWAAutoInstallAvailable: action.payload,
      };

    case SET_INSTALL_MOBILE_APP_PROMOCODE_SECONDS:
      return {
        ...state,
        installMobileAppPromocodeSeconds: action.payload,
      };

    case SET_INSTALL_MOBILE_APP_SHOW_COUNT:
      return {
        ...state,
        installMobileAppShowCount: action.payload,
      };

    default:
      return state;
  }
};
