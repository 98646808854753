import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getRandomInRangeForDay, sortRandomForDay } from 'utils/seedrandom';

import { TYPES } from './types';

export const setCompatibilityReports = createAction(TYPES.SET);

export const initCompatibilityReports = () => {
  return (dispatch: AppDispatch) => {
    const passedHours = new Date().getHours();
    const iconsNumbers = [...Array(20).keys()];

    dispatch(
      setCompatibilityReports({
        count: Math.floor(getRandomInRangeForDay(99, 999, passedHours)),
        icons: sortRandomForDay(iconsNumbers)
          .array.slice(0, 4)
          .map(number => number + 1),
      }),
    );
  };
};
