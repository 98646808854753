export function allSettled(promises: Promise<unknown>[]): Promise<unknown[]> {
  return Promise.all(
    promises.map(p =>
      Promise.resolve(p).then(
        (
          value: unknown,
        ): {
          status: 'fulfilled';
          value: unknown;
        } => {
          return { status: 'fulfilled', value };
        },
        (
          reason,
        ): {
          status: 'rejected';
          reason: unknown;
        } => {
          return { status: 'rejected', reason };
        },
      ),
    ),
  );
}

export function makeCancelablePromise<Type>(promise: Promise<Type>): {
  promise: Promise<Type>;
  resolve: (value: Type) => unknown;
  reject: (reason?: unknown) => void;
  cancel: () => void;
} {
  let _resolve, _reject;

  const wrappedPromise = new Promise((resolve: (value: Type) => unknown, reject) => {
    _resolve = resolve;
    _reject = reject;
    promise && promise.then(resolve, reject);
  });

  return {
    promise: wrappedPromise,
    resolve: _resolve,
    reject: _reject,
    cancel() {
      _reject({ isCanceled: true });
    },
  };
}
