import React, { FC, memo } from 'react';
import { ImageSourcePropType, StyleSheet, TouchableOpacity, View, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import CHECK from 'assets/icons/check/check-gradient-gold.png';
import { DARK_BLUE_GREY, DARK_GREY_BLUE, DARK_TEAL, WHITE } from 'constants/colors';
import HighLightText from 'components/hight-light-text';
import { MESSENGERS } from 'constants/messengers';

interface Props {
  image: ImageSourcePropType;
  name: MESSENGERS;
  onPress: (name: MESSENGERS) => void;
  isActive?: boolean;
}

const SelectItem: FC<Props> = ({ image, name, onPress, isActive = false }) => {
  const title = `via **${name}**`;

  const handlePress = () => {
    onPress(name);
  };

  return (
    <TouchableOpacity style={styles.item} onPress={handlePress}>
      <LinearGradient style={styles.border} colors={isActive ? [DARK_GREY_BLUE, DARK_GREY_BLUE] : [WHITE, '#E5A87F']}>
        <View style={[styles.container, isActive && styles.active]}>
          <View style={styles.content}>
            <Image source={image} />
            <HighLightText style={styles.title} highlightedTextStyle={styles.highlightedTitle}>
              {title}
            </HighLightText>
          </View>
          <View style={styles.checkContainer}>{isActive && <Image source={CHECK} />}</View>
        </View>
      </LinearGradient>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    width: '100%',
    marginBottom: paddingVertical(15),
  },
  border: {
    borderRadius: sw(15),
  },
  container: {
    margin: 1,
    borderRadius: sw(15),
    backgroundColor: DARK_BLUE_GREY,
    paddingHorizontal: paddingHorizontal(15),
    paddingVertical: paddingVertical(10),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  active: {
    backgroundColor: DARK_GREY_BLUE,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: fs(16),
    color: WHITE,
    marginLeft: paddingHorizontal(10),
  },
  highlightedTitle: {
    fontWeight: '600',
    textTransform: 'capitalize',
  },
  checkContainer: {
    backgroundColor: DARK_TEAL,
    width: 30,
    height: 30,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default memo(SelectItem);
