import { PROFILE_TYPES } from 'store/profile/types';

import { State, ActionType, TYPES } from './types';

const initialState: State = {
  list: [],
  isServicesTabActive: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_SERVICES:
      return {
        ...state,
        list: action.payload,
      };
    case TYPES.SET_ACTIVE_SERVICES_TAB:
      return {
        ...state,
        isServicesTabActive: action.payload,
      };
    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;
    default:
      return state;
  }
}
