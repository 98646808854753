import { APP_ICONS_TYPES } from 'constants/app-icons';

import { CUSTOM_APP_TYPES, State, ActionType } from './types';

const initialState: State = {
  selectedIcon: APP_ICONS_TYPES.DEFAULT,
  isIconPro: false,
};

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
    case CUSTOM_APP_TYPES.SET_ICON:
      return {
        ...state,
        selectedIcon: action.payload,
        isIconPro: action.payload === APP_ICONS_TYPES.PRO,
      };
    default:
      return state;
  }
};
