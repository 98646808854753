import { DatingCalendar, DatingPredictions } from 'api/calendars/interfaces';

export enum TYPES {
  SET = 'DATING_CALENDAR/SET',
  SET_WIDGETS = 'DATING_CALENDAR/SET_WIDGETS',
}

export interface State {
  calendar: DatingCalendar;
  widgets: DatingPredictions;
}

interface Set {
  type: typeof TYPES.SET;
  payload: DatingCalendar;
}

interface SetWidgets {
  type: typeof TYPES.SET_WIDGETS;
  payload: DatingPredictions;
}

export type ActionTypes = Set | SetWidgets;
