import { useCallback, useEffect } from 'react';
import { BackHandler } from 'react-native';
import { useSelector } from 'react-redux';

import { RootState } from 'store';
import { SUBSCRIPTIONS } from 'constants/routes';

type BackHandlerType = () => boolean | null | undefined;

const useBackHandler = (backHandler?: BackHandlerType) => {
  const currentScreen = useSelector((state: RootState) => state.navigation.currentRoute);
  const handleBackHandler = useCallback(() => {
    if (SUBSCRIPTIONS.includes(currentScreen)) {
      return true;
    }

    return false;
  }, [currentScreen]);

  const handler = backHandler ? backHandler : handleBackHandler;

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handler);

    return () => {
      BackHandler.removeEventListener('hardwareBackPress', handler);
    };
  }, [handler]);
};

export default useBackHandler;
