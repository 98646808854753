import api from '..';

import { AstrologyServices } from './interfaces';

const ASTROLOGY_SERVICE = '/astrologer-service';

export const getAstrologerServices = (): Promise<AstrologyServices> => {
  return api.fetch(ASTROLOGY_SERVICE, {
    method: 'GET',
  });
};
