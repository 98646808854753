import React, { useEffect, createRef, FC, RefObject } from 'react';
import Lottie, { LottieComponentProps, LottieRefCurrentProps } from 'lottie-react';

interface Props extends LottieComponentProps {
  source: { uri?: string } | number;
  autoPlay?: boolean;
  speed?: number;
  ref?: RefObject<any>;
}

const LottieView: FC<Props> = ({ source, autoPlay, speed, ref, ...props }) => {
  const innerRef = createRef<LottieRefCurrentProps>();
  const currentRef = (!!ref ? ref : innerRef) as RefObject<LottieRefCurrentProps>;

  useEffect(() => {
    if (currentRef.current) {
      if (speed) {
        currentRef.current?.setSpeed(speed);
      }
    }
  }, [currentRef, speed]);

  return <Lottie {...props} lottieRef={currentRef} autoplay={autoPlay} animationData={source} />;
};

export default LottieView;
