import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  active: false,
  inited: false, // for current session; inited used for session increase and notification init
  timeActivated: 0,
  session: 0,
  activationSessionNumber: 0,
  showCountInCurrentSession: 0,
};

export default (state = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TYPES.ACTIVATE_PROMO_OFFER:
      return {
        ...state,
        active: true,
        inited: true,
        activationSessionNumber: state.session,
        showCountInCurrentSession: 0,
        timeActivated: +new Date(),
      };
    case TYPES.DEACTIVATE_PROMO_OFFER:
      return {
        ...state,
        active: false,
        timeActivated: 0,
        activationSessionNumber: 0,
        showCountInCurrentSession: 0
      };

    case TYPES.INCREASE_SESSION:
      return {
        ...state,
        inited: true,
        session: state.session + 1,
      };

    case TYPES.INCREASE_SHOW_COUNT:
      return {
        ...state,
        showCountInCurrentSession: state.showCountInCurrentSession + 1,
      };

    default:
      return state;
  }
};
