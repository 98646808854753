import { AstrologyService } from 'api/astrologer-services/interfaces';

import { ResetUserData } from '../types';

export enum TYPES {
  SET_SERVICES = 'ASTROLOGERS/SET_SERVICES',
  SET_ACTIVE_SERVICES_TAB = 'ASTROLOGERS/SET_ACTIVE_SERVICES_TAB',
}

export interface CurrencyConfig {
  price: number;
  activePrice: string;
}

export interface Service extends AstrologyService {
  price: number;
  activePrice: string;
  USD?: CurrencyConfig;
  EUR?: CurrencyConfig;
  CAD?: CurrencyConfig;
  AUD?: CurrencyConfig;
}

export interface State {
  list: Service[];
  isServicesTabActive: boolean;
}

interface SetServices {
  type: typeof TYPES.SET_SERVICES;
  payload: Service[];
}

interface SetActiveServicesTab {
  type: typeof TYPES.SET_ACTIVE_SERVICES_TAB;
  payload: boolean;
}

export type ActionType = SetServices | SetActiveServicesTab | ResetUserData;
