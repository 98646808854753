import { PROFILE_TYPES } from '../profile/types';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  badgesShowed: [],
  lastVisitedDailyDate: 0,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET_BADGE_SHOWED:
      return {
        ...state,
        badgesShowed: state.badgesShowed.concat(action.payload),
      };

    case TYPES.SET_LAST_VISITED_DAILY_DATE:
      return {
        ...state,
        lastVisitedDailyDate: action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return initialState;

    default:
      return state;
  }
}
