const IMAGE = 'image';
const VIDEO = 'video';
const SURVEY = 'survey';
const BINARY_SURVEY = 'binary-survey';
const POPUP = 'popup';

export const FORMAT = {
  IMAGE,
  VIDEO,
  SURVEY,
  BINARY_SURVEY,
  POPUP,
};

export const LIKE = 'like';
export const DISLIKE = 'dislike';
export const SHARE = 'share';
export const EMOTION = 'emotion';

export const BIRTH_CHART_CATEGORY_ID = 34;
