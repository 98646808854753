import api from 'api';

import { ADD_FREE_TIME } from './constants';

const addFreeTime = async (time: number, purchased: boolean): Promise<undefined> => {
  try {
    const isUnpaidReward = +!purchased || 0;
    const response = await api.fetch(ADD_FREE_TIME, {
      method: 'POST',
      data: {
        time,
        isUnpaidReward,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] addFreeTime', error);
  }
};

export default addFreeTime;
