import React, { FC } from 'react';
import { StyleProp, ViewStyle, StyleSheet, View, Image } from 'react-native';
import { sw, fs, paddingHorizontal, HEADER_OFFSET } from '@wowmaking/ui/src/utils';
import Animated, { useAnimatedStyle, useDerivedValue, withTiming, interpolate } from 'react-native-reanimated';

import { t } from 'localization';
import Text from 'components/text';
import * as COLORS from 'constants/colors';

import COPYBOARD_ICON from './images/ic-link.png';

const HEIGHT = sw(50);

interface Props {
  style?: StyleProp<ViewStyle>;
  isVisible?: boolean;
}

const ClipBoardTooltip: FC<Props> = ({ style, isVisible }) => {
  const animation = useDerivedValue(() => {
    return isVisible ? withTiming(1, { duration: 500 }) : withTiming(0, { duration: 500 });
  });

  const animatedStyle = useAnimatedStyle(() => {
    const translateY = interpolate(animation.value, [0, 1], [-HEIGHT, 0]);

    return {
      opacity: animation.value,
      transform: [{ translateY }],
    };
  });

  return (
    <Animated.View style={[styles.root, style, animatedStyle]} pointerEvents="none">
      <View style={styles.container}>
        <Image source={COPYBOARD_ICON} />
        <Text style={styles.text}>{t('PWA_INSTRUCTIONS_V2.COPIED')}</Text>
      </View>
    </Animated.View>
  );
};

export default ClipBoardTooltip;

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: HEADER_OFFSET,
    left: 0,
    right: 0,
    paddingHorizontal: paddingHorizontal(15),
    zIndex: 99999,
  },
  text: {
    fontSize: fs(15),
    color: COLORS.WHITE,
    marginLeft: paddingHorizontal(10),
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: HEIGHT,
    backgroundColor: COLORS.DARK_GREY_BLUE,
    borderRadius: sw(15),
    paddingHorizontal: paddingHorizontal(15),
  },
});
