import api from '..';

import { TarotCard, TarotCardOfTheDay, TarotCardOfTheDayHistoryItem } from './interfaces';

const TAROT = '/tarot';

export const getTarot = (count = 0): Promise<TarotCard[]> => {
  return api.fetch(`${TAROT}/cards?count=${count}&deck=v2`, {
    method: 'GET',
  });
};

export const getCardsOfTheDayHistory = (): Promise<TarotCardOfTheDayHistoryItem[]> => {
  return api.fetch(`${TAROT}/history`, {
    method: 'GET',
  });
};

export const addCardOfTheDay = (date: string, cardId: number): Promise<TarotCardOfTheDay> => {
  return api.fetch(`${TAROT}/add-daily-cards`, {
    method: 'POST',
    data: {
      date,
      data: { cardID: cardId },
    },
  });
};
