export const FIRST_SESSION_FIRST_NOTIFICATION_ID = 'FIRST_SESSION_FIRST_NOTIFICATION_ID';
export const FIRST_SESSION_SECOND_NOTIFICATION_ID = 'FIRST_SESSION_SECOND_NOTIFICATION_ID';
export const FIRST_SESSION_THIRD_NOTIFICATION_ID = 'FIRST_SESSION_THIRD_NOTIFICATION_ID';
export const ROLL_UP_ONBOARDING_PROFILE_NOTIFICATION_ID = 'ROLL_UP_ONBOARDING_PROFILE_NOTIFICATION_ID';
export const ROLL_UP_DASHBOARD_AFTER_ONBOARDING_NOTIFICATION_ID = 'ROLL_UP_DASHBOARD_AFTER_ONBOARDING_NOTIFICATION_ID';
export const ROLL_UP_DASHBOARD_AFTER_USE_NOTIFICATION_ID = 'ROLL_UP_DASHBOARD_AFTER_USE_NOTIFICATION_ID';
export const HOROSCOPE_NOTIFICATION_ID = 'HOROSCOPE_NOTIFICATION_ID';
export const FUTURE_HOROSCOPE_NOTIFICATION_ID = 'FUTURE_NOTIFICATION_ID';
export const EVERYDAY_NOTIFICATION_ID = 'EVERYDAY';

export enum NOTIFICATION_UNIQ_ID {
  HOROSCOPE = 10001,
  FUTURE_HOROSCOPE = 10002,
  FIRST_SESSION_FIRST = 10003,
  FIRST_SESSION_SECOND = 10004,
  FIRST_SESSION_THIRD = 10005,
  ROLL_UP_ONBOARDING_PROFILE = 10006,
  ROLL_UP_DASHBOARD_AFTER_ONBOARDING = 10007,
  ROLL_UP_DASHBOARD_AFTER_USE = 10008,
  EVERYDAY = 100,
}

export const ANDROID_DEFAULT_CHANNEL_ID = 'astro_notification_channel_id';
export const ANDROID_DEFAULT_CHANNEL_NAME = 'Default Channel';
export const ANDROID_DEFAULT_CHANNEL_DESC = 'App default channel';

// statuses for pushesBehavior
export enum PUSH_BEHAVIOR {
  NONE = 'none',
  PAID = 'paid',
  FREE = 'free',
  ALL = 'all',
}
