import { some, takeRight } from 'lodash';

import { MAX_NUMBER_OF_ACTIVE_TODO } from 'screens/astro-calendar/constants';

import { TYPES, State, ActionTypes } from './types';

const initialState: State = {
  data: {},
  activeNotification: null,
  activeTodos: [],
  pendingTodos: [],
  retrogradesContent: [],
  tutorialShown: false,
  originPlace: null,
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.UPDATE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case TYPES.SET_RETROGRADES_CONTENT:
      return {
        ...state,
        retrogradesContent: action.payload,
      };
    case TYPES.SET_TUTORIAL_SHOWN:
      return {
        ...state,
        tutorialShown: true,
      };
    case TYPES.ADD_ACTIVE_TODO:
      return {
        ...state,
        activeTodos: takeRight([...state.activeTodos, action.payload], MAX_NUMBER_OF_ACTIVE_TODO),
      };
    case TYPES.RESET_ACTIVE_TODOS:
      return {
        ...state,
        activeTodos: initialState.activeTodos,
      };
    case TYPES.ADD_PENDING_TODO: {
      if (some(state.pendingTodos, todo => todo.id === action.payload.id)) {
        return state;
      }
      return {
        ...state,
        pendingTodos: [...state.pendingTodos, action.payload],
      };
    }
    case TYPES.DELETE_PENDING_TODO:
      return {
        ...state,
        pendingTodos: state.pendingTodos.filter(todo => todo.id !== action.payload.id),
      };
    case TYPES.SET_ACTIVE_NOTIFICATION:
      return {
        ...state,
        activeNotification: action.payload,
      };
    case TYPES.SET_ORIGIN_PLACE:
      return {
        ...state,
        originPlace: action.payload,
      };
    default:
      return state;
  }
}
