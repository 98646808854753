import React, { memo } from 'react';
import type { FC } from 'react';
import { Text } from 'react-native';
import type { StyleProp, TextStyle, TextProps } from 'react-native';

import type { Chunk } from './types';
import { findAll } from './utils';

interface Props extends TextProps {
  textToHighlight: string;
  searchWords: string[];
  highlightStyle: StyleProp<TextStyle>;
  style?: StyleProp<TextStyle>;
  autoEscape?: boolean;
  sanitize?: (string: string) => string;
}

/**
 * @param autoEscape - Escape characters which are meaningful in regular expressions
 * @param highlightStyle - Styles applied to highlighted text
 * @param sanitize - Process each search word and text to highlight before
 *   comparing (eg remove accents); signature (text: string): string
 * @param searchWords - Array of search words
 * @param style - Styles applied to the text wrapper
 * @param textToHighlight - Text to highlight matches in
 */

const TextHighlighter: FC<Props> = ({ autoEscape, highlightStyle, sanitize, searchWords, style = null, textToHighlight, ...props }) => {
  const chunks = findAll({
    textToHighlight,
    searchWords,
    sanitize,
    autoEscape,
  });

  return (
    <Text style={style} {...props}>
      {chunks.map((chunk: Chunk, index) => {
        const text = textToHighlight.substring(chunk.start, chunk.end);

        return !chunk.highlight ? (
          text
        ) : (
          <Text key={index} style={chunk.highlight && highlightStyle}>
            {text}
          </Text>
        );
      })}
    </Text>
  );
};

export default memo(TextHighlighter);
