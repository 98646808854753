export enum AUTH_TYPES {
  SET_USER_CREDENTIALS = 'AUTH/SET_USER_CREDENTIALS',
  RESET_DATA = 'AUTH/RESET_DATA',
  SET_WEB_UUID = 'AUTH/SET_WEB_UUID',
}

export interface UserCredentials {
  id: string;
  accessToken: string;
}

export interface State extends UserCredentials {
  webUUID: string;
  isSuccessful: boolean;
}

interface SetUserCredentials {
  type: typeof AUTH_TYPES.SET_USER_CREDENTIALS;
  payload: UserCredentials;
}

interface SetWebUUID {
  type: typeof AUTH_TYPES.SET_WEB_UUID;
  payload: string;
}

interface ResetData {
  type: typeof AUTH_TYPES.RESET_DATA;
}

export type ActionType = SetUserCredentials | SetWebUUID | ResetData;
