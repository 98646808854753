import { AUTH_TYPES, State, ActionType } from './types';

const initialState: State = {
  id: '',
  webUUID: '',
  accessToken: '',
  isSuccessful: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case AUTH_TYPES.SET_USER_CREDENTIALS:
      return {
        ...state,
        id: action.payload.id,
        accessToken: action.payload.accessToken,
        isSuccessful: true,
      };
    case AUTH_TYPES.SET_WEB_UUID:
      return {
        ...state,
        webUUID: action.payload,
      };
    case AUTH_TYPES.RESET_DATA:
      return {
        ...initialState,
      };
  }

  return state;
}
