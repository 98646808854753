export const INDIAN_MOON = 'INDIAN_MOON';
export const INDIAN_SUN = 'INDIAN_SUN';
export const ZODIAC = 'ZODIAC';
export const CHINESE = 'CHINESE';
export const DRUID = 'DRUID';
export const MAYAN = 'MAYAN';

export enum HoroscopeTypes {
  indian_moon = 'indian_moon',
  indian_sun = 'indian_sun',
  zodiac = 'zodiac',
  chinese = 'chinese',
  druid = 'druid',
  mayan = 'mayan',
}
