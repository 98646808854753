import { SEEDS, TRAITS, LEFT_HAND_PERCENTAGE } from 'constants/palm-reading-daily';
import { getRandomInRange } from 'utils/seedrandom';

export const percentageToDescVariant = percentage => {
  if (percentage >= 91 && percentage <= 95) {
    return '5';
  }
  if (percentage >= 86 && percentage <= 90) {
    return '4';
  }
  if (percentage >= 81 && percentage <= 85) {
    return '3';
  }
  if (percentage >= 76 && percentage <= 80) {
    return '2';
  }
  if (percentage >= 71 && percentage <= 75) {
    return '1';
  }

  return '0';
};
export const percentageToHealthDescVariant = percentage => {
  if (percentage >= 90 && percentage <= 95) {
    return '4';
  }
  if (percentage >= 85 && percentage <= 89) {
    return '3';
  }
  if (percentage >= 76 && percentage <= 84) {
    return '2';
  }
  if (percentage >= 71 && percentage <= 75) {
    return '1';
  }

  return '0';
};

export const calcLanguageDistribution = seed => {
  let total = 100;

  const largestIndex = Math.round(getRandomInRange(0, 4, seed + SEEDS.LANGUAGE_LARGEST_INDEX));
  const largestPercentage = Math.round(getRandomInRange(35, 50, seed + SEEDS.LANGUAGE_LARGEST_PERCENTAGE));

  const distribution = [];

  let i = 0;
  while (i < 4) {
    distribution[i] = i === largestIndex ? largestPercentage : Math.round(total * 0.2);
    total -= distribution[i];
    i++;
  }

  distribution.push(total);

  return distribution;
};

export const calcDominantTraits = seed => {
  const logic = getRandomInRange(0, 100, seed + SEEDS.LOGIC_INTUITION) > 50;
  const ethics = getRandomInRange(0, 100, seed + SEEDS.ETHICS_SENSATION) > 50;
  const introverted = getRandomInRange(0, 100, seed + SEEDS.INTROVERTED_EXTROVERTED) > 50;

  return {
    [TRAITS.LOGIC]: logic,
    [TRAITS.ETHICS]: ethics,
    [TRAITS.INTROVERTED]: introverted,
    [TRAITS.INTUITION]: !logic,
    [TRAITS.SENSATION]: !ethics,
    [TRAITS.EXTROVERTED]: !introverted,
  };
};

export const getRandomPercentage = customSeed => {
  return Math.round(getRandomInRange(LEFT_HAND_PERCENTAGE.MIN, LEFT_HAND_PERCENTAGE.MAX, customSeed));
};
