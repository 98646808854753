import { ImageSourcePropType } from 'react-native';

import BIORHYTHMS_IMAGE from '../../assets/images/banners/biorhythms.png';
import BIRTH_CHART_IMAGE from '../../assets/images/banners/birth-chart.png';
import DATING_IMAGE from '../../assets/images/banners/dating.png';
import FIND_TRUE_LOVE_IMAGE from '../../assets/images/banners/find-true-love.png';
import MARRIAGE_COMPATIBILITY_IMAGE from '../../assets/images/banners/marriage-compatibility.png';
import PALM_READING_IMAGE from '../../assets/images/banners/palm-reading.png';
import ZODIAC_COMPATIBILITY_IMAGE from '../../assets/images/banners/zodiac-compatibility.png';

const TEXT = 'text';
const STRONG = 'strong';
const HEADER = 'header';
const LIST = 'list';
const GALLERY = 'gallery';
const IMAGE = 'image';
const RECOMMENDATION = 'recommendation';
const BOT = 'bot';
const ARTICLE = 'article';
const VIDEO = 'video';
const PODCAST = 'podcast';
const ANSWERS = 'answers';
const RATE = 'rate';
const TYPING = 'typing';

export const CONTENT_TYPE = {
  TEXT,
  STRONG,
  HEADER,
  LIST,
  GALLERY,
  IMAGE,
  RECOMMENDATION,
};

export const SESSION_TYPES = {
  BOT,
  ARTICLE,
  VIDEO,
  PODCAST,
};

export const MESSAGE_TYPES = {
  IMAGE,
  VIDEO,
  TEXT,
  ANSWERS,
  RATE,
  TYPING,
};

export const SESSION_TYPE_LIST = [BOT, ARTICLE, VIDEO, PODCAST];

interface GUIDES_IDS {
  [key: string]: string;
}

interface GUIDES_IMAGES {
  [key: string]: ImageSourcePropType;
}

export enum BANNERS {
  ZODIAC_COMPATIBILITY = 'ZODIAC_COMPATIBILITY',
  BIRTH_CHART = 'BIRTH_CHART',
  BIORHYTHMS = 'BIORHYTHMS',
  DATING = 'DATING',
  MARRIAGE_COMPATIBILITY = 'MARRIAGE_COMPATIBILITY',
  FIND_TRUE_LOVE = 'FIND_TRUE_LOVE',
  PALM_READING = 'PALM_READING',
}

export const GUIDES_BANNERS_IMAGES: GUIDES_IMAGES = {
  [BANNERS.ZODIAC_COMPATIBILITY]: ZODIAC_COMPATIBILITY_IMAGE,
  [BANNERS.BIRTH_CHART]: BIRTH_CHART_IMAGE,
  [BANNERS.BIORHYTHMS]: BIORHYTHMS_IMAGE,
  [BANNERS.DATING]: DATING_IMAGE,
  [BANNERS.MARRIAGE_COMPATIBILITY]: MARRIAGE_COMPATIBILITY_IMAGE,
  [BANNERS.FIND_TRUE_LOVE]: FIND_TRUE_LOVE_IMAGE,
  [BANNERS.PALM_READING]: PALM_READING_IMAGE,
};

export const GUIDES_BANNERS_IDS: GUIDES_IDS = {
  [BANNERS.ZODIAC_COMPATIBILITY]: '51',
  [BANNERS.BIRTH_CHART]: '49',
  [BANNERS.BIORHYTHMS]: '52',
  [BANNERS.DATING]: '9',
  [BANNERS.MARRIAGE_COMPATIBILITY]: '50',
  [BANNERS.FIND_TRUE_LOVE]: '13',
  [BANNERS.PALM_READING]: '53',
};
