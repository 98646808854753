import { createAction } from 'redux-actions';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Platform } from 'react-native';

import { addCardOfTheDay, getCardsOfTheDayHistory, getTarot } from 'api/tarot';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { TAROT_LAYOUT_IDS } from 'screens/tarot/constants';
import { getCardsLayoutData } from 'screens/tarot/utils';
import CacheManager from 'utils/cache-manager';

import {
  SET_PENDING,
  SET_CARDS,
  SET_CARDS_OF_THE_DAY_HISTORY,
  SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR,
  RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR,
  SET_SELECTED_CARDS,
  RESET_CARDS,
  SET_CARDS_FETCH_DATE,
  SET_FORCE_RELOAD,
  SET_IMAGES_CACHED,
} from './types';

const setCards = createAction(SET_CARDS);
const setCardsOfTheDayHistory = createAction(SET_CARDS_OF_THE_DAY_HISTORY);
const setCardsOfTheDayRandomizationsAccumulator = createAction(SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR);
const resetCardsOfTheDayRandomizationsAccumulator = createAction(RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR);
const setCardsFetchDate = createAction(SET_CARDS_FETCH_DATE);
const setSelectedCards = createAction(SET_SELECTED_CARDS);
const setImagesCached = createAction(SET_IMAGES_CACHED);
const setPending = createAction(SET_PENDING);
export const setForceReload = createAction(SET_FORCE_RELOAD);
export const resetTarotCards = createAction(RESET_CARDS);

const COUNT_UNIQUE_CARDS_OF_THE_DAY = 15;

export const initTarot = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    await Promise.all([dispatch(loadCards()), dispatch(loadCardsOfTheDayHistory())]);
  };
};

export const loadCards = (): AppThunk => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      tarot: { cardsFetchDate, forceReload },
    } = getState();
    const getCards = !cardsFetchDate || dayjs().isAfter(dayjs(cardsFetchDate), 'days') || forceReload;

    if (getCards) {
      dispatch(setPending(true));

      try {
        const data = await getTarot();
        dispatch(setCards(data));
      } catch (error) {
        console.log('[LOAD TAROT CARDS ERROR]: ', error);
      } finally {
        dispatch(setPending(false));
      }

      dispatch(setCardsFetchDate(dayjs().format()));
    }
  };
};

export const loadCardsOfTheDayHistory = (): AppThunk => {
  return async (dispatch: AppDispatch) => {
    dispatch(setPending(true));

    try {
      const data = await getCardsOfTheDayHistory();
      dispatch(setCardsOfTheDayHistory(data));
    } catch (error) {
      console.log('[LOAD TAROT HISTORY CARDS ERROR]: ', error);
    } finally {
      dispatch(setPending(false));
    }
  };
};

export const generateCardsForLayout = (cardsLayout: TAROT_LAYOUT_IDS): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const cards = getState().tarot.cards;
    const layoutScheme = getCardsLayoutData(cardsLayout).layoutScheme;
    const count = _.flattenDeep(layoutScheme).length;
    const cardIds = _.sampleSize(cards, count).map(card => card.id);

    dispatch(setSelectedCards({ layout: cardsLayout, cards: cardIds }));
  };
};

export const generateCardOfTheDay = (date?: string): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      tarot: { cards, cardsOfTheDayRandAcc, cardsOfTheDayHistory },
    } = getState();

    const cardIds = cards.map(card => card.id);
    const newId = _.sample(_.difference(cardIds, cardsOfTheDayRandAcc)) || cardIds[0];
    const newDate = date ?? dayjs().format('YYYY-MM-DD');

    dispatch(
      cardsOfTheDayRandAcc.length < COUNT_UNIQUE_CARDS_OF_THE_DAY
        ? setCardsOfTheDayRandomizationsAccumulator(newId)
        : resetCardsOfTheDayRandomizationsAccumulator(),
    );

    addCardOfTheDay(newDate, newId);
    dispatch(
      setCardsOfTheDayHistory([
        ...cardsOfTheDayHistory,
        {
          date: newDate,
          data: {
            cardID: newId,
          },
        },
      ]),
    );

    dispatch(setSelectedCards({ layout: TAROT_LAYOUT_IDS.CARD_OF_THE_DAY, cards: [newId] }));
  };
};

export const cacheCardsImages = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      tarot: { cards, imagesCached },
    } = getState();

    if (imagesCached) {
      return;
    }

    cards?.map(card => {
      const url = card?.tarot_image?.image_path;

      if (Platform.OS === 'web') {
        const img = new Image();
        img.src = url;
      } else {
        CacheManager.downloadAndCacheUrl(url, {
          cacheLocation: CacheManager.CACHED_LOCATIONS.IMAGES,
        });
      }
    });

    dispatch(setImagesCached());
  };
};
