import { getPlatform } from 'utils/pwa';

import api from '..';

import { Subscribe } from './interfaces';

const MESSENGERS = '/notifications/settings';
const SET_FCM_TOKEN = '/firebase-notifications/track-device';

export const subscribeOnEmailNewsletter = (params: Subscribe): Promise<any> => {
  return api.fetch(MESSENGERS, {
    method: 'POST',
    data: {
      ...params,
      whatsapp_subscribe: null,
    },
  });
};

export const setFCMToken = (token: string): Promise<any> => {
  if (!token) {
    return Promise.reject('No FCM token given');
  }

  const platform_type = getPlatform();
  return api.fetch(SET_FCM_TOKEN, {
    method: 'POST',
    data: {
      token,
      platform_type,
    },
  });
};
