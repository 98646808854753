import { Address } from 'api/addresses/interfaces';

interface AddressItem {
  name: string;
  lat: Address['lat'];
  lon: Address['lon'];
}

export enum TYPES {
  SET = 'ADDRESSES/SET',
  LOADING = 'ADDRESSES/LOADING',
  CLEAR = 'ADDRESSES/CLEAR',
}

export interface State {
  addressesList: AddressItem[];
  loading: boolean;
}

interface SetList {
  type: typeof TYPES.SET;
  payload: AddressItem[];
}

interface SetLoading {
  type: typeof TYPES.LOADING;
}

interface Clear {
  type: typeof TYPES.CLEAR;
}

export type ActionTypes = SetList | SetLoading | Clear;
