import * as ROUTES from 'constants/routes';
import * as TRIGGERS from 'constants/monetization-triggers';
import { CHINESE, INDIAN_MOON } from 'constants/horoscopes-names';
import { TAROT_LAYOUT_IDS } from 'screens/tarot/constants';
import { getISODate } from 'utils/date';

import TUTORIAL_ADVISOR_ICON from '../images/tutorial-advisor-icon.png';
import type { AstroCalendarDataNavigateTodo, AstroCalendarNavigateTodoData, AstroCalendarOtherTodoData } from '../interfaces';
import ADVISORS_TODO_ICON from '../images/todos/advisors.png';
import BEST_MATCHES_TODO_ICON from '../images/todos/best_matches.png';
import BIORHYTHMS_TODO_ICON from '../images/todos/biorhythms.png';
import CHINESE_HOROSCOPE_TODO_ICON from '../images/todos/chinese_horoscope.png';
import DATING_CALENDAR_TODO_ICON from '../images/todos/dating_calendar.png';
import INDIAN_LUNAR_HOROSCOPE_TODO_ICON from '../images/todos/indian_lunar_horoscope.png';
import LUNAR_CALENDAR_TODO_ICON from '../images/todos/lunar_calendar.png';
import PALMISTRY_ANALYSIS_TODO_ICON from '../images/todos/palmistry_analysis.png';
import PLANETS_AND_HOUSES_TODO_ICON from '../images/todos/planets_and_houses.png';
import SIGN_COMPATIBILITY_TODO_ICON from '../images/todos/sign_compatibility.png';
import TAROT_CARD_OF_THE_DAY_TODO_ICON from '../images/todos/tarot_card_of_the_day.png';
import TAROT_NEAR_FUTURE_TODO_ICON from '../images/todos/tarot_near_future.png';
import TAROT_READINGS_TODO_ICON from '../images/todos/tarot_readings.png';
import TODAY_MATCHES_TODO_ICON from '../images/todos/today_matches.png';
import TRANSITS_TODO_ICON from '../images/todos/transits.png';
import RATE_US_TODO_ICON from '../images/todos/rate_us.png';
import DAILY_FORECAST_TODO_ICON from '../images/todos/daily_forecast.png';
import PWA_TODO_ICON from '../images/todos/pwa.png';

export enum ASTRO_CALENDAR_TODO_TYPE {
  NAVIGATE = 'navigate',
  RATE_US = 'rate_us',
  DAILY_FORECAST = 'daily_forecast',
  PWA = 'pwa',
}

export enum ASTRO_CALENDAR_NAVIGATE_TODO_ID {
  ADVISORS = 'advisors',
  BEST_MATCHES = 'best_matches',
  BIORHYTHMS = 'biorhythms',
  CHINESE_HOROSCOPE = 'chinese_horoscope',
  DATING_CALENDAR = 'dating_calendar',
  INDIAN_LUNAR_HOROSCOPE = 'indian_lunar_horoscope',
  LUNAR_CALENDAR = 'lunar_calendar',
  PALMISTRY_ANALYSIS = 'palmistry_analysis',
  PLANETS_AND_HOUSES = 'planets_and_houses',
  SIGN_COMPATIBILITY = 'sign_compatibility',
  TAROT_CARD_OF_THE_DAY = 'tarot_card_of_the_day',
  TAROT_NEAR_FUTURE = 'tarot_near_future',
  TAROT_READINGS = 'tarot_readings',
  TODAY_MATCHES = 'today_matches',
  TRANSITS = 'transits',
}

// Mock data for Astro calendar tutorial
export const TUTORIAL_TODO_DATA: AstroCalendarDataNavigateTodo[] = [
  {
    date: getISODate(),
    type: ASTRO_CALENDAR_TODO_TYPE.NAVIGATE,
    id: '1',
    contentId: ASTRO_CALENDAR_NAVIGATE_TODO_ID.ADVISORS,
    title: 'TODO_TITLE',
    subtitle: 'TODO_SUBTITLE',
    img: TUTORIAL_ADVISOR_ICON,
    completed: false,
  },
];

export const ASTRO_CALENDAR_NAVIGATE_TODO_DATA: AstroCalendarNavigateTodoData = {
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.ADVISORS]: {
    image: ADVISORS_TODO_ICON,
    navigation: {
      route: ROUTES.ADVISORS_QUESTIONS,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.BEST_MATCHES]: {
    image: BEST_MATCHES_TODO_ICON,
    navigation: {
      route: ROUTES.BEST_MATCHES,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.BIORHYTHMS]: {
    image: BIORHYTHMS_TODO_ICON,
    navigation: {
      route: ROUTES.BIORHYTHM,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.CHINESE_HOROSCOPE]: {
    image: CHINESE_HOROSCOPE_TODO_ICON,
    navigation: {
      route: ROUTES.HOROSCOPE,
      params: {
        horoscopeName: CHINESE,
        trigger: TRIGGERS.ALT_HOROSCOPES_CHINESE,
      },
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.DATING_CALENDAR]: {
    image: DATING_CALENDAR_TODO_ICON,
    navigation: {
      route: ROUTES.DATING_CALENDAR,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.INDIAN_LUNAR_HOROSCOPE]: {
    image: INDIAN_LUNAR_HOROSCOPE_TODO_ICON,
    navigation: {
      route: ROUTES.HOROSCOPE,
      params: {
        horoscopeName: INDIAN_MOON,
        trigger: TRIGGERS.ALT_HOROSCOPES_INDIAN_LUNAR,
      },
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.LUNAR_CALENDAR]: {
    image: LUNAR_CALENDAR_TODO_ICON,
    navigation: {
      route: ROUTES.LUNAR_CALENDAR,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.PALMISTRY_ANALYSIS]: {
    image: PALMISTRY_ANALYSIS_TODO_ICON,
    navigation: {
      route: ROUTES.PALM_READING_DAILY,
      params: {
        openTodayTab: false,
      },
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.PLANETS_AND_HOUSES]: {
    image: PLANETS_AND_HOUSES_TODO_ICON,
    navigation: {
      route: ROUTES.BIRTH_CHART,
      params: {
        screen: ROUTES.BIRTH_CHART_DASHBOARD,
        params: {
          screen: ROUTES.BIRTH_CHART_CHARTS,
          params: {
            place: 'astro_calendar',
          },
        },
      },
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.SIGN_COMPATIBILITY]: {
    image: SIGN_COMPATIBILITY_TODO_ICON,
    navigation: {
      route: ROUTES.COMPATIBILITY,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_CARD_OF_THE_DAY]: {
    image: TAROT_CARD_OF_THE_DAY_TODO_ICON,
    navigation: {
      route: ROUTES.TAROT_CALENDAR,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_NEAR_FUTURE]: {
    image: TAROT_NEAR_FUTURE_TODO_ICON,
    navigation: {
      route: ROUTES.TAROT_LAYOUT,
      params: {
        layout: TAROT_LAYOUT_IDS.NEAR_FUTURE,
      },
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.TAROT_READINGS]: {
    image: TAROT_READINGS_TODO_ICON,
    navigation: {
      route: ROUTES.TAROT,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.TODAY_MATCHES]: {
    image: TODAY_MATCHES_TODO_ICON,
    navigation: {
      route: ROUTES.COMPATIBILITY_DASHBOARD,
      params: {},
    },
  },
  [ASTRO_CALENDAR_NAVIGATE_TODO_ID.TRANSITS]: {
    image: TRANSITS_TODO_ICON,
    navigation: {
      route: ROUTES.BIRTH_CHART,
      params: {
        screen: ROUTES.BIRTH_CHART_DASHBOARD,
        params: {
          screen: ROUTES.BIRTH_CHART_TRANSITS,
          params: {
            place: 'astro_calendar',
          },
        },
      },
    },
  },
};

export const ASTRO_CALENDAR_OTHER_TODO_DATA: AstroCalendarOtherTodoData = {
  [ASTRO_CALENDAR_TODO_TYPE.RATE_US]: { image: RATE_US_TODO_ICON },
  [ASTRO_CALENDAR_TODO_TYPE.DAILY_FORECAST]: { image: DAILY_FORECAST_TODO_ICON },
  [ASTRO_CALENDAR_TODO_TYPE.PWA]: { image: PWA_TODO_ICON },
};

export const MAX_NUMBER_OF_ACTIVE_TODO = 2;
