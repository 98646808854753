import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {ActivityIndicator, StyleSheet, Platform} from 'react-native';

import {BEIGE} from '../constants/colors';

export default class AI extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    style: PropTypes.any,
    color: PropTypes.string,
  };

  static defaultProps = {
    size: Platform.OS === 'ios' ? 'large' : 45,
    style: {},
    color: BEIGE,
  };

  render() {
    const {size, style, color} = this.props;
    return <ActivityIndicator size={size} style={[styles.root, style]} color={color} />;
  }
}

const styles = StyleSheet.create({
  root: {
    flexGrow: 100,
  },
});
