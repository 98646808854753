import api from 'api';
import { TEMPLATE_IDS } from 'screens/advisors/constants';

import { Astrologist, USE_TEMPLATE } from './constants';

const useTemplate = async (
  id: Astrologist['astrologer_id'],
  templateId: TEMPLATE_IDS,
  params?: { [key: string]: string | number },
  useDelay: boolean = false,
  hiddenMessage: string = '',
): Promise<void> => {
  const data = {
    astrologer_id: id,
    template_id: templateId,
    useDelay,
    params,
    hiddenMessage: hiddenMessage?.length ? hiddenMessage : undefined,
  };

  try {
    await api.fetch(USE_TEMPLATE, {
      method: 'POST',
      data,
    });
  } catch (error) {
    console.log('ERROR [Astrologist chat] useTemplate', error);
  }
};

export default useTemplate;
