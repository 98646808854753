import { BestMatches } from 'api/best-matches/interfaces';

export enum TYPES {
  SET = 'BEST_MATCHES/SET',
}

export interface State {
  bestMatches: BestMatches;
}

interface Set {
  type: typeof TYPES.SET;
  payload: BestMatches;
}

export type ActionTypes = Set;
