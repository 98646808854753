import { Platform } from 'react-native';

import { BUNDLE_ID } from './general';

export const KEY = Platform.select({
  ios: 'dffda82599a341fe83a899d135928763',
  android:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAlVGJ8GVVvyftaiENcEbVXBsugHdiOnvoXphRmTLgQ2gaLydCFi9aYqpHVv/V3fE61iPdmlN6BLmbdTNzDIrQP2Ql/rJJ/PmBKWtqydH9PqesspnEiuGfY51uveIH4ff7DmjMXdoNabOUC1phpRCUfhmGZQKfpTynLc//cCHuurxpT3cc4s/4IrcAuMlvgWhoBrThxRkQMK5+kmrwanyRNhYdZYV4ivt/LGs3N1C0jYeygOigvczAEc4MNuSBXlY1lRKz+Rov0tnEcaY4ivMVC+4s4PQ2rMigR/OI4r5V53U9dBSDJdnv6iybAcywEUsOte63kA+AIOw9OvyZU3v3cQIDAQAB',
});

export const FAMILY_POSTFIX = '.fm';
export const SKU = BUNDLE_ID + '.premium';
export const PRO = BUNDLE_ID + '.pro';
export const OFFER = BUNDLE_ID + '.promotional.offer';

export const WEEK = SKU + '.w.a';
export const WEEK_A = SKU + '.weekly.a';
export const WEEK_B = SKU + '.w.b';
export const WEEK_G = SKU + '.w.g';
export const WEEK_N = SKU + '.w.n';
export const WEEK_H = SKU + '.w.h';
export const WEEK_F = SKU + '.w.f';
export const WEEK_E = SKU + '.w.e';
export const WEEK_L = SKU + '.w.l';
export const MONTH = SKU + '.m.a';
export const MONTH_C = SKU + '.m.c';
export const MONTH_D = SKU + '.m.d';
export const MONTH_F = SKU + '.m.f';
export const YEAR = SKU + '.y.a';
export const YEAR_E = SKU + '.y.e';
export const YEAR_F = SKU + '.y.f';
export const YEAR_D = SKU + '.y.d';
export const YEAR_C = SKU + '.y.c';
export const YEAR_H = SKU + '.q.b';
export const FAMILY_WEEK = SKU + `.w${FAMILY_POSTFIX}.a`;
export const FAMILY_MONTH = SKU + `.m${FAMILY_POSTFIX}.a`;
export const FAMILY_YEAR = SKU + `.y${FAMILY_POSTFIX}.a`;
export const LIFETIME = SKU + '.lifetime.c';
export const PRO_W = SKU + '.w.pro';
export const PRO_W_A = SKU + '.w.pro.a';

export const OFFERS = {
  T: OFFER + '.t',
  T_A: OFFER + '.t.a',
  T_B: OFFER + '.t.b',
};

// com.horoastroline.premium.w.pro

export const OFFERS_BY_PRODUCT = {
  PRO_W: [OFFERS.T, OFFERS.T_A, OFFERS.T_B],
};

export const FAMILY_SUBS = Platform.OS === 'ios' ? [FAMILY_WEEK, FAMILY_MONTH, FAMILY_YEAR] : [];

export const SUBSCRIPTION_IDS = [
  WEEK,
  WEEK_G,
  WEEK_A,
  WEEK_N,
  WEEK_H,
  WEEK_F,
  WEEK_E,
  WEEK_L,
  MONTH,
  MONTH_C,
  MONTH_D,
  MONTH_F,
  YEAR_E,
  YEAR_D,
  YEAR_C,
  YEAR_H,
  YEAR_F,
  PRO_W,
  ...FAMILY_SUBS,
];

export const PRODUCT_IDS = [LIFETIME];
