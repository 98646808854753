import React, { FC } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { createShimmerPlaceholder } from 'react-native-shimmer-placeholder';

import * as COLORS from 'constants/colors';

const ShimmerPlaceHolder = createShimmerPlaceholder(LinearGradient);

type Props = {
  colors?: string[];
  style?: StyleProp<ViewStyle>;
  visible?: boolean;
  duration?: number;
  children?: any;
};

const Shimmer: FC<Props> = ({
  style,
  colors = [COLORS.DENIM, COLORS.DARK_GREY_BLUE],
  children,
  visible,
  duration,
}) => {
  if (children) {
    return (
      <ShimmerPlaceHolder
        style={[styles.root, style]}
        visible={visible}
        shimmerColors={colors}
        duration={duration}
        width={350}>
        {children}
      </ShimmerPlaceHolder>
    );
  } else {
    return (
      <ShimmerPlaceHolder
        style={[styles.root, style]}
        shimmerColors={colors}
        duration={duration}
        width={350}
      />
    );
  }
};

export default Shimmer;

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
  },
});
