import moment from 'moment';

import 'moment/locale/ar';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ja';
import 'moment/locale/pt-br';

export const setMomentLocale = (lng: string) => {
  moment.locale(lng);

  moment.updateLocale(
    lng,
    /^es/i.test(lng)
      ? {
          LT: 'H:mm',
          LTS: 'H:mm:ss',
          L: 'DD/MM/YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D [de] MMMM [de] YYYY H:mm',
          LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm',
        }
      : null,
  );
};
