import { Biorhythms } from 'api/biorhythms/interfaces';

export enum TYPES {
  SET = 'SET_BIORHYTHMS/SET',
}

export interface State {
  biorhythms: Biorhythms;
}

interface Set {
  type: typeof TYPES.SET;
  payload: Biorhythms;
}

export type ActionTypes = Set;
