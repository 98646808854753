import React, { FC } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Modal from 'react-native-modal';
import { useDispatch, useSelector } from 'react-redux';
import { paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import { AppDispatch, RootState } from 'store';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { rate } from 'store/rate-us/actions';
import { closeCurrentModal } from 'store/modals/actions';
import { addActiveTodo } from 'store/astro-calendar/actions';
import { FEATURES } from 'store/rate-us/types';
import { ASTRO_CALENDAR_TODO_TYPE } from 'screens/astro-calendar/constants';

import Close from './images/close.svg';

import RateUsAfterPurchase from './index';

interface Props {}

const RateUsModal: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isVisible = useSelector((state: RootState) => state.modals.activeModal === MODALS.RATE_US);
  const modalParams = useSelector((state: RootState) => state.modals.params);

  const handleModalClose = () => dispatch(closeCurrentModal());

  const handleRate = (rating: number) => {
    dispatch(rate(rating));

    if (modalParams?.feature === FEATURES.ASTRO_CALENDAR) {
      dispatch(addActiveTodo({ type: ASTRO_CALENDAR_TODO_TYPE.RATE_US }));
    }
  };

  return (
    <Modal style={styles.bottomModal} isVisible={isVisible} statusBarTranslucent backdropOpacity={0.5} animationInTiming={500}>
      <View style={styles.contentContainer}>
        <TouchableOpacity style={styles.cross} onPress={handleModalClose}>
          <Close />
        </TouchableOpacity>
        <RateUsAfterPurchase mode="modal" onRate={handleRate} onClose={handleModalClose} />
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  bottomModal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  contentContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopRightRadius: sw(30),
    borderTopLeftRadius: sw(30),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    paddingHorizontal: paddingHorizontal(5),
    paddingTop: paddingHorizontal(42),
    paddingBottom: paddingHorizontal(40),
  },
  cross: {
    position: 'absolute',
    top: paddingVertical(15),
    right: paddingHorizontal(20),
  },
});

export default RateUsModal;
