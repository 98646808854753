export enum TYPES {
  SET_SURVEY_PASSED = 'SURVEY/SET_SURVEY_PASSED',
  SET_SURVEY_NEXT_SHOW = 'SURVEY/SET_SURVEY_NEXT_SHOW',
  SET_QUANTITY_SURVEY_SHOWN = 'SURVEY/SET_QUANTITY_SURVEY_SHOWN',
  SET_SHOWN_VERSION = 'SURVEY/SET_SHOWN_VERSION',
}

export interface State {
  surveyPassed: boolean;
  nextShowSession: number;
  showCount: number;
  lastVersion: number;
}
interface SetSurveyPassed {
  type: TYPES.SET_SURVEY_PASSED;
}

interface SetSurveyNextShow {
  type: TYPES.SET_SURVEY_NEXT_SHOW;
  payload: number;
}

interface SetQuantitySurveyShown {
  type: TYPES.SET_QUANTITY_SURVEY_SHOWN;
  payload: number;
}

interface SetShownVersion {
  type: TYPES.SET_SHOWN_VERSION;
  payload: number;
}

export type ActionType = SetSurveyPassed | SetSurveyNextShow | SetQuantitySurveyShown | SetShownVersion;
