import moment from 'moment';

import {CURRENT_DATE, CURRENT_YEAR} from '../constants/date-time-arrays';

const sumDigits = n => ((parseInt(n) - 1) % 9) + 1;

const reducer = (accumulator, currentValue) => currentValue + accumulator;

const getNameCode = name => {
  if (!name) {
    return '';
  }
  const value = name.toUpperCase(),
    charCode = [
      {startCode: 65, endCode: 90}, //english
      {startCode: 1040, endCode: 1071}, //russian
    ];

  let numberString = '';

  for (let i = 0; i < value.length; i++) {
    const symbol = value[i],
      symbolCode = symbol.charCodeAt(0);
    charCode.forEach(el => {
      if (el.startCode <= symbolCode && symbolCode <= el.endCode) {
        numberString += ((symbolCode - el.startCode) % 9) + 1;
      }
    });
  }

  return numberString;
};

export const getNumberOfDay = date => {
  if (!date) {
    return 0;
  }
  return sumDigits(
    moment(CURRENT_DATE, 'x', 'en')
      .format('M D YYYY')
      .split(' ')
      .reduce(reducer) +
      moment(date, 'YYYY-MM-DD', 'en')
        .format('M D')
        .split(' ')
        .reduce(reducer),
  );
};

export const getNumberOfYear = date => {
  if (!date) {
    return 0;
  }
  return sumDigits(
    moment(date, 'YYYY-MM-DD', 'en')
      .format('M D')
      .split(' ')
      .reduce(reducer) + CURRENT_YEAR,
  );
};

export const getDestinyNumber = date => {
  if (!date) {
    return 0;
  }
  return sumDigits(date.split('-').reduce(reducer));
};

export const getLuckNumber = (date, name = '') => {
  return sumDigits('' + getNameCode(name) + getDestinyNumber(date));
};
