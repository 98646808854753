import React, { FC, useCallback } from 'react';
import { StyleSheet, StyleProp, ViewStyle, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { t2 } from 'localization';
import { AppDispatch } from 'store';
import { openReport } from 'store/upsails/actions';
import { PDF_REPORTS } from 'constants/upsale';
import { PERSONAL_REPORTS, OPEN_REPORTS_PLACES } from 'constants/personal-reports';
import { PersonalReport } from 'interfaces/personal-reports';

import BIRTH_CHART_IMG from './img/birth-chart.png';
import COMPATIBILITY_IMG from './img/compatibility.png';
import Item from './item';

const IMAGES_MAP = {
  [PDF_REPORTS.BIRTH_CHART]: BIRTH_CHART_IMG,
  [PDF_REPORTS.COMPATIBILITY]: COMPATIBILITY_IMG,
};

interface Props {
  withAnimation?: boolean;
  place: OPEN_REPORTS_PLACES;
  reports: PDF_REPORTS[];
  style?: StyleProp<ViewStyle>;
  onPress?: (id: PDF_REPORTS) => void;
}

const PersonalReportsList: FC<Props> = ({ withAnimation, place, reports, style, onPress }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleItemPress = useCallback((id: PDF_REPORTS) => {
    dispatch(openReport(id, place));

    if (typeof onPress === 'function') {
      onPress(id);
    }
  }, []);

  return (
    <View style={[styles.wrap, style]}>
      {reports.map((id: PDF_REPORTS) => {
        const data: PersonalReport = PERSONAL_REPORTS[id];
        return (
          <Item
            key={id}
            id={id}
            title={t2(`PERSONAL_REPORTS.${data.title}.TITLE`)}
            image={IMAGES_MAP[id]}
            withAnimation={withAnimation}
            onPress={handleItemPress}
          />
        );
      })}
    </View>
  );
};

export default PersonalReportsList;

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
  },
});
