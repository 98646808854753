import { APP_ICONS_TYPES } from 'constants/app-icons';

export enum CUSTOM_APP_TYPES {
  SET_ICON = 'APP_ICON/SET_ICON',
}

export interface State {
  selectedIcon: APP_ICONS_TYPES;
  isIconPro: boolean;
}

interface SetIcon {
  type: typeof CUSTOM_APP_TYPES.SET_ICON;
  payload: APP_ICONS_TYPES;
}

export type ActionType = SetIcon;
