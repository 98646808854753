import { WEB_LINK_DEV_DEFINER } from 'constants/general';
import { PDF_REPORTS } from 'constants/upsale';
import { PersonalReports } from 'interfaces/personal-reports';

export enum OPEN_REPORTS_PLACES {
  NOTIFICATION = 'notification',
  SETTINGS = 'settings',
  TODAY = 'today',
}

export const PERSONAL_REPORTS: PersonalReports = {
  [PDF_REPORTS.BIRTH_CHART]: {
    title: 'BIRTH_CHART',
    pathName: `birth-chart${WEB_LINK_DEV_DEFINER}`,
  },
  [PDF_REPORTS.COMPATIBILITY]: {
    title: 'COMPATIBILITY',
    pathName: 'compatibility',
  },
};
