import { Platform } from 'react-native';
import { createAction } from 'redux-actions';
import _ from 'lodash';
import DeviceProps from '@magnus/react-native-device-props';

import Analytics from 'analytics';
import { AppDispatch, AppGetState } from 'store';
import { navigateWithMonetization } from 'store/unlock-content/actions';
import { ZODIAC } from 'constants/horoscopes-names';
import { WIDGETS_TYPES, WIDGETS_THEMES, WIDGETS_SIZES } from 'constants/native-widgets';
import * as TRIGGERS from 'constants/monetization-triggers';
import * as ROUTES from 'constants/routes';
import { WIDGETS } from 'constants/widgets';
import SharedData from 'utils/shared-data';
import { DOMAIN as API_URL } from 'api';

import { TYPES } from './types';

export const setActiveWidgets = createAction(TYPES.SET_ACTIVE_WIDGETS);
export const setHoroscopeWidgetPressed = createAction(TYPES.SET_HOROSCOPE_WIDGET_PRESSED);
export const setHoroscopeBannerPressed = createAction(TYPES.SET_HOROSCOPE_BANNER_PRESSED);
export const setHoroscopeBannerMoved = createAction(TYPES.SET_HOROSCOPE_BANNER_MOVED);

export const initNativeWidgets = () => {
  return async (dispatch: AppDispatch) => {
    if (Platform.OS !== 'ios') {
      return false;
    }

    try {
      await SharedData.pushConfig([{ id: WIDGETS_TYPES.HOROSCOPE }]);

      SharedData.reloadAllTimelines();

      dispatch(checkActiveNativeWidgets());
      dispatch(setNativeWidgetsData());
    } catch (error) {
      console.log('[ERROR INIT NATIVE WIDGETS]', error);
    }
  };
};

export const checkActiveNativeWidgets = () => {
  return async (dispatch: AppDispatch) => {
    if (Platform.OS !== 'ios') {
      return false;
    }

    try {
      const widgetsConfigs = await SharedData.getCurrentConfigurations();
      const activeWidgets = widgetsConfigs.map((w: any) => w.family) as WIDGETS_SIZES[];

      dispatch(setActiveWidgets(activeWidgets));
    } catch (error) {
      console.log('[ERROR CHECK ACTIVE NATIVE WIDGETS]', error);
    }
  };
};

export const setNativeWidgetsData = () => {
  return async (_dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: {
        profileData: { signs },
      },
      auth: { accessToken },
    } = getState();

    const sign = !_.isEmpty(signs) && signs.find(item => item.type === ZODIAC.toLowerCase())?.sign;

    if (Platform.OS !== 'ios' || !sign || !accessToken) {
      return false;
    }

    try {
      const idfm = await DeviceProps.getIDFM();

      await SharedData.pushData({
        sign,
        idfm,
        apiURL: API_URL,
        token: accessToken,
        type: WIDGETS_TYPES.HOROSCOPE,
        theme: WIDGETS_THEMES.H1,
      });

      SharedData.reloadAllTimelines();
    } catch (error) {
      console.log('[ERROR SET NATIVE WIDGETS DATA]', error);
    }
  };
};

export const handleHoroscopeNativeWidgetPress = () => {
  return async (dispatch: AppDispatch) => {
    Analytics.trackEvent('widget_native', 'click');
    dispatch(setHoroscopeWidgetPressed(true));
  };
};

export const checkAndNavigateIfNativeWidgetPressed = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      profile: {
        profileData: { signs },
      },
      onboarding: { isOnboardingCompleted },
      nativeWidgets: { isHoroscopeWidgetPressed },
    } = getState();

    if (Platform.OS !== 'ios') {
      return false;
    }

    const sign = !_.isEmpty(signs) && signs.find(item => item.type === ZODIAC.toLowerCase())?.sign;

    if (isHoroscopeWidgetPressed && sign && isOnboardingCompleted) {
      Analytics.trackEvent('widget_native', 'click');
      setTimeout(() => {
        dispatch(
          navigateWithMonetization({
            trigger: TRIGGERS.NATIVE_WIDGET,
            route: ROUTES.PERSONAL_READINGS,
            usePush: true,
            params: {
              id: WIDGETS.SUMMARY,
            },
          }),
        );
      }, 500);

      dispatch(setHoroscopeWidgetPressed(false));
    }
  };
};
