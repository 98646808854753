import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DeviceProps from '@magnus/react-native-device-props';

import { RootState } from 'store';
import { type SolidMetadata } from 'api/purchases/interfaces';

const useSolidMetadata = (): SolidMetadata => {
  const [data, setData] = useState<SolidMetadata>({});
  const web_uuid = useSelector((state: RootState) => state.auth.webUUID);

  useEffect(() => {
    const getData = async () => {
      const idfm = await DeviceProps.getIDFM();
      const idfv = await DeviceProps.getIDFV();
      const idfa = await DeviceProps.getIDFA();
      const adjust_id = await DeviceProps.getAdjustId();
      const firebase_instance_id = await DeviceProps.getFirebaseId();
      const d = {} as SolidMetadata;

      if (web_uuid) {
        d.web_uuid = web_uuid;
      }

      if (idfm) {
        d.idfm = idfm;
      }

      if (idfv) {
        d.idfv = idfv;
      }

      if (idfa) {
        d.idfa = idfa;
      }

      if (adjust_id) {
        d.adjust_id = adjust_id;
      }

      if (firebase_instance_id) {
        d.firebase_instance_id = firebase_instance_id;
      }

      setData(d);
    };

    getData();
  }, [web_uuid]);

  return data;
};

export default useSolidMetadata;
