import { State, ActionType, TYPES } from './types';

const initialState: State = {
  currentTrigger: '',
  renewData: null,
  transactionData: null,
  flowStep: 0,
  startFlowCallbacks: {},
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case TYPES.SET_CURRENT_TRIGGER:
      return {
        ...state,
        currentTrigger: action.payload,
      };

    case TYPES.SET_RENEW_DATA:
      return {
        ...state,
        renewData: action.payload,
      };

    case TYPES.SET_TRANSACTION_DATA:
      return {
        ...state,
        transactionData: action.payload,
      };

    case TYPES.SET_FLOW_STEP:
      return {
        ...state,
        flowStep: action.payload,
      };

    case TYPES.SET_START_FLOW_CALLBACKS:
      return {
        ...state,
        startFlowCallbacks: action.payload,
      };
    default:
      return state;
  }
}
