import React, { FC, useEffect, useState } from 'react';
import { View, StyleSheet, Image, Dimensions, Text } from 'react-native';
import Share from 'react-native-share';
import { useDispatch, useSelector } from 'react-redux';
import { fs, HEADER_OFFSET, paddingVertical, sh, BOTTOM_OFFSET, sw, paddingHorizontal } from '@wowmaking/ui/src/utils';

import Button from 'components/button';
import FadeView from 'components/fade-view';
import LoaderOverlay from 'components/loader/overlay';
import * as COLORS from 'constants/colors';
import * as FONTS from 'constants/fonts';
import * as MODALS from 'constants/modals';
import { APP_NAME } from 'constants/general';
import { t } from 'localization';
import { AppDispatch, RootState } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import { logEvent } from 'utils/events';

import CloseButton from '../components/close-button';
import Steps from '../components/steps';
import Title from '../components/title';
import IMG from '../img/image.jpg';

const ANIMATION_DURATION = 400;

const { height } = Dimensions.get('window');

interface Props {}

enum TRIGGER_TYPES {
  BUTTON = 'button',
  LINK = 'link',
}

const FamilyGuide: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [visible, setVisible] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const link = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.familyGuideLink);
  const showModal = useSelector((state: RootState) => state.modals.activeModal) === MODALS.FAMILY_GUIDE;

  useEffect(() => {
    if (showModal) {
      logEvent('family_guide', 'open');

      const timeout = setTimeout(() => {
        setVisible(true);
      }, ANIMATION_DURATION);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showModal]);

  const handleClose = () => {
    setVisible(false);

    setTimeout(() => {
      dispatch(closeCurrentModal());
    }, ANIMATION_DURATION);
  };

  const handleClosePress = () => {
    handleClose();
  };

  const handleShareLink = (type: TRIGGER_TYPES) => {
    setPending(true);
    const message = `${t('FAMILY_GUIDE.SHARE_TEXT', { appName: APP_NAME })} \n\n`;
    logEvent('family_guide', 'share_click', { type });

    const shareOptions = {
      url: link,
      message,
    };

    return Share.open(shareOptions)
      .then(() => {
        logEvent('family_guide', 'share_success');
        handleClose();
      })
      .catch(() => {
        logEvent('family_guide', 'share_error');
        return false;
      })
      .finally(() => {
        setPending(false);
      });
  };

  const handleButtonPress = () => {
    handleShareLink(TRIGGER_TYPES.BUTTON);
  };

  const handleLinkPress = () => {
    handleShareLink(TRIGGER_TYPES.LINK);
  };

  if (!showModal) {
    return null;
  }

  return (
    <FadeView style={styles.root} visible={visible} duration={ANIMATION_DURATION}>
      <Image source={IMG} style={styles.image} />
      <View style={styles.core}>
        <View style={styles.topControls}>
          <CloseButton onPress={handleClosePress} />
        </View>
        <View style={styles.container}>
          <Title text={t('FAMILY_GUIDE.GUIDE.TITLE')} style={styles.titleWrapper} />

          <Steps onLinkPress={handleLinkPress} />

          <View style={styles.bottomContainer}>
            <View style={styles.buttonContainer}>
              <Button
                title={t('FAMILY_GUIDE.GUIDE.BUTTON_TITLE')}
                onPress={handleButtonPress}
                style={styles.button}
                titleStyle={styles.buttonTitle}
              />
            </View>
            <Text style={styles.textUnderButton}>{t('FAMILY_GUIDE.GUIDE.TEXT_UNDER_BUTTON')}</Text>
          </View>
        </View>
      </View>
      <LoaderOverlay visible={pending} />
    </FadeView>
  );
};

export default FamilyGuide;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: COLORS.DARK_BLUE_GREY_2,
    ...StyleSheet.absoluteFillObject,
  },
  core: {
    flex: 1,
    minHeight: '100%',
    justifyContent: 'flex-end',
  },
  image: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
    height: (height / 100) * 42,
  },
  topControls: {
    alignItems: 'flex-start',
    width: '100%',
    position: 'absolute',
    top: sh(HEADER_OFFSET + 16),
    zIndex: 9,
  },
  container: {
    flex: 0.7,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleWrapper: {
    width: '100%',
    alignItems: 'center',
  },
  bottomContainer: {
    marginBottom: BOTTOM_OFFSET + paddingVertical(20),
    alignItems: 'center',
    width: '100%',
  },
  buttonContainer: {
    alignItems: 'center',
    width: '80%',
  },
  button: {
    width: '100%',
    maxWidth: sw(278),
    alignSelf: 'center',
    height: 55,
    borderRadius: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonTitle: {
    fontSize: fs(22),
  },
  textUnderButton: {
    fontFamily: FONTS.OpenSans,
    marginTop: paddingVertical(15),
    fontSize: fs(12),
    color: COLORS.WHITE_WITH_OPACITY,
    textAlign: 'center',
    paddingHorizontal: paddingHorizontal(15),
  },
});
