import { ImageSourcePropType } from 'react-native';
import { ImageMaps } from 'interfaces/image';

import WHATSAPP_ICON from 'assets/icons/messengers/whatsapp.png';
import EMAIL_ICON from 'assets/icons/messengers/email.png';
import { startWhatsAppBot, startEmailNewsletter } from 'store/messengers/actions';

export interface Buttons {
  name: MESSENGERS;
  image: ImageSourcePropType;
}

export enum MESSENGERS {
  WHATS_UP = 'whatsApp',
  EMAIL = 'email',
}

export const MESSENGERS_MODAL_IMAGES_MAP: ImageMaps<MESSENGERS> = {
  [MESSENGERS.WHATS_UP]: WHATSAPP_ICON,
  [MESSENGERS.EMAIL]: EMAIL_ICON,
};

export const MESSENGERS_HANDLERS_MAP: { [key in MESSENGERS]: Function } = {
  [MESSENGERS.WHATS_UP]: startWhatsAppBot,
  [MESSENGERS.EMAIL]: startEmailNewsletter,
};
