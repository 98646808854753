import { AppRegistry } from 'react-native';

import { initializeApp as initFirebase } from 'firebase/app';
import { getAnalytics as getFirebaseAnalytics } from 'firebase/analytics';
import { getMessaging as getFirebaseMessaging, isSupported } from 'firebase/messaging';

import './url-sync';

import './index.css';

import App from '../../src';

// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
window._frameTimestamp = null;

const IGNORED_LOGS = ['Warning: Failed %s type: %s%s', 'Warning: React does not recognize the `resizeMode`'];

let warn = console.error;
console.error = (m, ...args) => {
  if (IGNORED_LOGS.some(ignoredLog => m?.includes?.(ignoredLog))) {
    warn(m, ...args);
  }
};

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', { rootTag: document.getElementById('root') });

const app = initFirebase({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

getFirebaseAnalytics(app);

(async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      getFirebaseMessaging(app);
    }
  } catch (error) {
    console.log('> Init firebase messaging error', error);
  }
})();
