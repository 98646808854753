import { useEffect } from 'react';

import { PAYMENT_METHODS, WEB_POST_MESSAGE_STATUS } from 'modules/payments/constants';

interface Arguments {
  source: string;
  method?: PAYMENT_METHODS;
  onSuccess: (method: PAYMENT_METHODS, transactionDetails: any) => void;
  onError?: (method: PAYMENT_METHODS, error: any) => void;
  onLoaded?: () => void;
  onDismiss?: () => void;
}

const useMessageListener = ({ source, method, onSuccess, onError, onLoaded, onDismiss }: Arguments) => {
  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const paymentMethod = (event?.data?.data?.method as unknown as PAYMENT_METHODS) ?? method;
      const error = event?.data?.data?.message;
      const status = event?.data?.status as WEB_POST_MESSAGE_STATUS;

      if (event?.data?.source === source) {
        switch (status) {
          case WEB_POST_MESSAGE_STATUS.SUCCESS:
            onSuccess(paymentMethod, event?.data?.data);
            break;
          case WEB_POST_MESSAGE_STATUS.ERROR:
            if (typeof onError === 'function') {
              onError(paymentMethod, error);
            }
            break;
          case WEB_POST_MESSAGE_STATUS.SKIPED:
          case WEB_POST_MESSAGE_STATUS.CLOSED:
            if (typeof onDismiss === 'function') {
              onDismiss();
            }
            break;
          case WEB_POST_MESSAGE_STATUS.LOADED:
            if (typeof onLoaded === 'function') {
              onLoaded();
            }
            break;
          default:
            break;
        }
      }
    };

    window.addEventListener('message', listener, false);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [source]);
};

export default useMessageListener;
