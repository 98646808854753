import { TODAY_MATCHES_IDS, TodayMatchesItem } from 'api/todays-features/interfaces';

export enum TYPES {
  SET_MATCHES = 'MATCHES/SET_MATCHES',
}

export interface Matches {
  bestToday: TodayMatchesItem[];
  worstToday: TodayMatchesItem[];
  tab: TODAY_MATCHES_IDS;
}

export interface State {
  todayMatches: Matches[];
}

interface SetMatches {
  type: typeof TYPES.SET_MATCHES;
  payload: {
    todayMatches: Matches[];
  };
}

export type ActionTypes = SetMatches;
