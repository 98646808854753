import { Platform, Dimensions, PlatformOSType } from 'react-native';
import { BOTTOM_OFFSET, HEADER_OFFSET } from '@wowmaking/ui/src/utils';

import { isAndroid, isStandalone } from 'utils/pwa';

const { height, width } = Dimensions.get('window');

type PlatformType = PlatformOSType | 'pwa';

export function select<T>(platforms: { [key in PlatformType]?: T }) {
  const pwaStyles = !!platforms?.pwa && isStandalone();
  const params = {
    ...platforms,
    web: pwaStyles ? platforms?.pwa : platforms?.web,
  };

  return Platform.select<T>(params) || 0;
}

export const DEFAULT_HEADER_HEIGHT = Platform.OS === 'web' ? (height > 650 ? 64 : 54) : 80;
export const DEFAULT_HEADER_OFFSET = Platform.OS === 'web' ? 0 : HEADER_OFFSET;
export const DEFAULT_BOTTOM_OFFSET = select({
  android: BOTTOM_OFFSET,
  ios: BOTTOM_OFFSET,
  web: 10,
  pwa: isAndroid() ? 0 : 30,
});

export const IS_SMALL = height <= 667;

export const windowHeight = height;
export const windowWidth = width;
