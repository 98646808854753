import moment from 'moment';
import {generateDatesToString} from './format-dates-in-horoscopes';

export const isRetrograde = planet => {
  let result = false;

  planet.duration.forEach(period => {
    const from = new Date(period.from).setHours(0, 0, 0);
    const to = new Date(period.to).setHours(0, 0, 0);
    const now = new Date().setHours(0, 0, 0);

    if (now >= from && now <= to) {
      result = true;
      return;
    }
  });

  return result;
};

export const getCurrentPeriod = duration => {
  const period = duration.find(period => {
    const from = new Date(period.from).setHours(0, 0, 0);
    const to = new Date(period.to).setHours(0, 0, 0);
    const now = new Date().setHours(0, 0, 0);
    if (now >= from && now <= to) {
      return true;
    }
    return false;
  });
  return period;
};

export const getPercentsForPeriod = period => {
  const daysPassed = moment(period.from).diff(moment(), 'days');
  const allDays = moment(period.from).diff(moment(period.to), 'days');
  return Math.round((daysPassed / allDays) * 100);
};

export const getPeriodString = period => {
  return period
    ? generateDatesToString(
        [
          {
            start: period.from.split('-').reverse().slice(0, 2).join(':'),
            end: period.to.split('-').reverse().slice(0, 2).join(':'),
          },
        ],
        false,
        false,
      )
    : null;
};
