import React from 'react';
import { StyleProp, TextStyle, StyleSheet, TouchableOpacity } from 'react-native';
import { paddingVertical, fs, paddingHorizontal } from '@wowmaking/ui/src/utils';

import { t } from 'localization';
import * as COLORS from 'constants/colors';
import Text from 'components/text';

interface Props {
  children?: string;
  onPress: () => void;
  style?: StyleProp<TextStyle>;
  titleStyle?: StyleProp<TextStyle>;
}

const ClipboardButton: React.FC<Props> = ({ style, titleStyle, onPress }) => {
  const copyText = t(`PWA_INSTRUCTIONS_V2.COPY_LINK`);

  return (
    <TouchableOpacity style={[styles.button, style]} onPress={onPress}>
      <Text style={[styles.title, titleStyle]}>{copyText}</Text>
    </TouchableOpacity>
  );
};

export default ClipboardButton;

const styles = StyleSheet.create({
  button: {
    alignSelf: 'flex-start',
    marginTop: -paddingVertical(5),
    paddingHorizontal: paddingHorizontal(25),
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    borderRadius: 40,
    backgroundColor: COLORS.AQUA,
  },
  title: {
    fontSize: fs(16),
    color: COLORS.WHITE,
    fontWeight: '700',
  },
});
