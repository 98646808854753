import React, { FC, useCallback, useState } from 'react';
import { Image, View, StyleSheet } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { StoreBadge, PressData } from 'react-native-store-badges';
import { useSelector, useDispatch } from 'react-redux';

import { BEIGE_2, MODAL_BG_COLOR, SURVEY_MODAL_BACKGROUND, WHITE_WITH_07 } from 'constants/colors';
import { AppDispatch, RootState } from 'store';
import { isAndroid, isIos } from 'utils/pwa';
import CLOSE from 'assets/icons/close.png';
import Text from 'components/text';
import { generateAppLink } from 'store/app/actions';
import { PATH_ROUTES_MAP, BIRTH_CHART_ROUTES, PATH_NAMES, ADVISORS_ROUTES, ADVISORS_CHAT } from 'constants/routes';
import useModalIsVisible from 'hooks/use-modal-is-visibly';
import { INSTALL_MOBILE_APP } from 'constants/modals';
import { closeCurrentModal } from 'store/modals/actions';
import CloseButtonWithTimeout from 'components/close-button-with-timeout';
import Localization, { t } from 'localization';
import { formatMinutes } from 'utils/astrologist';
import { APP_NAME } from 'constants/general';
import Analytics from 'analytics';

import ARROW from './images/arrow.png';

const BADGE_HEIGHT = sw(53);
const TRANSLATION_MAP = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  es: 'es-ES',
  pt: 'pt-BR',
  ja: 'ja-JP',
};

interface Props {}

const InstallMobileApp: FC<Props> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentRoute = useSelector((state: RootState) => state.navigation.currentRoute);
  const seconds = useSelector((state: RootState) => state.pwa.installMobileAppPromocodeSeconds);
  const promocode = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.installMobileAppModalConfig?.promocode);
  const closeBtnTimeout = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.installMobileAppModalConfig?.closeBtnTimeout);
  const configTitle = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.installMobileAppModalConfig?.title);
  const configSubtitle = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.installMobileAppModalConfig?.subtitle);
  const appLink = useSelector((state: RootState) => state.remoteConfig.remoteConfigParams?.appLink);
  const isVisible = useModalIsVisible(INSTALL_MOBILE_APP);
  const locale = TRANSLATION_MAP[Localization.getLngCode()];

  const [link, setLink] = useState(appLink);

  const title = configTitle?.length ? configTitle : t('INSTALL_MOBILE_APP_MODAL.TITLE', { appName: APP_NAME });
  const subtitle = configSubtitle?.length ? configSubtitle : t('INSTALL_MOBILE_APP_MODAL.SUBTITLE');

  const handleShow = useCallback(async () => {
    Analytics.trackEvent('Install_App_Modal', 'Open', { promocode, seconds });
    let pathname = Object.entries(PATH_ROUTES_MAP).find(([_key, value]) => value === currentRoute)?.[0];

    if (BIRTH_CHART_ROUTES.includes(currentRoute)) {
      pathname = PATH_NAMES['birth-chart'];
    }

    if (ADVISORS_ROUTES.includes(currentRoute) && ADVISORS_CHAT !== currentRoute) {
      pathname = PATH_NAMES.advisors;
    }

    const generatedLink = await dispatch(generateAppLink(appLink, { pathname, promocode, install_app: true }));
    setLink(generatedLink);
  }, [promocode, seconds, currentRoute, appLink]);

  const handleClose = () => {
    dispatch(closeCurrentModal());
  };

  const handlePress = useCallback(({ platform }: PressData) => {
    Analytics.trackEvent('Install_App_Modal', 'Click', { platform });
    dispatch(closeCurrentModal());
  }, []);

  const renderButtons = () => {
    if (!isIos() && !isAndroid()) {
      return (
        <>
          <StoreBadge href={link} platform="ios" height={BADGE_HEIGHT} locale={locale} style={styles.firstBadge} onPress={handlePress} />
          <StoreBadge href={link} platform="android" height={BADGE_HEIGHT} locale={locale} style={styles.defaultBadge} onPress={handlePress} />
        </>
      );
    }

    return (
      <StoreBadge
        href={link}
        platform={isIos() ? 'ios' : 'android'}
        height={BADGE_HEIGHT}
        locale={locale}
        style={styles.defaultBadge}
        onPress={handlePress}
      />
    );
  };

  return (
    <Modal isVisible={isVisible} style={styles.root} backdropColor={SURVEY_MODAL_BACKGROUND} onModalShow={handleShow}>
      <View style={styles.wrap}>
        <View style={styles.iconContainer}>
          <CloseButtonWithTimeout timeout={closeBtnTimeout} onPress={handleClose} source={CLOSE} style={styles.close} iconStyle={styles.icon} />
        </View>
        <View style={styles.container}>
          <View style={styles.titleContainer}>
            <Text style={styles.subtitle}>{subtitle}</Text>
            {seconds > 0 && (
              <Text style={styles.subtitle}>{t('INSTALL_MOBILE_APP_MODAL.PROMOCODE_TIME_SUBTITLE', { minutes: formatMinutes(seconds) })}</Text>
            )}
            <Text font="Philosopher" style={styles.title}>
              {title}
            </Text>
            <Image source={ARROW} style={styles.arrow} />
          </View>
          {renderButtons()}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  wrap: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(40),
    paddingHorizontal: paddingHorizontal(20),
    borderTopLeftRadius: sw(30),
    borderTopRightRadius: sw(30),
    backgroundColor: MODAL_BG_COLOR,
    alignItems: 'center',
    position: 'relative',
  },
  iconContainer: {
    alignSelf: 'flex-end',
    justifyContent: 'center',
    marginTop: paddingVertical(10),
  },
  icon: {
    tintColor: BEIGE_2,
    padding: 0,
  },
  close: {
    position: 'relative',
    padding: 0,
    zIndex: undefined,
  },
  container: {
    alignItems: 'center',
    width: '100%',
  },
  titleContainer: {
    paddingBottom: paddingVertical(35),
    justifyContent: 'center',
    width: '100%',
  },
  subtitle: {
    fontSize: fs(17),
    color: WHITE_WITH_07,
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: fs(23),
    width: '100%',
  },
  title: {
    fontSize: fs(26),
    fontWeight: '700',
    color: BEIGE_2,
    lineHeight: fs(34),
    textAlign: 'center',
    width: '100%',
  },
  arrow: {
    position: 'absolute',
    left: paddingHorizontal(20),
    bottom: -paddingVertical(15),
  },
  defaultBadge: {
    padding: 0,
  },
  firstBadge: {
    padding: 0,
    paddingBottom: paddingVertical(11),
  },
});

export default InstallMobileApp;
