import { Platform } from 'react-native';

export const Philosopher = Platform.select({
  ios: 'Philosopher',
  android: 'Philosopher-Regular',
  web: 'Philosopher',
});
export const Philosopher_Bold = Platform.select({
  ios: 'Philosopher-Bold',
  android: 'Philosopher-Bold',
  web: 'Philosopher',
});
export const OpenSans = Platform.select({
  ios: 'Open Sans',
  android: 'OpenSans-Regular',
  web: 'Open Sans',
});
export const OpenSans_Semibold = Platform.select({
  ios: 'OpenSans-Semibold',
  android: 'OpenSans-Semibold',
  web: 'Open Sans',
});
export const OpenSans_Bold = Platform.select({
  ios: 'OpenSans-Bold',
  android: 'Open Sans',
  web: 'Open Sans',
});

export type FONT_TYPES = 'OpenSans' | 'Philosopher';

//for preloader animation
export const Arial = 'Arial';
