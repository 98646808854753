import { PROFILE_TYPES } from '../profile/types';

export enum TYPES {
  SET_BADGE_SHOWED = 'BADGES/SET_BADGE_SHOWED',
  SET_LAST_VISITED_DAILY_DATE = 'BADGES/SET_LAST_VISITED_DAILY_DATE',
}

export interface State {
  badgesShowed: string[];
  lastVisitedDailyDate: number;
}

interface SetBadgeShowed {
  type: typeof TYPES.SET_BADGE_SHOWED;
  payload: string[];
}

interface SetLastVisitedDailyDate {
  type: typeof TYPES.SET_LAST_VISITED_DAILY_DATE;
  payload: number;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionTypes = SetBadgeShowed | SetLastVisitedDailyDate | ResetUserData;
