import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getBestMatches } from 'api/best-matches';

import { TYPES } from './types';

const setBestMatches = createAction(TYPES.SET);

export const initBestMatches = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const bestMatches = await getBestMatches();
      dispatch(setBestMatches(bestMatches));
    } catch (error) {
      console.log('[ERROR BEST MATCHES]: ', error);
    }
  };
};
