const RNRestart = {
  Restart: () => {
    window.location.reload();
  },
  restart: () => {
    window.location.reload();
  },
};

export default RNRestart;
