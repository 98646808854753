import { WIDGETS_SIZES } from 'constants/native-widgets';

export enum TYPES {
  SET_ACTIVE_WIDGETS = 'SET_ACTIVE_WIDGETS',
  SET_HOROSCOPE_WIDGET_PRESSED = 'SET_HOROSCOPE_WIDGET_PRESSED',
  SET_HOROSCOPE_BANNER_PRESSED = 'SET_HOROSCOPE_BANNER_PRESSED',
  SET_HOROSCOPE_BANNER_MOVED = 'SET_HOROSCOPE_BANNER_MOVED',
}

export const SET_ACTIVE_WIDGETS = 'NATIVE_WIDGETS/SET_ACTIVE_WIDGETS';
export const SET_HOROSCOPE_BANNER_PRESSED = 'NATIVE_WIDGETS/SET_HOROSCOPE_BANNER_PRESSED';

export interface State {
  activeWidgets: WIDGETS_SIZES[];
  isHoroscopeWidgetPressed: boolean;
  isHoroscopeBannerPressed: boolean;
  isHoroscopeBannerMoved: boolean;
}

interface SetActiveWidgets {
  type: typeof TYPES.SET_ACTIVE_WIDGETS;
  payload: WIDGETS_SIZES[];
}

interface SetHoroscopeWidgetPressed {
  type: typeof TYPES.SET_HOROSCOPE_WIDGET_PRESSED;
  payload: boolean;
}

interface SetHoroscopeBannerPressed {
  type: typeof TYPES.SET_HOROSCOPE_BANNER_PRESSED;
  payload: boolean;
}

interface SetHoroscopeBannerMoved {
  type: typeof TYPES.SET_HOROSCOPE_BANNER_MOVED;
  payload: boolean;
}

export type ActionTypes =
  | SetActiveWidgets
  | SetHoroscopeWidgetPressed
  | SetHoroscopeBannerPressed
  | SetHoroscopeBannerMoved;
