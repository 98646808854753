import Analytics from 'analytics';

const isDebugEnabled = __DEV__;

/* Log Event */
export const logEvent = (category, action, params, options) => {
  const extendedOptions = {
    ...options,
    optional: category !== 'Subscription',
  };

  Analytics.trackEvent(category, action, params, extendedOptions);

  if (isDebugEnabled) {
    console.log(
      '[Event]',
      category + '_' + action,
      params || 'NA',
      extendedOptions || 'NA',
    );
  }
};
