import Localization from 'localization';

import api from '..';

import { Profile, ProfileData, UserParams } from './interfaces';

const PROFILE = '/profile';

export const setProfileUserParams = (userParams: UserParams): Promise<ProfileData> => {
  return api.fetch(`${PROFILE}/set-profile-params`, {
    method: 'POST',
    data: {
      data: userParams,
    },
  });
};

export const update = (p: Profile): Promise<ProfileData> => {
  let utc_offset;
  try {
    utc_offset = new Date().getTimezoneOffset() / -60;
  } catch (e) {
    utc_offset = 0;
  }
  return api.fetch(PROFILE, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      ...p,
      utc_offset,
      lang: p.lang ? p.lang : Localization.getLngCode(),
    },
  });
};

export const getProfile = (): Promise<ProfileData> => {
  return api.fetch(PROFILE, {
    method: 'GET',
  });
};

export const removeProfile = (): Promise<boolean> => {
  return api.fetch(PROFILE, {
    method: 'DELETE',
  });
};
