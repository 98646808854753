export const BLACK = '#000000';
export const BLACK_07 = 'rgba(0, 0, 0, 0.7)';
export const BLACK_OPACITY = BLACK + '50';
export const SHADOW = 'rgba(0, 0, 0, 0.2)';
export const RED = '#ff3738';
export const LIGHT_GREY = '#b6b8be';
export const WARM_GREY = '#949494';
export const GRAY_DARK = '#818080';
export const GRAY_DARK_2 = '#4B4B4B';
export const GRAY_666 = '#666666';
export const ELECTRIC_PURPLE = '#9043ff';
export const LIGHTER_PURPLE = '#884ef8';
export const EASTER_PURPLE_TWO = '#a466ff';
export const PALE_PURPLE_ALPHA = '#9b9ad5';
export const PALM_BG = '#78AAB199';
export const BLUEBERRY = '#5550a9';
export const BLUEBERRY_TWO = '#322e76';
export const AQUA_MARINE = '#46e8e1';
export const BRIGHT_TURQUOISE = '#0df8dd';
export const WHEAT = '#fcc678';
export const VIOLET = '#1e0943';
export const GRAY_DARK_TRANSPERENT = 'rgba(0, 0, 0, 0.50)';
export const DARK_BLUE = '#1e0843';
export const BG = DARK_BLUE;
export const TEALISH = '#42d1d2';
export const BRIGHT_SKY_BLUE = '#0fccff';
export const BRIGHT_LIGHT_BLUE = '#36d1f3';
export const BRIGHT_AQUA = '#00ffe1';
export const BRIGHT_AQUA_TWO = '#0df8dd';
export const BLACK_RUSSIAN = '#070026';
export const BLACK_RUSSIAN_2 = '#071D31';
export const BRIGHT_TEAL = '#00eedb';
export const DARK_TEAL = '#1E3B48';
export const DARK_TEAL_05 = 'rgba(30, 59, 72, 0.5)';
export const CERULEAN = '#0082df';
export const DARK_SLATE_BLUE = '#2b2472';
export const BLUE_WITH_A_HINT_OF_PURPLE = '#3935ac';
export const PASTEL_BLUE = '#b0aeff';
export const LIGHT_MAGENTA = '#f689fa';
export const BUBBLE_GUM_PINK = '#ff5e96';
export const BATTLESHIP_GRAY = '#6a7082';
export const ONBOARDING_LOADER_BG = 'rgba(255, 255, 255, 0.2)';
export const WHITE_WITH_FIFTH_TRANSPARENCY = 'rgba(255, 255, 255, 0.8)';
export const PROFILE_PLACEHOLDER = 'rgba(255, 255, 255, 0.5)';
export const LIGHT_GREY_OPACITY = 'rgba(118, 118, 128, 0.3)';
export const DIVIDER_LIGHT_GREY = '#244a5b';
export const LILAC_AMETHYST = '#5946be';
export const FAUX_MEDIUM_BLUE = '#716ae3';
export const LIGHT_STEEL_BLUE = '#b9b8e2';
export const DOMINATE_BLUE = '#3531a7';
export const USER_MESSAGE = '#dddbff';
export const GHOST_WHITE = '#f5f4ff';
export const LIGHT_LAVENDER = '#e4e3ff';
export const BRILLIANT_ROSE = '#f03a9f';
export const VIOLET_RED = '#f8347a';
export const BRIGHT_TURQUOISE_2 = '#1FE7E8';
export const SHAMROCK = '#31c9af';
export const COBALT = '#2b1a6e';
export const GOLDEN = '#F2E6C4';
export const DEEP_CERULEAN = '#0088a6';
export const MEDIUM_RED_VIOLET = '#c42aa9';
export const FROLY = '#ee6a88';
export const IRIS = '#5946be';
export const BLUEBERRY_THREE = '#343098';

export const CARD_BG_COLOR = '#1E3B48';
export const MODAL_BG_COLOR = '#1E3B48';

export const GRADIENT_CARD_TRANSPARENT = [CARD_BG_COLOR + '00', CARD_BG_COLOR];
export const GRADIENT_COLORS_BUTTON = ['#fd5b86', '#eb48d5'];
export const GRADIENT_PRIMARY_BLUE_BUTTON = [BRIGHT_TEAL, CERULEAN];
export const GRADIENT_SETTINGS_BUTTON = [BRIGHT_SKY_BLUE, BRIGHT_AQUA];
export const GRADIENT_COLORS_PICKERS = ['#b283f5', '#ec2df8'];
export const GRADIENT_CARDS = [BLUE_WITH_A_HINT_OF_PURPLE, DARK_SLATE_BLUE];
export const GRADIENT_CARDS_REVERT = [DARK_SLATE_BLUE, BLUE_WITH_A_HINT_OF_PURPLE];
export const LILAC_PURPLE_GRADIENT = [LILAC_AMETHYST, BLUE_WITH_A_HINT_OF_PURPLE];
export const GRADIENT_LILAC_DOMINATE = [LILAC_AMETHYST, DOMINATE_BLUE];
export const GRADIENT_VIOLET_FROLY = [MEDIUM_RED_VIOLET, FROLY];

export const RETRY_GRADIENT = ['#00eedb', '#0082df'];
export const BOTTOM_GRADIENT = ['rgba(0,0,0,0)', DARK_BLUE];
export const WHITE_WITH_OPACITY = 'rgba(255, 255, 255, 0.6)';
export const WHITE_19 = 'rgba(255, 255, 255, 0.19)';
export const WHITE_HALF = 'rgba(255, 255, 255, 0.5)';
export const WHITE_03 = 'rgba(255, 255, 255, 0.3)';
export const LILAC_WITH_BLUE_GRADIENT = [LILAC_AMETHYST, DOMINATE_BLUE];
export const LILAC_WITH_PURPLE_BETA = [LILAC_AMETHYST, BLUE_WITH_A_HINT_OF_PURPLE];
export const BLUR_BLUE = 'rgba(32, 20, 94, 0.9)';
export const BLUR_VIOLET = 'rgba(24, 4, 60, 0.19)';
export const BLACK_TRANSPARENT = 'rgba(0, 0, 0, 0.3)';
export const BLUE_TRANSPARENT_2 = ['rgba(41, 76, 91, 0)', '#294C5B'];

/* Colors for AstroStellium theme */

export const TRANSPARENT = 'transparent';
export const WHITE = '#ffffff';
export const WHITE_WITH_OPACITY_0_15 = 'rgba(255, 255, 255, 0.15)';
export const WHITE_WITH_07 = 'rgba(255, 255, 255, 0.7)';
export const BEIGE = '#faf6e8';
export const BEIGE_08 = '#faf6e8CC';
export const BEIGE_2 = '#f2e6c4';
export const BEIGE_3 = '#e2cfa4';
export const BEIGE_WITH_OPACITY_015 = 'rgba(242, 230, 196, 0.15)';
export const BEIGE_WITH_OPACITY_01 = 'rgba(242, 230, 196, 0.2)';
export const BEIGE_WITH_OPACITY_03 = 'rgba(226, 207, 164, 0.3)';
export const BEIGE_WITH_OPACITY_05 = 'rgba(242, 230, 196, 0.5)';
export const BEIGE_WITH_OPACITY_07 = 'rgba(242, 230, 196, 0.7)';
export const BEIGE_WITH_OPACITY_08 = 'rgba(242, 230, 196, 0.8)';
export const BEIGE_WITH_OPACITY_08_2 = 'rgba(250, 246, 232, 0.8)';
export const BEIGE_WITH_OPACITY_07_2 = 'rgba(250, 246, 232, 0.7)';
export const BEIGE_3_WITH_OPACITY_03 = 'rgba(226, 207, 164, 0.3)';
export const BEIGE_4 = '#8C9898';
export const TOUPE = '#d0b894';
export const TOUPE_08 = 'rgba(208, 184, 148, 0.8)';
export const TOUPE_2 = '#888c80';
export const SAND_BROWN = '#d3935e';
export const SAND_1 = '#ECC498';
export const SAND_2 = '#F7E6C4';
export const STONE = '#a39376';
export const LIGHT_TAN = '#fae6c4';
export const CORAL = '#fd5347';
export const FADED_ORANGE = '#f27067';
export const RED_PALE = '#E16158';
export const BROWN = '#692C00';
export const AQUA = '#14d5c2';
export const AQUA_05 = 'rgba(20, 213, 194, 0.5)';
export const AQUA_03 = 'rgba(20, 213, 194, 0.3)';
export const AQUA_02 = 'rgba(20, 213, 194, 0.2)';
export const AQUA_015 = 'rgba(20, 213, 194, 0.15)';
export const AQUA_LIGHT = '#11bcaa';
export const TOPAZ = '#19cebb';
export const TOPAZ_02 = 'rgba(25, 206, 187, 0.2)';
export const DENIM = '#3d7481';
export const DENIM_DARK = '#196e6c';
export const DIRTY_BLUE = '#43909e';
export const DIRTY_BLUE_OPACITY = 'rgba(67, 144, 158, 0.1)';
export const DUSK_BLUE = '#247583';
export const DARK_GREY_BLUE = '#2d5668';
export const LIGHT_GREY_BLUE = '#336479';
export const LIGHT_GREY_BLUE_1 = 'rgba(45, 86, 104, 1)';
export const LIGHT_GREY_BLUE_07 = 'rgba(45, 86, 104, 0.7)';
export const LIGHT_GREY_BLUE_08 = 'rgba(45, 86, 104, 0.8)';
export const DARK_BLUE_GREY = '#152b35';
export const DARK_BLUE_GREY_2 = '#101D23';
export const DARK_BLUE_GREY_2_09 = 'rgba(16, 29, 35, 0.9)';
export const DARK_BLUE_GREY_3 = '#344548';
export const TURQUOISE_BLUE = '#0b9eae';
export const SILVER = '#d5dde1';
export const LIGHT_MUSTARD = '#f9d05d';
export const LIGHT_MUSTARD_02 = 'rgba(249, 208, 93, 0.2)';
export const LILAC = '#c583fa';
export const LILAC_02 = 'rgba(197, 131, 250, 0.2)';
export const TEAL = '#27baa7';
export const TEAL2 = '#00EEDB';
export const TEAL3 = '#19CEBB';
export const DARK_1 = '#101D23';
export const DARK_BLUE_GREY_2_TRANSPARENT = 'rgba(30, 59, 72, 0.7)';
export const DARK_BLUE_GREY_4 = '#274B5B';
export const DARK_BLUE_GREY_5 = '#1E3B47';
export const DARK_BLUE_GREY_6 = '#427389';
export const DARK_BLUE_GREY_7 = '#3A7087';
export const DARK_BLUE_GREY_8 = '#172D36';
export const DARK_BLUE_GREY_9 = '#152B35';

export const TEAL_GRADIENT = ['#00eedb', '#27baa7'];
export const TEAL_GRADIENT_DARK = ['#008077', '#1a766a'];
export const TURQUOISE_GRADIENT = ['#0b9eae', '#0baea5'];
export const SAND_GRADIENT = ['#e5a87f', '#ecbf98', SAND_2, '#e5a87f'];
export const SAND_GRADIENT_2 = [SAND_2, '#E3A67F'];
export const APRICOT_GRADIENT = ['#ffdd69', '#fbb566'];
export const TRANSPARENT_GRADIENT = ['transparent', 'transparent'];
export const DARK_GREY_BLUE_TRANSPARENT_GRADIENT = [DARK_GREY_BLUE + '00', DARK_GREY_BLUE];
export const DARK_TEAL_TRANSPARENT_GRADIENT_2 = [DARK_TEAL + '00', DARK_TEAL];
export const DARK_GREY_BLUE_60_GRADIENT = [DARK_BLUE_GREY + '66', DARK_BLUE_GREY + '66'];
export const GRADIENT_DISABLE_BUTTON_SETTINGS_LANGUAGE = ['#152b35', '#152b35'];
export const GRADIENT_DARK_GREY_ONE_COLOR = [DARK_BLUE_GREY_4, DARK_BLUE_GREY_4];
export const GRADIENT_DARK_GREY = ['#3B7D99', '#316175'];
export const SURVEY_MODAL_BACKGROUND = 'rgba(16, 29, 35, 0.8)';
export const LIGHT_GREY_BLUE_2 = '#1e586a';
export const LIGHT_BEIGE = 'rgba(250, 246, 232, 0.8)';
export const SEMI_BEIGE = 'rgba(250, 246, 232, 0.5)';
export const SEMI_LIGHT_BEIGE = 'rgba(250, 246, 232, 0.2)';
export const LIGHT_POWDER_BLUE = 'rgba(20, 213, 194, 0.1)';
export const REPORT_PROGRESS_BG = '#35637755';
export const OVERLAY_BACKGROUND = 'rgba(21, 43, 53, 0.97)';
export const OVERLAY_BACKGROUND_HEX = '#152B35';
export const LIGHT_BLUE_GREY_08 = 'rgba(21, 43, 53, 0.8)';
export const DARK_GREEN = '#101D23';
export const WHITE_005 = 'rgba(255, 255, 255, 0.05)';
export const WHITE_025 = 'rgba(255, 255, 255, 0.25)';
export const DARK_LAVENDER = '#2C3651';
export const ORANGE = '#FF994F';
export const BLUE = '#31B4F2';
export const BLUE_02 = 'rgba(49, 180, 242, 0.2)';
export const BLUE_08 = 'rgba(23, 45, 54, 0.8)';
export const COMPATIBILITY_BUTTON_GRADIENT = ['rgba(16, 29, 35, 0)', 'rgba(16, 29, 35, 0.65)', 'rgba(16, 29, 35, 0.9)'];
export const GRADIENT_AQUA_TO_PURPLE = ['#23FFE8', '#B861FF'];
export const GRADIENT_DARK_PURPLE_TO_DARK_GREEN = ['#484D75', '#1E3B48'];
export const GABLE_GREEN = '#12272D';
export const GRADIENT_BENEFITS_BORDER = ['#A4A18D', '#3E4543'];
export const GRADIENT_BENEFITS_BG = ['#37403F', '#101D23'];
export const GRADIENT_WELCOME_SECOND_BUTTON = ['rgba(20, 213, 194, 0.10)', 'rgba(20, 213, 194, 0.10)'];
