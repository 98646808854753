import { PROFILE_TYPES } from '../profile/types';

import { TYPES, State, Action } from './types';

const initialState: State = {
  rated: false,
  visibleRateUsThanksModal: false,
  trustPilotReviewLink: '',
  trustPilotPromoFreeMinutes: 0,
  likeCounter: 0,
  showedInCurrentSession: false,
  showedOnHome: false,
  rating: 0,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case TYPES.SET_RATE_US_THANKS_MODAL_VISIBILITY:
      return {
        ...state,
        visibleRateUsThanksModal: action.payload,
      };

    case TYPES.INCREMENT_RATE_US_LIKE_COUNTER:
      return {
        ...state,
        likeCounter: state.likeCounter + 1,
      };

    case TYPES.SET_SHOWED_IN_CURRENT_SESSION:
      return {
        ...state,
        showedInCurrentSession: true,
      };

    case TYPES.SET_SHOWED_ON_HOME:
      return {
        ...state,
        showedOnHome: true,
      };

    case TYPES.SET_TRUST_PILOT_REVIEW_LINK:
      return {
        ...state,
        trustPilotReviewLink: action.payload,
      };

    case TYPES.SET_TRUST_PILOT_PROMO_FREE_MINUTES:
      return {
        ...state,
        trustPilotPromoFreeMinutes: action.payload,
      };

    case TYPES.SET_RATED:
      return {
        ...state,
        rated: true,
      };

    case TYPES.SET_RATING:
      return {
        ...state,
        rating: action.payload,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...state,
        likeCounter: initialState.likeCounter,
      };

    default:
      return state;
  }
};
