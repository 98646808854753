import { TAROT_LAYOUT_IDS } from 'screens/tarot/constants';

import {
  State,
  ActionTypes,
  SET_PENDING,
  SET_CARDS,
  SET_CARDS_OF_THE_DAY_HISTORY,
  SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR,
  RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR,
  SET_CARDS_FETCH_DATE,
  SET_SELECTED_CARDS,
  SET_IMAGES_CACHED,
  RESET_CARDS,
  SET_FORCE_RELOAD,
} from './types';

const initialState: State = {
  cards: [],
  cardsOfTheDayHistory: [],
  cardsOfTheDayRandAcc: [],
  cardsFetchDate: null,
  imagesCached: false,
  selectedCards: {
    [TAROT_LAYOUT_IDS.CARD_OF_THE_DAY]: [],
    [TAROT_LAYOUT_IDS.NEAR_FUTURE]: [],
    [TAROT_LAYOUT_IDS.LOVE]: [],
    [TAROT_LAYOUT_IDS.YES_OR_NO]: [],
  },
  loading: false,
  forceReload: false,
};

export default (state: State = initialState, action: ActionTypes): State => {
  switch (action.type) {
    case SET_PENDING:
      return {
        ...state,
        loading: action.payload,
      };

    case SET_CARDS:
      return {
        ...state,
        cards: action.payload,
        loading: false,
      };

    case SET_CARDS_OF_THE_DAY_HISTORY:
      return {
        ...state,
        cardsOfTheDayHistory: action.payload,
        loading: false,
      };

    case SET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR:
      return {
        ...state,
        cardsOfTheDayRandAcc: [...state.cardsOfTheDayRandAcc, action.payload],
      };

    case RESET_CARDS_OF_THE_DAY_RANDOMIZATIONS_ACCUMULATOR:
      return {
        ...state,
        cardsOfTheDayRandAcc: [action.payload],
      };

    case SET_CARDS_FETCH_DATE:
      return {
        ...state,
        cardsFetchDate: action.payload,
        forceReload: false,
      };

    case SET_FORCE_RELOAD:
      return {
        ...state,
        forceReload: true,
      };

    case SET_SELECTED_CARDS:
      return {
        ...state,
        selectedCards: {
          ...state.selectedCards,
          [action.payload.layout]: action.payload.cards,
        },
        loading: false,
      };

    case SET_IMAGES_CACHED:
      return {
        ...state,
        imagesCached: true,
      };

    case RESET_CARDS:
      return initialState;

    default:
      return state;
  }
};
