import { Platform } from 'react-native';
import { CardStyleInterpolators } from '@react-navigation/stack';

import { TRANSPARENT, DARK_BLUE_GREY_2 } from './colors';

interface ScreenOptions {
  [key: string]: any;
}

export const DEFAULT_OPTIONS: ScreenOptions = {
  headerShown: false,
  gestureEnabled: false,
  cardStyle: { backgroundColor: DARK_BLUE_GREY_2, flex: 1 },
  cardOverlayEnabled: true,
  presentation: 'card',
  cardStyleInterpolator: Platform.select({
    ios: CardStyleInterpolators.forHorizontalIOS,
    android: CardStyleInterpolators.forRevealFromBottomAndroid,
    web: CardStyleInterpolators.forHorizontalIOS,
  }),
};

export const MODALS_DEFAULT_OPTIONS = {
  headerShown: false,
  cardStyle: {
    backgroundColor: TRANSPARENT,
  },
  cardOverlayEnabled: true,
  presentation: 'modal',
  cardStyleInterpolator: CardStyleInterpolators.forRevealFromBottomAndroid,
};
