import queryString from 'query-string';

import type { AstroCalendarDBDay, RetrogradeDBItem } from 'screens/astro-calendar/interfaces';

import api from '..';

import type {
  GetAstroCalendarDaysRequest,
  PostAstroCalendarDaysRequest,
  PutAstroCalendarDaysRequest,
  AddTimeRequest,
  AddTimeResponse,
} from './interfaces';

const ASTRO_CALENDAR_DAY = '/calendar/day';
const RETROGRADE = '/retrograde';
const ADD_TIME = `/astrology-questions/add-time`;

export const getAstroCalendarDays = async (data: GetAstroCalendarDaysRequest): Promise<AstroCalendarDBDay[]> => {
  try {
    const response = await api.fetch(`${ASTRO_CALENDAR_DAY}?${queryString.stringify(data)}`, {
      method: 'GET',
    });

    return response?.length ? response : [];
  } catch (err) {
    console.log('[ERROR GET ASTRO CALENDAR DAYS]', err);
    return [];
  }
};

export const postAstroCalendarDays = async (data: PostAstroCalendarDaysRequest): Promise<boolean> => {
  try {
    await api.fetch(ASTRO_CALENDAR_DAY, {
      method: 'POST',
      data,
    });

    return true;
  } catch (err) {
    console.log('[ERROR POST ASTRO CALENDAR DAYS]', err);
    return false;
  }
};

export const putAstroCalendarDays = async (data: PutAstroCalendarDaysRequest): Promise<boolean> => {
  try {
    await api.fetch(ASTRO_CALENDAR_DAY, {
      method: 'PUT',
      data,
    });

    return true;
  } catch (err) {
    console.log('[ERROR PUT ASTRO CALENDAR DAYS]', err);
    return false;
  }
};

let getRetrogradesRequestPromise: Promise<RetrogradeDBItem[]> | null = null;

export const getRetrogrades = async (): Promise<RetrogradeDBItem[]> => {
  try {
    if (getRetrogradesRequestPromise === null) {
      getRetrogradesRequestPromise = api.fetch(RETROGRADE, {
        method: 'GET',
      });
    }

    const response = await getRetrogradesRequestPromise;

    getRetrogradesRequestPromise = null;

    return response?.length ? response : [];
  } catch (err) {
    console.log('[ERROR GET RETROGRADES]', err);
    return [];
  }
};

// TODO: Replace this function with the same one from AS-6203 when both releases will be merged
export const addTime = async (data: AddTimeRequest): Promise<AddTimeResponse | null> => {
  try {
    const response = await api.fetch(ADD_TIME, {
      method: 'POST',
      data,
    });

    return 'code' in response ? response : null;
  } catch (err) {
    console.log('[ERROR ADD TIME]', err);
    return null;
  }
};
