import * as ROUTES from 'constants/routes';
import { ResetUserData } from 'store/astrologers/types';

export enum TYPES {
  SET_RATE_US_THANKS_MODAL_VISIBILITY = 'RATE_US/SET_RATE_US_THANKS_MODAL_VISIBILITY',
  INCREMENT_RATE_US_LIKE_COUNTER = 'RATE_US/INCREMENT_RATE_US_LIKE_COUNTER',
  SET_SHOWED_ON_HOME = 'RATE_US/SET_SHOWED_ON_HOME',
  SET_TRUST_PILOT_REVIEW_LINK = 'RATE_US/SET_TRUST_PILOT_REVIEW_LINK',
  SET_TRUST_PILOT_PROMO_FREE_MINUTES = 'RATE_US/SET_TRUST_PILOT_PROMO_FREE_MINUTES',
  SET_RATED = 'RATE_US/SET_RATED',
  SET_SHOWED_IN_CURRENT_SESSION = 'RATE_US/SET_SHOWED_IN_CURRENT_SESSION',
  SET_RATING = 'RATE_US/SET_RATING',
}

export interface State {
  rated: boolean;
  visibleRateUsThanksModal: boolean;
  likeCounter: number;
  trustPilotReviewLink: string;
  trustPilotPromoFreeMinutes: number;
  showedInCurrentSession: boolean;
  showedOnHome: boolean;
  rating: number;
}

interface SetTrustPilotReviewLink {
  type: typeof TYPES.SET_TRUST_PILOT_REVIEW_LINK;
  payload: string;
}

interface SetTrustPilotPromoSeconds {
  type: typeof TYPES.SET_TRUST_PILOT_PROMO_FREE_MINUTES;
  payload: number;
}

interface SetRateUsThanksModalVisibility {
  type: typeof TYPES.SET_RATE_US_THANKS_MODAL_VISIBILITY;
  payload: boolean;
}

interface IncrementRateUsLikeCounter {
  type: typeof TYPES.INCREMENT_RATE_US_LIKE_COUNTER;
}

interface SetShowedOnHome {
  type: typeof TYPES.SET_SHOWED_ON_HOME;
}

interface SetShowedInCurrentSession {
  type: typeof TYPES.SET_SHOWED_IN_CURRENT_SESSION;
}

interface SetRated {
  type: typeof TYPES.SET_RATED;
}

interface SetRating {
  type: typeof TYPES.SET_RATING;
  payload: number;
}

export type Action =
  | SetRateUsThanksModalVisibility
  | IncrementRateUsLikeCounter
  | SetShowedOnHome
  | SetShowedInCurrentSession
  | SetTrustPilotReviewLink
  | SetRated
  | SetRating
  | ResetUserData
  | SetTrustPilotPromoSeconds;

export enum FEATURES {
  PALM = 'palm',
  COMPATIBILITY = 'compatibility',
  BIRTH_CHART = 'birth_chart',
  BIORHYTHM = 'biorhythm',
  LUNAR = 'lunar',
  DATING = 'dating',
  TODAY_MATCHES = 'today_matches',
  HOROSCOPE = 'horoscope',
  TAROT = 'taro',
  DASHBOARD = 'dashboard',
  LIKES = 'likes',
  ONBOARDING = 'onboarding',
  SETTINGS = 'settings',
  ASTRO_CALENDAR = 'astro_calendar',
}

export const FEATURE_BY_ROUTE = {
  [ROUTES.PALM_READING_DAILY]: FEATURES.PALM,
  [ROUTES.COMPATIBILITY_RESULT]: FEATURES.COMPATIBILITY,
  [ROUTES.BIRTH_CHART_PLANET_INFO]: FEATURES.BIRTH_CHART,
  [ROUTES.BIRTH_CHART_TRANSIT_DETAILS]: FEATURES.BIRTH_CHART,
  [ROUTES.BIORHYTHM]: FEATURES.BIORHYTHM,
  [ROUTES.LUNAR_CALENDAR]: FEATURES.LUNAR,
  [ROUTES.DATING_CALENDAR]: FEATURES.DATING,
  [ROUTES.HOROSCOPE]: FEATURES.HOROSCOPE,
  [ROUTES.TODAY_MATCHES]: FEATURES.TODAY_MATCHES,
  [ROUTES.TAROT_CALENDAR]: FEATURES.TAROT,
  [ROUTES.TAROT_YES_OR_NO]: FEATURES.TAROT,
  [ROUTES.TAROT_CARD_OF_THE_DAY]: FEATURES.TAROT,
  [ROUTES.TAROT_CARDS_DESCRIPTION]: FEATURES.TAROT,
};
