import { RefObject } from 'react';
import Animated from 'react-native-reanimated';
import { View } from 'react-native';
import { find, flattenDeep, sortBy, times } from 'lodash';
import { paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';
import dayjs from 'dayjs';

import { windowHeight, windowWidth } from 'constants/sizes';

import { CardCoords, CardPlaceHolderIds, CardSize, CardsLayoutItem, DescriptionOption, DescriptionLayout } from './types';
import {
  CARDS_LAYOUTS,
  DEFAULT_CARD_HEIGHT,
  DEFAULT_CARD_PLACEHOLDER_HEIGHT,
  DEFAULT_CARD_PLACEHOLDER_WIDTH,
  DEFAULT_CARD_WIDTH,
  DEFAULT_OPEN_CARD_HEIGHT,
  DEFAULT_OPEN_CARD_WIDTH,
  DESCRIPTION_CATEGORY_IDS,
  IS_SMALL_SCREEN,
  TAROT_LAYOUT_IDS,
} from './constants';

export const getCardsLayoutData = (layout: TAROT_LAYOUT_IDS): CardsLayoutItem => {
  return find(CARDS_LAYOUTS, { id: layout }) ?? CARDS_LAYOUTS[0];
};

export const getInitialData = (layoutScheme: CardPlaceHolderIds[][], value: null | false): { [key: number]: typeof value } => {
  const count = flattenDeep(layoutScheme).length;
  return times(count).reduce((acc, item) => {
    acc[item + 1] = value;
    return acc;
  }, {});
};

export const getCardSizes = (layoutScheme: CardPlaceHolderIds[][], withScaling: boolean): CardSize => {
  let height = withScaling ? DEFAULT_OPEN_CARD_HEIGHT : DEFAULT_CARD_PLACEHOLDER_HEIGHT;
  let width = withScaling ? DEFAULT_OPEN_CARD_WIDTH : DEFAULT_CARD_PLACEHOLDER_WIDTH;

  const rowCount = layoutScheme.length;

  if (rowCount > 1) {
    const maxCountItemsInRow = sortBy(layoutScheme, row => row.length).reverse()[0].length;
    if (maxCountItemsInRow > 3) {
      width = (windowWidth - paddingHorizontal(maxCountItemsInRow * 5 + (IS_SMALL_SCREEN ? 75 : 40))) / maxCountItemsInRow;
      height = width * (DEFAULT_CARD_PLACEHOLDER_HEIGHT / DEFAULT_CARD_PLACEHOLDER_WIDTH);
    } else {
      const maxAvailableHeight = windowHeight * (withScaling ? 0.7 : 0.5);
      height = (maxAvailableHeight - paddingVertical(rowCount * 5 + 20)) / rowCount;
      width = height * (DEFAULT_CARD_PLACEHOLDER_WIDTH / DEFAULT_CARD_PLACEHOLDER_HEIGHT);
    }
  }

  return { height, width };
};

export const getGlobalPosition = async (ref: RefObject<Animated.View | View>): Promise<CardCoords> =>
  new Promise(resolve => {
    ref.current?.measure((_x, _y, _width, _height, pageX, pageY) => {
      resolve({
        pageX,
        pageY,
      });
    });
  });

export const getCirclePosition = (r: number) => ({
  position: 'absolute' as const,
  width: 2 * r,
  height: 2 * r,
  borderRadius: r,
  left: -r + windowWidth / 2,
  bottom: -r * 2 + DEFAULT_CARD_HEIGHT + paddingVertical(65),
});

export const getCardPosition = (r: number, angle: number) => ({
  position: 'absolute' as const,
  width: DEFAULT_CARD_WIDTH,
  height: 2 * r,
  top: 0,
  bottom: 0,
  left: r - DEFAULT_CARD_WIDTH / 2,
  transform: [
    {
      rotateZ: `${angle}deg`,
    },
  ],
});

export const getDescriptionOptionsTemplate = (layout: DescriptionLayout, category: DESCRIPTION_CATEGORY_IDS): DescriptionOption[] => {
  switch (layout) {
    case TAROT_LAYOUT_IDS.NEAR_FUTURE:
      return [
        {
          id: 'overview',
          title: 'OVERVIEW',
          icon: null,
        },
        {
          id: `${category}`,
          title: null,
          icon: null,
        },
        {
          id: `${category}_recommendation`,
          title: 'RECOMMENDATION',
          icon: 'star',
        },
        {
          id: `${category}_warning`,
          title: 'WARNING',
          icon: 'warning',
        },
      ];
    case TAROT_LAYOUT_IDS.LOVE:
      return [
        {
          id: `love_reading_${category}`,
          title: `LOVE_${category.toUpperCase()}`,
          icon: null,
        },
      ];
  }
};

export const isTodayDate = (date?: string): boolean => dayjs().isSame(date, 'day');
