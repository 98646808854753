export enum WEB_APP_TYPES {
  ASTROLINE = 'astroline',
  ATRIAL = 'atria',
}

export enum BROWSERS_TYPES {
  IOS_SAFARI = 'IOS_SAFARI',
  IOS_CHROME = 'IOS_CHROME',
  IOS_OTHERS = 'IOS_OTHERS',
  ANDROID_SAMSUNG = 'ANDROID_SAMSUNG',
  ANDROID_CHROME = 'ANDROID_CHROME',
  ANDROID_OTHERS = 'ANDROID_OTHERS',
}

export enum BROWSERS_NAMES {
  CHROME = 'Chrome',
  SAMSUNG_BROWSER = 'Samsung Browser',
  IOS_MOBILE_SAFARI = 'Mobile Safari',
  IOS_SAFARI = 'Safari',
  EDGE = 'Edge',
  GSA = 'GSA',
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  OPERA = 'Opera',
  WEBKIT = 'WebKit',
  FIREFOX = 'Firefox',
  UNKNOWN = 'Unknown',
}

export const ALLOWED_BROWSERS = [BROWSERS_NAMES.IOS_MOBILE_SAFARI, BROWSERS_NAMES.IOS_SAFARI, BROWSERS_NAMES.CHROME, BROWSERS_NAMES.SAMSUNG_BROWSER];
export const ALLOWED_BROWSER_TYPES = [
  BROWSERS_TYPES.IOS_SAFARI,
  BROWSERS_TYPES.IOS_CHROME,
  BROWSERS_TYPES.ANDROID_CHROME,
  BROWSERS_TYPES.ANDROID_SAMSUNG,
];
