export enum TYPES {
  SET = 'MOON_PHASES/SET',
}

export interface State {
  phase: string;
}

interface Set {
  type: typeof TYPES.SET;
  payload: string;
}

export type ActionTypes = Set;
