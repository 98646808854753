import PLANETS from '../../../assets/data/planets.json';

import { TYPES, State, ActionTypes, Planet } from './types';

const initialState: State = {
  retrogradePlanets: [],
  planets: PLANETS as unknown as Planet[],
};

export default function (state = initialState, action: ActionTypes): State {
  switch (action.type) {
    case TYPES.SET:
      return {
        ...state,
        retrogradePlanets: action.payload,
      };
    default:
      return state;
  }
}
