import { PROFILE_TYPES } from '../profile/types';

export enum TYPES {
  SET = 'WIDGETS/SET',
  SHOWN = 'WIDGETS/SHOWN',
  RESET_VISITED = 'WIDGETS/RESET_VISITED',
}

export interface State {
  widgets: string[];
  visited: string[];
  visitedOn: null | Date;
  seed: null | number;
}

interface Set {
  type: typeof TYPES.SET;
  payload: {
    widgets: string[];
    seed: number;
  };
}

interface SetShown {
  type: typeof TYPES.SHOWN;
  payload: {
    widget: string;
  };
}

interface ResetVisited {
  type: typeof TYPES.RESET_VISITED;
}

interface ResetUserData {
  type: typeof PROFILE_TYPES.RESET_USER_DATA;
}

export type ActionTypes = Set | SetShown | ResetUserData | ResetVisited;
