import React, { useMemo, useEffect, useState } from 'react';
import type { FC, CSSProperties } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, ActivityIndicator } from 'react-native';
import queryString from 'query-string';

import { useAppSelector } from 'store';
import Iframe from 'components/web/iframe';
import { ASB_URL } from 'constants/general';
import * as COLORS from 'constants/colors';
import { PAYMENT_METHODS } from 'modules/payments/constants';
import useMessageListener from 'hooks/use-message-listener';

import { type Product } from '../../../../interfaces/product';

interface Props {
  product: Product;
  metadata?: {
    [key: string]: any;
  };
  productTrigger?: string;
  idfm: string;
  onSuccess: (method: PAYMENT_METHODS, transactionDetails: any) => void;
  style?: StyleProp<ViewStyle>;
}

const PayPalIframeButton: FC<Props> = ({ product, idfm, productTrigger, metadata, onSuccess }) => {
  const [isLoading, setLoading] = useState(true);
  const webMode = useAppSelector(state => state.app.webMode);
  const email = useAppSelector(state => state.profile.profileData?.email);

  const handleLoaded = () => {
    setLoading(false);
  };

  useMessageListener({
    source: 'one-time-payment-paypal',
    method: PAYMENT_METHODS.PAYPAL,
    onSuccess,
    onLoaded: handleLoaded,
  });

  useEffect(() => {
    const iframe = document.getElementById('astrologersOneTimeIframe');

    if (iframe) {
      //@ts-ignore
      iframe?.contentWindow?.postMessage('openModal', '*');
    }
  }, []);

  const url = useMemo(() => {
    const query = {
      amount: product.price,
      currency: product.currency,
      idfm,
      metadata: JSON.stringify({
        ...metadata,
      }),
      product: product.id,
      product_trigger: productTrigger,
      email,
      mode: webMode,
    };

    const uri = queryString.stringifyUrl({
      url: `${ASB_URL}/paypal` as unknown as string,
      query,
    });

    return uri;
  }, [metadata, email, idfm, product, webMode, productTrigger]);

  const handleClose = () => {};

  return (
    <View style={styles.wrap}>
      <Iframe id="astrologersPayOneTimeIframe" src={url} style={iframeStyles} onError={handleClose} />
      {isLoading && (
        <View style={styles.loader}>
          <ActivityIndicator size="large" color={COLORS.TEAL} />
        </View>
      )}
    </View>
  );
};

export default PayPalIframeButton;

const iframeStyles: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  border: 'none',
  backgroundColor: COLORS.TRANSPARENT,
};

const styles = StyleSheet.create({
  wrap: {
    position: 'relative',
    width: '100%',
    height: 55,
    overflow: 'hidden',
    borderRadius: 50,
    alignItems: 'center',
    backgroundColor: COLORS.OVERLAY_BACKGROUND,
    justifyContent: 'center',
  },
  loader: {
    ...StyleSheet.absoluteFillObject,
    width: '100%',
    height: '100%',
    zIndex: 99,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.OVERLAY_BACKGROUND,
  },
});
