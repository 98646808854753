import api from 'api';

import { ADD_TIME } from './constants';

const addPromocode = async (code: string): Promise<{ code: string; balance: number; seconds: number } | undefined> => {
  return api.fetch(ADD_TIME, {
    method: 'POST',
    data: {
      code,
    },
  });
};

export default addPromocode;
