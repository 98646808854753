import api from 'api';

import { Astrologist, PING_CHAT } from './constants';

const pingChat = async (id: Astrologist['astrologer_id']): Promise<any> => {
  try {
    const response = await api.fetch(PING_CHAT, {
      method: 'POST',
      data: {
        astrologer_id: id,
      },
    });
    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] pingChat', error);
    return [];
  }
};

export default pingChat;
