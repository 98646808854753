export type Priorities = {
  [key in string]: number;
}

export const orderByPriority = (arr: any[], key: string, priorities: Priorities) => {
  return arr.sort((a, b) => {
    const important_a = priorities[a[key]];
    const important_b = priorities[b[key]];
    let ret;

    if (important_a && !important_b) {
      ret = -1;
    } else if (important_b && !important_a) {
      ret = 1;
    } else if (important_a && important_b) {
      ret = important_a - important_b;
    } else {
      ret = 0;
    }

    return ret;
  });
};