import React, { memo, useRef } from 'react';
import type { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import type { StyleProp, ViewStyle } from 'react-native';
import { Defs, LinearGradient, Path, Stop, Svg } from 'react-native-svg';
import _ from 'lodash';

import icons from './icons';
import { GradientPreset, IconName } from './model';
import { gradientPresets } from './presets';

interface Props {
  name: IconName;
  color?: string;
  gradientPreset?: GradientPreset;
  size?: number;
  style?: StyleProp<ViewStyle>;
}

const Icon: FC<Props> = ({ color, name, size, gradientPreset, style }) => {
  const id = useRef(`${name}-gradient-${_.uniqueId()}`);

  if (icons[name]) {
    const { h, path, w } = icons[name];
    const gradient = gradientPreset ? gradientPresets[gradientPreset] : null;

    return (
      <View
        style={[
          styles.wr,
          {
            width: size,
            height: size,
          },
          style,
        ]}>
        <Svg
          viewBox={`0 0 ${w} ${h}`}
          style={{
            width: size,
            height: size,
          }}>
          {gradient && (
            <Defs>
              <LinearGradient id={id.current} x1="0" y1="0" x2="1" y2="50%">
                {gradient.colors?.map((stopColor, i) => (
                  <Stop key={`${stopColor}_${i}`} offset={gradient.locations?.[i] ?? 1} stopColor={stopColor} />
                ))}
              </LinearGradient>
            </Defs>
          )}

          {path && path.length
            ? path.map((item, i) => <Path fill={gradient ? `url(#${id.current})` : color || item.color} d={item.d} key={i} />)
            : null}
        </Svg>
      </View>
    );
  } else {
    return <Text>NA</Text>;
  }
};

const styles = StyleSheet.create({
  wr: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default memo(Icon);
