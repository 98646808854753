import api from 'api';

import { CHARGE_TIME, ChargeTime } from './constants';

const chargeTime = async (seconds: number, chatId: number): Promise<ChargeTime> => {
  try {
    const response = await api.fetch(CHARGE_TIME, {
      method: 'POST',
      data: {
        seconds,
        chatId,
      },
    });

    return response;
  } catch (error) {
    console.log('ERROR [Astrologist chat] chargeTime', error);
    return { balance: 0, freeBalance: 0 };
  }
};

export default chargeTime;
