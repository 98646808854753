import { createAction } from 'redux-actions';

import { AppDispatch } from 'store';
import { getDatingCalendar, getDatingWidgets } from 'api/calendars';

import { TYPES } from './types';

const setDating = createAction(TYPES.SET);
const setWidgets = createAction(TYPES.SET_WIDGETS);

export const initDatingCalendar = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const dates = await getDatingCalendar();
      dispatch(setDating(dates));
    } catch (error) {
      console.log('[ERROR DATING CALENDAR]: ', error);
    }
  };
};

export const initDatingWidgets = () => {
  return async (dispatch: AppDispatch) => {
    try {
      const widgets = await getDatingWidgets();
      dispatch(setWidgets(widgets));
    } catch (error) {
      console.log('[ERROR DATING CALENDAR WIDGET]: ', error);
    }
  };
};
