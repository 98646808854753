import { ElementType } from 'react';

import BannerBig from '../banner-big';
import BannerSmall from '../banner-small';

export enum ASTROLOGERS_PROMO_SIZES {
  BIG = 'big',
  SMALL = 'small',
  DEFAULT = 'default',
}

type AstrologerPromoComponents = {
  [key in ASTROLOGERS_PROMO_SIZES]: ElementType;
};

const ASTROLOGERS_PROMO_COMPONENTS_MAP: AstrologerPromoComponents = {
  [ASTROLOGERS_PROMO_SIZES.SMALL]: BannerSmall,
  [ASTROLOGERS_PROMO_SIZES.BIG]: BannerBig,
  [ASTROLOGERS_PROMO_SIZES.DEFAULT]: BannerBig,
};

export default ASTROLOGERS_PROMO_COMPONENTS_MAP;
