import { createAction } from 'redux-actions';
import dayjs from 'dayjs';

import { AppDispatch } from 'store';
import { getTodayMatches } from 'api/todays-features';
import { TODAY_MATCHES_IDS } from 'api/todays-features/interfaces';

import { TYPES, Matches } from './types';

const setMatches = createAction(TYPES.SET_MATCHES);

export const loadTodaysMatches = () => {
  return async (dispatch: AppDispatch) => {
    const todayMatches: Matches[] = [];
    const matches = await getTodayMatches();

    if (matches) {
      const keysMatches = Object.keys(matches) as TODAY_MATCHES_IDS[];

      keysMatches.forEach(matchId => {
        const bestToday = matches?.[matchId]?.best_today;
        const worstToday = matches?.[matchId]?.worst_today;

        todayMatches.push({ tab: matchId, bestToday, worstToday });
      });

      dispatch(
        setMatches({
          todayMatches,
          matchesUpdateDate: dayjs().format(),
        }),
      );
    }
  };
};
